const initialState = {
  statusLoading: false,
};

const App = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, statusLoading: action.payload.statusLoading };
    case "APP_RESET":
      return initialState;
    default:
      return state;
  }
};

export default App;
