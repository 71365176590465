import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useNotification = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listNotification = useSelector((state) => state.notification.index);
  const dataNotification = useSelector((state) => state.notification.data);

  function setIndex(index) {
    dispatch({
      type: "NOTIFICATION_INDEX",
      payload: { index: index },
    });
  }

  function setDataNotification(data) {
    dispatch({
      type: "NOTIFICATION_DATA",
      payload: { data: data },
    });
  }

  function resetNotification() {
    dispatch({
      type: "NOTIFICATION_RESET",
    });
  }

  const indexNotification = () => {
    api
      .get("/notification", token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const getNotification = (id) => {
    api
      .get(`/notification/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataNotification(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const updateNotification = (id, params, success, error) => {
    api
      .update(`/notification/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const readNotification = (id, params, success, error) => {
    api
      .update(`/notification/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    getNotification,
    indexNotification,
    updateNotification,
    readNotification,
    resetNotification,
    listNotification,
    dataNotification,
  };
};

export default useNotification;
