import useReport from "../../../Hooks/useReport";

import Filters from "./Filters";
import Leads from "./Leads";
import Appointments from "./Appointments";
import Evaluations from "./Evaluations";
import NewClients from "./NewClients";
import Ads from "./Ads";
import Sources from "./Sources";
import SourcesNewClient from "./SourcesNewClient";

import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { useState } from "react";
import { format } from "date-fns";

const DashboardProspects = () => {
  const {
    indexLeadsByDate,
    indexAppoitmentsByDate,
    indexEvaluationsByDate,
    indexNewClientsByDate,
    indexAdsByDate,
    indexSourcesByDate,
    indexSourcesNewClientByDate,
  } = useReport();

  const [indexLeads, setIndexLeads] = useState();
  const [indexOldLeads, setIndexOldLeads] = useState();
  const [indexAppoitments, setIndexAppoitments] = useState();
  const [indexEvaluations, setIndexEvaluations] = useState();
  const [indexNewClients, setIndexNewClients] = useState();
  const [indexAds, setIndexAds] = useState();
  const [indexSources, setIndexSources] = useState();
  const [indexSourcesNewClient, setIndexSourcesNewClient] = useState();

  const handleFilter = (params) => {
    const { start_date, end_date, company } = params;
    let old_start = new Date(start_date);
    let old_end = new Date(end_date);

    old_start = new Date(
      old_start.getFullYear(),
      old_start.getMonth() - 1,
      old_start.getDate()
    );
    old_end = new Date(
      old_end.getFullYear(),
      old_end.getMonth() - 1,
      old_end.getDate()
    );

    let old = {
      start_date: format(old_start, "yyyy-MM-dd 00:00:00"),
      end_date: format(old_end, "yyyy-MM-dd 23:59:59"),
      company: company,
    };

    indexLeadsByDate(params, setIndexLeads);
    indexLeadsByDate(old, setIndexOldLeads);
    indexAppoitmentsByDate(params, setIndexAppoitments);
    indexEvaluationsByDate(params, setIndexEvaluations);
    indexNewClientsByDate(params, setIndexNewClients);
    indexAdsByDate(params, setIndexAds);
    indexSourcesByDate(params, setIndexSources);
    indexSourcesNewClientByDate(params, setIndexSourcesNewClient);
  };

  return (
    <ContainerFlex padding="3">
      <h1>Dashboard</h1>
      <Row marginTop="3">
        <Column col="12">
          <Filters filter={handleFilter} />
        </Column>
      </Row>
      <Row marginTop="3">
        <Column col="12" md="3">
          <Leads list={indexLeads} old={indexOldLeads} />
        </Column>
        <Column col="12" md="3">
          <Appointments list={indexAppoitments} />
        </Column>
        <Column col="12" md="3">
          <Evaluations list={indexEvaluations} />
        </Column>
        <Column col="12" md="3">
          <NewClients list={indexNewClients} />
        </Column>
      </Row>
      <Row marginTop="3">
        <Column col="12" md="5">
          <Ads list={indexAds} />
        </Column>
        <Column col="12" md="4">
          <Sources list={indexSources} />
        </Column>
        <Column col="12" md="3">
          <SourcesNewClient list={indexSourcesNewClient} />
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default DashboardProspects;
