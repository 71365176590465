import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import useError from "./useError";
import api from "../Data/api";

const useProspect = () => {
  const { token } = useAuth();
  const { setAddError } = useError();

  const dispatch = useDispatch();

  const activeProspectId = useSelector((state) => state.prospect.id);
  const activeProspectAction = useSelector((state) => state.prospect.action);
  const listProspect = useSelector((state) => state.prospect.index);
  const listProspectStatus = useSelector(
    (state) => state.prospect.index_status
  );
  const listHistoryType = useSelector((state) => state.lead.indexHistoryType);
  const dataProspect = useSelector((state) => state.prospect.data);
  const dataProspectStatus = useSelector((state) => state.prospect.data_status);

  function setIndexProspect(index) {
    let statusWithProspects = [];

    statusWithProspects = listProspectStatus.data.map((propStatus) => {
      let prospect = [];
      index.data.forEach((data) => {
        if (propStatus.id === data.status.id && propStatus.show) {
          prospect.push(data);
        }
      });
      return { ...propStatus, prospect };
    });

    dispatch({
      type: "CONTENT_PROSPECT_INDEX",
      payload: { index: statusWithProspects },
    });
  }

  function setIndexStatus(index) {
    dispatch({
      type: "CONTENT_PROSPECT_INDEX_STATUS",
      payload: { index_status: index },
    });
  }

  function setIndexLeadHistoryType(index) {
    dispatch({
      type: "CONTENT_LEAD_HISTOTY_TYPE_INDEX",
      payload: { indexHistoryType: index },
    });
  }

  function setActiveProspectId(id) {
    dispatch({
      type: "CONTENT_PROSPECT_ID",
      payload: { id: id },
    });
  }

  function setActiveProspectAction(id) {
    dispatch({
      type: "CONTENT_PROSPECT_ACTION",
      payload: { action: id },
    });
  }

  function setDataProspectStatus(id) {
    dispatch({
      type: "CONTENT_PROSPECT_DATA_STATUS",
      payload: { data_status: id },
    });
  }

  function setDataProspect(data) {
    dispatch({
      type: "CONTENT_PROSPECT_DATA",
      payload: { data: data },
    });
  }

  function resetProspect() {
    dispatch({
      type: "CONTENT_PROSPECT_RESET",
    });
  }

  const indexLeadHistoryType = () => {
    api
      .get("/lead/history/status", token)
      .then((response) => {
        if (response.status === 200) {
          setIndexLeadHistoryType(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        setAddError(err.response);
      });
  };

  const indexProspect = () => {
    api
      .get(`prospect`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndexProspect(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        setAddError(err.response);
      });
  };

  const indexProspectFilter = (filters, success) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`prospect?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        }
      })
      .catch((err) => {
        setAddError(err.response);
      });
  };

  const indexConversions = (filters, success) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`prospect/conversion?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        }
      })
      .catch((err) => {
        setAddError(err.response);
      });
  };

  const indexProspectStatus = (filters, success) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`prospect/status?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndexStatus(response.data);
          success(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        setAddError(err.response);
      });
  };

  const getProspect = (id, success) => {
    api
      .get(`prospect/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataProspect(response.data.data);
          success(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        setAddError(err.response);
      });
  };

  const postProspect = (params, success) => {
    api
      .post("/prospect", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response);
        }
      })
      .catch((err) => {
        setAddError(err.response);
      });
  };

  const updateProspect = (id, params, success) => {
    api
      .update(`prospect/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response);
        }
      })
      .catch((err) => {
        setAddError(err.response);
      });
  };

  const delProspect = (id, success) => {
    api
      .delete(`prospect/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        setAddError(err.response);
      });
  };

  return {
    getProspect,
    postProspect,
    activeProspectId,
    activeProspectAction,
    indexProspect,
    indexConversions,
    indexProspectFilter,
    indexProspectStatus,
    indexLeadHistoryType,
    updateProspect,
    delProspect,
    resetProspect,
    listProspect,
    listProspectStatus,
    listHistoryType,
    dataProspect,
    dataProspectStatus,
    setIndexProspect,
    setDataProspect,
    setDataProspectStatus,
    setActiveProspectId,
    setActiveProspectAction,
  };
};

export default useProspect;
