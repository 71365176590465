import LeadTable from "./LeadTable";

import { ContainerFlex } from "../../Components/Layout";
import { Text } from "../../Components/Text";

import { Card, CardBody, CardHeader } from "../../Components/Card";
import Filters from "./Filters";
import { Button, ButtonModal } from "../../Components/Button";

const Content = () => {
  return (
    <ContainerFlex full padding="3" overflow>
      <Filters />
      <Card marginTop="3" padding="2">
        <CardHeader between middle>
          <Text className="h5" margin="0">
            Lista de Contatos
          </Text>
          <ContainerFlex row>
            <Button
              // onClick={handleReload}
              modal="Campaign_Modal"
              id="btn-reload-campaign"
            >
              <Text tag="i" className="fa fa-redo-alt" />
            </Button>
            <ButtonModal modal="Campaign_Modal">
              <Text tag="i" className="fa fa-plus" />
            </ButtonModal>
          </ContainerFlex>
        </CardHeader>
        <CardBody>
          <LeadTable />
        </CardBody>
      </Card>
    </ContainerFlex>
  );
};

export default Content;
