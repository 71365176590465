import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import useUser from "../../Hooks/useUser";

import { Text } from "../../Components/Text";
import { ContainerFlex } from "../../Components/Layout";
import { Card, CardBody, CardHeader } from "../../Components/Card";
import UsersTable from "./Table";
import { Button } from "../../Components/Button";

const Users = () => {
  const { indexUser, listUser } = useUser();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    indexUser({}, handleSuccess, handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listUser && listUser.data) {
      setUsers(listUser.data);
      setLoading(false);
    }
  }, [listUser]);

  const handleSuccess = (data) => {
    console.log(data);
    setUsers(data);
    setLoading(false);
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const handleUsers = () => {
    if (users && users.length > 0) {
      // return users.map((data, index) => <Item key={index} item={data}></Item>);
      return <UsersTable userslist={users} />;
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum item foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <ContainerFlex full padding="3">
      <Card padding="3" className="shadow">
        <CardHeader between>
          <Text className="h3 m-0 p-0" middle>
            Gestão de Usuários
          </Text>
          <Button outline="primary" size="sm" middle>
            <Text middle>Cadastrar</Text>
          </Button>
        </CardHeader>
        <CardBody>
          <ContainerFlex className="accordion accordion-flush">
            {loading ? (
              <ContainerFlex full middle center column>
                <ReactLoading type={"spin"} color={"#1c208c"} />
                <Text>Carregando...</Text>
              </ContainerFlex>
            ) : (
              handleUsers()
            )}
          </ContainerFlex>
        </CardBody>
      </Card>
    </ContainerFlex>
  );
};

export default Users;
