import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const usePatient = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listPatient = useSelector((state) => state.patient.index);
  const dataPatient = useSelector((state) => state.patient.data);

  function setIndex(index) {
    dispatch({
      type: "PATIENT_INDEX",
      payload: { index: index },
    });
  }

  function setDataPatient(data) {
    dispatch({
      type: "PATIENT_DATA",
      payload: { data: data },
    });
  }

  function resetPatient(data) {
    dispatch({
      type: "PATIENT_RESET",
    });
  }

  const indexPatient = () => {
    api
      .get("/patient", token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPatient = (id) => {
    api
      .get(`/patient/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataPatient(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const postPatient = (params, success, error) => {
    api
      .post("/patient", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataPatient(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const postLeadPatient = (params, success, error) => {
    api
      .post("/lead/patient", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updatePatient = (id, params, success, error) => {
    api
      .update(`/patient/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataPatient(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delPatient = (id, success, error) => {
    api
      .delete(token, id)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    getPatient,
    postPatient,
    postLeadPatient,
    indexPatient,
    updatePatient,
    delPatient,
    resetPatient,
    listPatient,
    dataPatient,
    setDataPatient,
  };
};

export default usePatient;
