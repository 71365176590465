import { useDispatch, useSelector } from "react-redux";

import useAuth from "../useAuth";
import api from "../../Data/api";

const usePriority = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listPriority = useSelector((state) => state.supportpriority.index);
  const dataPriority = useSelector((state) => state.supportpriority.data);

  function setIndex(index) {
    dispatch({
      type: "SUPPORT_PRIORITY_INDEX",
      payload: { index: index },
    });
  }

  function setDataPriority(data) {
    dispatch({
      type: "SUPPORT_PRIORITY_DATA",
      payload: { data: data },
    });
  }

  function resetPriority() {
    dispatch({
      type: "SUPPORT_PRIORITY_RESET",
    });
  }

  const indexPriority = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/support/priority?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPriority = (id) => {
    api
      .get(`/support/priority/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataPriority(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const postPriority = (params, success, error) => {
    api
      .post("/support/priority", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updatePriority = (id, params, success, error) => {
    api
      .update(`/support/priority/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delPriority = (id, success, error) => {
    api
      .delete(`/support/priority/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    getPriority,
    postPriority,
    indexPriority,
    updatePriority,
    delPriority,
    resetPriority,
    listPriority,
    dataPriority,
    setDataPriority,
  };
};

export default usePriority;
