import instance from "./config";

const api = {
  get: (url, token) =>
    instance({
      method: "GET",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  post: (url, token, data) =>
    instance({
      method: "POST",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    }),

  update: (url, token, data) =>
    instance({
      method: "PUT",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    }),

  delete: (url, token) =>
    instance({
      method: "DELETE",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default api;
