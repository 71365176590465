import useAd from "../../../Hooks/useAd";

import { Row, Column } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { ButtonModal } from "../../../Components/Button";
import { Card } from "../../../Components/Card";

// import image from "../../../Assets/images/noimage.png";

const Item = ({ item }) => {
  const { getAd } = useAd();

  let Active, Color;
  if (item.is_active) {
    Active = "Ativo";
    Color = "success";
  } else {
    Active = "Inativo";
    Color = "danger";
  }

  const handleEdit = () => {
    getAd(item.id);
  };

  return (
    <Card
      marginTopBottom="3"
      padding="1"
      rounded
      bgColor="white"
      className="shadow-box"
    >
      <Row middle>
        <Column col="4" row middle>
          <Text marginLeft="2" tag="h6" margin="0">
            {item.name}
          </Text>
        </Column>
        <Column col="4" row middle>
          <Text marginLeft="2" tag="h6" margin="0">
            {item.tag}
          </Text>
        </Column>
        <Column col="2" center>
          <Text rounded bgColor={Color} paddingLeftRight="3" txtColor="white">
            {Active}
          </Text>
        </Column>
        <Column col="2" center>
          {/* <Button bgColor="danger" txtColor="white" marginLeftRight="2">
            <Text tag="i" className="fa fa-trash" />
          </Button> */}
          <ButtonModal
            bgColor="info"
            txtColor="white"
            onClick={handleEdit}
            modal="Ad_Modal"
          >
            <Text tag="i" className="fa fa-pen" /> Editar
          </ButtonModal>
        </Column>
      </Row>
    </Card>
  );
};

export default Item;
