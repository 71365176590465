import PropTypes from "prop-types";

export function overflowProps() {
  return {
    overflow: PropTypes.oneOf([
      "auto",
      "hidden",
      "scroll",
      "visible",
      true,
      false,
    ]),
  };
}

export function overflowClass(obj) {
  let className = "";
  let { overflow, ...attributes } = obj;

  if (typeof overflow === "boolean") {
    if (overflow) className += `overflow-auto`;
  } else {
    if (overflow) className += `overflow-${overflow}`;
  }

  return [className, attributes];
}
