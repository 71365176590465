import React from "react";
import PropTypes from "prop-types";

import { colorsProps, colorsClass } from "../utilities/colors";
import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";
import { bordersProps, bordersClass } from "../utilities/borders";
import { overflowProps, overflowClass } from "../utilities/overflow";
import { flexProps, flexClass } from "../utilities/flex";
import cn from "classnames";

const Col = (props) => {
  let {
    tag: Tag,
    children,
    className,
    col,
    sm,
    md,
    lg,
    xl,
    xxl,
    ...attributes
  } = props;

  let colors, sizing, spacing, borders, overflow, flex;
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);
  [overflow, attributes] = overflowClass(attributes);
  [flex, attributes] = flexClass(attributes);

  if (col === true) {
    className += " col ";
  } else if (col) {
    className += ` col-${col} `;
  }

  if (sm) {
    className += ` col-sm-${sm} `;
  }

  if (md) {
    className += ` col-md-${md} `;
  }

  if (lg) {
    className += ` col-lg-${lg} `;
  }

  if (xl) {
    className += ` col-xl-${xl} `;
  }

  if (xxl) {
    className += ` col-xxl-${xxl} `;
  }

  return (
    <Tag
      {...attributes}
      className={cn(
        className,
        flex,
        colors,
        sizing,
        spacing,
        borders,
        overflow
      )}
    >
      {children}
    </Tag>
  );
};

const sizes = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  true,
];

Col.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  col: PropTypes.oneOf([...sizes]),
  sm: PropTypes.oneOf([...sizes]),
  md: PropTypes.oneOf([...sizes]),
  lg: PropTypes.oneOf([...sizes]),
  xl: PropTypes.oneOf([...sizes]),
  xxl: PropTypes.oneOf([...sizes]),
  ...sizingProps(),
  ...colorsProps(),
  ...spacingProps(),
  ...bordersProps(),
  ...overflowProps(),
  ...flexProps(),
};

Col.defaultProps = {
  tag: "div",
  className: "",
};

export default Col;
