import { useEffect, useState } from "react";

import useStatus from "../../../Hooks/Support/useStatus";
import useAgentTicket from "../../../Hooks/Support/useAgentTicket";

import { Nav } from "../../../Components/Nav";
import { Button } from "../../../Components/Button";
import { ContainerFlex } from "../../../Components/Layout";
import { Card, CardBody, CardHeader } from "../../../Components/Card";
import SupportManagerTable from "./Table";

const Content = () => {
  const { listStatus, statusActiveId, setStatusActiveId } = useStatus();
  const { listAgentTicket } = useAgentTicket();

  const [tabList, setTabList] = useState([]);
  // const [listTickets, setListTickets] = useState([]);

  useEffect(() => {
    if (listStatus && listStatus.data && listStatus.data.length > 0) {
      let list = [];
      listStatus.data.forEach((element) => {
        if (element.is_open) {
          list.push(element);
        }
      });
      setTabList(list);
    }
  }, [listStatus]);

  // useEffect(() => {
  //   if (
  //     listAgentTicket &&
  //     listAgentTicket.data &&
  //     typeof listAgentTicket.data === "object"
  //   ) {
  //     setListTickets(listAgentTicket);
  //   } else {
  //     setListTickets([]);
  //   }
  // }, [listAgentTicket]);

  const handleStatus = (e) => {
    setStatusActiveId(e.currentTarget.id);
  };

  return (
    <ContainerFlex marginTop="3">
      <Card>
        <CardHeader>
          <Nav
            className="nav nav-pills nav-justified"
            // marginBottom="3"
            id="pills-tab"
            role="tablist"
          >
            {tabList &&
              tabList.length > 0 &&
              tabList.map((data, index) => (
                <Button
                  className={
                    statusActiveId === `menu-status_${data.id}`
                      ? "nav-link active active-tab rounded-top"
                      : "nav-link border border-1 bg-white"
                  }
                  aria-current="page"
                  id={`menu-status_${data.id}`}
                  key={index}
                  data-bs-toggle="tab"
                  data-bs-target={`#nav-status_${data.id}`}
                  onClick={handleStatus}
                >
                  {data.name}
                </Button>
              ))}
          </Nav>
        </CardHeader>
        <CardBody>
          <ContainerFlex className="tab-content" id="nav-tabContent" width>
            {listAgentTicket &&
              listAgentTicket.data &&
              typeof listAgentTicket.data === "object" &&
              Object.keys(listAgentTicket.data).map(
                (key, index) => (
                  // listAgentTicket.data[key].map((data, index) => (
                  <ContainerFlex
                    className={
                      statusActiveId === `menu-${key}`
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    role="tabpanel"
                    aria-labelledby={`nav-${key}`}
                    id={`nav-${key}`}
                    key={index}
                  >
                    {listAgentTicket.data[key] &&
                    listAgentTicket.data[key].length > 0 ? (
                      <SupportManagerTable
                        listTickets={listAgentTicket.data[key]}
                      />
                    ) : (
                      <ContainerFlex center middle>
                        Nenhum chamado encontrado
                      </ContainerFlex>
                    )}
                  </ContainerFlex>
                )
                // ))
              )}
          </ContainerFlex>
        </CardBody>
      </Card>
    </ContainerFlex>
  );
};

export default Content;
