import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useHistoryPoints = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const dataHistoryPoints = useSelector((state) => state.historyPoints.data);

  function setDataHistoryPoints(data) {
    dispatch({
      type: "CONTENT_HISTORY_POINTS_DATA",
      payload: { data: data },
    });
  }

  function resetHistoryPoints() {
    dispatch({
      type: "CONTENT_HISTORY_POINTS_RESET",
    });
  }

  const indexHistoryPoints = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/history/point?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const getHistoryPoints = (id) => {
    api
      .get(`/history/point/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataHistoryPoints(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const postHistoryPoints = (params, success, error) => {
    api
      .post("/history/point", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataHistoryPoints(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateHistoryPoints = (id, params, success, error) => {
    api
      .update(`/history/point/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delHistoryPoints = (id, success, error) => {
    api
      .delete(`/history/point/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getHistoryPoints,
    postHistoryPoints,
    indexHistoryPoints,
    updateHistoryPoints,
    delHistoryPoints,
    resetHistoryPoints,
    dataHistoryPoints,
    setDataHistoryPoints,
  };
};

export default useHistoryPoints;
