import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useLead = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const activeLeadId = useSelector((state) => state.lead.id);
  const listLead = useSelector((state) => state.lead.index);
  const listLeadType = useSelector((state) => state.lead.type);
  const dataLead = useSelector((state) => state.lead.data);

  function setIndex(index) {
    dispatch({
      type: "CONTENT_LEAD_INDEX",
      payload: { index: index },
    });
  }

  function setActiveLeadId(id) {
    dispatch({
      type: "CONTENT_LEAD_ID",
      payload: { id: id },
    });
  }

  function setIndexType(index) {
    dispatch({
      type: "CONTENT_LEAD_TYPE",
      payload: { type: index },
    });
  }

  function setDataLead(data) {
    dispatch({
      type: "CONTENT_LEAD_DATA",
      payload: { data: data },
    });
  }

  function resetLead() {
    dispatch({
      type: "CONTENT_LEAD_RESET",
    });
  }

  const indexLeadType = () => {
    api
      .get(`lead/types`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndexType(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const indexLead = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`lead?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const getLead = (id) => {
    api
      .get(`lead/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataLead(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const postLead = (params, success, error) => {
    api
      .post("lead", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataLead(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateLead = (id, params, success, error) => {
    api
      .update(`lead/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataLead(response.data.data);
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delLead = (id, success, error) => {
    api
      .delete(`lead/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getLead,
    postLead,
    activeLeadId,
    indexLead,
    indexLeadType,
    updateLead,
    delLead,
    resetLead,
    listLead,
    listLeadType,
    dataLead,
    setActiveLeadId,
    setDataLead,
  };
};

export default useLead;
