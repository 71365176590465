import { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import isValid from "date-fns/isValid";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import Upload from "./Upload";

import { Text } from "../../Components/Text";
import { Card, CardBody, CardFooter, CardHeader } from "../../Components/Card";
import { Column, ContainerFlex, Row } from "../../Components/Layout";
import { Input, FormLabel, Switch } from "../../Components/Form";
import { Button } from "../../Components/Button";
import { useNavigate, useParams } from "react-router-dom";
import useMarketing from "../../Hooks/useMarketing";

registerLocale("ptBR", ptBR);

const NewAppointment = () => {
  const { date } = useParams();
  const now = new Date();

  let navigate = useNavigate();

  const { postMarketing } = useMarketing();

  const [start, setStart] = useState("");
  const [validStart, setValidStart] = useState("");

  const [end, setEnd] = useState("");
  const [validEnd, setValidEnd] = useState("");

  const [title, setTitle] = useState("");
  const [validTitle, setValidTitle] = useState("");

  const [files, setFiles] = useState([]);

  const [text, setText] = useState("");
  const [validText, setValidText] = useState("");

  const [errorTitle, setErrorTitle] = useState("");
  const [error, setError] = useState({});

  const [allDay, setAllDay] = useState(false);
  const handleAllDay = () => {
    setAllDay(!allDay);
  };

  const editor = useRef(null);

  const config = {
    readonly: false,
    height: "100%",
    enableDragAndDropFileToEditor: false,
    placeholder: "",
    useSearch: false,
    toolbar: false,
  };

  const handleDateStart = (date) => {
    setStart(date);
    setValidStart("");
  };

  const handleDateEnd = (date) => {
    setEnd(date);
    setValidEnd("");
  };

  const handleText = (e) => {
    setText(e);
    setValidText("");
  };

  const handleTitle = (e) => {
    setTitle(e.currentTarget.value);
    setValidTitle("");
  };

  const handleValidade = () => {
    let err = true;

    if (!title) {
      setValidTitle("O assunto não pode ser vazio.");
      err = false;
    }

    if (!text) {
      setValidText("A descrição não pode ser vazia.");
      err = false;
    }

    if (!allDay) {
      if (!start) {
        setValidStart("A data de início não pode ser vazia.");
        err = false;
      }
      if (!end) {
        setValidEnd("A data de término não pode ser vazia.");
        err = false;
      }
    }

    return err;
  };

  const handleSuccess = (rs) => {
    if (rs) {
      setError({});

      navigate("/gestao/marketing");
    } else {
      setError(rs.data);
    }
  };

  const handleError = (error) => {
    if (error.data.errors) {
      let arr = [];
      for (let key in error.data.errors) {
        arr.push({
          key: key,
          message: error.data.errors[key][0],
        });
      }
      console.error(arr);
      setError(arr);
      setErrorTitle(`Erro: ${error.status} - ${error.data.message}`);
    } else {
      setError({});
      setErrorTitle("");
    }
  };

  const handleConfirm = () => {
    if (handleValidade()) {
      let params = {};
      if (allDay) {
        params = {
          title,
          text,
          start: date,
          end: date,
          is_allday: allDay,
          files,
        };
      } else {
        params = {
          title,
          text,
          start: isValid(start)
            ? date + " " + start.getHours() + ":" + start.getUTCMinutes()
            : null,
          end: isValid(end)
            ? date + " " + end.getHours() + ":" + end.getUTCMinutes()
            : null,
          is_allday: allDay,
          files,
        };
      }
      postMarketing(params, handleSuccess, handleError);
    }
  };

  return (
    <ContainerFlex full padding="3" stretch>
      <Card marginTop="3" padding="2">
        {error && error.length > 0 && (
          <Column col="12" marginBottom="3">
            <div className="alert alert-danger m-3">
              {errorTitle && (
                <Text tag="h6" fontWeight="bolder">
                  {errorTitle}
                </Text>
              )}
              {error &&
                error.length > 0 &&
                error.map((item) => {
                  return (
                    <Text tag="p" key={item.key}>
                      {item.message}
                    </Text>
                  );
                })}
            </div>
          </Column>
        )}
        <CardHeader between middle>
          <Text className="h5" margin="0">
            Novo Evento de Marketing
          </Text>
        </CardHeader>
        <CardBody padding="3">
          <Row>
            {!allDay && (
              <>
                <Column col="12" md="3" marginBottom="3">
                  <FormLabel>Início</FormLabel>
                  <DatePicker
                    locale="ptBR"
                    selected={
                      new Date(
                        date + " " + now.getHours() + ":" + now.getUTCMinutes()
                      )
                    }
                    onChange={(date) => handleDateStart(date)}
                    selectsStart
                    showTimeSelect
                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                    maxTime={setHours(setMinutes(new Date(end), 0), 18)}
                    minDate={new Date(date)}
                    maxDate={new Date(date)}
                    timeCaption="Hora"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    withPortal
                    portalId="root-start"
                    className={
                      validStart
                        ? "form-control is-invalid bg-white"
                        : "form-control bg-white"
                    }
                  />
                  {validStart && (
                    <div className="invalid-feedback">{validStart}</div>
                  )}
                </Column>
                <Column col="12" md="3" marginBottom="3">
                  <FormLabel>Término</FormLabel>
                  <DatePicker
                    locale="ptBR"
                    selected={
                      new Date(
                        date + " " + now.getHours() + ":" + now.getUTCMinutes()
                      )
                    }
                    onChange={(date) => handleDateEnd(date)}
                    selectsEnd
                    showTimeSelect
                    minTime={setHours(setMinutes(new Date(), 0), start)}
                    maxTime={setHours(setMinutes(new Date(), 0), 18)}
                    minDate={new Date(date)}
                    maxDate={new Date(date)}
                    timeCaption="Hora"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    withPortal
                    portalId="root-end"
                    className={
                      validEnd
                        ? "form-control is-invalid bg-white"
                        : "form-control bg-white"
                    }
                  />
                  {validEnd && (
                    <div className="invalid-feedback">{validEnd}</div>
                  )}
                </Column>
              </>
            )}
            <Column col="12" md="3" marginBottom="3">
              <FormLabel>Dia Inteiro</FormLabel>
              <Switch
                id="allday"
                label={allDay ? "Sim" : "Não"}
                onClick={handleAllDay}
                checked={allDay}
              />
            </Column>
            <Column col="12">
              <Text tag="h6">Título</Text>
              <Input
                value={title}
                onChange={handleTitle}
                placeholder="Informe o título do evento."
                maxLength="255"
                marginBottom="2"
                bgColor="white"
                className={validTitle && "is-invalid"}
              />
              {validTitle && (
                <div className="invalid-feedback">{validTitle}</div>
              )}
            </Column>

            <Column col="12" marginTop="2">
              <Text tag="h6">Anexos</Text>
              <Upload uploadedFiles={files} setUploadedFiles={setFiles} />
            </Column>
            <Column col="12">
              <Text tag="h6" marginTop="3">
                Descrição
              </Text>
              <JoditEditor
                ref={editor}
                value={text}
                config={config}
                onBlur={(newContent) => handleText(newContent)}
                onChange={(newContent) => {}}
                placeholder="Informe o assunto resumido sobre o chamado."
              />
              {validText && (
                <div className="invalid-feedback d-block">{validText}</div>
              )}
            </Column>
          </Row>
        </CardBody>
        <CardFooter bgColor="white">
          <Button onClick={handleConfirm} bgColor="success">
            Registrar
          </Button>
        </CardFooter>
      </Card>
    </ContainerFlex>
  );
};

export default NewAppointment;
