import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useMenu = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const activeMenu = useSelector((state) => state.menu.active);
  const activeSubmenu = useSelector((state) => state.menu.activeSubmenu);
  const listMenu = useSelector((state) => state.menu.index);
  const dataMenu = useSelector((state) => state.menu.data);

  function setActiveMenu(active) {
    dispatch({
      type: "CONTENT_MENU_ACTIVE",
      payload: { active: active },
    });
  }

  function setActiveSubmenu(active) {
    dispatch({
      type: "CONTENT_MENU_ACTIVE",
      payload: { activeMenuSubmenu: active },
    });
  }

  function setIndex(index) {
    dispatch({
      type: "CONTENT_MENU_INDEX",
      payload: { index: index },
    });
  }

  function setDataMenu(data) {
    dispatch({
      type: "CONTENT_MENU_DATA",
      payload: { data: data },
    });
  }

  function resetMenu() {
    dispatch({
      type: "CONTENT_MENU_RESET",
    });
  }

  const indexMenu = () => {
    api
      .get("/", token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const getMenu = (id) => {
    api
      .get(`/menu/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataMenu(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const postMenu = (params, success, error) => {
    api
      .post("/menu", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataMenu(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateMenu = (id, params, success, error) => {
    api
      .update(`/menu/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delMenu = (id, success, error) => {
    api
      .delete(`/menu/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getMenu,
    postMenu,
    indexMenu,
    updateMenu,
    delMenu,
    resetMenu,
    activeMenu,
    activeSubmenu,
    dataMenu,
    listMenu,
    setActiveMenu,
    setDataMenu,
    setActiveSubmenu,
  };
};

export default useMenu;
