import { useDispatch, useSelector } from "react-redux";

import useAuth from "../useAuth";
import api from "../../Data/api";

const useTicket = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listTicket = useSelector((state) => state.supportticket.index);
  // const dataTicket = useSelector((state) => state.supportticket.data);

  function setIndex(index) {
    dispatch({
      type: "SUPPORT_TICKET_INDEX",
      payload: { index: index },
    });
  }

  // function setDataTicket(data) {
  //   dispatch({
  //     type: "SUPPORT_TICKET_DATA",
  //     payload: { data: data },
  //   });
  // }

  function resetTicket() {
    dispatch({
      type: "SUPPORT_TICKET_RESET",
    });
  }

  const indexTicket = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/support?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          error(response.response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const getTicket = (id, success, error) => {
    api
      .get(`/support/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        } else {
          error(response.response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const postTicket = (params, success, error) => {
    api
      .post("/support", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateTicket = (id, params, success, error) => {
    api
      .update(`/support/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const setVizualized = (id) => {
    api
      .update(`/support/vizualized/agent/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          console.warn("Agent vizualized");
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const setVizualizedClient = (id) => {
    api
      .update(`/support/vizualized/client/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          console.warn("client vizualized");
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const closeTicket = (id, success, error) => {
    api
      .update(`/support/close/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delTicket = (id, success, error) => {
    api
      .delete(`/support/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    getTicket,
    postTicket,
    indexTicket,
    updateTicket,
    setVizualized,
    setVizualizedClient,
    closeTicket,
    delTicket,
    listTicket,
    resetTicket,
  };
};

export default useTicket;
