import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import { Modal, ModalBody, ModalFooter } from "../../../Components/Modal";
import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
import { ButtonModalClose } from "../../../Components/Button";

import useNote from "../../../Hooks/useNotes";

const NotesModal = ({ noteId, setNoteId }) => {
  const { getNote } = useNote();

  const [loading, setLoading] = useState(true);

  const [note, setNote] = useState({});

  useEffect(() => {
    if (noteId) {
      getNote(noteId, handleGetNote);
    } else {
      setNoteId("");
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteId]);

  const handleGetNote = (data) => {
    setNote(data);
    setLoading(false);
  };

  const handleDataDefault = () => {
    setNote({});
    setNoteId("");
    setLoading(true);
  };

  return (
    <Modal
      id="Notes_Modal"
      center
      fixed
      scroll
      size="lg"
      style={{ background: "rgba(255, 251, 196, 1)" }}
    >
      <ModalBody padding="0">
        <ContainerFlex column>
          {loading ? (
            <ContainerFlex full middle center column>
              <ReactLoading type={"spin"} color={"#1c208c"} />
              <Text>Carregando...</Text>
            </ContainerFlex>
          ) : (
            <ContainerFlex full>
              <ContainerFlex rounded padding="3" full>
                <Text
                  tag="h5"
                  dangerouslySetInnerHTML={{ __html: note.title ?? "" }}
                />
                <hr />
                <ContainerFlex
                  dangerouslySetInnerHTML={{ __html: note.note ?? "" }}
                />
              </ContainerFlex>
            </ContainerFlex>
          )}
        </ContainerFlex>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex right width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
          >
            Fechar
          </ButtonModalClose>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default NotesModal;
