import React from 'react';
import PropTypes from 'prop-types';

import { colorsProps, colorsClass } from '../utilities/colors';
import { sizingProps, sizingClass } from '../utilities/sizing';
import { spacingProps, spacingClass } from '../utilities/spacing';
import { textProps, textClass } from '../utilities/text';

import cn from 'classnames';

const CardBody = (props) => {
  let { tag: Tag, className, children, ...attributes } = props;

  let colors, sizing, spacing, text;
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [text, attributes] = textClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn('card-body', colors, sizing, spacing, text, className)}
    >
      {children}
    </Tag>
  );
};

CardBody.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  ...sizingProps(),
  ...colorsProps(),
  ...spacingProps(),
  ...textProps(),
};
CardBody.defaultProps = {
  tag: 'div',
  className: '',
  // bgColor: 'transparent',
};

export default CardBody;
