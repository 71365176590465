import React from "react";
import PropTypes from "prop-types";

import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";

import cn from "classnames";

const Table = (props) => {
  let {
    children,
    className,
    color,
    striped,
    hover,
    border,
    borderColor,
    top,
    middle,
    bottom,
    ...attributes
  } = props;

  let sizing, spacing;
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);

  color = color ? ` table-${color}` : "";
  hover = hover ? " table-hover" : "";
  striped = striped ? " table-striped" : "";
  border = border ? " table-bordered" : "";

  if (borderColor) {
    if (!border) {
      borderColor = `table-bordered border-${borderColor}`;
    } else {
      borderColor = `border-${borderColor}`;
    }
  } else {
    borderColor = "";
  }

  let align = "";
  if (top) {
    align = "align-top";
  }
  if (middle) {
    align = "align-middle";
  }
  if (bottom) {
    align = "align-bottom";
  }

  return (
    <div className="table-responsive">
      <table
        {...attributes}
        className={cn(
          "table",
          color,
          hover,
          striped,
          border,
          borderColor,
          align,
          sizing,
          spacing,
          className
        )}
      >
        {children}
      </table>
    </div>
  );
};

Table.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "light",
    "dark",
  ]),

  striped: PropTypes.bool,
  hover: PropTypes.bool,
  border: PropTypes.bool,
  borderColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "light",
    "dark",
  ]),
  top: PropTypes.bool,
  middle: PropTypes.bool,
  bottom: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  ...sizingProps(),
  ...spacingProps(),
};

Table.defaultProps = {
  className: "",
};

export default Table;
