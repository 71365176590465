export function cep(e) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  e.currentTarget.value = value;
  return e;
}

export function onlyNumber(e) {
  let value = e.currentTarget.value;
  value.replace(/\D/g, "");

  return Number(value);
}

export function currency(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

  e.currentTarget.value = value;
  return e;
}

export function cpf(e) {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, "");

  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{2})$/, "$1-$2");
    e.currentTarget.value = value;
  }
  return e;
}

export function phone(e) {
  e.currentTarget.maxLength = 15;
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, "");

  if (value.length <= 14) {
    if (!value.match(/^\((\d{2})\)(\d{4})-(\d{4})$/)) {
      value = value.replace(/(\d{2})(\d)/, "($1) $2");
      value = value.replace(/(\d{4})(\d)/, "$1-$2");
      e.currentTarget.value = value;
    }
  } else {
    if (!value.match(/^\((\d{2})\)\s(\d{5})-(\d{4})$/)) {
      value = value.replace(/(\d{2})(\d)/, "($1) $2");
      value = value.replace(/(\d{5})(\d{4})$/, "$1-$2");
      e.currentTarget.value = value;
    }
  }

  return e;
}

export function formatPhone(value) {
  value = value.replace(/\D/g, "");

  if (value.length <= 14) {
    if (!value.match(/^\((\d{2})\)(\d{4})-(\d{4})$/)) {
      value = value.replace(/(\d{2})(\d)/, "($1) $2");
      value = value.replace(/(\d{4})(\d)/, "$1-$2");
    }
  } else {
    if (!value.match(/^\((\d{2})\)\s(\d{5})-(\d{4})$/)) {
      value = value.replace(/(\d{2})(\d)/, "($1) $2");
      value = value.replace(/(\d{5})(\d{4})$/, "$1-$2");
    }
  }

  return value;
}

export function formatDatetime(dateString) {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return new Date(dateString).toLocaleDateString("pt-br", options);
}

export function formatDate(dateString) {
  if (dateString.match(/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/)) {
    dateString = dateString.split(" ")[0];
    dateString = dateString.split("-");
    dateString = `${dateString[2]}/${dateString[1]}/${dateString[0]}`;
  } else if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
    dateString = dateString.split("-");
    dateString = `${dateString[2]}/${dateString[1]}/${dateString[0]}`;
  } else if (dateString.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) {
    dateString = dateString.split("T")[0];
    dateString = dateString.split("-");
    dateString = `${dateString[2]}/${dateString[1]}/${dateString[0]}`;
  }
  return dateString;

  // const options = {
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  // };
  // return new Date(dateString).toLocaleDateString("pt-br", options);

  // return new Intl.DateTimeFormat("pt-br", {
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  // }).format(value);
}
