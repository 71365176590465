import PropTypes from "prop-types";

export function sizingProps() {
  return {
    full: PropTypes.bool,
    width: PropTypes.oneOf([
      "w-20",
      "w-25",
      "w-50",
      "w-75",
      "w-80",
      "w-100",
      "w-auto",
      "vw-100",
      true,
    ]),
    height: PropTypes.oneOf([
      "h-25",
      "h-50",
      "h-75",
      "h-100",
      "h-auto",
      "vh-100",
      true,
    ]),
  };
}

export function sizingClass(obj) {
  let className = "";
  let { full, width, height, ...attributes } = obj;

  if (full) {
    className += "w-100 h-100 ";
  } else {
    if (width === true) {
      className += `w-100 `;
    } else if (width) {
      className += `${width} `;
    }

    if (height === true) {
      className += `h-100 `;
    } else if (height) {
      className += `${height} `;
    }
  }

  return [className.trim(), attributes];
}
