import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { useNavigate } from "react-router-dom";

const DocumentItem = ({ item }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/treinamento/${item.name}`);
  };
  return (
    <ContainerFlex
      type="button"
      className="card-item accordion-item shadow-sm"
      margin="2"
      padding="2"
      bgColor="white"
      onClick={handleClick}
    >
      <Row>
        <Column col="12" between marginBottom="2">
          <ContainerFlex row middle>
            <Text
              tag="h5"
              padding="0"
              margin="0"
              marginTop="1"
              fontWeight="bolder"
            >
              {item.name}
            </Text>
          </ContainerFlex>
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default DocumentItem;
