import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";

import useAuth from "../../../Hooks/useAuth";
import useDocumentation from "../../../Hooks/Documentation/useDocument";

import { Text } from "../../../Components/Text";
import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../Components/Card";
import { Button } from "../../../Components/Button";

const DocumentContent = () => {
  let navigate = useNavigate();

  const { name } = useParams();
  // const { token } = useAuth();

  // const { getDocumentation } = useDocumentation();
  // const [loading, setLoading] = useState(true);
  // const [documentation, setDocumentation] = useState({});
  // const [files, setFiles] = useState([]);

  // useEffect(() => {
  //   setLoading(true);
  //   getDocumentation(id, handleSuccess, handleError);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (documentation && documentation.files) {
  //     setFiles(documentation.files);
  //   } else {
  //     setFiles([]);
  //   }
  // }, [documentation]);

  // const handleSuccess = (data) => {
  //   setDocumentation(data);
  //   setLoading(false);
  // };

  // const handleError = (error) => {
  //   setLoading(false);
  // };

  // const handleDownload = (file) => {
  //   axios({
  //     url: `/api/download/document/${file}`,
  //     method: "GET",
  //     responseType: "blob",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => {
  //       const url = window.URL.createObjectURL(new Blob([res.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", file);
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  // const handleLoadDocument = () => {
  //   if (documentation && documentation.id) {
  //     return (
  //       <Card full padding="3" bgColor="white" rounded>
  //         <CardHeader between>
  //           <Text tag="h1">{documentation.title}</Text>
  //           <ContainerFlex>
  //             <Button
  //               size="sm"
  //               padding="0"
  //               paddingLeftRight="2"
  //               marginRight="2"
  //               className="btn btn-outline-secondary"
  //               onClick={() => {
  //                 navigate(-1);
  //               }}
  //             >
  //               <Text tag="i" className="fas fa-angle-double-left" />
  //               <Text marginLeft="2">Voltar</Text>
  //             </Button>
  //           </ContainerFlex>
  //         </CardHeader>
  //         {files && files.length > 0 && (
  //           <ContainerFlex paddingTopBottom="2">
  //             <ContainerFlex paddingTopBottom="2" borderBottom>
  //               <Text tag="h6" padding="1">
  //                 Anexos:
  //               </Text>

  //               {files.map((file, index) => (
  //                 <Button
  //                   margin="1"
  //                   paddingBottom="0"
  //                   key={index}
  //                   className="btn btn-outline-secondary"
  //                   onClick={() => {
  //                     handleDownload(file.name);
  //                   }}
  //                 >
  //                   <Text
  //                     tag="i"
  //                     className={`fas fa-file-${
  //                       file.name.includes("jpg")
  //                         ? "image"
  //                         : file.name.includes("jpeg")
  //                         ? "image"
  //                         : file.name.includes("bmp")
  //                         ? "image"
  //                         : file.name.includes("png")
  //                         ? "image"
  //                         : file.name.includes("doc")
  //                         ? "word"
  //                         : file.name.includes("docx")
  //                         ? "word"
  //                         : file.name.includes("csv")
  //                         ? "excel"
  //                         : file.name.includes("rtf")
  //                         ? "excel"
  //                         : file.name.includes("xlsx")
  //                         ? "excel"
  //                         : file.name.includes("xls")
  //                         ? "excel"
  //                         : file.name.includes("txt")
  //                         ? "alt"
  //                         : file.name.includes("pdf")
  //                         ? "pdf"
  //                         : file.name.includes("zip")
  //                         ? "archive"
  //                         : "file"
  //                     }`}
  //                     marginRight="2"
  //                   />
  //                   {file.name}
  //                 </Button>
  //               ))}
  //             </ContainerFlex>
  //           </ContainerFlex>
  //         )}
  //         <CardBody>
  //           <ContainerFlex
  //             dangerouslySetInnerHTML={{
  //               __html: documentation.content[0].content,
  //             }}
  //           />
  //         </CardBody>
  //       </Card>
  //     );
  //   } else {
  //     return (
  //       <ContainerFlex full middle center column>
  //         <Text>Nenhum conteúdo foi localizado.</Text>
  //       </ContainerFlex>
  //     );
  //   }
  // };

  return (
    <ContainerFlex full padding="3">
      <Row>
        <Column col="12" md="8">
          <Card>
            <CardHeader center>
              <Text tag="h1" paddingTop="3">
                {name}
              </Text>
            </CardHeader>
            <CardBody>
              <ContainerFlex middle center width>
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/76979871?quality=&amp;color=19d4fa"
                  data-clip-info="76979871"
                  allow="autoplay; fullscreen"
                  allowfullscreen=""
                  mozallowfullscreen=""
                  webkitallowfullscreen=""
                  width="640"
                  height="360"
                  frameborder="0"
                ></iframe>
              </ContainerFlex>
              <hr />
              <Text tag="p">
                Integer ac nulla ac mauris aliquet condimentum vitae ac eros.
                Mauris hendrerit eu enim non ultricies. Integer maximus, quam
                sed egestas commodo, erat turpis congue felis, at rutrum purus
                risus ac orci. Vestibulum egestas pharetra elit, ut commodo enim
                convallis a. Vivamus dictum varius fermentum. Ut tincidunt
                cursus arcu vitae viverra. In aliquam maximus arcu in lobortis.
                Nulla facilisi. Phasellus pharetra vitae risus sit amet feugiat.
              </Text>
            </CardBody>
          </Card>
        </Column>
        <Column col="12" md="4">
          <Card>
            <CardHeader center>
              <Text tag="h5" paddingTop="3">
                Próximos Vídeos
              </Text>
            </CardHeader>
            <CardBody>
              <ContainerFlex
                width
                rounded
                className="shadow-sm"
                padding="2"
                marginBottom="2"
                border
                role="button"
                onClick={() => {
                  navigate(1);
                }}
              >
                <Text tag="i" className="fas fa-video" />
                <Text marginLeft="2">Aula 02 - Integer ac nulla ac mauris</Text>
              </ContainerFlex>
              <ContainerFlex
                width
                rounded
                className="shadow-sm"
                padding="2"
                marginBottom="2"
                border
                role="button"
                onClick={() => {
                  navigate(1);
                }}
              >
                <Text tag="i" className="fas fa-video" />
                <Text marginLeft="2">
                  Aula 03 - Orci varius natoque penatibus
                </Text>
              </ContainerFlex>
              <ContainerFlex
                width
                rounded
                className="shadow-sm"
                padding="2"
                marginBottom="2"
                border
                role="button"
                onClick={() => {
                  navigate(1);
                }}
              >
                <Text tag="i" className="fas fa-video" />
                <Text marginLeft="2">Aula 04 - Fusce tincidunt nisl sed</Text>
              </ContainerFlex>
              <ContainerFlex
                width
                rounded
                className="shadow-sm"
                padding="2"
                marginBottom="2"
                border
                role="button"
                onClick={() => {
                  navigate(1);
                }}
              >
                <Text tag="i" className="fas fa-video" />
                <Text marginLeft="2">
                  Aula 05 - Suspendisse fringilla lectus
                </Text>
              </ContainerFlex>
              <ContainerFlex
                width
                rounded
                className="shadow-sm"
                padding="2"
                marginBottom="2"
                border
                role="button"
                onClick={() => {
                  navigate(1);
                }}
              >
                <Text tag="i" className="fas fa-video" />
                <Text marginLeft="2">Aula 06 - Duis neque turpis</Text>
              </ContainerFlex>
              <ContainerFlex
                width
                rounded
                className="shadow-sm"
                padding="2"
                marginBottom="2"
                border
                role="button"
                onClick={() => {
                  navigate(1);
                }}
              >
                <Text tag="i" className="fas fa-video" />
                <Text marginLeft="2">Aula 07 - Quisque condimentum</Text>
              </ContainerFlex>
            </CardBody>
          </Card>
        </Column>
      </Row>

      {/* <iframe
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/04T4rRfPWeA"
        title="Sorriso Digital"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe> */}
      {/* {loading ? (
        <ContainerFlex full middle center column padding="2">
          <ReactLoading type={"spin"} color={"#1c208c"} height={"20"} />
          <Text>Carregando...</Text>
        </ContainerFlex>
      ) : (
        handleLoadDocument()
      )} */}
      {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
    </ContainerFlex>
  );
};

export default DocumentContent;
