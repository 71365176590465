import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";

import useProspect from "../../../Hooks/useProspect";

import { ContainerFlex } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import Content from "./Content";
import useGender from "../../../Hooks/useGender";

const ProspectEdit = () => {
  const { id } = useParams();
  const { getProspect } = useProspect();
  const { indexGender } = useGender();

  const [loading, setLoading] = useState(true);
  const [prospect, setProspect] = useState({});

  useEffect(() => {
    indexGender();
    if (id) {
      getProspect(id, handleProspectSuccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleProspectSuccess = (data) => {
    setLoading(false);
    setProspect(data.data);
  };

  const handleProspect = () => {
    if (prospect && prospect.id) {
      return <Content data={prospect} />;
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum item foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <ContainerFlex full>
      {loading ? (
        <ContainerFlex full middle center column>
          <ReactLoading type={"spin"} color={"#1c208c"} />
          <Text>Carregando...</Text>
        </ContainerFlex>
      ) : (
        handleProspect()
      )}
    </ContainerFlex>
  );
};

export default ProspectEdit;
