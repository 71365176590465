import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { Button } from "../../../Components/Button";
import { formatDatetime } from "../../../Components/Form/masks";
import { useNavigate } from "react-router-dom";

const Item = ({ item }) => {
  let navigate = useNavigate();

  let Color;
  if (item.status.color) {
    Color = item.status.color;
  } else {
    Color = "#ddd";
  }

  const handleEdit = () => {
    navigate(`/fale-conosco/ticket/${item.id}`);
  };

  return (
    <ContainerFlex
      className="card-item accordion-item shadow-sm"
      marginBottom="3"
      padding="2"
    >
      <Row>
        <Column col="12" between marginBottom="2">
          <ContainerFlex row middle>
            <Text
              style={{ backgroundColor: Color }}
              padding="1"
              paddingLeftRight="2"
              margin="0"
              marginRight="2"
              rounded
              tag="small"
            >
              {item.status.name}
            </Text>
            <Text
              tag="h5"
              padding="0"
              margin="0"
              marginTop="1"
              fontWeight="bolder"
            >
              {item.title}{" "}
              {!item.vizualized_customer && (
                <Text
                  tag="i"
                  className="fas fa-exclamation-circle"
                  txtColor="success"
                />
              )}
            </Text>
          </ContainerFlex>
          <ContainerFlex middle>
            <Button
              txtColor="ydental-gray"
              onClick={handleEdit}
              marginRight="2"
              middle
            >
              <Text tag="i" className="fa fa-pen" />
            </Button>
          </ContainerFlex>
        </Column>
        <Column col="12">
          <Column col="12">
            <Text tag="small" className="text-muted" marginRight="2">
              Categoria:
            </Text>
            <Text tag="small" fontWeight="bolder">
              {item.category.name}
            </Text>
          </Column>
          <Column col="12">
            <Text tag="small" className="text-muted" marginRight="2">
              Aberto em:
            </Text>
            <Text tag="small" fontWeight="bolder">
              {formatDatetime(item.created_at)}
            </Text>
          </Column>
          <Column col="12">
            <Text tag="small" className="text-muted" marginRight="2">
              Interações:
            </Text>
            <Text tag="small" fontWeight="bolder">
              {item.count_messages}
            </Text>
          </Column>

          {!item.status.is_open && (
            <Column col="12">
              <Text tag="small" className="text-muted" marginRight="2">
                Encerrado em:
              </Text>
              <Text tag="small" fontWeight="bolder">
                {formatDatetime(item.closed_at)}
              </Text>
            </Column>
          )}
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default Item;
