import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import JoditEditor from "jodit-react";

import useCategory from "../../../Hooks/Support/useCategory";
import useTicket from "../../../Hooks/Support/useTicket";

import Upload from "./Upload";

import { Text } from "../../../Components/Text";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../Components/Card";
import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Input } from "../../../Components/Form";
import { Button } from "../../../Components/Button";
import { useNavigate } from "react-router-dom";

const NewTicket = () => {
  const animatedComponents = makeAnimated();
  let navigate = useNavigate();

  const { postTicket } = useTicket();
  const { indexCategory, listCategory } = useCategory();

  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [validCategory, setValidCategory] = useState("");

  const [title, setTitle] = useState("");
  const [validTitle, setValidTitle] = useState("");

  const [text, setText] = useState("");
  const [validText, setValidText] = useState("");

  const [files, setFiles] = useState([]);

  const [errorTitle, setErrorTitle] = useState("");
  const [error, setError] = useState({});

  const editor = useRef(null);

  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: false,
    placeholder: "",
    useSearch: false,
    toolbar: false,
  };

  useEffect(() => {
    indexCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listCategory && listCategory.data.length > 0) {
      let list = listCategory.data.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setCategoryList(list);
    }
  }, [listCategory]);

  const handleCategory = (e) => {
    setCategory(e);
    setValidCategory("");
  };

  const handleText = (e) => {
    setText(e);
    setValidText("");
  };

  const handleTitle = (e) => {
    setTitle(e.currentTarget.value);
    setValidTitle("");
  };

  const handleValidade = () => {
    let err = true;

    if (!title) {
      setValidTitle("O assunto não pode ser vazio.");
      err = false;
    }

    if (!text) {
      setValidText("A descrição não pode ser vazia.");
      err = false;
    }

    if (!category) {
      setValidCategory("Selecione uma categoria válida.");
      err = false;
    }

    return err;
  };

  const handleSuccess = (rs) => {
    if (rs) {
      setError({});

      navigate("/fale-conosco");
    } else {
      setError(rs.data);
    }
  };

  const handleError = (error) => {
    if (error.data.errors) {
      let arr = [];
      for (let key in error.data.errors) {
        arr.push({
          key: key,
          message: error.data.errors[key][0],
        });
        // arr.push(error.response.data.errors[key]);
      }
      console.error(arr);
      setError(arr);
      setErrorTitle(`Erro: ${error.status} - ${error.data.message}`);
    } else {
      setError({});
      setErrorTitle("");
    }
  };

  const handleConfirm = () => {
    if (handleValidade()) {
      let params = {
        support_category_id: category.value,
        title,
        text,
        files,
      };
      postTicket(params, handleSuccess, handleError);
    }
  };

  return (
    <ContainerFlex full padding="3" stretch>
      <Card marginTop="3" padding="2" full>
        {error && error.length > 0 && (
          <Column col="12" marginBottom="3">
            <div className="alert alert-danger m-3">
              {errorTitle && (
                <Text tag="h6" fontWeight="bolder">
                  {errorTitle}
                </Text>
              )}
              {error &&
                error.length > 0 &&
                error.map((item) => {
                  return (
                    <Text tag="p" key={item.key}>
                      {item.message}
                    </Text>
                  );
                })}
            </div>
          </Column>
        )}
        <CardHeader between middle>
          <Text className="h5" margin="0">
            Abertura de Chamado
          </Text>
          <Button
            size="sm"
            padding="0"
            paddingLeftRight="2"
            marginRight="2"
            className="btn btn-outline-secondary"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Text tag="i" className="fas fa-angle-double-left" />
            <Text marginLeft="2">Voltar</Text>
          </Button>
        </CardHeader>
        <CardBody padding="3">
          <Row>
            <Column col="12" md="8">
              <Text tag="h6">Assunto</Text>
              <Input
                value={title}
                onChange={handleTitle}
                placeholder="Informe o assunto resumido sobre o chamado."
                maxLength="255"
                marginBottom="2"
                bgColor="white"
                className={validTitle && "is-invalid"}
              />
              {validTitle && (
                <div className="invalid-feedback">{validTitle}</div>
              )}
            </Column>
            <Column col="12" md="4">
              <Text tag="h6">Setor</Text>
              <Select
                closeMenuOnSelect={true}
                components={animatedComponents}
                value={category}
                options={categoryList}
                menuPosition={"fixed"}
                onChange={handleCategory}
                className={validCategory && "is-invalid"}
                placeholder="Selecione uma categoria"
              />
              {validCategory && (
                <div className="invalid-feedback">{validCategory}</div>
              )}
            </Column>

            <Column col="12">
              <Upload uploadedFiles={files} setUploadedFiles={setFiles} />
            </Column>
            <Column col="12">
              <Text tag="h6" marginTop="3">
                Descrição
              </Text>
              <JoditEditor
                ref={editor}
                value={text}
                config={config}
                onBlur={(newContent) => handleText(newContent)}
                onChange={(newContent) => {}}
                placeholder="Informe o assunto resumido sobre o chamado."
              />
              {validText && (
                <div className="invalid-feedback d-block">{validText}</div>
              )}
            </Column>
          </Row>
        </CardBody>
        <CardFooter bgColor="white">
          <Button onClick={handleConfirm} bgColor="success">
            Registrar
          </Button>
        </CardFooter>
      </Card>
    </ContainerFlex>
  );
};

export default NewTicket;
