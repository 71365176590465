import { useState, useLayoutEffect } from "react";

import Event from "./Event";
import Calendar from "./Calendar";
import MarketingModal from "./Modal";

import { Column, ContainerFlex, Row } from "../../Components/Layout";
import { ButtonModal } from "../../Components/Button";
import { Text } from "../../Components/Text";

const Marketing = () => {
  const [height, setHeight] = useState(window.innerHeight - 240);

  const [dayClick, setDayClick] = useState("");
  const [error, setError] = useState({});
  const [outsidelist, setoutsidelist] = useState([]);

  useLayoutEffect(() => {
    setHeight(window.innerHeight - 240);
  }, []);

  const handleList = () => {
    if (outsidelist && outsidelist.length > 0) {
      return outsidelist.map((data, index) => (
        <Event item={data} key={index} />
      ));
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum item foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <Row padding="3">
      <Column col="12">
        <Text tag="h1">Agenda de Marketing</Text>
      </Column>
      <Column col="12" md="8">
        <ContainerFlex padding="3" rounded bgColor="white">
          {error && error.length > 0 && (
            <ContainerFlex col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                {error &&
                  error.length > 0 &&
                  error.map((item) => {
                    return (
                      <Text tag="p" key={item.key}>
                        {item.message}
                      </Text>
                    );
                  })}
              </div>
            </ContainerFlex>
          )}
          <Calendar
            setoutsidelist={setoutsidelist}
            setDayClick={setDayClick}
            setError={setError}
          />
        </ContainerFlex>
      </Column>
      <Column col="12" md="4">
        <ContainerFlex rounded bgColor="white" paddingTop="3">
          <Text tag="h5" center>
            Listagem de Eventos
          </Text>
          <ContainerFlex
            paddingLeftRight="3"
            style={{ height: height, overflowX: "hidden", overflowY: "scroll" }}
          >
            {handleList()}
          </ContainerFlex>
        </ContainerFlex>
      </Column>
      <MarketingModal dayClick={dayClick} setDayClick={setDayClick} />

      <ButtonModal
        id="Open_Marketing_Modal"
        modal="Marketing_Modal"
        className="d-none"
      />
    </Row>
  );
};

export default Marketing;
