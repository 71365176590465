import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";

import useAuth from "../../Hooks/useAuth";
import useMarketing from "../../Hooks/useMarketing";

import { Text } from "../../Components/Text";
import { ContainerFlex } from "../../Components/Layout";
import { Card, CardBody, CardHeader } from "../../Components/Card";
import { Button } from "../../Components/Button";

const DocumentContent = () => {
  let navigate = useNavigate();

  const { id } = useParams();
  const { token } = useAuth();

  const { getMarketing } = useMarketing();
  const [loading, setLoading] = useState(true);
  const [documentation, setDocumentation] = useState({});
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setLoading(true);
    getMarketing(id, handleSuccess, handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (documentation && documentation.files) {
      setFiles(documentation.files);
    } else {
      setFiles([]);
    }
  }, [documentation]);

  const handleSuccess = (data) => {
    setDocumentation(data);
    setLoading(false);
  };

  const handleError = (error) => {
    setLoading(false);
  };

  const handleDownload = (file) => {
    axios({
      url: `/api/download/marketing/${file}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleLoadDocument = () => {
    if (documentation && documentation.id) {
      return (
        <Card full padding="3" bgColor="white" rounded>
          <CardHeader between>
            <Text tag="h1">{documentation.title}</Text>
            <ContainerFlex>
              <Button
                size="sm"
                padding="0"
                paddingLeftRight="2"
                marginRight="2"
                className="btn btn-outline-secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Text tag="i" className="fas fa-angle-double-left" />
                <Text marginLeft="2">Voltar</Text>
              </Button>
            </ContainerFlex>
          </CardHeader>
          {files && files.length > 0 && (
            <ContainerFlex paddingTopBottom="2">
              <ContainerFlex paddingTopBottom="2" borderBottom>
                <Text tag="h6" padding="1">
                  Anexos:
                </Text>

                {files.map((file, index) => (
                  <Button
                    margin="1"
                    paddingBottom="0"
                    key={index}
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      handleDownload(file.name);
                    }}
                  >
                    <Text
                      tag="i"
                      className={`fas fa-file-${
                        file.name.includes("jpg")
                          ? "image"
                          : file.name.includes("jpeg")
                          ? "image"
                          : file.name.includes("bmp")
                          ? "image"
                          : file.name.includes("png")
                          ? "image"
                          : file.name.includes("doc")
                          ? "word"
                          : file.name.includes("docx")
                          ? "word"
                          : file.name.includes("csv")
                          ? "excel"
                          : file.name.includes("rtf")
                          ? "excel"
                          : file.name.includes("xlsx")
                          ? "excel"
                          : file.name.includes("xls")
                          ? "excel"
                          : file.name.includes("txt")
                          ? "alt"
                          : file.name.includes("pdf")
                          ? "pdf"
                          : file.name.includes("zip")
                          ? "archive"
                          : "file"
                      }`}
                      marginRight="2"
                    />
                    {file.name}
                  </Button>
                ))}
              </ContainerFlex>
            </ContainerFlex>
          )}
          <CardBody>
            <ContainerFlex
              dangerouslySetInnerHTML={{
                __html: documentation.content[0].message,
              }}
            />
          </CardBody>
        </Card>
      );
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum conteúdo foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <ContainerFlex full padding="3">
      {loading ? (
        <ContainerFlex full middle center column padding="2">
          <ReactLoading type={"spin"} color={"#1c208c"} height={"20"} />
          <Text>Carregando...</Text>
        </ContainerFlex>
      ) : (
        handleLoadDocument()
      )}
      {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
    </ContainerFlex>
  );
};

export default DocumentContent;
