import { useDispatch, useSelector } from "react-redux";
import api from "../Data/Auth";

const useAuth = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);
  const me = useSelector((state) => state.auth.me);

  function setToken(userToken) {
    refresh(userToken);
    dispatch({
      type: "SET_TOKEN",
      payload: { token: userToken },
    });
  }

  function setMe(me) {
    dispatch({
      type: "SET_ME",
      payload: { me: me },
    });

    dispatch({
      type: "COMPANY_INDEX",
      payload: { index: me.companies },
    });

    dispatch({
      type: "CONTENT_MENU_INDEX",
      payload: { index: me.menus },
    });
  }

  const login = (email, password, setError) => {
    api.login(email, password, setToken, setError);
  };

  const refresh = (token) => {
    if (token) {
      api
        .me(token)
        .then((response) => {
          if (response.status === 200) {
            setMe(response.data);
          }
        })
        .catch((err) => {
          console.warn(err.response);
        });
    }
  };

  const logout = () => {
    dispatch({
      type: "RESET_TOKEN",
    });
  };

  return {
    me,
    login,
    logout,
    refresh,
    setToken,
    token,
  };
};

export default useAuth;
