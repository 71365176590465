import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useAppointmentStatus = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listAppointmentStatus = useSelector(
    (state) => state.appointmentstatus.index
  );
  const dataAppointmentStatus = useSelector(
    (state) => state.appointmentstatus.data
  );

  function setIndex(index) {
    dispatch({
      type: "CONTENT_APPOINTMENT_STATUS_INDEX",
      payload: { index: index },
    });
  }

  function setDataAppointmentStatus(data) {
    dispatch({
      type: "CONTENT_APPOINTMENT_STATUS_DATA",
      payload: { data: data },
    });
  }

  function resetAppointmentStatus() {
    dispatch({
      type: "CONTENT_APPOINTMENT_STATUS_RESET",
    });
  }

  const indexAppointmentStatus = () => {
    api
      .get("/agenda/status", token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const getAppointmentStatus = (id) => {
    api
      .get(`/agenda/status/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataAppointmentStatus(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const postAppointmentStatus = (params, success, error) => {
    api
      .post("/agenda/status", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataAppointmentStatus(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateAppointmentStatus = (id, params, success, error) => {
    api
      .update(`/agenda/status/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delAppointmentStatus = (id, success, error) => {
    api
      .delete(`/agenda/status/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getAppointmentStatus,
    postAppointmentStatus,
    indexAppointmentStatus,
    updateAppointmentStatus,
    delAppointmentStatus,
    resetAppointmentStatus,
    listAppointmentStatus,
    dataAppointmentStatus,
    setDataAppointmentStatus,
  };
};

export default useAppointmentStatus;
