import useAuth from "./useAuth";
import api from "../Data/api";

const useMarketing = () => {
  const { token } = useAuth();

  const indexMarketing = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/marketing?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const getMarketing = (id, success, error) => {
    api
      .get(`/marketing/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const postMarketing = (params, success, error) => {
    api
      .post("/marketing", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateMarketing = (id, params, success, error) => {
    api
      .update(`/marketing/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delMarketing = (id, success, error) => {
    api
      .delete(`/marketing/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getMarketing,
    postMarketing,
    indexMarketing,
    updateMarketing,
    delMarketing,
  };
};

export default useMarketing;
