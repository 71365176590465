import { useEffect, useState } from "react";

import useNotification from "../../../Hooks/useNotification";

import { Text } from "../../../Components/Text";
import { NavItem, NavLink } from "../../../Components/Nav";
import NotificationItem from "./NotificationItem";

const Notification = () => {
  const { indexNotification, listNotification } = useNotification();

  const [countMsg, setCountMsg] = useState([]);
  const [newMsg, setNewMsg] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      indexNotification();
    }, 50000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listNotification.data && listNotification.data.length > 0) {
      setNewMsg(listNotification.data);

      let filtered = listNotification.data.filter(
        (item) => item.read === false
      );
      setCountMsg(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listNotification]);

  return (
    <NavItem className="dropdown" marginRight="3">
      <NavLink
        size="lg"
        txtColor="dark"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        paddingRight="2"
      >
        <Text className="fa fa-envelope" />
        {countMsg.length > 0 && (
          <>
            <Text className="position-absolute start-100 translate-middle badge rounded-pill bg-danger">
              {countMsg.length}
            </Text>
            <div className="position-absolute start-100  pulse"></div>
          </>
        )}
      </NavLink>
      <ul className="dropdown-menu dropdown-menu-end">
        {newMsg.length > 0 ? (
          <>
            <div className="dropdown-menu-header text-center">
              {countMsg.length} novas notificações
            </div>

            {newMsg.map((item) => (
              <NotificationItem key={item.id} item={item} />
            ))}
          </>
        ) : (
          <li className="dropdown-menu-header">
            Nenhum notificação encontrada
          </li>
        )}
      </ul>
    </NavItem>
  );
};

export default Notification;
