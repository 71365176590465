import { useEffect, useState } from "react";

import { ContainerFlex } from "../../../Components/Layout";

import Sidebar from "./Sidebar";
import Content from "./Content";
import Modal from "./Modal";

import useSource from "../../../Hooks/useSource";
import useCompany from "../../../Hooks/useCompany";

const Source = () => {
  const { indexSource } = useSource();
  const { selectedCompanies } = useCompany();

  const [reload, setReload] = useState(true);

  const handleReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    if (selectedCompanies && selectedCompanies.length > 0) {
      let comps = selectedCompanies.map((data) => {
        return data.value;
      });
      indexSource({ comp_id: comps });
    } else {
      indexSource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      <ContainerFlex full row>
        <Sidebar setReload={handleReload} />
        <Content />
      </ContainerFlex>
      <Modal setReload={handleReload} />
    </>
  );
};

export default Source;
