import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Components/Button";
import { ContainerFlex } from "../Components/Layout";
import { Text } from "../Components/Text";

const NoMatch = () => {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <ContainerFlex middle center column full padding="3" bgColor="dark">
      <ContainerFlex middle center column padding="3" txtColor="white" rounded>
        <Text
          tag="i"
          className="fas fa-exclamation-triangle"
          txtColor="warning"
          style={{ fontSize: "65pt" }}
          marginBottom="3"
        />
        <Text tag="h1" txtColor="warning">
          <Text>404</Text>
        </Text>
        <h4>Opsss! Parece que você está perdido.</h4>
        <p>A página que você tentou acessar não foi encontrada.</p>
        <Button
          onClick={handleClick}
          bgColor="primary"
          txtColor="white"
          size="lg"
        >
          <Text tag="i" className="fas fa-angle-double-left" marginRight="2" />
          <Text>Início</Text>
        </Button>
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default NoMatch;
