import { Text } from "../../Components/Text";
import { useNavigate } from "react-router-dom";
import useMenu from "../../Hooks/useMenu";

import { NavLink } from "../../Components/Nav";

const MenuChildren = ({ item }) => {
  const { activeMenu, setActiveMenu } = useMenu();

  let navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    setActiveMenu(item.id);
    navigate(item.url);
  };

  return (
    <li
      className={activeMenu === item.id ? "menu-child selected" : "menu-child"}
      onClick={(e) => handleClick(e)}
    >
      <NavLink paddingLeft="5">
        <Text tag="i" className={`bi fas fa-caret-right me-2`} />
        <Text>{item.name}</Text>
      </NavLink>
    </li>
  );
};

export default MenuChildren;
