import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import { useNavigate } from "react-router-dom";

import {
  Table,
  TableBody,
  TableHead,
  TableHeadTh,
  TableTd,
  TableTr,
} from "../../Components/Table";

import useProspect from "../../Hooks/useProspect";

import { formatPhone, formatDate } from "../../Components/Form/masks";
import { Button } from "../../Components/Button";
import { Text } from "../../Components/Text";
import { ContainerFlex } from "../../Components/Layout";
import NumberFormat from "react-number-format";
import Loading from "../../Components/Loading";

const ProspectTable = ({ listProspect, pagination }) => {
  const navigate = useNavigate();
  const { setActiveProspectAction, getProspect } = useProspect();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (Array.isArray(listProspect)) {
      setData(listProspect);
    }
  }, [listProspect]);

  const handleActive = (id) => {
    setActiveProspectAction(id.currentTarget.id);
    getProspect(id.currentTarget.id);
  };

  const handleProspect = (id) => {
    navigate(`/prospeccao/${id}`);
    // let prospect_id = id.currentTarget.id.replace("prospect-", "");

    // setActiveProspectId(prospect_id);
    // getProspect(prospect_id);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Dados do Contato",
        columns: [
          {
            id: "Contato",
            Header: "Contato",
            accessor: "lead.lead.lead",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value && formatPhone(value)}
                </ContainerFlex>
              );
            },
          },
          {
            Header: "Nome",
            accessor: "lead.patient.name",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
          {
            id: "Unidade",
            Header: "Unidade",
            accessor: "lead.lead.company.label",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
        ],
      },
      {
        id: "Campanha",
        Header: "Campanha",
        columns: [
          {
            Header: "Data",
            accessor: "lead.lead.created_at",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value && formatDate(value)}
                </ContainerFlex>
              );
            },
          },
          {
            Header: "Fluxo",
            accessor: "lead.inflow.source.name",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
          {
            Header: "Anuncio",
            accessor: "lead.inflow.ad.name",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
          {
            id: "Conversão",
            Header: "Conversão",
            accessor: "value",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex right middle>
                  <NumberFormat
                    value={value && value.replace(".", ",")}
                    prefix="R$ "
                    type="text"
                    displayType="text"
                    thousandsGroupStyle="thousand"
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </ContainerFlex>
              );
            },
          },
          {
            id: "Opções",
            Header: "Opções",
            accessor: "id",
            Cell: ({ cell: { value } }) => {
              return (
                <div className="d-flex justify-content-evenly">
                  <Button
                    bgColor="success"
                    id={value}
                    onClick={handleActive}
                    size="sm"
                  >
                    <Text tag="i" className="fa fa-phone" txtColor="white" />
                  </Button>
                  <Button
                    size="sm"
                    modal="Prospect_Modal"
                    bgColor="info"
                    id={`prospect-${value}`}
                    onClick={() => handleProspect(value)}
                  >
                    <Text tag="i" className="fa fa-pen" txtColor="white" />
                  </Button>
                </div>
              );
            },
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Table hover border {...getTableProps()} style={{ 
      fontSize: "12px", 
      // display: "block", 
      maxHeight: "380px", 
      overflowY: "scoll"
    }}>
      <TableHead className="w-100">
        {headerGroups.map((headerGroup) => (
          <TableTr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeadTh {...column.getHeaderProps()} txtAlign="center">
                {column.render("Header")}
              </TableHeadTh>
            ))}
          </TableTr>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {
        rows && rows.length > 0 ?
          rows.map((row) => {
            prepareRow(row);
            return (
              <TableTr {...row.getRowProps()} id={`row-${row.original.id}`}>
                {row.cells.map((cell) => {
                  return (
                    <TableTd {...cell.getCellProps()} middle>
                      {cell.render("Cell")}
                    </TableTd>
                  );
                })}
              </TableTr>
            );
          })
          :
          (<TableTr>
            <TableTd colSpan="8">
              <Text tag="p" width paddingTop="3" txtAlign="center">Nenhuma prospecção localizada para o status atual</Text>
            </TableTd>
          </TableTr>)
        }
      </TableBody>
    </Table>
  );
};

export default ProspectTable;
