import { useDispatch, useSelector } from "react-redux";

import useAuth from "../useAuth";
import api from "../../Data/api";

const useStatus = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listStatus = useSelector((state) => state.supportstatus.index);
  const dataStatus = useSelector((state) => state.supportstatus.data);
  const statusActiveId = useSelector((state) => state.supportstatus.activeId);

  function setIndex(index) {
    dispatch({
      type: "SUPPORT_STATUS_INDEX",
      payload: { index: index },
    });
  }

  function setStatusActiveId(id) {
    dispatch({
      type: "SUPPORT_STATUS_ACTIVE_ID",
      payload: { activeId: id },
    });
  }

  function setDataStatus(data) {
    dispatch({
      type: "SUPPORT_STATUS_DATA",
      payload: { data: data },
    });
  }

  function resetStatus() {
    dispatch({
      type: "SUPPORT_STATUS_RESET",
    });
  }

  const indexStatus = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/support/status?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getStatus = (id) => {
    api
      .get(`/support/status/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataStatus(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const postStatus = (params, success, error) => {
    api
      .post("/support/status", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateStatus = (id, params, success, error) => {
    api
      .update(`/support/status/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delStatus = (id, success, error) => {
    api
      .delete(`/support/status/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    getStatus,
    postStatus,
    indexStatus,
    updateStatus,
    delStatus,
    resetStatus,
    listStatus,
    dataStatus,
    setDataStatus,
    setStatusActiveId,
    statusActiveId,
  };
};

export default useStatus;
