import { useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import useAuth from "../../../Hooks/useAuth";
import useReport from "../../../Hooks/useReport";

import DatePicker from "react-datepicker";
import isValid from "date-fns/isValid";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";

import { Card, CardBody } from "../../../Components/Card";
import { Text } from "../../../Components/Text";
import { Column, Row } from "../../../Components/Layout";
import { Button } from "../../../Components/Button";
import { FormLabel, Switch } from "../../../Components/Form";

const Filters = ({ filter }) => {
  const animatedComponents = makeAnimated();

  const { me } = useAuth();
  const { setReportDtStart, setReportDtEnd, resetReport } = useReport();

  const [timeout, setTimeout] = useState(false);
  const [autoload, setAutoload] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [validStartDate, setValidStartDate] = useState("");

  const [endDate, setEndDate] = useState("");
  const [validEndDate, setValidEndDate] = useState("");

  const [unit, setUnit] = useState();
  const [listUnits, setListUnits] = useState([]);
  const [validUnits, setValidUnits] = useState("");

  useEffect(() => {
    const today = new Date();
    const start_date = new Date(today.setDate(1));
    const end_date = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    setStartDate(start_date);
    setEndDate(end_date);
    setReportDtStart(start_date);
    setReportDtEnd(end_date);

    if (me && me.data && me.data.companies.length > 0) {
      setListUnits(me.data.companies);
      setUnit(me.data.companies[0]);

      let params = {
        start_date: format(start_date, "yyyy-MM-dd 00:00:00"),
        end_date: format(end_date, "yyyy-MM-dd 23:59:59"),
        company: me.data.companies[0].value,
      };

      filter(params);
    } else {
      resetReport();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timeout) {
      setAutoload(
        setInterval(() => {
          handleFilter();
        }, 30000)
      );
    } else {
      clearInterval(autoload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);

  const handleAutoload = () => {
    setTimeout(!timeout);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setReportDtStart(date);
    setValidStartDate(isValid(date) ? "" : "Data inválida");
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    setReportDtEnd(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setReportDtEnd(date);
    setValidEndDate(isValid(date) ? "" : "Data inválida");
  };

  const handleCompany = (e) => {
    setUnit(e);
    setValidUnits(e ? "" : "Selecione uma empresa");
  };

  const handleValidate = () => {
    let valid = true;

    if (!isValid(startDate)) {
      setValidStartDate("Data inicial inválida");
      valid = false;
    } else {
      setValidStartDate("");
    }

    if (!isValid(endDate)) {
      setValidEndDate("Data final inválida");
      valid = false;
    } else {
      setValidEndDate("");
    }

    if (!unit || unit.value === undefined) {
      setValidUnits("Selecione uma empresa");
      valid = false;
    } else {
      setValidUnits("");
    }

    return valid;
  };

  const handleFilter = () => {
    if (handleValidate()) {
      setReportDtStart(startDate);
      setReportDtEnd(endDate);
      let params = {
        start_date: format(startDate, "yyyy-MM-dd 00:00:00"),
        end_date: format(endDate, "yyyy-MM-dd 23:59:59"),
        company: unit.value,
      };
      filter(params);
    }
  };

  return (
    <Card bgColor="white" marginBottom="3" padding="2">
      <CardBody>
        <Row>
          <Column col="6" md="2">
            <Text tag="h6">Data Início</Text>
            <DatePicker
              locale="ptBR"
              selected={startDate}
              onChange={(date) => handleStartDateChange(date)}
              onBlur={() => handleStartDateChange(startDate)}
              dateFormat="dd/MM/yyyy"
              portalId="root"
              className={
                validStartDate
                  ? "form-control is-invalid bg-white"
                  : "form-control bg-white"
              }
              maxDate={endDate}
              minDate={subDays(endDate, 60)}
            />
            {validStartDate && (
              <div className="invalid-feedback">{validStartDate}</div>
            )}
          </Column>
          <Column col="6" md="2">
            <Text tag="h6">Data Final</Text>
            <DatePicker
              locale="ptBR"
              selected={endDate}
              onChange={(date) => handleEndDateChange(date)}
              onBlur={() => handleEndDateChange(endDate)}
              dateFormat="dd/MM/yyyy"
              portalId="root"
              className={
                validEndDate
                  ? "form-control is-invalid bg-white"
                  : "form-control bg-white"
              }
              minDate={startDate}
              maxDate={addDays(startDate, 60)}
            />
            {validEndDate && (
              <div className="invalid-feedback">{validEndDate}</div>
            )}
          </Column>
          <Column col="12" md="5">
            <Text tag="h6">Unidades</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={unit}
              options={listUnits}
              onChange={handleCompany}
              menuPosition={"fixed"}
              className={validUnits && "form-control is-invalid bg-white"}
            />
            {validUnits && <div className="invalid-feedback">{validUnits}</div>}
          </Column>

          <Column col="12" md="2" top marginTop="3">
            <Button
              txtAlign="center"
              bgColor="info"
              width
              txtColor="white"
              marginTop="2"
              onClick={handleFilter}
              id="btn-filter-campaign"
            >
              <Text tag="i" className="fa fa-filter" marginRight="2" />
              <Text tag="small">Filtrar</Text>
            </Button>
          </Column>
          <Column col="12" md="1" marginBottom="3">
            <FormLabel>Reload</FormLabel>
            <Switch
              id="autoload_dashboard"
              // label={autoload ? "Sim" : "Não"}
              onClick={handleAutoload}
              checked={timeout}
            />
          </Column>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Filters;
