import { useState } from "react";
import useCampaign from "../../../Hooks/useCampaign";

import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { Button, ButtonModal } from "../../../Components/Button";
import AdsList from "./AdsList";

const CampaignItem = ({ item }) => {
  const { getCampaign } = useCampaign();

  const [open, setOpen] = useState(false);

  let Active, Color;
  if (item.is_active) {
    Active = "Ativo";
    Color = "success";
  } else {
    Active = "Inativo";
    Color = "danger";
  }

  const handleEdit = () => {
    getCampaign(item.id);
  };

  const handleOpen = (id) => {
    let col = document.getElementById(`collapse-campaign-${item.id}`);

    setTimeout(() => {
      let show = col.classList.contains("show");

      if (id && show) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, 500);
  };

  return (
    <ContainerFlex
      className="card-item accordion-item shadow-sm"
      marginBottom="3"
      padding="2"
    >
      <Row>
        <Column col="12" between>
          <ContainerFlex row middle>
            <Text
              bgColor={Color}
              padding="0"
              paddingLeftRight="2"
              margin="0"
              marginRight="2"
              txtColor="white"
              rounded
              tag="small"
            >
              {Active}
            </Text>
            <Text
              tag="h5"
              padding="0"
              margin="0"
              marginTop="1"
              fontWeight="bolder"
            >
              {item.name}
            </Text>
          </ContainerFlex>
          <ContainerFlex middle>
            <ButtonModal
              txtColor="ydental-gray"
              onClick={handleEdit}
              modal="Campaign_Modal_Edit"
              marginRight="2"
              middle
            >
              <Text tag="i" className="fa fa-pen" />
            </ButtonModal>
            <Button
              txtColor="ydental-gray"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-campaign-${item.id}`}
              onClick={() => handleOpen(item.id)}
              middle
            >
              <Text tag="i" className="fa fa-bars" />
            </Button>
          </ContainerFlex>
        </Column>
        <Column col="12">
          <Column col="12">
            <Text tag="small" className="text-muted" marginRight="2">
              Origem:
            </Text>
            <Text tag="small" fontWeight="bolder">
              {item.source.name}
            </Text>
          </Column>
          <Column col="12">
            <Text tag="small" className="text-muted" marginRight="2">
              Unidade:
            </Text>
            <Text tag="small" fontWeight="bolder">
              {item.company.label}
            </Text>
          </Column>
        </Column>
      </Row>
      <Row>
        <Column col="12">
          <ContainerFlex
            className="accordion-collapse collapse"
            data-bs-parent="#campaign-collapse-list"
            id={`collapse-campaign-${item.id}`}
            paddingLeftRight="2"
          >
            <AdsList campaign={item.id} open={open} />
          </ContainerFlex>
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default CampaignItem;
