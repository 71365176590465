import PropTypes from 'prop-types';

const sizes = ['0', '1', '2', '3', '4', '5', 'auto'];

export function spacingProps() {
  return {
    margin: PropTypes.oneOf([...sizes]),
    marginTop: PropTypes.oneOf([...sizes]),
    marginLeft: PropTypes.oneOf([...sizes]),
    marginRight: PropTypes.oneOf([...sizes]),
    marginBottom: PropTypes.oneOf([...sizes]),
    marginLeftRight: PropTypes.oneOf([...sizes]),
    marginTopBottom: PropTypes.oneOf([...sizes]),
    padding: PropTypes.oneOf([...sizes]),
    paddingTop: PropTypes.oneOf([...sizes]),
    paddingLeft: PropTypes.oneOf([...sizes]),
    paddingRight: PropTypes.oneOf([...sizes]),
    paddingBottom: PropTypes.oneOf([...sizes]),
    paddingLeftRight: PropTypes.oneOf([...sizes]),
    paddingTopBottom: PropTypes.oneOf([...sizes]),
  };
}

export function spacingClass(obj) {
  let className = '';
  let {
    margin,
    marginTop,
    marginLeft,
    marginRight,
    marginBottom,
    marginLeftRight,
    marginTopBottom,
    padding,
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    paddingLeftRight,
    paddingTopBottom,
    ...attributes
  } = obj;

  if (margin) {
    className += `m-${margin} `;
  }

  if (marginTop) {
    className += `mt-${marginTop} `;
  }

  if (marginLeft) {
    className += `ms-${marginLeft} `;
  }

  if (marginRight) {
    className += `me-${marginRight} `;
  }

  if (marginBottom) {
    className += `mb-${marginBottom} `;
  }

  if (marginLeftRight) {
    className += `mx-${marginLeftRight} `;
  }

  if (marginTopBottom) {
    className += `my-${marginTopBottom} `;
  }

  if (padding) {
    className += `p-${padding} `;
  }
  
  if (paddingTop) {
    className += `pt-${paddingTop} `;
  }
  
  if (paddingLeft) {
    className += `ps-${paddingLeft} `;
  }
  
  if (paddingRight) {
    className += `pe-${paddingRight} `;
  }
  
  if (paddingBottom) {
    className += `pb-${paddingBottom} `;
  }
  
  if (paddingLeftRight) {
    className += `px-${paddingLeftRight} `;
  }

  if (paddingTopBottom) {
    className += `py-${paddingTopBottom} `;
  }

  return [className.trim(), attributes];
}
