import { useState } from "react";

import useMenu from "../../Hooks/useMenu";

import { Nav } from "../../Components/Nav";
import { Text } from "../../Components/Text";
import MenuChildren from "./MenuChildren";
import {
  AccordionItem,
  AccordionBody,
  AccordionHeader,
} from "../../Components/Accordion";

const MenuCollapse = ({ item, children }) => {
  const { setActiveMenu } = useMenu();
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setActiveMenu(item.id);
    setOpen(!open);
  };

  return (
    <AccordionItem bgColor="dark">
      <AccordionHeader
        className="submenu-accordion bg-dark text-white"
        id={item.id.toString()}
        onClick={handleClick}
      >
        <Text tag="i" className={`submenu-icon bi ${item.icon} me-2`} />
        <Text width>{item.name}</Text>
      </AccordionHeader>
      <AccordionBody id={item.id.toString()} parent="sidemenu" className="p-0">
        <Nav>
          {item &&
            item.children &&
            item.children.map((submenu, index) => (
              <MenuChildren key={index} item={submenu} />
            ))}
          {children}
        </Nav>
      </AccordionBody>
    </AccordionItem>
  );
};

export default MenuCollapse;
