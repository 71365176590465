import React from "react";
import PropTypes from "prop-types";

import { spacingProps, spacingClass } from "../utilities/spacing";

import cn from "classnames";

const TableTr = (props) => {
  let { children, className, color, top, middle, bottom, ...attributes } =
    props;

  let spacing;
  [spacing, attributes] = spacingClass(attributes);

  color = color ? `table-${color}` : "";

  let align = "";
  if (top) {
    align = "align-top";
  }
  if (middle) {
    align = "align-middle";
  }
  if (bottom) {
    align = "align-bottom";
  }

  return (
    <tr {...attributes} className={cn(color, align, spacing, className)}>
      {children}
    </tr>
  );
};

TableTr.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "light",
    "dark",
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  ...spacingProps(),
};

TableTr.defaultProps = {
  className: "",
};

export default TableTr;
