import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import useNote from "../../../Hooks/useNotes";

import NoteItem from "./Note";
import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
import { ButtonModal } from "../../../Components/Button";
import NotesModal from "../Notes";
import NotesModalNew from "../Notes/New";

const NoteList = ({ history }) => {
  const [noteId, setNoteId] = useState("");
  const [noteList, setNoteList] = useState([]);

  const [loading, setLoading] = useState(true);
  const { indexNote, listNote } = useNote();

  useEffect(() => {
    if (history) indexNote({ history_id: history });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    setNoteList(listNote.data);
    setLoading(false);
  }, [listNote]);

  const handleNoteList = () => {
    if (noteList && noteList.length > 0) {
      return noteList.map((data, index) => (
        <NoteItem note={data} key={index} setNoteId={setNoteId} />
      ));
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum item foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <ContainerFlex
      borderColor="ydental-gray"
      border
      paddingLeftRight="2"
      padding="2"
      overflow="scroll"
    >
      <ContainerFlex between middle padding="2">
        <Text className="h5" marginRight="2">
          Lista de Anotações - {noteList ? noteList.length : 0}
        </Text>
        <ButtonModal
          size="sm"
          id="Notes_Modal_Open"
          modal="Notes_Modal_New"
          outline="primary"
          onClick={() => setNoteId("")}
        >
          <Text tag="i" className="fa fa-plus" />
          <Text>Nova Anotação</Text>
        </ButtonModal>
      </ContainerFlex>
      {loading ? (
        <ContainerFlex full middle center column>
          <ReactLoading type={"spin"} color={"#1c208c"} />
          <Text>Carregando...</Text>
        </ContainerFlex>
      ) : (
        handleNoteList()
      )}
      <NotesModal history={history} noteId={noteId} setNoteId={setNoteId} />
      <NotesModalNew history={history} />
    </ContainerFlex>
  );
};

export default NoteList;
