import React, { useMemo, useState, useEffect } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import generateExcel from "zipcelx";
import format from "date-fns/format";

import useReport from "../../../Hooks/useReport";

import {
  Table,
  TableBody,
  TableHead,
  TableHeadTh,
  TableTd,
  TableTr,
} from "../../../Components/Table";
import { ContainerFlex } from "../../../Components/Layout";
import {
  formatPhone,
  formatDate,
  formatDatetime,
} from "../../../Components/Form/masks";
import NumberFormat from "react-number-format";
import { Button } from "../../../Components/Button";
import { Text } from "../../../Components/Text";
import { Input, Select } from "../../../Components/Form";

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
    >
      <option value="">Todos</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <Input
      variant="outlined"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
      placeholder={
        count > 1 ? `Pesquisar ${count} registros...` : "Pesquisar..."
      }
    />
  );
}

const ProspectTable = ({ exportExcel, setExportExcel }) => {
  const { reportProspectList } = useReport();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (reportProspectList && reportProspectList.data) {
      setData(reportProspectList.data);
    }
  }, [reportProspectList]);

  useEffect(() => {
    if (exportExcel) {
      getExcel();
      setExportExcel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportExcel]);

  const columns = useMemo(
    () => [
      {
        Header: "Unidade",
        accessor: "Unidade",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex center>{value}</ContainerFlex>;
        },
      },
      {
        Header: "Operador",
        accessor: "Operador",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex center>{value}</ContainerFlex>;
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: "Data de Cadastro",
        accessor: "Data",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center>{value && formatDate(value)}</ContainerFlex>
          );
        },
      },
      {
        Header: "Nome",
        accessor: "Nome",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex left middle>
              {value}
            </ContainerFlex>
          );
        },
      },
      {
        Header: "Contato",
        accessor: "Contato",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center>{value && formatPhone(value)}</ContainerFlex>
          );
        },
      },
      {
        Header: "Origem",
        accessor: "Origem",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex left>{value}</ContainerFlex>;
        },
      },
      {
        Header: "Campanha",
        accessor: "Campanha",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex left>{value}</ContainerFlex>;
        },
      },
      {
        Header: "Anúncio",
        accessor: "Anuncio",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex left>{value}</ContainerFlex>;
        },
      },
      {
        Header: "Tag",
        accessor: "Tag",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex left>{value}</ContainerFlex>;
        },
      },
      {
        Header: "Status da Prospecção",
        accessor: "Status",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex center>{value}</ContainerFlex>;
        },
      },
      {
        Header: "Status do Agendamento",
        accessor: "AgendamentoStatus",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex center>{value}</ContainerFlex>;
        },
      },
      {
        Header: "Data do Agendamento",
        accessor: "Agendamento",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center>
              {value && formatDatetime(value)}
            </ContainerFlex>
          );
        },
      },
      {
        Header: "Orcamento",
        accessor: "Orcamento",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex right middle>
              <NumberFormat
                value={value && value.replace(".", ",")}
                prefix="R$ "
                type="text"
                displayType="text"
                thousandsGroupStyle="thousand"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </ContainerFlex>
          );
        },
      },
    ],
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      // filterTypes,
      initialState: { pageIndex: 0 },
      disableMultiSort: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    rows,

    // pagination stuff
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  function getHeader(column) {
    return [
      {
        value: column.Header,
        type: "string",
      },
    ];
    // if (column.totalHeaderCount === 1) {
    //   return [
    //     {
    //       value: column.Header,
    //       type: "string",
    //     },
    //   ];
    // } else {
    //   const span = [...Array(column.totalHeaderCount - 1)].map((x) => ({
    //     value: "",
    //     type: "string",
    //   }));
    //   return [
    //     {
    //       value: column.Header,
    //       type: "string",
    //     },
    //     ...span,
    //   ];
    // }
  }

  function getExcel() {
    const config = {
      filename: "prospect-report-" + format(new Date(), "yyyymmdd-hhmmss"),
      sheet: {
        data: [],
      },
    };

    const dataSet = config.sheet.data;

    // review with one level nested config
    // HEADERS
    headerGroups.forEach((headerGroup) => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          headerRow.push(...getHeader(column));
        });
      }

      dataSet.push(headerRow);
    });

    // FILTERED ROWS
    if (rows.length > 0) {
      rows.forEach((row) => {
        const dataRow = [];

        Object.values(row.values).forEach((value) =>
          dataRow.push({
            value,
            type: typeof value === "number" ? "number" : "string",
          })
        );

        dataSet.push(dataRow);
      });
    } else {
      dataSet.push([
        {
          value: "No data",
          type: "string",
        },
      ]);
    }

    return generateExcel(config);
  }

  return (
    <>
      <Table hover border {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableTr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeadTh {...column.getHeaderProps()} nowrap="nowrap">
                  <ContainerFlex row between middle>
                    <ContainerFlex
                      {...column.getSortByToggleProps()}
                      center
                      column
                      height
                      width
                    >
                      <ContainerFlex marginLeftRight="1" center>
                        {column.render("Header")}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </ContainerFlex>
                    </ContainerFlex>
                    {/* <ContainerFlex>
                      <Button paddingTopBottom="0" paddingLeftRight="1">
                        <Text tag="small" className="fa fa-filter" />
                      </Button>
                    </ContainerFlex> */}
                  </ContainerFlex>
                  <ContainerFlex>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </ContainerFlex>
                </TableHeadTh>
              ))}
            </TableTr>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableTr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableTd {...cell.getCellProps()} middle nowrap="nowrap">
                      {cell.render("Cell")}
                    </TableTd>
                  );
                })}
              </TableTr>
            );
          })}
        </TableBody>
      </Table>

      <ContainerFlex marginTop="3" row>
        <ContainerFlex row marginRight="2" middle>
          <Text marginRight="2">Página:</Text>
          <Text tag="strong" width>
            {pageIndex + 1} de {page.length - 1}
          </Text>
        </ContainerFlex>
        {/* <span>
          | Ir para:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            min={1}
            max={rows.length - 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span> */}
        <ContainerFlex marginRight="2" middle>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </ContainerFlex>
        <ContainerFlex marginRight="2" middle>
          <nav aria-label="Page navigation">
            <ul className="pagination m-0">
              <li className="page-item">
                <Button
                  tag="a"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="page-link"
                >
                  Início
                </Button>
              </li>
              <li className="page-item">
                <Button
                  tag="a"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="page-link"
                >
                  {"<"}
                </Button>
              </li>
              <li className="page-item">
                <Button
                  tag="a"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="page-link"
                >
                  {">"}
                </Button>
              </li>
              <li className="page-item">
                <Button
                  tag="a"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="page-link"
                >
                  {"Fim"}
                </Button>
              </li>
              <li>
                <Button
                  onClick={getExcel}
                  modal="Campaign_Modal"
                  id="btn-reload-campaign"
                >
                  <Text tag="i" className="fa fa-file-export" />
                </Button>
              </li>
            </ul>
          </nav>
        </ContainerFlex>
      </ContainerFlex>

      <Button className="hidden" id="export-data-prospect" />
    </>
  );
};

export default ProspectTable;
