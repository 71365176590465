import React from "react";
import PropTypes from "prop-types";

import { colorsProps, colorsClass } from "../utilities/colors";
import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";
import { bordersProps, bordersClass } from "../utilities/borders";
import { overflowProps, overflowClass } from "../utilities/overflow";
import { flexProps, flexClass } from "../utilities/flex";
import { textProps, textClass } from "../utilities/text";

import cn from "classnames";

const TableHeadTh = (props) => {
  let { children, className, color, ...attributes } = props;

  let colors, sizing, spacing, borders, overflow, flex, text;
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);
  [overflow, attributes] = overflowClass(attributes);
  [flex, attributes] = flexClass(attributes);
  [text, attributes] = textClass(attributes);

  color = color ? `table-${colors}` : "";

  return (
    <th
      scope="col"
      {...attributes}
      className={cn(
        flex,
        text,
        color,
        sizing,
        spacing,
        borders,
        overflow,
        className
      )}
    >
      {children}
    </th>
  );
};

TableHeadTh.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "light",
    "dark",
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  ...textProps(),
  ...sizingProps(),
  ...colorsProps(),
  ...spacingProps(),
  ...bordersProps(),
  ...overflowProps(),
  ...flexProps(),
};

TableHeadTh.defaultProps = {
  className: "",
};

export default TableHeadTh;
