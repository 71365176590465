const initialState = {
  index: [],
};

const Error = (state = initialState, action) => {
  switch (action.type) {
    case "ERROR_INDEX":
      return { ...state, index: action.payload.index };
    case "ERROR_RESET":
      return initialState;
    default:
      return state;
  }
};

export default Error;
