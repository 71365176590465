import { formatDatetime } from "../../../Components/Form/masks";
import { ButtonModal } from "../../../Components/Button";

const NoteItem = ({ note, setNoteId }) => {
  return (
    <ButtonModal
      tag="a"
      modal="Notes_Modal"
      padding="0"
      margin="0"
      marginBottom="1"
      width="w-100"
      onClick={() => {
        setNoteId(note.id);
      }}
    >
      <div
        className="toast show w-100"
        style={{ background: "rgba(255, 251, 196, 0.85)" }}
      >
        <div className="toast-header">
          <small className="me-auto">{formatDatetime(note.created_at)}</small>
        </div>
        <div className="toast-body text-start">{note.title}</div>
      </div>
    </ButtonModal>
  );
};

export default NoteItem;
