import { useState } from "react";

import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { Button } from "../../../Components/Button";
import ItemList from "./ItemList";

const CategoryItem = ({ item }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (id) => {
    let col = document.getElementById(`collapse-documentations-${item.id}`);

    setTimeout(() => {
      let show = col.classList.contains("show");

      if (id && show) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, 500);
  };

  return (
    <ContainerFlex
      type="button"
      className="card-item accordion-item shadow-sm"
      marginBottom="3"
      padding="2"
    >
      <Row
        data-bs-toggle="collapse"
        data-bs-target={`#collapse-documentations-${item.id}`}
        onClick={() => handleOpen(item.id)}
      >
        <Column col="12" between marginBottom="2">
          <ContainerFlex row middle>
            <Text
              tag="h5"
              padding="0"
              margin="0"
              marginTop="1"
              fontWeight="bolder"
            >
              {item.name}
            </Text>
          </ContainerFlex>
          <ContainerFlex middle>
            <Button txtColor="ydental-gray" marginRight="2" middle>
              <Text tag="i" className="fa fa-list" />
            </Button>
          </ContainerFlex>
        </Column>
        <Column col="12">
          <Column col="12">
            <Text tag="small" className="text-muted" marginRight="2">
              Documentos:
            </Text>
            <Text tag="small" fontWeight="bolder">
              {item.count_documentations}
            </Text>
          </Column>
        </Column>
      </Row>
      <Row>
        <Column col="12">
          <ContainerFlex
            className="accordion-collapse collapse"
            data-bs-parent="#documentations-collapse-list"
            id={`collapse-documentations-${item.id}`}
            paddingLeftRight="2"
          >
            <ItemList category={item.id} open={open} />
          </ContainerFlex>
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default CategoryItem;
