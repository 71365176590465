import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";

const HistoryInflow = ({ history }) => {
  return (
    <ContainerFlex border padding="2">
      <Row>
        <Column col="12" md="3" column>
          <small className="text-muted w-100 text-center">Origem</small>
          <Text tag="p" padding="0" margin="0" txtAlign="center">
            {history.inflow_id.source ? history.inflow_id.source.name : "-"}
          </Text>
        </Column>
        <Column md="1" middle>
          <Text tag="i" className="fas fa-arrow-circle-right" />
        </Column>
        <Column col="12" md="4" column>
          <small className="text-muted w-100 text-center">Campanha</small>
          <Text tag="p" padding="0" margin="0" txtAlign="center">
            {history.inflow_id.campaign ? history.inflow_id.campaign.name : "-"}
          </Text>
        </Column>
        <Column md="1" middle>
          <Text tag="i" className="fas fa-arrow-circle-right" />
        </Column>
        <Column col="12" md="3" column>
          <small className="text-muted w-100 text-center">Anúncio</small>

          <Text tag="p" padding="0" margin="0" txtAlign="center">
            {history.inflow_id.ad ? history.inflow_id.ad.name : "-"}
          </Text>
        </Column>
        {history.note && (
          <Column col="12">
            <small>{history.note.title}</small>
          </Column>
        )}
      </Row>
    </ContainerFlex>
  );
};

export default HistoryInflow;
