import instance from "./config";

const getUA = () => {
  let device = "Unknown";
  const ua = {
    "Generic Linux": /Linux/i,
    Android: /Android/i,
    BlackBerry: /BlackBerry/i,
    Bluebird: /EF500/i,
    "Chrome OS": /CrOS/i,
    Datalogic: /DL-AXIS/i,
    Honeywell: /CT50/i,
    iPad: /iPad/i,
    iPhone: /iPhone/i,
    iPod: /iPod/i,
    macOS: /Macintosh/i,
    Windows: /IEMobile|Windows/i,
    Zebra: /TC70|TC55/i,
  };
  Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
  return device;
};

const api = {
  login: (email, password, setToken, setError) =>
    instance({
      method: "POST",
      url: "/auth",
      data: {
        email: email,
        password: password,
        device_name: getUA(),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setToken(response.data.token);
        }
      })
      .catch((error) => {
        setError(error.response);
      }),

  logout: (token) =>
    instance({
      method: "POST",
      url: "/auth/logout",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  me: (token) =>
    instance({
      method: "GET",
      url: "/auth/me",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default api;
