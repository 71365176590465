import { format } from "date-fns";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function App({ data, old, dt_start, dt_end, accumulated, label }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data) {
      handleLabels(data);
    } else {
      setChartData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleLabels = (rs) => {
    let labels = [];
    let values = [];
    let lastMonthLabel = [];
    let lastMonthValue = [];

    if (accumulated) {
      var one_day = 1000 * 60 * 60 * 24;
      let start = new Date(dt_start);
      let end = new Date(dt_end);
      let days = Math.round(end.getTime() - start.getTime()) / one_day;
      let total = 0;
      let totalLastMonth = 0;

      for (let i = 0; i <= days + 1; i++) {
        let label = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() + i
        );
        let labelOld = new Date(
          start.getFullYear(),
          start.getMonth() - 1,
          start.getDate() + i
        );

        let value = 0;
        let valueLastMonth = 0;

        rs.forEach((item) => {
          if (item.label === format(label, "yyyy-MM-dd")) {
            value = Number(item.value);
          }
        });

        if (old) {
          old.forEach((item) => {
            if (item.label === format(labelOld, "yyyy-MM-dd")) {
              valueLastMonth = Number(item.value);
            }
          });
          totalLastMonth = totalLastMonth + valueLastMonth;
          lastMonthLabel.push(format(label, "dd"));
          lastMonthValue.push(totalLastMonth);
        }
        total = total + value;
        labels.push(format(label, "dd"));
        values.push(total);
      }
    } else {
      rs.forEach((item) => {
        labels.push(format(item.label, "dd/MM/yyyy"));
        values.push(item.value);
      });
    }

    let chart = {};
    if (old) {
      chart = {
        labels: labels,
        datasets: [
          {
            label: "Período Atual",
            data: values,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            pointBackgroundColor: "rgba(75,192,192,0.4)",
            pointHoverBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
          },
          {
            label: "Período Anterior",
            data: lastMonthValue,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            pointBackgroundColor: "rgba(255, 99, 132, 0.2)",
            pointHoverBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
          },
        ],
      };
    } else {
      chart = {
        labels: labels,
        datasets: [
          {
            label: label,
            data: values,
            lineTension: 0.1,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            pointBorderColor: "rgba(75,192,192,1)",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(75,192,192,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
          },
        ],
      };
    }

    setChartData(chart);
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        formatter: (value) => {
          return Math.round(value);
        },
        color: "black",
        font: {
          size: 26,
          weight: "regular",
        },
      },
    },
  };

  return (
    <>
      {chartData && chartData.labels && chartData.labels.length > 0 && (
        <Line data={chartData} options={options} />
      )}
    </>
  );
}

export default App;
