import { Text } from "../../../Components/Text";
import { Button, ButtonModal } from "../../../Components/Button";
import { ContainerFlex } from "../../../Components/Layout";
import { Card, CardBody, CardHeader } from "../../../Components/Card";

import useAd from "../../../Hooks/useAd";

const Sidebar = ({ setReload }) => {
  const { setDataAd } = useAd();

  const handleClearData = () => {
    setDataAd({});
  };

  return (
    <Card bgColor="white" height className="submenu" padding="2">
      <CardHeader>
        <Text tag="h5">Anúncios</Text>
      </CardHeader>

      <Card rounded bgColor="white" marginBottom="2">
        <CardHeader bgColor="ydental-dark" txtColor="white">
          <Text>Opções</Text>
        </CardHeader>
        <ContainerFlex column left>
          <ButtonModal
            txtAlign="start"
            middle
            modal="Ad_Modal"
            onClick={handleClearData}
          >
            <Text tag="i" className="fa fa-plus-square" marginRight="2" />
            <Text tag="small">Cadastrar</Text>
          </ButtonModal>
          <Button txtAlign="start" middle onClick={setReload}>
            <Text tag="i" className="fa fa-sync-alt" marginRight="2" />
            <Text tag="small">Atualizar Lista</Text>
          </Button>
          <Button txtAlign="start" middle disabled>
            <Text tag="i" className="fa fa-file-download" marginRight="2" />
            <Text tag="small">Exportar</Text>
          </Button>
        </ContainerFlex>
      </Card>
      <Card>
        <CardHeader bgColor="ydental-dark" txtColor="white">
          <Text>Filtros</Text>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Card>
  );
};

export default Sidebar;
