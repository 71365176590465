import React from 'react';
import PropTypes from 'prop-types';

import { flexProps, flexClass } from "../utilities/flex";
import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";

import cn from 'classnames';

const getVerticalClass = (vertical) => {
  if (vertical === false) {
    return false;
  } else if (vertical === true || vertical === 'xs') {
    return 'flex-column';
  }

  return `flex-${vertical}-column`;
};

const Nav = (props) => {
  let {
    tag: Tag,
    children,
    className,
    navbar,
    vertical,
    horizontal,
    ...attributes
  } = props;

  let sizing, spacing, flex;

  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [flex, attributes] = flexClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn(
        flex,
        sizing, 
        spacing, 
        getVerticalClass(vertical),
        navbar ? 'navbar-nav' : 'nav',
        horizontal ? `justify-content-${horizontal}` : false,
        className
      )}
    >
      {children}
    </Tag>
  );
};

Nav.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  navbar: PropTypes.bool,
  vertical: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', true, false]),
  horizontal: PropTypes.oneOf(['start', 'end', 'center', 'around', 'between']),
  ...sizingProps(),
  ...spacingProps(),
  ...flexProps(),
};

Nav.defaultProps = {
  tag: 'ul',
  className: '',
  vertical: false,
};

export default Nav;
