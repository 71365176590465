import { useEffect, useState } from "react";
import LineChart from "./LineChart";
import { Card, CardBody, CardHeader } from "../../../Components/Card";
import { Text } from "../../../Components/Text";
import useReport from "../../../Hooks/useReport";

const Evaluations = ({ list }) => {
  const { reportdtstart, reportdtend } = useReport();

  const [listEvaluationsByDate, setListEvaluationsByDate] = useState([]);
  const [countEvaluationsByDate, setCountEvaluationsByDate] = useState(0);

  useEffect(() => {
    if (list && list.data && list.data.length > 0) {
      setListEvaluationsByDate(list.data);
      let count = 0;
      list.data.forEach((item) => {
        count += Number(item.value);
      });
      setCountEvaluationsByDate(count);
    } else {
      setListEvaluationsByDate([]);
      setCountEvaluationsByDate(0);
    }
  }, [list]);

  return (
    <Card>
      <CardHeader border={null} between middle>
        <Text fontWeight="bold" fontSize="5">
          Avaliações
        </Text>
        <Text tag="h3" className="m-0 p-0">
          {countEvaluationsByDate ? countEvaluationsByDate : 0}
        </Text>
      </CardHeader>
      <CardBody paddingTop="0">
        <LineChart
          data={listEvaluationsByDate}
          dt_start={reportdtstart}
          dt_end={reportdtend}
          accumulated
          label="Orçamentos"
        />
      </CardBody>
    </Card>
  );
};

export default Evaluations;
