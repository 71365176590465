import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import DocumentItem from "./DocumentItem";

import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
import useDocumentation from "../../../Hooks/Documentation/useDocument";

const List = ({ setReload }) => {
  // const { indexCategory, listCategory } = useCategory();
  const { indexDocumentation } = useDocumentation();

  const [loading, setLoading] = useState(true);
  const [ticket, setDocumentation] = useState([]);

  useEffect(() => {
    indexDocumentation({}, handleListDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (listCategory && listCategory.data) {
  //     setDocumentation(listCategory.data);
  //     setLoading(false);
  //   }
  // }, [listCategory]);

  const handleListDocuments = (data) => {
    setLoading(false);
    setDocumentation(data.data);
  };

  const handleDocumentation = () => {
    if (ticket && ticket.length > 0) {
      return ticket.map((data, index) => (
        <DocumentItem key={index} item={data}></DocumentItem>
      ));
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum item foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <ContainerFlex
      padding="2"
      className="accordion accordion-flush"
      id="documentations-collapse-list"
    >
      {loading ? (
        <ContainerFlex full middle center column>
          <ReactLoading type={"spin"} color={"#1c208c"} />
          <Text>Carregando...</Text>
        </ContainerFlex>
      ) : (
        handleDocumentation()
      )}
    </ContainerFlex>
  );
};

export default List;
