import React from "react";
import PropTypes from "prop-types";

import { colorsProps, colorsClass } from "../utilities/colors";
import { spacingProps, spacingClass } from "../utilities/spacing";
import { bordersProps, bordersClass } from "../utilities/borders";

import cn from "classnames";

const ModalFooter = (props) => {
  let { tag: Tag, className, children, ...attributes } = props;

  let colors, spacing, borders;
  [colors, attributes] = colorsClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn("modal-footer", colors, spacing, borders, className)}
    >
      {children}
    </Tag>
  );
};

ModalFooter.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  ...colorsProps(),
  ...bordersProps(),
  ...spacingProps(),
};

ModalFooter.defaultProps = {
  tag: "div",
  className: "",
};

export default ModalFooter;
