import React, { useMemo, useState, useEffect } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import generateExcel from "zipcelx";
import format from "date-fns/format";

import useReport from "../../../Hooks/useReport";

import {
  Table,
  TableBody,
  TableHead,
  TableHeadTh,
  TableTd,
  TableTr,
} from "../../../Components/Table";
import { ContainerFlex } from "../../../Components/Layout";
import { formatDate } from "../../../Components/Form/masks";
import { Button } from "../../../Components/Button";
import { Text } from "../../../Components/Text";
import { Input, Select } from "../../../Components/Form";
import { useNavigate } from "react-router-dom";

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
    >
      <option value="">Todos</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <Input
      variant="outlined"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
      placeholder={
        count > 1 ? `Pesquisar ${count} registros...` : "Pesquisar..."
      }
    />
  );
}

const ProspectTable = ({ exportExcel, setExportExcel }) => {
  const navigate = useNavigate();
  const { reportTickets } = useReport();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (reportTickets && reportTickets.data) {
      setData(reportTickets.data);
    }
  }, [reportTickets]);

  useEffect(() => {
    if (exportExcel) {
      getExcel();
      setExportExcel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportExcel]);

  const handleOpen = (data) => {
    navigate(`/gestao/chamados/${data.currentTarget.id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Data de Abertura",
        accessor: "data",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center>{value && formatDate(value)}</ContainerFlex>
          );
        },
        Filter: false,
      },
      {
        Header: "Título",
        accessor: "title",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex>{value}</ContainerFlex>;
        },
      },
      {
        Header: "Usuário",
        accessor: "user_name",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex center>{value}</ContainerFlex>;
        },
        Filter: SelectColumnFilter,
      },

      {
        Header: "Unidade",
        accessor: "company_name",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex center>{value}</ContainerFlex>;
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: "Situação",
        accessor: "status_name",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex center>{value}</ContainerFlex>;
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: "Categoria",
        accessor: "category_name",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex center>{value}</ContainerFlex>;
        },
        Filter: SelectColumnFilter,
      },
      {
        id: "Opções",
        Header: "Opções",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="d-flex justify-content-evenly">
              <Button
                bgColor="success"
                id={value}
                onClick={handleOpen}
                size="sm"
                txtColor="white"
              >
                <Text tag="i" className="fa fa-envelope-open" />
                <Text marginLeft="2">Abrir</Text>
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      // filterTypes,
      initialState: { pageIndex: 0 },
      disableMultiSort: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    rows,

    // pagination stuff
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // nextPage,
    // previousPage,
    // gotoPage,
    // setPageSize,
    // state: { pageIndex, pageSize },
  } = tableInstance;

  function getHeader(column) {
    return [
      {
        value: column.Header,
        type: "string",
      },
    ];
    // if (column.totalHeaderCount === 1) {
    //   return [
    //     {
    //       value: column.Header,
    //       type: "string",
    //     },
    //   ];
    // } else {
    //   const span = [...Array(column.totalHeaderCount - 1)].map((x) => ({
    //     value: "",
    //     type: "string",
    //   }));
    //   return [
    //     {
    //       value: column.Header,
    //       type: "string",
    //     },
    //     ...span,
    //   ];
    // }
  }

  function getExcel() {
    const config = {
      filename: "report-" + format(new Date(), "yyyymmdd-hhmmss"),
      sheet: {
        data: [],
      },
    };

    const dataSet = config.sheet.data;

    // review with one level nested config
    // HEADERS
    headerGroups.forEach((headerGroup) => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          headerRow.push(...getHeader(column));
        });
      }

      dataSet.push(headerRow);
    });

    // FILTERED ROWS
    if (rows.length > 0) {
      rows.forEach((row) => {
        const dataRow = [];

        Object.values(row.values).forEach((value) =>
          dataRow.push({
            value,
            type: typeof value === "number" ? "number" : "string",
          })
        );

        dataSet.push(dataRow);
      });
    } else {
      dataSet.push([
        {
          value: "No data",
          type: "string",
        },
      ]);
    }

    return generateExcel(config);
  }

  return (
    <>
      <Table hover border {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableTr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeadTh
                  {...column.getHeaderProps()}
                  nowrap="nowrap"
                  style={{ verticalAlign: "top" }}
                >
                  <ContainerFlex row between middle top>
                    <ContainerFlex
                      {...column.getSortByToggleProps()}
                      center
                      column
                      height
                      width
                    >
                      <ContainerFlex marginLeftRight="1" center>
                        {column.render("Header")}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </ContainerFlex>
                    </ContainerFlex>
                    {/* <ContainerFlex>
                      <Button paddingTopBottom="0" paddingLeftRight="1">
                        <Text tag="small" className="fa fa-filter" />
                      </Button>
                    </ContainerFlex> */}
                  </ContainerFlex>
                  <ContainerFlex>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </ContainerFlex>
                </TableHeadTh>
              ))}
            </TableTr>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableTr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableTd {...cell.getCellProps()} middle nowrap="nowrap">
                      {cell.render("Cell")}
                    </TableTd>
                  );
                })}
              </TableTr>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default ProspectTable;
