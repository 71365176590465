import { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Text } from "../../../Components/Text";
import { Button, ButtonModal } from "../../../Components/Button";
import { ContainerFlex } from "../../../Components/Layout";
import { Card, CardBody, CardHeader } from "../../../Components/Card";

import useCompany from "../../../Hooks/useCompany";

const Sidebar = ({ setReload }) => {
  const { listCompany, selectedCompanies, setSelectedCompanies } = useCompany();
  const animatedComponents = makeAnimated();

  const [list, setList] = useState();
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (listCompany) {
      setList(listCompany);
    }
  }, [listCompany]);

  useEffect(() => {
    if (selectedCompanies) {
      setSelected(selectedCompanies);
    }
  }, [selectedCompanies]);

  const selectStyle = {
    option: (styles, { data }) => {
      return {
        ...styles,
        fontSize: "8px",
        color: "red",
      };
    },
  };

  const handleCompany = (e) => {
    setSelectedCompanies(e);
  };

  const handleFilter = () => {
    setReload();
  };

  return (
    <Card bgColor="white" height className="submenu" padding="2">
      <CardHeader>
        <Text tag="h5">Origem das Campanhas</Text>
      </CardHeader>

      <Card rounded bgColor="white" marginBottom="2" border borderColor="gray">
        <CardHeader bgColor="ydental-dark" txtColor="white">
          <Text>Opções</Text>
        </CardHeader>
        <ContainerFlex column left>
          <ButtonModal txtAlign="start" middle modal="Source_Modal">
            <Text tag="i" className="fa fa-plus-square" marginRight="2" />
            <Text tag="small">Cadastrar</Text>
          </ButtonModal>
          <Button txtAlign="start" middle onClick={setReload}>
            <Text tag="i" className="fa fa-sync-alt" marginRight="2" />
            <Text tag="small">Atualizar Lista</Text>
          </Button>
          <Button txtAlign="start" middle>
            <Text tag="i" className="fa fa-file-download" marginRight="2" />
            <Text tag="small">Exportar</Text>
          </Button>
        </ContainerFlex>
      </Card>
      <Card border borderColor="gray">
        <CardHeader bgColor="ydental-dark" txtColor="white">
          <Text>Filtros</Text>
        </CardHeader>
        <CardBody padding="2">
          <ContainerFlex>
            <Text>Unidades</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={selected}
              value={selected}
              isMulti
              options={list}
              onChange={handleCompany}
            />
          </ContainerFlex>
          <Button
            txtAlign="center"
            bgColor="info"
            width
            txtColor="white"
            marginTop="2"
            onClick={handleFilter}
            styles={selectStyle}
          >
            <Text tag="i" className="fa fa-file-download" marginRight="2" />
            <Text tag="small">Filtrar</Text>
          </Button>
        </CardBody>
      </Card>
    </Card>
  );
};

export default Sidebar;
