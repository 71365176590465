import PropTypes from "prop-types";

export const colorsDef = [
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "danger",
  "muted",
  "light",
  "dark",
  "gray",
  "white",
  "black",
  "blue",
  "indigo",
  "purple",
  "pink",
  "red",
  "orange",
  "yellow",
  "green",
  "teal",
  "cyan",
  "ydental-gradient",
  "ydental-blue",
  "ydental-light-blue",
  "ydental-dark-blue",
  "ydental-dark",
  "ydental-light",
  "ydental-gray",
];

export function colorsProps() {
  return {
    txtColor: PropTypes.oneOf([...colorsDef]),
    bgColor: PropTypes.oneOf([...colorsDef, "transparent"]),
    gradient: PropTypes.bool,
  };
}

export function colorsClass(obj) {
  let className = "";
  let { txtColor, bgColor, gradient, ...attributes } = obj;

  if (txtColor) {
    className += `text-${txtColor} `;
  }

  if (bgColor) {
    if (!gradient) {
      className += `bg-${bgColor} `;
    } else {
      className += `bg-gradient-${bgColor} `;
    }
  }

  return [className.trim(), attributes];
}
