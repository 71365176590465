import { useEffect, useState } from "react";
import { ContainerFlex } from "../../../Components/Layout";
import usePriority from "../../../Hooks/Support/usePriority";
// import Filters from "./Filters";
import List from "./List";
import PriorityModal from "./Modal";
const Priority = () => {
  const { indexPriority } = usePriority();

  const [reload, setReload] = useState(true);

  const handleReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    indexPriority();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <ContainerFlex full padding="3">
      {/* <Filters /> */}
      <List setReload={handleReload} />
      <PriorityModal setReload={handleReload} />
    </ContainerFlex>
  );
};

export default Priority;
