const initialState = {
  dt_start: "",
  dt_end: "",
  prospectList: [],
  tickets: [],
  leadsByDate: [],
  appointmentsByDate: [],
  evaluationsByDate: [],
  newclientsByDate: [],
  adsbydate: [],
  sourcesbydate: [],
};

const Report = (state = initialState, action) => {
  switch (action.type) {
    case "REPORT_DT_START":
      return { ...state, dt_start: action.payload.dt_start };
    case "REPORT_DT_END":
      return { ...state, dt_end: action.payload.dt_end };
    case "REPORT_PROSPECT_LIST":
      return { ...state, prospectList: action.payload.prospectList };
    case "REPORT_TICKETS":
      return { ...state, tickets: action.payload.tickets };
    case "REPORT_LEADS_BY_DATE_LIST":
      return { ...state, leadsByDate: action.payload.leadsByDate };
    case "REPORT_APPOINTMENTS_BY_DATE_LIST":
      return {
        ...state,
        appointmentsByDate: action.payload.appointmentsByDate,
      };
    case "REPORT_EVALUATIONS_BY_DATE_LIST":
      return { ...state, evaluationsByDate: action.payload.evaluationsByDate };
    case "REPORT_NEW_CLIENTS_BY_DATE_LIST":
      return { ...state, newclientsByDate: action.payload.newclientsByDate };
    case "REPORT_ADS_BY_DATE_LIST":
      return { ...state, adsbydate: action.payload.adsbydate };
    case "REPORT_SOURCES_BY_DATE_LIST":
      return { ...state, sourcesbydate: action.payload.sourcesbydate };
    case "REPORT_RESET":
      return initialState;
    default:
      return state;
  }
};

export default Report;
