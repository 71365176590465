import React from "react";
import PropTypes from "prop-types";

import { colorsDef } from "../utilities/colors";
import { textProps, textClass } from "../utilities/text";
import { colorsProps, colorsClass } from "../utilities/colors";
import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";
import { flexProps, flexClass } from "../utilities/flex";

import cn from "classnames";

const NavItem = (props) => {
  let { tag: Tag, children, className, ...attributes } = props;

  let colors, sizing, spacing, text, flex;
  [text, attributes] = textClass(attributes);
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [flex, attributes] = flexClass(attributes);

  return (
    <>
      <Tag
        {...attributes}
        className={cn(
          "nav-item",
          flex,
          text,
          colors,
          sizing,
          spacing,
          className
        )}
      >
        {children}
      </Tag>
    </>
  );
};

NavItem.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([...colorsDef]),
  ...textProps(),
  ...sizingProps(),
  ...colorsProps(),
  ...spacingProps(),
  ...flexProps(),
};

NavItem.defaultProps = {
  tag: "li",
  className: "",
};

export default NavItem;
