import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useReport = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const reportdtstart = useSelector((state) => state.report.dt_start);
  const reportdtend = useSelector((state) => state.report.dt_end);
  const reportProspectList = useSelector((state) => state.report.prospectList);
  const reportTickets = useSelector((state) => state.report.tickets);

  function setReportDtStart(date) {
    dispatch({
      type: "REPORT_DT_START",
      payload: { dt_start: date },
    });
  }

  function setReportDtEnd(date) {
    dispatch({
      type: "REPORT_DT_END",
      payload: { dt_end: date },
    });
  }

  function setReportProspectList(index) {
    dispatch({
      type: "REPORT_PROSPECT_LIST",
      payload: { prospectList: index },
    });
  }

  function setReportTickets(index) {
    dispatch({
      type: "REPORT_TICKETS",
      payload: { tickets: index },
    });
  }

  function resetReport() {
    dispatch({
      type: "REPORT_RESET",
    });
  }

  const indexProspectList = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`report/prospects?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setReportProspectList(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        setReportProspectList([]);
        console.error(err.response);
      });
  };

  const indexTickets = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`report/tickets?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setReportTickets(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        setReportTickets([]);
        console.error(err.response);
      });
  };

  const indexLeadsByDate = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`report/leadsbydate?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const indexAppoitmentsByDate = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`report/appoitmentsbydate?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const indexEvaluationsByDate = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`report/evaluationsbydate?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const indexNewClientsByDate = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`report/newclientsbydate?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const indexAdsByDate = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`report/adsbydate?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const indexSourcesByDate = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`report/sourcesbydate?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const indexSourcesNewClientByDate = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`report/sourcesnewclientbydate?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  return {
    setReportDtStart,
    setReportDtEnd,
    resetReport,
    indexProspectList,
    indexTickets,
    indexLeadsByDate,
    indexAppoitmentsByDate,
    indexEvaluationsByDate,
    indexNewClientsByDate,
    indexAdsByDate,
    indexSourcesByDate,
    indexSourcesNewClientByDate,
    reportdtstart,
    reportdtend,
    reportProspectList,
    reportTickets,
  };
};

export default useReport;
