import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import useTask from "../../Hooks/useTask";

import { ContainerFlex } from "../../Components/Layout";
import Content from "./ContentNew";
import { Text } from "../../Components/Text";

const Task = () => {
  const { indexTask } = useTask();

  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    indexTask({ count: "all" }, handleSucess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSucess = (result) => {
    setLoading(false);
    if (result && result.data) {
      setTasks(result);
    }
  };

  const handleTask = () => {
    if (tasks && tasks.data) {
      return <Content tasks={tasks} />;
    } else {
      <ContainerFlex full middle center column>
        <Text>Nenhuma tarefa foi localizada.</Text>
      </ContainerFlex>;
    }
  };

  return (
    <ContainerFlex full row>
      {loading ? (
        <ContainerFlex full middle center column>
          <ReactLoading type={"spin"} color={"#1c208c"} />
          <Text>Carregando...</Text>
        </ContainerFlex>
      ) : (
        handleTask()
      )}
    </ContainerFlex>
  );
};

export default Task;
