import styled from "styled-components";

export const CardItem = styled.div`
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  margin-bottom: 8px;
  margin-top: 16px;
  opacity: 1;
  background: rgb(248, 249, 250) none repeat scroll 0% 0%;
  color: rgb(52, 71, 103);
  border-radius: 0.75rem;
`;

// export const CardBody = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   opacity: 1;
//   background: transparent none repeat scroll 0% 0%;
//   color: rgb(52, 71, 103);
// `;

// export const CardBody = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   opacity: 1;
//   background: transparent none repeat scroll 0% 0%;
//   color: rgb(52, 71, 103);
// `;
