import React, { useRef } from "react";
import JoditEditor from "jodit-react";
import { FormLabel, Input } from "../../../Components/Form";

const Editor = ({ content, setContent, title, handleTitle }) => {
  const editor = useRef(null);

  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: false,
    useSearch: false,
    toolbar: false,
  };

  return (
    <div className="">
      <FormLabel>Título da Tarefa</FormLabel>
      <Input
        value={title}
        onChange={handleTitle}
        placeholder="Qualquer observação pertinente."
        maxLength="255"
        marginBottom="2"
      />
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onBlur={(newContent) => setContent(newContent)}
        onChange={(newContent) => {}}
      />
    </div>
  );
};

export default Editor;
