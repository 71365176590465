import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Color, Swatch, Popover, Cover } from "./style";

import usePriority from "../../../../Hooks/Support/usePriority";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../../Components/Modal";
import { ContainerFlex, Row, Column } from "../../../../Components/Layout";
import { Input, FormLabel } from "../../../../Components/Form";
import { onlyNumber } from "../../../../Components/Form/masks";
import { Button, ButtonModalClose } from "../../../../Components/Button";
import { Text } from "../../../../Components/Text";

const PriorityModal = ({ setReload }) => {
  const { dataPriority, postPriority, updatePriority } = usePriority();

  const [id, setId] = useState("");

  const [priority, setPriority] = useState("");
  const [validPriority, setValidPriority] = useState("");

  const [order, setOrder] = useState("");
  const [validOrder, setValidOrder] = useState("");

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState("#ddd");
  const [validColor, setValidColor] = useState("");

  const [error, setError] = useState({});
  const handleError = (err) => {
    setError(err);
  };

  const handleId = (e) => {
    setId(e.target.value);
  };

  const handlePriority = (e) => {
    setPriority(e.target.value);
    setValidPriority("");
  };

  const handleColor = (color) => {
    setColor(color.hex);
  };

  const handleOrder = (num) => {
    setOrder(onlyNumber(num));
  };

  const handleSuccess = () => {
    document.getElementById("Modal_Priority_Close").click();
    setReload();
    handleDataDefault(null);
  };

  const handleValidade = () => {
    let err = true;
    if (priority.length <= 3 || priority.length >= 100) {
      setValidPriority("O nome da prioridade é inválido.");
      err = false;
    } else {
      setValidPriority("");
    }

    if (typeof order !== "number") {
      setValidOrder("É necessário informar o nível de prioridade.");
      err = false;
    } else {
      setValidOrder("");
    }

    if (color.length <= 3 || color.length >= 100) {
      setValidColor("É necessário informar o nível de prioridade.");
      err = false;
    } else {
      setValidColor("");
    }

    return err;
  };

  const handleConfirm = () => {
    if (handleValidade()) {
      if (id === "") {
        let params = {
          name: priority,
          order: order,
          color: color,
        };
        postPriority(params, handleSuccess, handleError);
      } else {
        let params = {
          name: priority,
          order: order,
          color: color,
        };
        updatePriority(id, params, handleSuccess, handleError);
      }
    }
  };

  const handleDataDefault = (Data) => {
    if (Data && Data.id) {
      setId(Data.id);
      setPriority(Data.name);
      setOrder(Data.order);
      setColor(Data.color);
    } else {
      setTimeout(() => {
        setId("");
        setPriority("");
        setValidPriority("");
        setOrder("");
        setValidOrder("");
        setColor("#ddd");
        setValidColor("");
      }, 1000);
    }
  };

  useEffect(() => {
    handleDataDefault(dataPriority);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPriority]);

  return (
    <Modal id="Support_Priority_Modal" center fixed scroll>
      <ModalHeader
        title="Cadastro de Categoria"
        id="Modal_Priority_Close"
        onClick={handleDataDefault}
        border={false}
      />
      <ModalBody>
        <Row>
          {error && error.status && error.data.message && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                <Text tag="h6" fontWeight="bolder">
                  Erro: {error.status} - {error.data.message}
                </Text>
                <Text fontWeight="bolder"></Text>
                <p>{error.data.errors && JSON.stringify(error.data.errors)}</p>
              </div>
            </Column>
          )}
          <Input value={id} onChange={handleId} maxLength={60} type="hidden" />
          <Column col="8" marginBottom="3">
            <FormLabel>Nome da Categoria</FormLabel>
            <Input
              value={priority}
              onChange={handlePriority}
              maxLength={60}
              required
              className={validPriority && "is-invalid"}
              onKeyDown={() => setValidPriority("")}
            />
            {validPriority && (
              <div className="invalid-feedback">{validPriority}</div>
            )}
          </Column>
          <Column col="2" marginBottom="3">
            <FormLabel>Ordem</FormLabel>
            <Input
              value={order}
              onChange={handleOrder}
              maxLength={60}
              required
              className={validOrder && "is-invalid"}
              onKeyDown={() => setValidOrder("")}
            />
            {validOrder && <div className="invalid-feedback">{validOrder}</div>}
          </Column>
          <Column col="2" marginBottom="3">
            <FormLabel>Cor</FormLabel>
            <Swatch onClick={() => setDisplayColorPicker(true)}>
              <Color color={color} />
            </Swatch>
            {displayColorPicker ? (
              <Popover>
                <Cover onClick={() => setDisplayColorPicker(false)} />
                <SketchPicker color={color} onChange={handleColor} />
              </Popover>
            ) : null}

            {validColor && <div className="invalid-feedback">{validColor}</div>}
          </Column>
        </Row>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
          >
            Cancelar
          </ButtonModalClose>
          <Button bgColor="success" txtColor="white" onClick={handleConfirm}>
            Concluir
          </Button>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default PriorityModal;
