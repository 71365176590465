import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import subDays from "date-fns/subDays";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import format from "date-fns/format";
import addMinutes from "date-fns/addMinutes";

import useProspect from "../../../Hooks/useProspect";
import useTask from "../../../Hooks/useTask";
import useAgenda from "../../../Hooks/useAgenda";
import useAppointment from "../../../Hooks/useAppointment";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../Components/Modal";
import { Text } from "../../../Components/Text";
import { Row, Column } from "../../../Components/Layout";
import { FormLabel, Input, Select } from "../../../Components/Form";
import { ContainerFlex } from "../../../Components/Layout";
import { Button, ButtonModalClose } from "../../../Components/Button";

registerLocale("ptBR", ptBR);

const Action = () => {
  const {
    setActiveProspectAction,
    setActiveProspectId,
    dataProspect,
    listHistoryType,
    listProspectStatus,
    activeProspectAction,
    indexProspect,
  } = useProspect();
  const { postTask } = useTask();
  const { listAgenda } = useAgenda();
  const { postAppointment } = useAppointment();

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [validDateTime, setValidDateTime] = useState("");

  const [agenda, setagenda] = useState("");
  const [agendaName, setAgendaName] = useState("");
  const [showAgenda, setShowAgenda] = useState(false);
  const [validAgenda, setValidAgenda] = useState("");

  const [appointment, setappointment] = useState("");
  const [validAppointment, setValidAppointment] = useState("");

  const [status, setStatus] = useState("");
  // const [statusName, setStatusName] = useState("");
  const [validStatus, setValidStatus] = useState("");

  const [action, setAction] = useState("none");
  const [actionList, setActionList] = useState([]);
  const [validAction, setValidAction] = useState("");

  const [rsTask, setRsTask] = useState("");
  const [error, setError] = useState({});

  const handleDate = (date) => {
    setDateTime(date);
    setValidDateTime("");
  };

  const handleAppointment = (date) => {
    setappointment(date);
    setValidAppointment("");
  };

  const handleActionList = (arg) => {
    let list = [];
    arg.forEach((id) => {
      listProspectStatus.data.forEach((data) => {
        if (data.id === id) {
          list.push(data);
        }
      });
    });
    setActionList(list);
  };

  const handleAction = (e) => {
    setAction(e.currentTarget.value);
    setValidAction("");
  };

  const handleAgenda = (e) => {
    setagenda(e.currentTarget.value);
    setValidAgenda("");
    listAgenda.data.forEach((data) => {
      if (data.id === e.currentTarget.value) {
        setAgendaName(data.name);
      }
    });
  };

  const handleStatus = (e) => {
    setStatus(e.currentTarget.value);
    setValidStatus("");
    setAction("none");

    // listHistoryType.data.forEach((data) => {
    //   if (Number(data.id) === Number(e.currentTarget.value)) {
    //     setStatusName(data.name);
    //   }
    // });

    switch (e.currentTarget.value) {
      case "1":
        handleActionList([3, 4, 8, 9]);
        break;
      case "2":
      case "3":
        handleActionList([2, 8]);
        break;
      case "4":
      case "5":
        handleActionList([2, 8, 9]);
        break;
      default:
        setActionList([]);
    }
  };

  const handleDesc = (e) => {
    setDesc(e.currentTarget.value);
  };
  const handleTitle = (e) => {
    setTitle(e.currentTarget.value);
  };

  useEffect(() => {
    if (action === "4") {
      setShowAgenda(true);
    } else {
      setShowAgenda(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const handleValidade = () => {
    let err = true;

    if (status === "" || status === "none") {
      setValidStatus("Selecione uma situação.");
      err = false;
    }

    if (action === "" || action === "none") {
      setValidAction("Selecione uma ação.");
      err = false;
    }

    if (dateTime === "" && action !== "4") {
      setValidDateTime("Selecione uma data de retorno.");
      err = false;
    }

    if (action === "4") {
      if (agenda === "" || agenda === "none") {
        setValidAgenda("Selecione uma agenda.");
        err = false;
      }

      if (appointment === "") {
        setValidAppointment("Selecione uma data de agendamento.");
        err = false;
      }
      if (!dataProspect.lead.patient) {
        let alert = {
          status: "Paciente não cadastrado.",
          data: {
            message:
              "Para realizar um agendamento, é necessário vincular um paciente ao contato!",
            errors: "",
          },
        };
        setError(alert);
        err = false;
      }
    }

    return err;
  };

  const handleSuccess = (response) => {
    indexProspect();
    let thisModal = document.getElementById("Prospect_Modal_Action_Close");
    thisModal.click();
    handleDataDefault();
  };

  const handleError = (err) => {
    setError(err);
    console.warn(err);
  };

  const handlePostAgenda = (rs) => {
    if (rsTask === "") {
      setRsTask(rs);
    }
    if (rs.data.data.id) {
      let params = {
        title:
          "Agenda: " +
          agendaName +
          " -- Data: " +
          format(appointment, "yyyy-MM-dd HH:mm:00"),
        patient_id: dataProspect.lead.patient.id,
        desc: desc,
        note_id: rs.data.data.note.id,
        status_id: 1,
        history_id: dataProspect.history_id,
        agenda_id: agenda,
        start: appointment && format(appointment, "yyyy-MM-dd HH:mm:00"),
        end:
          appointment &&
          format(addMinutes(appointment, 30), "yyyy-MM-dd HH:mm:00"),
        prospect_id: dataProspect.id,
      };

      if (handleValidade()) {
        postAppointment(params, handleSuccess, handleError);
      }
    }
  };

  const handleConfirm = () => {
    var taskTitle,
      taskType,
      dt_start,
      dt_end = "";
    if (action === "4") {
      setDateTime(appointment);
      dt_start = appointment && format(appointment, "yyyy-MM-dd HH:mm:00");
      dt_end =
        appointment &&
        format(addMinutes(appointment, 15), "yyyy-MM-dd HH:mm:00");
      // setTitle("Agendamento");
      taskTitle =
        "Agenda: " +
        agendaName +
        " -- Data: " +
        format(appointment, "yyyy-MM-dd HH:mm:00");
      taskType = 2;
    } else {
      dt_start = dateTime && format(dateTime, "yyyy-MM-dd HH:mm:00");
      dt_end =
        dateTime && format(addMinutes(dateTime, 15), "yyyy-MM-dd HH:mm:00");
      taskTitle = title;
      taskType = 1;
    }

    if (handleValidade()) {
      var params = {
        title: taskTitle,
        desc: desc,
        type_id: taskType,
        dt_start: dt_start,
        dt_end: dt_end,
        prospect: activeProspectAction,
        action: action,
        caller_status: status,
        lead_id: dataProspect.lead.lead.id,
        history_id: dataProspect.history_id,
      };

      if (action === "4" && rsTask === "") {
        postTask(params, handlePostAgenda, handleError);
      } else if (action === "4" && rsTask !== "") {
        handlePostAgenda(rsTask);
      } else if (rsTask === "" && action !== "4") {
        postTask(params, handleSuccess, handleError);
      }
    }
  };

  const handleDataDefault = () => {
    setTitle("");
    setDesc("");
    setDateTime("");
    setValidDateTime("");
    setagenda("");
    setAgendaName("");
    setShowAgenda(false);
    setValidAgenda("");
    setappointment("");
    setValidAppointment("");
    setStatus("");
    setValidStatus("");
    setAction("none");
    setActionList([]);
    setValidAction("");
    setRsTask("");
    setError({});
    setActiveProspectAction("");
    setActiveProspectId("");
  };

  return (
    <Modal id="Prospect_Modal_Action" center fixed scroll size="lg">
      <ModalHeader
        id="Prospect_Modal_Action_Close"
        border={false}
        title="Evento"
        onClick={handleDataDefault}
      />
      <ModalBody>
        <Row>
          {error && error.status && error.data.message && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                <Text tag="h6" fontWeight="bolder">
                  Erro: {error.status} - {error.data.message}
                </Text>
                <Text fontWeight="bolder"></Text>
                <p>{error.data.errors && JSON.stringify(error.data.errors)}</p>
              </div>
            </Column>
          )}
          <Column col="12" md="6" marginBottom="3">
            <FormLabel>Resultado do Contato</FormLabel>
            <Select
              onChange={(e) => handleStatus(e)}
              value={status}
              className={validStatus && "is-invalid"}
            >
              <option value="none" defaultValue>
                Selecione
              </option>
              {listHistoryType.data &&
                listHistoryType.data.length > 0 &&
                listHistoryType.data.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.name}
                  </option>
                ))}
            </Select>
            {validStatus && (
              <div className="invalid-feedback">{validStatus}</div>
            )}
          </Column>
          <Column col="12" md="6" marginBottom="3">
            <FormLabel>Situação da Prospecção</FormLabel>
            <Select
              onChange={(e) => handleAction(e)}
              value={action}
              className={validAction && "is-invalid"}
            >
              {actionList && actionList.length > 0 && (
                <>
                  <option value="none" defaultValue>
                    Selecione
                  </option>
                  {actionList.map((data, index) => (
                    <option value={data.id} key={index}>
                      {data.name}
                    </option>
                  ))}
                </>
              )}
            </Select>
            {validAction && (
              <div className="invalid-feedback">{validAction}</div>
            )}
          </Column>
          {!showAgenda && (
            <>
              <Column col="12" md="4" marginBottom="3">
                <FormLabel>Próxima Tarefa</FormLabel>
                <ContainerFlex>
                  <DatePicker
                    locale="ptBR"
                    selected={dateTime}
                    onChange={(date) => handleDate(date)}
                    showTimeSelect
                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                    maxTime={setHours(setMinutes(new Date(), 0), 18)}
                    // minDate={subDays(new Date(), -60)}
                    timeCaption="Hora"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    withPortal
                    portalId="root-portal"
                    className={validDateTime && "form-control is-invalid"}
                  />
                  {validDateTime && (
                    <div className="invalid-feedback">{validDateTime}</div>
                  )}
                </ContainerFlex>
              </Column>

              <Column col="12" md="8">
                <FormLabel>Título da Tarefa</FormLabel>
                <Input
                  value={title}
                  onChange={handleTitle}
                  placeholder="Qualquer observação pertinente."
                  maxLength="255"
                />
              </Column>
              <Column col="12">
                <FormLabel>Observação</FormLabel>
                <Input
                  tag="textarea"
                  value={desc}
                  onChange={handleDesc}
                  placeholder="Qualquer observação pertinente."
                  maxLength="255"
                />
              </Column>
            </>
          )}

          {showAgenda && (
            <>
              {listAgenda.data && (
                <Column col="12" md="6" marginBottom="3">
                  <FormLabel>Agenda</FormLabel>
                  <Select
                    onChange={(e) => handleAgenda(e)}
                    value={agenda}
                    className={validAgenda && "is-invalid"}
                  >
                    <option value="none" defaultValue>
                      Selecione
                    </option>
                    {listAgenda.data.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))}
                  </Select>
                  {validAgenda && (
                    <div className="invalid-feedback">{validAgenda}</div>
                  )}
                </Column>
              )}
              <Column col="12" md="4" marginBottom="3">
                <FormLabel>Data do Agendamento</FormLabel>

                <DatePicker
                  locale="ptBR"
                  selected={appointment}
                  onChange={(date) => handleAppointment(date)}
                  showTimeSelect
                  minTime={setHours(setMinutes(new Date(), 0), 8)}
                  maxTime={setHours(setMinutes(new Date(), 0), 18)}
                  // minDate={subDays(new Date(), 0)}
                  timeCaption="Hora"
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="dd/MM/yyyy HH:mm"
                  withPortal
                  portalId="root-portal2"
                  className={validAppointment && "form-control is-invalid"}
                />
                {validAppointment && (
                  <div className="invalid-feedback">{validAppointment}</div>
                )}
              </Column>
              <Column col="12" marginBottom="3">
                <FormLabel>Observação</FormLabel>
                <Input
                  tag="textarea"
                  value={desc}
                  onChange={handleDesc}
                  placeholder="Qualquer observação pertinente."
                  maxLength="255"
                />
              </Column>
            </>
          )}
        </Row>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
          >
            Cancelar
          </ButtonModalClose>
          <Button bgColor="success" txtColor="white" onClick={handleConfirm}>
            Cadastrar
          </Button>

          {/* <ButtonModalClose className="d-none" /> */}
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default Action;
