const initialState = {
  id: "",
  action: "",
  index: [],
  data: {},
  index_status: [],
  data_status: {},
};

const Prospect = (state = initialState, action) => {
  switch (action.type) {
    case "CONTENT_PROSPECT_ID":
      return { ...state, id: action.payload.id };
    case "CONTENT_PROSPECT_ACTION":
      return { ...state, action: action.payload.action };
    case "CONTENT_PROSPECT_INDEX":
      return { ...state, index: action.payload.index };
    case "CONTENT_PROSPECT_DATA":
      return { ...state, data: action.payload.data };
    case "CONTENT_PROSPECT_INDEX_STATUS":
      return { ...state, index_status: action.payload.index_status };
    case "CONTENT_PROSPECT_DATA_STATUS":
      return { ...state, data_status: action.payload.data_status };
    case "CONTENT_PROSPECT_RESET":
      return initialState;
    default:
      return state;
  }
};

export default Prospect;
