import { format, isValid } from "date-fns";
import "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  // ChartDataLabels,
  ArcElement,
  Tooltip,
  Legend
);

export function App({ data, dt_start, dt_end, accumulated, label }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      handleLabels(data);
    } else {
      setChartData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleLabels = (rs) => {
    let labels = [];
    let values = [];

    if (accumulated) {
      var one_day = 1000 * 60 * 60 * 24;
      let start = new Date(dt_start);
      let end = new Date(dt_end);
      let days = Math.round(end.getTime() - start.getTime()) / one_day;
      let total = 0;

      for (let i = 0; i <= days + 1; i++) {
        let label = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() + i
        );

        let value = 0;

        rs.forEach((item) => {
          if (item.date === format(label, "yyyy-MM-dd")) {
            value = item.value;
          }
        });

        total = total + value;
        labels.push(format(label, "dd"));
        values.push(total);
      }
    } else {
      rs.forEach((item) => {
        if (isValid(item.date)) {
          labels.push(format(item.label, "dd/MM/yyyy"));
        } else {
          labels.push(item.label);
        }

        values.push(item.value);
      });
    }

    let chart = {
      labels: labels,
      datasets: [
        {
          display: false,
          label: label,
          data: values,

          fill: "start",
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "#05deb3",
        },
      ],
    };
    setChartData(chart);
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        formatter: (value) => {
          return Math.round(value);
        },
        color: "black",
        font: {
          size: 26,
          weight: "regular",
        },
      },
    },
  };

  return (
    <>
      {chartData && chartData.labels && chartData.labels.length > 0 && (
        <Pie data={chartData} plugins={[ChartDataLabels]} options={options} />
      )}
    </>
  );
}

export default App;
