import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { cep, currency, cpf, phone } from "./masks";

import { colorsProps, colorsClass } from "../utilities/colors";
import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";
import { bordersProps, bordersClass } from "../utilities/borders";

import cn from "classnames";

const Input = (props) => {
  let {
    tag: Tag,
    className,
    label,
    type,
    name,
    id,
    placeholder,
    mask,
    ...attributes
  } = props;

  let colors, sizing, spacing, borders;
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);

  const handleKeyUp = useCallback(
    (e) => {
      if (mask === "cep") {
        cep(e);
      }
      if (mask === "currency") {
        currency(e);
      }
      if (mask === "cpf") {
        cpf(e);
      }
      if (mask === "phone") {
        phone(e);
      }
    },
    [mask]
  );

  return (
    <Tag
      {...attributes}
      placeholder={placeholder}
      id={id}
      name={name}
      type={type}
      className={cn(
        "form-control",
        sizing,
        spacing,
        borders,
        colors,
        className
      )}
      onKeyDown={handleKeyUp}
    />
  );
};

Input.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  label: PropTypes.string,
  mask: PropTypes.oneOf(["cep", "currency", "cpf", "phone"]),
  type: PropTypes.oneOf([
    "button",
    "color",
    "date",
    "datetime",
    "email",
    "file",
    "hidden",
    "image",
    "month",
    "number",
    "password",
    "reset",
    "search",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]),
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  ...sizingProps(),
  ...colorsProps(),
  ...spacingProps(),
  ...bordersProps(),
};

Input.defaultProps = {
  tag: "input",
  type: "text",
  className: "",
  placeholder: "",
};

export default Input;
