import { useEffect, useState } from "react";
import LineChart from "./LineChart";
import { Card, CardBody, CardHeader } from "../../../Components/Card";
import { Text } from "../../../Components/Text";
import useReport from "../../../Hooks/useReport";

const Appointments = ({ list }) => {
  const { reportdtstart, reportdtend } = useReport();

  const [listAppointmentsByDate, setListAppointmentsByDate] = useState([]);
  const [countAppointmentsByDate, setCountAppointmentsByDate] = useState(0);

  useEffect(() => {
    if (list && list.data && list.data.length > 0) {
      setListAppointmentsByDate(list.data);
      let count = 0;
      list.data.forEach((item) => {
        count += Number(item.value);
      });
      setCountAppointmentsByDate(count);
    } else {
      setListAppointmentsByDate([]);
      setCountAppointmentsByDate(0);
    }
  }, [list]);

  return (
    <Card>
      <CardHeader border={null} between middle>
        <Text fontWeight="bold" fontSize="5">
          Agendamentos
        </Text>
        <Text tag="h4" className="float-end m-0 p-0">
          {countAppointmentsByDate ? countAppointmentsByDate : 0}
        </Text>
      </CardHeader>
      <CardBody paddingTop="0">
        <LineChart
          data={listAppointmentsByDate}
          dt_start={reportdtstart}
          dt_end={reportdtend}
          accumulated
          label="Agendados"
        />
      </CardBody>
    </Card>
  );
};

export default Appointments;
