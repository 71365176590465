import React, { useEffect, useState } from "react";
import SelectReact from "react-select";
import makeAnimated from "react-select/animated";

import useCampaign from "../../../../Hooks/useCampaign";
import useSource from "../../../../Hooks/useSource";
import useCompany from "../../../../Hooks/useCompany";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../../Components/Modal";
import { ContainerFlex, Row, Column } from "../../../../Components/Layout";
import { Input, FormLabel } from "../../../../Components/Form";
import { Button, ButtonModalClose } from "../../../../Components/Button";
import { Switch } from "../../../../Components/Form";
import { Text } from "../../../../Components/Text";

const CampaignModal = ({ setReload }) => {
  const animatedComponents = makeAnimated();

  const { postCampaign, updateCampaign, dataCampaign } = useCampaign();
  const { indexSource, listSource } = useSource();
  const { indexCompany, listCompany, setSelectedCompanies } = useCompany();

  const [list, setList] = useState([]);
  const [listSources, setListSources] = useState([]);
  const [validCampaign, setValidCampaign] = useState("");
  const [validOrigin, setValidOrigin] = useState("");
  const [validUnit, setValidUnit] = useState("");
  const [unit, setUnit] = useState([]);
  const [id, setId] = useState("");
  const [campaign, setCampaign] = useState("");
  const [origin, setOrigin] = useState([]);
  const [active, setActive] = useState(true);
  const [error, setError] = useState({});

  const handleError = (err) => {
    setError(err);
  };

  const handleUnit = (e) => {
    setUnit(e);
    setSelectedCompanies(e);
    setValidUnit("");

    let comps = e.map((data) => {
      return data.value;
    });

    indexSource({ comp_id: comps });
  };

  useEffect(() => {
    indexCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listSource.data && listSource.data.length > 0) {
      let sources = listSource.data.map((data) => {
        return {
          value: data.id,
          label: data.name + " - " + data.company.label,
        };
      });
      setListSources(sources);
    }
  }, [listSource]);

  useEffect(() => {
    if (listCompany) {
      setList(listCompany);
    }
  }, [listCompany]);

  useEffect(() => {
    setOrigin([]);
  }, [unit]);

  const handleId = (e) => {
    setId(e.target.value);
  };

  const handleCampaign = (e) => {
    setCampaign(e.target.value);
    setValidCampaign("");
  };

  const handleOrigin = (e) => {
    setOrigin(e);
    setValidOrigin("");
  };

  const handleActive = () => {
    setActive(!active);
  };

  const handleSuccess = () => {
    setReload();

    let thisModal = document.getElementById("Campaign_Modal_Close");
    thisModal.click();

    handleDataDefault(null);
  };

  const handleValidade = () => {
    let err = true;
    if (campaign.length <= 3 && campaign !== "") {
      setValidCampaign("O nome da campanha deve ser maior.");
      err = false;
    } else if (campaign.length > 255) {
      setValidCampaign("O nome da campanha deve ser menor.");
      err = false;
    } else if (campaign === "") {
      setValidCampaign("O nome da campanha não pode ser vazio.");
      err = false;
    }

    if (!Array.isArray(origin) || origin.length < 1) {
      setValidOrigin("A origin não pode ser vazia.");
      err = false;
    }

    if (!Array.isArray(unit) || unit.length < 1) {
      setValidUnit("Selecione uma unidade.");
      err = false;
    }

    return err;
  };

  const handleConfirm = () => {
    if (handleValidade()) {
      let sources = origin.map((data) => {
        return { source_id: data.value, source_name: data.label };
      });

      let comps = unit.map((data) => {
        return { comp_id: data.value, comp_name: data.label };
      });

      let params = {
        name: campaign,
        source_id: sources,
        comp_id: comps,
        is_active: active,
      };

      if (id === "") {
        postCampaign(params, handleSuccess, handleError);
      } else if (id !== "" && origin) {
        updateCampaign(id, params, handleSuccess, handleError);
      }
    }
  };

  const handleDataDefault = (Data) => {
    if (Data && Data.id) {
      setId(Data.id);
      setUnit(Data.company);

      setOrigin({ value: Data.source.id, label: Data.source.name });
      setCampaign(Data.name);
      setActive(Data.is_active);
    } else {
      setTimeout(() => {
        setListSources([]);
        setValidCampaign("");
        setValidOrigin("");
        setValidUnit("");
        setUnit([]);
        setId("");
        setCampaign("");
        setOrigin([]);
        setActive(true);
        setError({});
      }, 1000);
    }
  };

  useEffect(() => {
    handleDataDefault(dataCampaign);
  }, [dataCampaign]);

  return (
    <Modal id="Campaign_Modal" center fixed scroll className="h-75">
      <ModalHeader
        title="Cadastro de Campanha"
        id="Campaign_Modal_Close"
        onClick={handleDataDefault}
        border={false}
      />
      <ModalBody>
        <Row height>
          {error && error.status && error.data.message && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                <Text tag="h6" fontWeight="bolder">
                  Erro: {error.status} - {error.data.message}
                </Text>
                <Text fontWeight="bolder"></Text>
                <p>{error.data.errors && JSON.stringify(error.data.errors)}</p>
              </div>
            </Column>
          )}
          <Input value={id} onChange={handleId} maxLength={60} type="hidden" />
          <Column col="12" marginBottom="3">
            <FormLabel>Unidade</FormLabel>
            <SelectReact
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={unit}
              isMulti
              options={list}
              onChange={handleUnit}
            />
            {validUnit && <div className="text-danger p-1">{validUnit}</div>}
          </Column>

          <Column col="12" marginBottom="3">
            <FormLabel>Origem</FormLabel>
            <SelectReact
              className={validOrigin && "is-invalid"}
              required
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={origin}
              isMulti
              options={listSources}
              onChange={handleOrigin}
            />
            {validOrigin && (
              <div className="text-danger p-1">{validOrigin}</div>
            )}
          </Column>
          <Column col="12" marginBottom="3">
            <FormLabel>Nome da Campanha</FormLabel>
            <Input
              value={campaign}
              onChange={handleCampaign}
              maxLength={60}
              required
              className={validCampaign && "is-invalid"}
              onKeyDown={() => setValidCampaign("")}
            />
            {validCampaign && (
              <div className="invalid-feedback">{validCampaign}</div>
            )}
          </Column>
          <Column col="12" md="3" marginBottom="3">
            <FormLabel>Status</FormLabel>
            <Switch
              id="is_active"
              label={active ? "Ativo" : "Inativo"}
              onClick={handleActive}
              checked={active}
            />
          </Column>
        </Row>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
          >
            Cancelar
          </ButtonModalClose>
          <Button bgColor="success" txtColor="white" onClick={handleConfirm}>
            Concluir
          </Button>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default CampaignModal;
