import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

import useTicket from "../../../Hooks/Support/useTicket";

import Item from "./Item";

import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
import { Button } from "../../../Components/Button";
import { Card, CardBody, CardHeader } from "../../../Components/Card";

const List = () => {
  let navigate = useNavigate();

  const handleNewTicket = () => {
    navigate("/fale-conosco/ticket");
  };
  const { listTicket } = useTicket();

  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState([]);

  useEffect(() => {
    if (listTicket && listTicket.data) {
      setTicket(listTicket.data);
      setLoading(false);
    }
  }, [listTicket]);

  const handleTicket = () => {
    if (ticket && ticket.length > 0) {
      return ticket.map((data, index) => <Item key={index} item={data}></Item>);
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum item foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <Card marginTop="3" padding="2">
      <CardHeader between middle>
        <Text className="h5" margin="0">
          Histórico de Chamados
        </Text>
        <ContainerFlex row>
          <Button
            size="sm"
            className="btn btn-outline-primary"
            onClick={handleNewTicket}
          >
            <Text tag="i" className="fas fa-plus" />
            <Text marginLeft="2">Novo Chamado</Text>
          </Button>
        </ContainerFlex>
      </CardHeader>
      <CardBody padding="0">
        <ContainerFlex
          padding="2"
          className="accordion accordion-flush"
          id="campaign-collapse-list"
        >
          {loading ? (
            <ContainerFlex full middle center column>
              <ReactLoading type={"spin"} color={"#1c208c"} />
              <Text>Carregando...</Text>
            </ContainerFlex>
          ) : (
            handleTicket()
          )}
        </ContainerFlex>
      </CardBody>
    </Card>
  );
};

export default List;
