import React, { useEffect, useState } from "react";

import useLead from "../../../Hooks/useLead";
import useAd from "../../../Hooks/useAd";
import useSource from "../../../Hooks/useSource";
import useCampaign from "../../../Hooks/useCampaign";
import useCompany from "../../../Hooks/useCompany";
import useProspect from "../../../Hooks/useProspect";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../Components/Modal";
import { Text } from "../../../Components/Text";
import { Row, Column } from "../../../Components/Layout";
import { Input, FormLabel, Select, Switch } from "../../../Components/Form";
import { ContainerFlex } from "../../../Components/Layout";
import { Button, ButtonModalClose } from "../../../Components/Button";
import { phone } from "../../../Components/Form/masks";

const LeadModal = () => {
  const { indexAd, listAd, resetAd } = useAd();
  const { indexSource, listSource, resetSource } = useSource();
  const { indexCampaign, listCampaign, resetCampaign } = useCampaign();
  const { indexCompany, listCompany } = useCompany();
  const { postLead, indexLeadType, listLeadType, indexLead } = useLead();
  const { indexProspect, listProspectStatus } = useProspect();

  const [patientId, setPatientId] = useState("");
  const [patientName, setPatientName] = useState("");

  const [id, setId] = useState("");
  const [lead, setLead] = useState("");
  const [validLead, setValidLead] = useState("");

  const [unit, setUnit] = useState("");
  const [validUnit, setValidUnit] = useState("");

  const [type, setType] = useState("");
  const [validType, setValidType] = useState("");

  const [ad, setAd] = useState("");
  // const [validAd, setValidAd] = useState("");

  const [source, setSource] = useState("");
  // const [validSource, setValidSource] = useState("");

  const [campaign, setCampaign] = useState("");
  // const [validCampaign, setValidCampaign] = useState("");

  const [prospectStatus, setProspectStatus] = useState("");
  const [prospectStatusList, setProspectStatusList] = useState([]);
  const [validProspectStatusList, setValidProspectStatusList] = useState("");

  const [error, setError] = useState({});

  const [addProspect, setAddProspect] = useState(true);
  const handleAddProspect = () => {
    setAddProspect(!addProspect);
  };

  useEffect(() => {
    indexLeadType();
    indexCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (source && source !== "none") {
      indexCampaign({ source: source });
    }
    resetCampaign();
    resetAd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  useEffect(() => {
    if (campaign && campaign !== "none") {
      indexAd({ campaign: campaign });
    }
    resetAd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  useEffect(() => {
    if (listProspectStatus && listProspectStatus.data) {
      setProspectStatusList(listProspectStatus.data);
    }
  }, [listProspectStatus]);

  const handleId = (e) => {
    setId(e.target.value);
  };

  const handlePatientId = (e) => {
    setPatientId(e.target.value);
  };

  const handlePatientName = (e) => {
    setPatientName(e.target.value);
  };

  const handleLead = (e) => {
    setValidLead("");
    phone(e);
    let value = e.currentTarget.value;
    setLead(value);
  };

  const handleUnit = (e) => {
    setUnit(e.currentTarget.value);
    setValidUnit("");
    if (e.currentTarget.value !== "" && e.currentTarget.value !== "none") {
      indexSource({ comp_id: e.currentTarget.value });
    } else {
      resetSource();
    }
  };

  const handleProspectStatus = (e) => {
    setProspectStatus(e.currentTarget.value);
    setValidProspectStatusList("");
  };

  const handleSource = (e) => {
    setSource(e.currentTarget.value);
  };

  const handleCampaign = (e) => {
    setCampaign(e.currentTarget.value);
  };

  const handleAd = (e) => {
    setAd(e.currentTarget.value);
  };

  const handleType = (e) => {
    setType(e.currentTarget.value);
    setValidType("");
  };

  const handleValidade = () => {
    let err = true;
    if (lead.length <= 13 || lead.length >= 16) {
      setValidLead("Número inválido");
      err = false;
    }

    if (unit === "" || unit === "none") {
      setValidUnit("Selecione uma unidade de atendimento.");
      err = false;
    }

    if (type === "" || type === "none") {
      setValidType("Selecione um tipo de contato.");
      err = false;
    }
    return err;
  };

  const handleCloseModal = () => {
    indexLead();
    // indexProspect();
    handleDataDefault();
    let thisModal = document.getElementById("Lead_Modal_Close");
    thisModal.click();
  };

  const handleError = (err) => {
    setError(err);
  };

  const handleConfirm = () => {
    let params = {
      lead: lead.replace(/[\W\s.]/gi, ""),
      comp_id: unit,
      source_id: source,
      campaign_id: campaign,
      ad_id: ad,
      type_id: type,
      name: patientName,
      prospect: addProspect,
      prospectStatus: prospectStatus,
    };

    if (handleValidade()) {
      postLead(params, handleCloseModal, handleError);
    }
  };

  const handleDataDefault = (Data) => {
    setPatientId("");
    setPatientName("");
    setId("");
    setLead("");
    setValidLead("");
    setUnit("");
    setValidUnit("");
    setType("");
    setValidType("");
    setAd("");
    setSource("");
    setCampaign("");
    setError({});
    setAddProspect(true);

    // }
  };

  return (
    <Modal id="Lead_Modal" center fixed scroll size="lg">
      <ModalHeader
        id="Lead_Modal_Close"
        border={false}
        title="Cadastro de Contato"
        onClick={handleDataDefault}
      />
      <ModalBody>
        <Row>
          {error.status && error.data.message && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                <Text tag="h6" fontWeight="bolder">
                  Erro: {error.status} - {error.data.message}
                </Text>
                <Text fontWeight="bolder"></Text>
                <p>{error.data.errors && JSON.stringify(error.data.errors)}</p>
              </div>
            </Column>
          )}
          <Column col="12" md="4" marginBottom="3">
            <FormLabel>Tipo</FormLabel>
            <Select
              onChange={(e) => handleType(e)}
              value={type}
              className={validType && "is-invalid"}
            >
              <option value="none" defaultValue>
                Selecione
              </option>
              {listLeadType &&
                listLeadType.data &&
                listLeadType.data.length > 0 &&
                listLeadType.data.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.name}
                  </option>
                ))}
            </Select>
            {validType && <div className="invalid-feedback">{validType}</div>}
          </Column>
          <Column col="12" md="8" marginBottom="3">
            <Input
              value={patientId}
              onChange={handlePatientId}
              maxLength={60}
              type="hidden"
            />
            <FormLabel>Nome</FormLabel>
            <Input
              value={patientName}
              onChange={handlePatientName}
              placeholder="Nome do paciente"
              maxLength="65"
            />
          </Column>
          <Column col="12" md="4" marginBottom="3">
            <Input
              value={id}
              onChange={handleId}
              maxLength={60}
              type="hidden"
            />
            <FormLabel>Contato</FormLabel>
            <Input
              value={lead}
              onChange={handleLead}
              placeholder="(00) 00000-0000"
              className={validLead && "is-invalid"}
            />
            {validLead && <div className="invalid-feedback">{validLead}</div>}
          </Column>

          <Column col="12" md="8" marginBottom="3">
            <FormLabel>Unidade</FormLabel>
            <Select
              onChange={(e) => handleUnit(e)}
              value={unit}
              className={validUnit && "is-invalid"}
            >
              <option value="none" defaultValue>
                Selecione
              </option>
              {listCompany &&
                listCompany.length > 0 &&
                listCompany.map((data, index) => (
                  <option value={data.value} key={index}>
                    {data.label}
                  </option>
                ))}
            </Select>
            {validUnit && <div className="invalid-feedback">{validUnit}</div>}
          </Column>

          <Column col="12" md="4" marginBottom="3">
            <FormLabel>Origem</FormLabel>
            <Select onChange={(e) => handleSource(e)} value={source}>
              <option value="none" defaultValue>
                Selecione
              </option>
              {listSource.data &&
                listSource.data.length > 0 &&
                listSource.data.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.name}
                  </option>
                ))}
            </Select>
          </Column>

          <Column col="12" md="4" marginBottom="3">
            <FormLabel>Campanha</FormLabel>
            <Select onChange={(e) => handleCampaign(e)} value={campaign}>
              <option value="none" defaultValue>
                Selecione
              </option>
              {listCampaign.data &&
                listCampaign.data.length > 0 &&
                listCampaign.data.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.name}
                  </option>
                ))}
            </Select>
          </Column>
          <Column col="12" md="4" marginBottom="3">
            <FormLabel>Anúncio</FormLabel>
            <Select onChange={(e) => handleAd(e)} value={ad}>
              <option value="none" defaultValue>
                Selecione
              </option>
              {listAd.data &&
                listAd.data.length > 0 &&
                listAd.data.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.name}
                  </option>
                ))}
            </Select>
          </Column>

          <Column col="12" md="3" marginBottom="3">
            <FormLabel>Cadastrar Propecção</FormLabel>
            <Switch
              id="add_prospect"
              label={addProspect ? "Sim" : "Não"}
              onClick={handleAddProspect}
              checked={addProspect}
            />
          </Column>

          {addProspect && (
            <Column col="12" md="4" marginBottom="3">
              <FormLabel>Etapa</FormLabel>
              <Select
                onChange={(e) => handleProspectStatus(e)}
                value={prospectStatus}
                className={validProspectStatusList && "is-invalid"}
              >
                <option value="none" defaultValue>
                  Selecione
                </option>
                {prospectStatusList &&
                  prospectStatusList.length > 0 &&
                  prospectStatusList.map((data, index) => (
                    <option value={data.id} key={index}>
                      {data.name}
                    </option>
                  ))}
              </Select>
              {validProspectStatusList && (
                <div className="invalid-feedback">
                  {validProspectStatusList}
                </div>
              )}
            </Column>
          )}
        </Row>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
          >
            Cancelar
          </ButtonModalClose>
          <Button bgColor="success" txtColor="white" onClick={handleConfirm}>
            Cadastrar
          </Button>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default LeadModal;
