import { useEffect, useState } from "react";

import { ContainerFlex } from "../../../Components/Layout";

import Filters from "./Filters";
import CampaignList from "./CampaignList";
import Modal from "./Modal";
import ModalEdit from "./ModalEdit";
import ModalAd from "./ModalAd";

import useCampaign from "../../../Hooks/useCampaign";
// import SideFilter from "./SideFilter";

const Campaign = () => {
  const { indexCampaign } = useCampaign();

  const [reload, setReload] = useState(true);

  const handleReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    indexCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      <ContainerFlex full padding="3">
        <Filters setReload={handleReload} />
        <CampaignList />
      </ContainerFlex>
      <Modal setReload={handleReload} />
      <ModalEdit />
      <ModalAd />
      {/* <SideFilter setReload={handleReload} /> */}
    </>
  );
};

export default Campaign;
