import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import useDocumentation from "../../../Hooks/Documentation/useDocument";

import { Text } from "../../../Components/Text";
import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import DocumentItem from "./DocumentItemOLD";

const ItemList = ({ category, open }) => {
  const { indexDocumentation } = useDocumentation();

  const [loading, setLoading] = useState(true);
  const [listDocuments, setListDocuments] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const data = {
      data: [
        { name: "Aula 01 - Lorem ipsum dolor sit amet." },
        { name: "Aula 02 - Integer ac nulla ac mauris" },
        { name: "Aula 03 - Orci varius natoque penatibus" },
        { name: "Aula 04 - Fusce tincidunt nisl sed" },
        { name: "Aula 05 - Suspendisse fringilla lectus" },
        { name: "Aula 06 - Duis neque turpis" },
        { name: "Aula 07 - Quisque condimentum" },
      ],
    };
    if (category && open) {
      setLoading(true);
      handleListDocuments(data);
      // indexDocumentation({ category: category }, handleListDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, open]);

  useEffect(() => {
    if (reload) {
      setLoading(true);
      indexDocumentation({ category: category }, handleListDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const handleListDocuments = (data) => {
    setLoading(false);
    setReload(false);
    setListDocuments(data.data);
  };

  const handleLoadDocument = () => {
    if (listDocuments && listDocuments.length > 0) {
      return listDocuments.map((document) => (
        <DocumentItem key={document.id} item={document} />
      ));
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum anúncio foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <Row>
      <Column col="12" between middle bgColor="secondary" padding="2">
        <Text margin="0" txtColor="white">
          Documentos Encontrados
        </Text>
      </Column>

      <Column col="12" padding="0" border>
        {loading ? (
          <ContainerFlex full middle center column padding="2">
            <ReactLoading type={"spin"} color={"#1c208c"} height={"20"} />
            <Text>Carregando...</Text>
          </ContainerFlex>
        ) : (
          handleLoadDocument()
        )}
      </Column>
    </Row>
  );
};

export default ItemList;
