import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { Button } from "../../Components/Button";
import { ContainerFlex } from "../../Components/Layout";
import {
  Table,
  TableBody,
  TableHead,
  TableHeadTh,
  TableTd,
  TableTr,
} from "../../Components/Table";
import { Text } from "../../Components/Text";
import { Input, Select } from "../../Components/Form";

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
    >
      <option value="">Todos</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <Input
      variant="outlined"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
      placeholder={
        count > 1 ? `Pesquisar ${count} registros...` : "Pesquisar..."
      }
    />
  );
}

const UsersTable = ({ userslist }) => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (userslist) {
      setData(userslist);
    }
  }, [userslist]);

  const handleEdit = (data) => {
    navigate(`/gestao/usuarios/${data.currentTarget.id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Usuário",
        accessor: "name",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex middle>{value}</ContainerFlex>;
        },
      },
      {
        Header: "Unidade",
        accessor: "company.label",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              {value}
            </ContainerFlex>
          );
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: "Situação",
        accessor: "is_active",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              {value ? (
                <Text className="badge bg-primary">Ativo</Text>
              ) : (
                <Text className="badge bg-danger">Inativo</Text>
              )}
            </ContainerFlex>
          );
        },
        Filter: SelectColumnFilter,
      },

      {
        id: "Opções",
        Header: "Opções",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="d-flex justify-content-evenly">
              <Button
                bgColor="success"
                id={value}
                onClick={handleEdit}
                size="sm"
              >
                <Text tag="i" className="fa fa-pen" txtColor="white" />
              </Button>
            </div>
          );
        },
        Filter: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    { columns, defaultColumn, data },
    useFilters,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Table hover border {...getTableProps()} style={{ fontSize: "14px" }}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableTr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeadTh {...column.getHeaderProps()}>
                <ContainerFlex
                  {...column.getSortByToggleProps()}
                  center
                  column
                  height
                  width
                >
                  <ContainerFlex marginLeftRight="1" center>
                    {column.render("Header")}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </ContainerFlex>
              </TableHeadTh>
            ))}
          </TableTr>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableTr {...row.getRowProps()} id={`row-${row.original.id}`}>
              {row.cells.map((cell) => {
                return (
                  <TableTd {...cell.getCellProps()} middle>
                    {cell.render("Cell")}
                  </TableTd>
                );
              })}
            </TableTr>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default UsersTable;
