import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Editor from "./Editor";
import Message from "./Message";

import useTicket from "../../../Hooks/Support/useTicket";
import useMessage from "../../../Hooks/Support/useMessage";

import { Text } from "../../../Components/Text";
import { Card, CardBody, CardHeader } from "../../../Components/Card";
import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { formatDatetime } from "../../../Components/Form/masks";
import { Button } from "../../../Components/Button";
import Collapse from "../../../Components/Collapse";
import Upload from "./Upload";

const Ticket = () => {
  let navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const { id } = useParams();

  const { getTicket, closeTicket, setVizualizedClient } = useTicket();
  const { postMessage, indexMessage } = useMessage();

  const [ticket, setTicket] = useState({});
  const [messageList, setMessageList] = useState([]);

  const [title, setTitle] = useState("");

  const [text, setText] = useState("");
  const [validText, setValidText] = useState("");

  const [files, setFiles] = useState([]);

  const [errorTitle, setErrorTitle] = useState("");
  const [error, setError] = useState({});

  useEffect(() => {
    getTicket(id, setTicket, setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ticket) {
      setTitle(ticket.title);
      setMessageList(ticket.messages);

      if (ticket.vizualized_customer === false) {
        setVizualizedClient(ticket.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  const handleListMessage = () => {
    indexMessage({ support_id: ticket.id }, setMessageList, handleError);
  };

  const handleText = (e) => {
    setText(e);
    setValidText("");
  };

  const handleValidade = () => {
    let err = true;

    if (!text) {
      setValidText("A nota não pode ser vazia.");
      err = false;
    }

    return err;
  };

  const handleCloseTicket = () => {
    MySwal.fire({
      title: "Tem certeza que deseja fechar este ticket?",
      text: "Ao fechar o ticket, ele não poderá ser reaberto.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, fechar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        closeTicket(ticket.id, setTicket, setError);
        navigate(-1);
      }
    });
  };

  const handleSuccess = (rs) => {
    if (rs && rs.data) {
      setError({});
      setText("");
      setFiles([]);
      document.getElementById("new_message_button").click();
      handleListMessage();
    } else {
      setError(rs.data);
    }
  };

  const handleError = (error) => {
    console.error(error);

    if (error.data) {
      if (error.data.errors) {
        let arr = [];
        for (let key in error.data.errors) {
          arr.push({
            key: key,
            message: error.data.errors[key][0],
          });
        }
        setError(arr);
        setErrorTitle(`Erro: ${error.status} - ${error.data.message}`);
      }
      if (error.data.message) {
        setErrorTitle(`Erro: ${error.status} - ${error.data.message}`);
      }
    } else {
      setError({});
      setErrorTitle("");
    }
  };

  const handleSubmit = () => {
    if (handleValidade()) {
      setValidText("");

      let params = {
        support_id: ticket.id,
        message: text,
        files,
      };

      postMessage(params, handleSuccess, handleError);
    } else {
      setValidText("Preencha o campo de texto.");
    }
  };

  return (
    <ContainerFlex full padding="3" stretch>
      <Card padding="2" full>
        {(error && error.length > 0) ||
          (errorTitle && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                {errorTitle && (
                  <Text tag="h6" fontWeight="bolder">
                    {errorTitle}
                  </Text>
                )}
                {error &&
                  error.length > 0 &&
                  error.map((item) => {
                    return (
                      <Text tag="p" key={item.key}>
                        {item.message}
                      </Text>
                    );
                  })}
              </div>
            </Column>
          ))}
        {ticket && (
          <>
            <CardHeader>
              <Row>
                <Column col="12" between middle>
                  <Text className="h5" margin="0">
                    {title && title}
                  </Text>
                  <Button
                    size="sm"
                    padding="0"
                    paddingLeftRight="2"
                    marginRight="2"
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <Text tag="i" className="fas fa-angle-double-left" />
                    <Text marginLeft="2">Voltar</Text>
                  </Button>
                </Column>

                <Column col="12" md="3">
                  <Text tag="p" className="text-muted" margin="0">
                    Setor
                  </Text>
                  <Text fontWeight="bolder">
                    {ticket.category && ticket.category.name}
                  </Text>
                </Column>
                <Column col="12" md="3">
                  <Text tag="p" className="text-muted" margin="0">
                    Data de Abertura
                  </Text>
                  <Text fontWeight="bolder">
                    {formatDatetime(ticket && ticket.created_at)}
                  </Text>
                </Column>
                <Column col="12" md="3">
                  <Text tag="p" className="text-muted" margin="0">
                    Data de Atualização
                  </Text>
                  <Text fontWeight="bolder">
                    {formatDatetime(ticket && ticket.updated_at)}
                  </Text>
                </Column>
                <Column col="12" md="3">
                  <Text tag="p" className="text-muted" margin="0">
                    Situação
                  </Text>
                  <Text
                    style={{
                      backgroundColor: ticket.status && ticket.status.color,
                    }}
                    padding="1"
                    paddingLeftRight="3"
                    // margin="0"
                    // marginRight="2"
                    rounded
                    tag="small"
                  >
                    {ticket.status && ticket.status.name}
                  </Text>
                </Column>

                {ticket && ticket.status && ticket.status.is_open && (
                  <Column col="12" paddingTop="3">
                    <ContainerFlex right>
                      <Button
                        size="sm"
                        padding="0"
                        paddingLeftRight="2"
                        marginRight="2"
                        className="btn btn-outline-primary"
                        data-bs-toggle="collapse"
                        href="#new_message"
                        role="button"
                        aria-expanded="false"
                        aria-controls="new_message"
                        id="new_message_button"
                      >
                        <Text tag="i" className="fas fa-plus" />
                        <Text marginLeft="2">Interação</Text>
                      </Button>
                      <Button
                        size="sm"
                        padding="0"
                        paddingLeftRight="2"
                        marginRight="2"
                        className="btn btn-outline-danger"
                        onClick={handleCloseTicket}
                      >
                        <Text tag="i" className="fas fa-ban" />
                        <Text marginLeft="2">Encerrar</Text>
                      </Button>
                    </ContainerFlex>
                  </Column>
                )}
              </Row>
            </CardHeader>
            <CardBody padding="3">
              <Row>
                <Column col="12">
                  {messageList &&
                    messageList.length > 0 &&
                    messageList.map((message, index) => (
                      <Message key={index} message={message} />
                    ))}
                </Column>
              </Row>
            </CardBody>
            <Collapse marginBottom="3" id="new_message" padding="3" border>
              <Upload uploadedFiles={files} setUploadedFiles={setFiles} />
              <Editor content={text} setContent={handleText} />
              <Column col="12" marginTop="3"></Column>
              {validText && (
                <div className="invalid-feedback d-block">{validText}</div>
              )}
              <Column col="12" marginTop="3">
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  bgColor="success"
                  txtColor="white"
                  full
                >
                  <Text marginRight="1">Enviar</Text>
                  <Text tag="i" className="fas fa-paper-plane" />
                </Button>
              </Column>
            </Collapse>
          </>
        )}
      </Card>
    </ContainerFlex>
  );
};

export default Ticket;
