const initialState = {
  confirmOpen: false,
  confirmTitle: "",
  confirmMessage: "",
};

const Modal = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MODAL_CONFIRM_OPEN":
      return { ...state, confirmOpen: action.payload.confirmOpen };
    case "SET_MODAL_CONFIRM_TITLE":
      return { ...state, confirmTitle: action.payload.confirmTitle };
    case "SET_MODAL_CONFIRM_MESSAGE":
      return { ...state, confirmMessage: action.payload.confirmMessage };
    case "MODAL_RESET":
      return initialState;
    default:
      return state;
  }
};

export default Modal;
