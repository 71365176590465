import { useEffect, useState } from "react";

import ProspectTable from "./ProspectTable";
import FilterOptions from "./FilterOptions";
import Action from "./Action";

import { ContainerFlex } from "../../Components/Layout";
import { Button, ButtonModal } from "../../Components/Button";
import { Card, CardBody } from "../../Components/Card";
import { Nav } from "../../Components/Nav";

import useNote from "../../Hooks/useNotes";
import useProspect from "../../Hooks/useProspect";

const Content = () => {
  const {
    dataProspect,
    indexProspectFilter,
    dataProspectStatus,
    setDataProspectStatus,
    listProspectStatus,
    activeProspectId,
    activeProspectAction,
  } = useProspect();
  const { indexNote } = useNote();

  const [tabList, setTabList] = useState([]);
  const [reload, setReload] = useState(false);
  const [status, setStatus] = useState("");
  const [company, setCompany] = useState("");
  const [prospects, setProspects] = useState({});
  const [links, setLinks] = useState({});

  const handleProspects = (page) => {
    if (company && status) {
      let data = {
        company_id: company,
        status_id: Number(dataProspectStatus.replace("menu-", "")),
      };
      if (page) {
        data.page = page;
      }
      indexProspectFilter(data, setProspects);
    }
  };

  const handlePage = (e, link) => {
    e.preventDefault();
    if (link.url !== null)
      handleProspects(
        link.url.replace("http://localhost:8000/api/prospect?page=", "")
      );
  };

  const handleStatus = (e) => {
    setStatus(e.currentTarget.id.replace("menu-", ""));
    setDataProspectStatus(e.currentTarget.id);

    handleProspects();
  };

  useEffect(() => {
    if (dataProspect && dataProspect.id && activeProspectId) {
      let thisModal = document.getElementById("Prospect_Modal_Open");
      thisModal.click();
      let thisActive = document.getElementById("nav-notes-tab");
      thisActive.click();

      if (
        Array.isArray(dataProspect.lead.patients) &&
        dataProspect.lead.patients[0]
      ) {
        indexNote({ patient: dataProspect.lead.patients[0].id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProspect, activeProspectId]);

  useEffect(() => {
    if (dataProspect && dataProspect.id && activeProspectAction) {
      let thisModal = document.getElementById("Prospect_Modal_Open_Action");
      thisModal.click();
    }
  }, [dataProspect, activeProspectAction]);

  useEffect(() => {
    if (
      listProspectStatus &&
      listProspectStatus.data &&
      listProspectStatus.data.length > 0
    ) {
      let thisTab = [];
      listProspectStatus.data.forEach((item) => {
        if (item.show) {
          thisTab.push(item);
        }
      });
      setTabList(thisTab);
    }
  }, [listProspectStatus]);

  useEffect(() => {
    if (prospects) {
      setLinks(prospects.meta);
    }
  }, [prospects]);

  useEffect(() => {
    if ((company, status)) {
      handleProspects();
    }
  }, [company, status]);

  useEffect(() => {
    if (company && dataProspectStatus) {
      setStatus(dataProspectStatus && dataProspectStatus.replace("menu-", ""));
      handleProspects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContainerFlex padding="3">
        <FilterOptions
          reload={reload}
          setReload={setReload}
          setCompany={setCompany}
        />
        <Nav
          className="nav nav-pills nav-justified"
          id="pills-tab"
          role="tablist"
        >
          {tabList &&
            tabList.length > 0 &&
            tabList.map((data, index) => (
              <Button
                className={
                  dataProspectStatus === `menu-${data.id}`
                    ? "nav-link active active-tab rounded-top"
                    : "nav-link border border-1 bg-white"
                }
                aria-current="page"
                id={`menu-${data.id}`}
                key={index}
                data-bs-toggle="tab"
                data-bs-target={`#nav-${data.id}`}
                onClick={handleStatus}
              >
                {data.name}
              </Button>
            ))}
        </Nav>
        <ContainerFlex className="tab-content shadow" id="nav-tabContent" width>
          <ContainerFlex
            className={"tab-pane fade show active"}
            role="tabpanel"
          >
            <Card bgColor="white" rounded="0">
              <CardBody width>
                <ProspectTable listProspect={prospects.data} />

                {links && links.links && Array.isArray(links.links) && (
                  <ContainerFlex paddingTop="2" center>
                    <nav aria-label="...">
                      <ul className="pagination m-0">
                        {links.links.map((link, index) => (
                          <li
                            className={`page-item ${
                              link.url == null && "disabled"
                            }`}
                            key={index}
                          >
                            <a
                              className={`page-link ${
                                link.active ? "active" : "pe-auto"
                              }`}
                              href=""
                              onClick={(e) => handlePage(e, link)}
                            >
                              {link.label
                                .replace("&laquo;", "")
                                .replace("&raquo;", "")}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </ContainerFlex>
                )}
              </CardBody>
            </Card>
          </ContainerFlex>
        </ContainerFlex>
      </ContainerFlex>
      <Action />
      <ButtonModal
        id="Prospect_Modal_Open_Action"
        className="d-none"
        modal="Prospect_Modal_Action"
      />
    </>
  );
};

export default Content;
