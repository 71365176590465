import React, { useState } from "react";
import ShortUniqueId from "short-unique-id";

export default function Uuid() {
  const [LEN, setLEN] = useState(8);
  const [count, setCount] = useState(0);
  const [UUID, setUUID] = useState("");
  const [list, setList] = useState([]);

  const [STATNOBJ, setSTATNOBJ] = useState(1000);
  const myDict = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "t",
    "w",
    "x",
    "y",
    "z",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];

  let uid = new ShortUniqueId({
    dictionary: myDict,
    length: LEN,
  });

  function regenUUID() {
    if (count > 0) {
      let li = [];

      for (let i = 0; i < count; i++) {
        uid = new ShortUniqueId({
          dictionary: myDict,
          length: LEN,
        });
        li.push(uid());
      }
      setList(li);
    } else {
      setList([]);

      uid = new ShortUniqueId({
        dictionary: myDict,
        length: LEN,
      });
      setUUID(uid());
    }
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-between w-100">
      <h1>Unique ID</h1>
      <p>Dictionary size: {uid.dictLength}</p>
      <p>
        Length:{" "}
        <input
          style={{ maxWidth: 50 }}
          value={LEN}
          onChange={(e) => setLEN(e.target.value)}
        />
      </p>
      <p>
        Count:{" "}
        <input
          style={{ maxWidth: 50 }}
          value={count}
          onChange={(e) => setCount(e.target.value)}
        />
      </p>
      Collision probability: {(100.0 * uid.collisionProbability()).toFixed(3)} %
      <p>
        <button
          onClick={regenUUID}
          className="btn bg-danger p-2 m-2 text-white"
        >
          GENERATE
        </button>
      </p>
      <p>
        On{" "}
        {
          <input
            style={{ maxWidth: 50 }}
            value={STATNOBJ}
            onChange={(e) => setSTATNOBJ(e.target.value)}
          />
        }{" "}
        could exists{" "}
        <span
          style={{
            color: uid.collisionProbability() * STATNOBJ < 1 ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {(uid.collisionProbability() * STATNOBJ).toFixed(2)}
        </span>{" "}
        ids collisions
      </p>
      <hr className="w-100" />
      {count === 0 ? (
        <span style={{ fontFamily: "Liberation Mono" }}>
          <h2>{UUID}</h2>
        </span>
      ) : (
        <ul>
          {list.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
