import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import { sizingProps, sizingClass } from '../utilities/sizing';
import { spacingProps, spacingClass } from '../utilities/spacing';
import { bordersProps, bordersClass } from '../utilities/borders';

const Image = (props) => {

  let { tag: Tag, children, className, src, ...attributes } = props;
  
  let sizing, spacing, borders;
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);

  return (
    <Tag 
      {...attributes}
      src={src}
      className={cn(
        'img-fluid',
        sizing,
        spacing,
        borders,
        className
      )} 
    />);
};

Image.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  ...sizingProps(),
  ...spacingProps(),
  ...bordersProps(),
};

Image.defaultProps = {
  tag: 'img',
  className: '',
};

export default Image;
