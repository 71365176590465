import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

import ProspectTable from "./ProspectTable";

import { Column, ContainerFlex, Row } from "../../Components/Layout";
import { Text } from "../../Components/Text";
import { Card, CardBody, CardHeader } from "../../Components/Card";

const Content = ({ prospectList }) => {
  const [totalCount, setTotalCount] = useState(0);
  const [totalValor, setTotalValor] = useState(0);

  const handleTotal = () => {
    let valor = 0;
    prospectList.forEach((element) => {
      valor += Number(element.value);
    });
    setTotalValor(valor);
  };

  useEffect(() => {
    if (Array.isArray(prospectList)) {
      setTotalCount(prospectList.length);
      handleTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectList]);

  return (
    <ContainerFlex full>
      <Row>
        <Column col="12" md="4">
          <ContainerFlex
            padding="3"
            style={{
              background: "#FFF7CD",
              borderRadius: "10px",
              border: "1px solid rgba(122, 79, 1, 0.2)",
              color: "rgb(122, 79, 1)",
            }}
          >
            <Text tag="h3">{totalCount}</Text>
            <Text tag="small" className="text-muted">
              Quantidade
            </Text>
          </ContainerFlex>
        </Column>
        <Column col="12" md="4">
          <ContainerFlex
            padding="3"
            column
            style={{
              background: "#C8FACD",
              borderRadius: "10px",
              border: "1px solid rgba(0, 82, 73, 0.2)",
              color: "rgb(0, 82, 73)",
            }}
          >
            <NumberFormat
              value={totalValor}
              prefix="R$ "
              type="text"
              displayType="text"
              thousandsGroupStyle="thousand"
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              className="h3"
            />
            <Text tag="small" className="text-muted">
              Valor
            </Text>
          </ContainerFlex>
        </Column>
      </Row>
      <hr />
      <Card bgColor="white">
        <CardHeader bgColor="ydental-dark" txtColor="white">
          <Text tag="h3">Conversões</Text>
        </CardHeader>
        <CardBody>
          <ProspectTable listProspect={prospectList} />
        </CardBody>
      </Card>
    </ContainerFlex>
  );
};

export default Content;
