import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { colorsDef } from "../utilities/colors";
import { textProps, textClass } from "../utilities/text";
import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";
import { flexProps, flexClass } from "../utilities/flex";

const Alert = (props) => {
  let {
    tag: Tag,
    className,
    children,
    dismissible,
    color,
    close,
    ...attributes
  } = props;

  let sizing, spacing, text, flex;
  [text, attributes] = textClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [flex, attributes] = flexClass(attributes);

  return (
    <Tag
      className={cn(
        "alert",
        flex,
        text,
        sizing,
        spacing,
        color && `alert-${color}`,
        dismissible && "alert-dismissible",
        className
      )}
      {...attributes}
      role="alert"
    >
      {children}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={close}
      ></button>
    </Tag>
  );
};

Alert.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([...colorsDef]),
  dismissible: PropTypes.bool,
  close: PropTypes.func,
  disabled: PropTypes.bool,
  ...textProps(),
  ...sizingProps(),
  ...spacingProps(),
  ...flexProps(),
};

Alert.defaultProps = {
  tag: "div",
  color: "primary",
  dismissible: true,
  close: () => {},
  className: "",
};

export default Alert;
