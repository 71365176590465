import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useAgenda = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const activeAgenda = useSelector((state) => state.agenda.active);
  const listAgenda = useSelector((state) => state.agenda.index);
  const dataAgenda = useSelector((state) => state.agenda.data);

  function setActiveAgenda(active) {
    dispatch({
      type: "CONTENT_AGENDA_ACTIVE",
      payload: { active: active },
    });
  }

  function setIndex(index) {
    dispatch({
      type: "CONTENT_AGENDA_INDEX",
      payload: { index: index },
    });
  }

  function setDataAgenda(data) {
    dispatch({
      type: "CONTENT_AGENDA_DATA",
      payload: { data: data },
    });
  }

  function resetAgenda() {
    dispatch({
      type: "CONTENT_AGENDA_RESET",
    });
  }

  const indexAgenda = () => {
    api
      .get("/agenda", token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const getAgenda = (id) => {
    api
      .get(`/agenda/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataAgenda(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const postAgenda = (params, success, error) => {
    api
      .post("/agenda", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataAgenda(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateAgenda = (id, params, success, error) => {
    api
      .update(`/agenda/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delAgenda = (id, success, error) => {
    api
      .delete(`/agenda/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getAgenda,
    postAgenda,
    indexAgenda,
    updateAgenda,
    delAgenda,
    resetAgenda,
    activeAgenda,
    dataAgenda,
    listAgenda,
    setActiveAgenda,
    setDataAgenda,
  };
};

export default useAgenda;
