import React from 'react';
import PropTypes from 'prop-types';

import { textProps, textClass } from '../utilities/text';
import { colorsProps, colorsClass } from '../utilities/colors';
import { spacingProps, spacingClass } from '../utilities/spacing';

import cn from 'classnames';

const ModalBody = (props) => {
  let {
    tag: Tag,
    className,
    children,
    ...attributes
  } = props;

  let colors, spacing, text;
  [text, attributes] = textClass(attributes);
  [colors, attributes] = colorsClass(attributes);
  [spacing, attributes] = spacingClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn(
        'modal-body',
        text,
        colors,
        spacing,
        className
      )}
    >
      {children}
    </Tag>
  );
};

ModalBody.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  ...textProps(),
  ...colorsProps(),
  ...spacingProps(),
};

ModalBody.defaultProps = {
  tag: 'div',
  className: '',
};

export default ModalBody;
