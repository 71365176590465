import { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";

import useAd from "../../../Hooks/useAd";

import {
  Table,
  TableBody,
  TableHead,
  TableHeadTh,
  TableTr,
  TableTd,
} from "../../../Components/Table";
import { Text } from "../../../Components/Text";
import { ButtonModal } from "../../../Components/Button";
import { ContainerFlex } from "../../../Components/Layout";

const AdsTable = ({ listAds }) => {
  const { getAd } = useAd();
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(listAds);
  }, [listAds]);

  const handleAd = (id) => {
    getAd(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Anúncio",
        accessor: "name",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              {value}
            </ContainerFlex>
          );
        },
      },
      {
        Header: "Tag",
        accessor: "tag",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              {value}
            </ContainerFlex>
          );
        },
      },
      {
        id: "Ativo",
        Header: "Unidade",
        accessor: "is_active",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              {value ? (
                <Text
                  tag="small"
                  fontWeight="bolder"
                  bgColor="success"
                  padding="1"
                  paddingLeftRight="2"
                  rounded
                  txtColor="white"
                >
                  Ativo
                </Text>
              ) : (
                <Text
                  tag="small"
                  fontWeight="bolder"
                  bgColor="danger"
                  padding="1"
                  paddingLeftRight="2"
                  rounded
                  txtColor="white"
                >
                  Inativo
                </Text>
              )}
            </ContainerFlex>
          );
        },
      },
      {
        Header: "Opções",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              <ButtonModal
                size="sm"
                modal="Ad_Modal_Campaign"
                bgColor="info"
                onClick={() => handleAd(value)}
                txtColor="white"
              >
                <Text tag="i" className="fa fa-pen" /> Editar
              </ButtonModal>
            </ContainerFlex>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Table
      hover
      border
      {...getTableProps()}
      style={{ fontSize: "12px" }}
      margin="0"
    >
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableTr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeadTh {...column.getHeaderProps()} txtAlign="center">
                {column.render("Header")}
              </TableHeadTh>
            ))}
          </TableTr>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableTr {...row.getRowProps()} id={`row-${row.original.id}`}>
              {row.cells.map((cell) => {
                return (
                  <TableTd {...cell.getCellProps()} middle>
                    {cell.render("Cell")}
                  </TableTd>
                );
              })}
            </TableTr>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default AdsTable;
