import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import CategoryItem from "./CategoryItem";

import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
// import useDocumentation from "../../../Hooks/Documentation/useDocument";

const List = () => {
  // const { indexDocumentation } = useDocumentation();

  const [loading, setLoading] = useState(true);
  const [ticket, setDocumentation] = useState();

  useEffect(() => {
    const data = {
      data: [
        { id: "teste_101", name: "Recepção" },
        { id: "teste_102", name: "Avaliação" },
        { id: "teste_103", name: "Negociação" },
        { id: "teste_104", name: "Auxiliar de Limpeza" },
        { id: "teste_105", name: "Dentista" },
        { id: "teste_106", name: "TSB" },
        { id: "teste_107", name: "Retorno" },
      ],
    };
    setTimeout(() => {
      handleListDocuments(data);
    }, 1000);
    // indexDocumentation({}, handleListDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (listCategory && listCategory.data) {
  //     setDocumentation(listCategory.data);
  //     setLoading(false);
  //   }
  // }, [listCategory]);

  const handleListDocuments = (data) => {
    setLoading(false);
    setDocumentation(data.data);
  };

  const handleDocumentation = () => {
    if (ticket && ticket.length > 0) {
      return ticket.map((data, index) => (
        <CategoryItem key={index} item={data}></CategoryItem>
      ));
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum item foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <ContainerFlex
      padding="2"
      className="accordion accordion-flush"
      id="documentations-collapse-list"
    >
      {loading ? (
        <ContainerFlex full middle center column>
          <ReactLoading type={"spin"} color={"#1c208c"} />
          <Text>Carregando...</Text>
        </ContainerFlex>
      ) : (
        handleDocumentation()
      )}
    </ContainerFlex>
  );
};

export default List;
