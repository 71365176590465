import PropTypes from "prop-types";

export function textProps() {
  return {
    txtAlign: PropTypes.oneOf([
      "start",
      "center",
      "end",
      true,
      false,
      undefined,
    ]),
    txtTrans: PropTypes.oneOf([
      "lowercase",
      "uppercase",
      "capitalize",
      true,
      false,
      undefined,
    ]),
    fontItalic: PropTypes.bool,
    fontWeight: PropTypes.oneOf([
      "bold",
      "bolder",
      "normal",
      "light",
      "lighter",
      true,
      false,
      undefined,
    ]),
    fontSize: PropTypes.oneOf(["1", "2", "3", "4", "5", "6"]),
  };
}

export function textClass(obj) {
  let className = "";
  let { txtAlign, txtTrans, fontItalic, fontWeight, fontSize, ...attributes } =
    obj;

  if (fontSize) {
    className += `fs-${fontSize} `;
  }

  if (typeof txtAlign === "boolean") {
    if (txtAlign) className += "text-center ";
  } else {
    if (txtAlign) className += `text-${txtAlign} `;
  }

  if (typeof txtTrans === "boolean") {
    if (txtTrans) className += "text-uppercase ";
  } else {
    if (txtTrans) className += `text-${txtTrans} `;
  }

  if (fontItalic) className += "font-italic ";

  if (typeof fontWeight === "boolean") {
    if (fontWeight) className += "fw-bold ";
  } else {
    if (fontWeight) className += `fw-${fontWeight} `;
  }

  return [className.trim(), attributes];
}
