const initialState = {
  token: "",
  me: {},
};

const Auth = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return { ...state, token: action.payload.token };
    case "SET_ME":
      return { ...state, me: action.payload.me };
    case "RESET_TOKEN":
      return initialState;

    default:
      return state;
  }
};

export default Auth;
