import instance from "./config";

const api = {
  index: (token) =>
    instance({
      method: "GET",
      url: "/companies",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  get: (token, id) =>
    instance({
      method: "GET",
      url: `/companies/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  post: (token, data) =>
    instance({
      method: "POST",
      url: "/companies",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    }),

  update: (token, id, data) =>
    instance({
      method: "PUT",
      url: `/companies/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    }),

  delete: (token, id, setResult) =>
    instance({
      method: "GET",
      url: `/os/cpe/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setResult(response.data);
      })
      .catch((error) => {
        console.warn(error);
      }),
};

export default api;
