const initialState = {
  active: "",
  index: [],
  selected: [],
  data: {},
};

const ContentCampaign = (state = initialState, action) => {
  switch (action.type) {
    case "CONTENT_CAMPAIGN_ACTIVE":
      return { ...state, active: action.payload.active };
    case "CONTENT_CAMPAIGN_INDEX":
      return { ...state, index: action.payload.index };
    case "CONTENT_CAMPAIGN_SELECTED":
      return { ...state, selected: action.payload.selected };
    case "CONTENT_CAMPAIGN_DATA":
      return { ...state, data: action.payload.data };
    case "CONTENT_CAMPAIGN_RESET":
      return initialState;
    default:
      return state;
  }
};

export default ContentCampaign;
