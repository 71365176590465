import React, { useState } from "react";

import { Modal, ModalBody, ModalFooter } from "../../../Components/Modal";
import { ContainerFlex } from "../../../Components/Layout";
import { Button, ButtonModalClose } from "../../../Components/Button";

import Editor from "./Editor";
import useNote from "../../../Hooks/useNotes";

const NotesModalNew = ({ history }) => {
  const { postNote, indexNote } = useNote();

  const [title, setTitle] = useState("");

  const [text, setText] = useState("");
  const [validText, setValidText] = useState("");

  const handleText = (e) => {
    setText(e);
    setValidText("");
  };

  const handleTitle = (e) => {
    setTitle(e.currentTarget.value);
  };

  const handleValidade = () => {
    let err = true;

    if (!text) {
      setValidText("A nota não pode ser vazia.");
      err = false;
    }
    if (!title) {
      setValidText("O titulo não pode ser vazia.");
      err = false;
    }

    return err;
  };

  const handleSuccess = () => {
    document.getElementById("Notes_Modal_New_Close").click();
  };

  const handleConfirm = () => {
    if (handleValidade()) {
      let params = {
        title: title,
        note: text,
        history_id: history,
      };
      postNote(params, handleSuccess);
    }
  };

  const handleDataDefault = () => {
    indexNote({ history_id: history });
    setTitle("");
    setText("");
    setValidText("");
  };

  return (
    <Modal id="Notes_Modal_New" center fixed scroll size="lg">
      <ModalBody padding="3">
        <ContainerFlex column>
          <Editor
            content={text}
            setContent={handleText}
            title={title}
            handleTitle={handleTitle}
          />

          {validText && (
            <div className="invalid-feedback d-block">{validText}</div>
          )}
        </ContainerFlex>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
            id="Notes_Modal_New_Close"
          >
            Cancelar
          </ButtonModalClose>
          <Button bgColor="success" txtColor="white" onClick={handleConfirm}>
            Cadastrar
          </Button>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default NotesModalNew;
