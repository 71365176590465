import { useEffect, useState } from "react";
import Content from "./Content";
import { ContainerFlex } from "../../Components/Layout";

import useProspect from "../../Hooks/useProspect";
import useAgenda from "../../Hooks/useAgenda";

const ProspectList = () => {
  const {
    indexProspectStatus,
    indexLeadHistoryType,
    setActiveProspectAction,
    setActiveProspectId,
  } = useProspect();
  const { indexAgenda } = useAgenda();

  const [reload, setReload] = useState(true);
  const [setProspectStatusList] = useState([]);

  const handleReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    indexProspectStatus({ all: 0 }, setProspectStatusList);
    indexLeadHistoryType();
    indexAgenda();
    setActiveProspectAction("");
    setActiveProspectId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <ContainerFlex>
      <Content setReload={handleReload} />
    </ContainerFlex>
  );
};

export default ProspectList;
