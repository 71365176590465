import React from "react";
import PropTypes from "prop-types";

import { colorsDef } from "../utilities/colors";
import { textProps, textClass } from "../utilities/text";
import { colorsProps, colorsClass } from "../utilities/colors";
import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";
import { flexProps, flexClass } from "../utilities/flex";

import cn from "classnames";

const Accordion = (props) => {
  let {
    tag: Tag,
    flush,
    id,
    className,
    children,
    onClick,
    ...attributes
  } = props;

  let colors, sizing, spacing, text, flex;
  [text, attributes] = textClass(attributes);
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [flex, attributes] = flexClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn(
        `accordion ${flush && "accordion-flush"}`,
        flex,
        text,
        sizing,
        spacing,
        colors,
        className
      )}
      onClick={onClick}
      id={`accordion-${id}`}
    >
      {children}
    </Tag>
  );
};

Accordion.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOf([...colorsDef]),
  flush: PropTypes.bool,
  id: PropTypes.string.isRequired,
  ...textProps(),
  ...sizingProps(),
  ...colorsProps(),
  ...spacingProps(),
  ...flexProps(),
};

Accordion.defaultProps = {
  tag: "div",
  flush: false,
  className: "",
};

export default Accordion;
