import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import CampaignItem from "./CampaignItem";
import useCampaign from "../../../Hooks/useCampaign";

import { ContainerFlex } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { Button, ButtonModal } from "../../../Components/Button";
import { Card, CardBody, CardHeader } from "../../../Components/Card";

const CampaignList = () => {
  const { listCampaign } = useCampaign();

  const [loading, setLoading] = useState(true);
  const [campaigns, setcampaigns] = useState([]);

  useEffect(() => {
    if (listCampaign && listCampaign.data) {
      setcampaigns(listCampaign.data);
      setLoading(false);
    }
  }, [listCampaign]);

  const handleCampign = () => {
    if (campaigns && campaigns.length > 0) {
      return campaigns.map((data, index) => (
        <CampaignItem key={index} item={data}></CampaignItem>
      ));
    } else {
      <ContainerFlex full middle center column>
        <Text>Nenhum item foi localizado.</Text>
      </ContainerFlex>;
    }
  };
  const handleReload = () => {
    document.getElementById("btn-filter-campaign").click();
  };

  return (
    <Card marginTop="3" padding="2">
      <CardHeader between middle>
        <Text className="h5" margin="0">
          Listagem de Campanhas
        </Text>
        <ContainerFlex row>
          <Button
            onClick={handleReload}
            modal="Campaign_Modal"
            id="btn-reload-campaign"
          >
            <Text tag="i" className="fa fa-redo-alt" />
          </Button>
          <ButtonModal modal="Campaign_Modal">
            <Text tag="i" className="fa fa-plus" />
          </ButtonModal>
          {/* <Button
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvas-campaign"
          >
            <i className="fa fa-filter"></i>
          </Button> */}
        </ContainerFlex>
      </CardHeader>
      <CardBody padding="0">
        <ContainerFlex
          padding="2"
          className="accordion accordion-flush"
          id="campaign-collapse-list"
        >
          {loading ? (
            <ContainerFlex full middle center column>
              <ReactLoading type={"spin"} color={"#1c208c"} />
              <Text>Carregando...</Text>
            </ContainerFlex>
          ) : (
            handleCampign()
          )}
        </ContainerFlex>
      </CardBody>
    </Card>
  );
};

export default CampaignList;
