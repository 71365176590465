import { ContainerFlex } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { Button } from "../../../Components/Button";

import Item from "./ContentItem";

import useAd from "../../../Hooks/useAd";

const Content = () => {
  const { listAd } = useAd();

  return (
    <ContainerFlex full padding="3">
      <ContainerFlex between middle>
        <Text tag="h5" margin="0">
          Listagem de Anúncios
        </Text>
        <ContainerFlex row>
          <Button>
            <i className="fa fa-bars"></i>
          </Button>
          <Button>
            <i className="fa fa-th"></i>
          </Button>
        </ContainerFlex>
      </ContainerFlex>
      <hr />

      {listAd.data && listAd.data.length > 0 ? (
        listAd.data.map((data, index) => <Item key={index} item={data}></Item>)
      ) : (
        <ContainerFlex full center middle column>
          <Text tag="i" className="fa fa-frown h1" />
          <Text tag="h5">Nenhum item foi encontrado</Text>
        </ContainerFlex>
      )}
    </ContainerFlex>
  );
};

export default Content;
