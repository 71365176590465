import ShortUniqueId from "short-unique-id";

const Uuid = () => {
  const myDict = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "t",
    "w",
    "x",
    "y",
    "z",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];

  function genUUID(LEN) {
    let uid = new ShortUniqueId({
      dictionary: myDict,
      length: LEN,
    });
    return uid();
  }

  return { genUUID };
};

export default Uuid;
