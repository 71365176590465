import PropTypes from 'prop-types';

const sizes = ['0', '1', '2', '3', '4', '5'];

export function gutterProps() {
  return {
    gutter: PropTypes.oneOf([...sizes]),
    gutterLeftRight: PropTypes.oneOf([...sizes]),
    gutterTopBottom: PropTypes.oneOf([...sizes]),
  };
}

export function gutterClass(obj) {
  let className = '';
  let {
    gutter,
    gutterLeftRight,
    gutterTopBottom,
    ...attributes
  } = obj;

  if (gutter) {
    className += `g-${gutter} `;
  }

  if (gutterLeftRight) {
    className += `gx-${gutterLeftRight} `;
  }

  if (gutterTopBottom) {
    className += `gy-${gutterTopBottom} `;
  }

  return [className.trim(), attributes];
}
