import useTask from "../../Hooks/useTask";
import { Button } from "../../Components/Button";
import { Column, ContainerFlex, Row } from "../../Components/Layout";
import { Text } from "../../Components/Text";
import { formatPhone, formatDatetime } from "../../Components/Form/masks";
import { CardItem } from "./Style";

const TaskItem = ({ event, success }) => {
  const { updateTask } = useTask();

  const handleError = (error) => {
    console.error(error.data);
  };

  const handleConfirm = () => {
    let params = {
      title: event.title,
      desc: event.desc,
      dt_start: event.start,
      dt_end: event.end,
      lead_id: event.lead.lead.id,
      type_id: event.type.id,
      is_active: 0,
      history_id: event.lead.lead.history_id,
    };

    updateTask(event.id, params, success, handleError);
  };

  return (
    <CardItem className="shadow-sm">
      <ContainerFlex row full>
        <ContainerFlex column paddingRight="3">
          <Text
            fontWeight="bolder"
            fontSize="5"
            style={{ color: event.type.color }}
            padding="2"
            rounded
            tag="i"
            className={`h1 ${event.type.icon}`}
          />
        </ContainerFlex>
        <ContainerFlex full>
          <Row>
            <Column col="12" md="4">
              {event.start && (
                <Text
                  tag="small"
                  className="text-muted"
                  border
                  rounded
                  paddingLeftRight="2"
                >
                  {formatDatetime(event.start)}
                </Text>
              )}
            </Column>
            <Column col="12" md="8">
              <ContainerFlex right>
                {/* <Button
                  size="sm"
                  bgColor="info"
                  txtColor="white"
                  marginRight="2"
                  onClick={handleConfirm}
                  row
                  middle
                >
                  Modificar
                </Button> */}
                <Button
                  size="sm"
                  bgColor="success"
                  txtColor="white"
                  marginRight="2"
                  onClick={handleConfirm}
                  row
                  middle
                >
                  <Text tag="i" className="fa fa-check" marginRight="2" />
                  Finalizar
                </Button>
              </ContainerFlex>
            </Column>
            <Column col="12">
              {event.lead && event.lead.patient && (
                <Text tag="h5" fontWeight="bolder">
                  {event.lead.patient.name}
                </Text>
              )}
            </Column>
            <Column col="12">
              {event.lead && event.lead.lead && (
                <>
                  <Text tag="small" className="text-muted" marginRight="2">
                    Contato:
                  </Text>
                  <Text tag="small" fontWeight="bolder">
                    {formatPhone(event.lead.lead.lead)}
                  </Text>
                </>
              )}
            </Column>
            <Column col="12">
              {event.note && event.note.title && (
                <>
                  <Text tag="small" className="text-muted" marginRight="2">
                    Anotação:
                  </Text>
                  <Text tag="small">{event.note.title}</Text>
                </>
              )}
            </Column>
            <Column col="12">
              {event.note && event.note.note && (
                <Text tag="small">{event.note.note}</Text>
              )}
            </Column>
          </Row>
        </ContainerFlex>
      </ContainerFlex>
    </CardItem>
  );
};

export default TaskItem;
