import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";

import {
  Table,
  TableBody,
  TableHead,
  TableHeadTh,
  TableTd,
  TableTr,
} from "../../Components/Table";

// import useProspect from "../../Hooks/useProspect";

import { formatPhone, formatDate } from "../../Components/Form/masks";
import { ContainerFlex } from "../../Components/Layout";
import NumberFormat from "react-number-format";

const ProspectTable = ({ listProspect }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Array.isArray(listProspect)) {
      setData(listProspect);
    }
  }, [listProspect]);

  const columns = useMemo(
    () => [
      {
        Header: "Dados do Contato",
        columns: [
          {
            id: "Contato",
            Header: "Contato",
            accessor: "lead.lead.lead",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value && formatPhone(value)}
                </ContainerFlex>
              );
            },
          },
          {
            Header: "Nome",
            accessor: "lead.patient.name",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
        ],
      },
      {
        id: "Campanha",
        Header: "Campanha",
        columns: [
          {
            Header: "Data",
            accessor: "updated_at",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value && formatDate(value)}
                </ContainerFlex>
              );
            },
          },
          {
            Header: "Fluxo",
            accessor: "lead.inflow.source.name",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
          {
            Header: "Anuncio",
            accessor: "lead.inflow.ad.name",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
          {
            id: "Conversão",
            Header: "Conversão",
            accessor: "value",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex right middle>
                  <NumberFormat
                    value={value && value.replace(".", ",")}
                    prefix="R$ "
                    type="text"
                    displayType="text"
                    thousandsGroupStyle="thousand"
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </ContainerFlex>
              );
            },
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Table hover border {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableTr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeadTh {...column.getHeaderProps()} txtAlign="center">
                {column.render("Header")}
              </TableHeadTh>
            ))}
          </TableTr>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableTr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableTd
                    {...cell.getCellProps()}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {cell.render("Cell")}
                  </TableTd>
                );
              })}
            </TableTr>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ProspectTable;
