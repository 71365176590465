import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Text } from "../../../Components/Text";
import { Input } from "../../../Components/Form";
import { ContainerFlex } from "../../../Components/Layout";
import { Button, ButtonGroup, ButtonModal } from "../../../Components/Button";
import { NavBar, NavItem } from "../../../Components/Nav";
import Notification from "./Notification";

const Topbar = ({ prospect }) => {
  const MySwal = withReactContent(Swal);
  const handleLogout = () => {
    MySwal.fire({
      title: <h3>Sair do Sistema</h3>,
      html: <p>Você tem certeza que deseja encerrar a sessão?</p>,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Encerrar",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("persist:root");
        window.location = "/";
      }
    });
  };

  return (
    <ContainerFlex
      width
      middle
      txtColor="white"
      bgColor="white"
      between
      className="navbar navbar-expand-md navbar-light bg-white shadow-sm border-bottom"
      paddingLeftRight="3"
    >
      <ContainerFlex row>
        {prospect && (
          <>
            <ButtonGroup>
              <Input
                type="search"
                placeholder="Pesquisar..."
                aria-label="Pesquisar"
              />
            </ButtonGroup>
            <ButtonModal modal="Lead_Modal" size="sm" txtColor="dark">
              <Text className="fa fa-plus" />
              <Text marginLeft="2">Prospecção</Text>
            </ButtonModal>
          </>
        )}
      </ContainerFlex>
      <ContainerFlex>
        <NavBar>
          <Notification />
          <NavItem>
            <Button size="sm" onClick={handleLogout} txtColor="dark">
              <Text marginRight="2">Sair</Text>
              <Text className="fa fa-sign-out-alt" />
            </Button>
          </NavItem>
        </NavBar>
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default Topbar;
