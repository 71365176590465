import styled from "styled-components";

export const Color = styled.div`
  width: 36px;
  height: 26px;
  border-radius: 2px;
  background: ${(props) => props.color};
`;

export const Swatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  border: solid 1px #b2b2b2;
  box-shadow: 0 0 0 1px rgba(0;0;0;.1);
  display: inline-block;
  cursor: pointer;
`;

export const Popover = styled.div`
  position: fixed;
  z-index: 99999999;
`;

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
