import React from 'react';
import PropTypes from 'prop-types';

import { textProps, textClass } from '../utilities/text';
import { colorsProps, colorsClass } from '../utilities/colors';
import { bordersProps, bordersClass } from '../utilities/borders';

import cn from 'classnames';

const CardText = (props) => {
  let { tag: Tag, className, children, onClick, ...attributes } = props;

  let text, colors, borders;
  [text, attributes] = textClass(attributes);
  [colors, attributes] = colorsClass(attributes);
  [borders, attributes] = bordersClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn('card-text', text, colors, borders, className)}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};

CardText.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  ...textProps(),
  ...colorsProps(),
  ...bordersProps(),
};

CardText.defaultProps = {
  tag: 'p',
  className: '',
  //   txtAlign: 'center',
  //   fontWeight: 'bold',
};

export default CardText;
