import { Text } from "../../../Components/Text";
import { Column, ContainerFlex, Row } from "../../../Components/Layout";

const HistoryProspect = ({ history }) => {
  return (
    <ContainerFlex border padding="2">
      <Row>
        <Column col="12">
          <Text tag="p" padding="0" margin="0">
            {history.prospect_status_id.name}
          </Text>
        </Column>
        {history.note && (
          <Column col="12">
            <ContainerFlex border paddingLeftRight="2">
              <Text tag="small" className="text-muted">
                Anotação:
              </Text>
              <small> {history.note.title}</small>
            </ContainerFlex>
          </Column>
        )}
      </Row>
    </ContainerFlex>
  );
};

export default HistoryProspect;
