import React from 'react';
import PropTypes from 'prop-types';

import { colorsProps, colorsClass } from '../utilities/colors';
import { sizingProps, sizingClass } from '../utilities/sizing';
import { spacingProps, spacingClass } from '../utilities/spacing';
import { bordersProps, bordersClass } from '../utilities/borders';
import { flexProps, flexClass } from '../utilities/flex';

import cn from 'classnames';

const Collapse = (props) => {
  let { 
    tag: Tag,
    children, 
    className, 
    isOpen,
    ...attributes
  } = props;

  let colors, sizing, spacing, borders,flex;
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);
  [flex, attributes] = flexClass(attributes);
  
  return (
      <Tag 
        {...attributes} 
        className={cn(
          "collapse",
          { show: isOpen },
          flex,
          sizing,
          spacing,
          borders,
          colors,
          className
        )}
      >
      {children}
      </Tag>
  )
};

Collapse.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  ...sizingProps(),
  ...spacingProps(),
  ...colorsProps(),
  ...bordersProps(),
  ...flexProps(),
};

Collapse.defaultProps = {
  tag: 'div',
  className: '',
  isOpen: false,
};

export default Collapse;