import React from "react";
import PropTypes from "prop-types";

import { spacingProps, spacingClass } from "../utilities/spacing";

import cn from "classnames";

const TableFooter = (props) => {
  let { children, className, color, ...attributes } = props;

  let spacing;
  [spacing, attributes] = spacingClass(attributes);

  color = color ? `table-${color}` : "";

  return (
    <tfoot {...attributes} className={cn(color, spacing, className)}>
      {children}
    </tfoot>
  );
};

TableFooter.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "light",
    "dark",
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  ...spacingProps(),
};

TableFooter.defaultProps = {
  className: "",
};

export default TableFooter;
