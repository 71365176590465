import { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import subDays from "date-fns/subDays";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import Upload from "./Upload";

import { Text } from "../../Components/Text";
import { Card, CardBody, CardFooter, CardHeader } from "../../Components/Card";
import { Column, ContainerFlex, Row } from "../../Components/Layout";
import { Input, FormLabel, Switch } from "../../Components/Form";
import { Button } from "../../Components/Button";
import { useNavigate, useParams } from "react-router-dom";
import useMarketing from "../../Hooks/useMarketing";
import { format } from "date-fns";

registerLocale("ptBR", ptBR);

const EditAppointment = () => {
  const { id } = useParams();

  let navigate = useNavigate();

  const { getMarketing, updateMarketing } = useMarketing();

  const [start, setStart] = useState("");
  const [validStart, setValidStart] = useState("");

  const [end, setEnd] = useState("");
  const [validEnd, setValidEnd] = useState("");

  const [title, setTitle] = useState("");
  const [validTitle, setValidTitle] = useState("");

  const [files, setFiles] = useState([]);

  const [textId, setTextId] = useState("");
  const [text, setText] = useState("");
  const [validText, setValidText] = useState("");

  const [errorTitle, setErrorTitle] = useState("");
  const [error, setError] = useState({});

  const [allDay, setAllDay] = useState(false);
  const handleAllDay = () => {
    setAllDay(!allDay);
  };

  const editor = useRef(null);

  const config = {
    readonly: false,
    height: "100%",
    enableDragAndDropFileToEditor: false,
    placeholder: "",
    useSearch: false,
    toolbar: false,
  };

  useEffect(() => {
    getMarketing(id, handleGetMarketing, handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetMarketing = (data) => {
    setStart(new Date(data.start));
    setValidStart("");
    setEnd(new Date(data.end));
    setValidEnd("");
    setTitle(data.title);
    if (data.content) {
      setTextId(data.content[0].id);
      setText(data.content[0].message);
    }
    setFiles(data.files);
    setAllDay(data.is_allday);
  };

  const handleDateStart = (date) => {
    setStart(date);
  };

  const handleDateEnd = (date) => {
    setEnd(date);
  };

  const handleText = (e) => {
    setText(e);
    setValidText("");
  };

  const handleTitle = (e) => {
    setTitle(e.currentTarget.value);
    setValidTitle("");
  };

  const handleValidade = () => {
    let err = true;

    if (!title) {
      setValidTitle("O assunto não pode ser vazio.");
      err = false;
    }

    if (!text) {
      setValidText("A descrição não pode ser vazia.");
      err = false;
    }

    return err;
  };

  const handleSuccess = (rs) => {
    if (rs) {
      setError({});

      navigate("/gestao/marketing");
    } else {
      setError(rs.data);
    }
  };

  const handleError = (error) => {
    if (error.data.errors) {
      let arr = [];
      for (let key in error.data.errors) {
        arr.push({
          key: key,
          message: error.data.errors[key][0],
        });
      }
      console.error(arr);
      setError(arr);
      setErrorTitle(`Erro: ${error.status} - ${error.data.message}`);
    } else {
      setError({});
      setErrorTitle("");
    }
  };

  const handleConfirm = () => {
    if (handleValidade()) {
      let params = {
        title,
        textId,
        text,
        start: start && format(start, "yyyy-MM-dd HH:mm:00"),
        end: end && format(end, "yyyy-MM-dd HH:mm:00"),
        is_allday: allDay,
        // files,
      };

      updateMarketing(id, params, handleSuccess, handleError);
    }
  };

  return (
    <ContainerFlex full padding="3" stretch>
      <Card marginTop="3" padding="2">
        {error && error.length > 0 && (
          <Column col="12" marginBottom="3">
            <div className="alert alert-danger m-3">
              {errorTitle && (
                <Text tag="h6" fontWeight="bolder">
                  {errorTitle}
                </Text>
              )}
              {error &&
                error.length > 0 &&
                error.map((item) => {
                  return (
                    <Text tag="p" key={item.key}>
                      {item.message}
                    </Text>
                  );
                })}
            </div>
          </Column>
        )}
        <CardHeader between middle>
          <Text className="h5" margin="0">
            Edição de Evento
          </Text>
          <Button
            onClick={() => {
              navigate("/gestao/marketing");
            }}
            color="primary"
            size="sm"
            className="btn btn-outline-dark"
            paddingBottom="0"
          >
            <Text tag="i" className="fa fa-angle-double-left" />
            <Text marginLeft="1">Voltar</Text>
          </Button>
        </CardHeader>
        <CardBody padding="3">
          <Row>
            {!allDay && (
              <>
                <Column col="12" md="3" marginBottom="3">
                  <FormLabel>Data Início</FormLabel>
                  <DatePicker
                    locale="ptBR"
                    selected={start}
                    onChange={(date) => handleDateStart(date)}
                    selectsStart
                    showTimeSelect
                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                    maxTime={setHours(setMinutes(new Date(end), 0), 18)}
                    minDate={subDays(new Date(), 0)}
                    maxDate={new Date(end)}
                    timeCaption="Hora"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    withPortal
                    portalId="root-start"
                    className={
                      validStart
                        ? "form-control is-invalid bg-white"
                        : "form-control bg-white"
                    }
                  />
                </Column>
                <Column col="12" md="3" marginBottom="3">
                  <FormLabel>Data Final</FormLabel>
                  <DatePicker
                    locale="ptBR"
                    selected={end}
                    onChange={(date) => handleDateEnd(date)}
                    selectsEnd
                    showTimeSelect
                    minTime={setHours(setMinutes(new Date(), 0), start)}
                    maxTime={setHours(setMinutes(new Date(), 0), 18)}
                    minDate={new Date(start)}
                    timeCaption="Hora"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    withPortal
                    portalId="root-end"
                    className={
                      validEnd
                        ? "form-control is-invalid bg-white"
                        : "form-control bg-white"
                    }
                  />
                </Column>
              </>
            )}
            <Column col="12" md="3" marginBottom="3">
              <FormLabel>Dia Inteiro</FormLabel>
              <Switch
                id="allday"
                label={allDay ? "Sim" : "Não"}
                onClick={handleAllDay}
                checked={allDay}
              />
            </Column>
            <Column col="12">
              <Text tag="h6">Assunto</Text>
              <Input
                value={title}
                onChange={handleTitle}
                placeholder="Informe o assunto resumido sobre o chamado."
                maxLength="255"
                marginBottom="2"
                bgColor="white"
                className={validTitle && "is-invalid"}
              />
              {validTitle && (
                <div className="invalid-feedback">{validTitle}</div>
              )}
            </Column>

            <Column col="12" marginTop="2">
              <Text tag="h6">Anexos</Text>
              <Upload
                uploadedFiles={files}
                setUploadedFiles={setFiles}
                id={id}
              />
            </Column>
            <Column col="12">
              <Text tag="h6" marginTop="3">
                Descrição
              </Text>
              <JoditEditor
                ref={editor}
                value={text}
                config={config}
                onBlur={(newContent) => handleText(newContent)}
                onChange={(newContent) => {}}
                placeholder="Informe o assunto resumido sobre o chamado."
              />
              {validText && (
                <div className="invalid-feedback d-block">{validText}</div>
              )}
            </Column>
          </Row>
        </CardBody>
        <CardFooter bgColor="white">
          <Button onClick={handleConfirm} bgColor="success">
            Registrar
          </Button>
        </CardFooter>
      </Card>
    </ContainerFlex>
  );
};

export default EditAppointment;
