import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import useStatus from "../../../Hooks/Support/useStatus";

import Item from "./Item";

import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
import { Button, ButtonModal } from "../../../Components/Button";
import { Card, CardBody, CardHeader } from "../../../Components/Card";

const List = ({ setReload }) => {
  const { listStatus } = useStatus();

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState([]);

  useEffect(() => {
    if (listStatus && listStatus.data) {
      setStatus(listStatus.data);
      setLoading(false);
    }
  }, [listStatus]);

  const handleStatus = () => {
    if (status && status.length > 0) {
      return status.map((data, index) => <Item key={index} item={data}></Item>);
    } else {
      <ContainerFlex full middle center column>
        <Text>Nenhum item foi localizado.</Text>
      </ContainerFlex>;
    }
  };

  return (
    <Card marginTop="3" padding="2">
      <CardHeader between middle>
        <Text className="h5" margin="0">
          Lista de Situações do Suporte
        </Text>
        <ContainerFlex row>
          <Button onClick={setReload} id="btn-reload-campaign">
            <Text tag="i" className="fa fa-redo-alt" />
          </Button>
          <ButtonModal modal="Support_Status_Modal">
            <Text tag="i" className="fa fa-plus" />
          </ButtonModal>
        </ContainerFlex>
      </CardHeader>
      <CardBody padding="0">
        <ContainerFlex
          padding="2"
          className="accordion accordion-flush"
          id="campaign-collapse-list"
        >
          {/* <Item /> */}
          {loading ? (
            <ContainerFlex full middle center column>
              <ReactLoading type={"spin"} color={"#1c208c"} />
              <Text>Carregando...</Text>
            </ContainerFlex>
          ) : (
            handleStatus()
          )}
        </ContainerFlex>
      </CardBody>
    </Card>
  );
};

export default List;
