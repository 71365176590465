import { useEffect, useState } from "react";

import { ContainerFlex } from "../../../Components/Layout";

import Sidebar from "./Sidebar";
import Content from "./Content";
import Modal from "./Modal";

import useAds from "../../../Hooks/useAd";
import useCampaign from "../../../Hooks/useCampaign";

const Ad = () => {
  const { indexAd } = useAds();
  const { indexCampaign } = useCampaign();

  const [reload, setReload] = useState(true);

  const handleReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    indexAd();
    indexCampaign();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      <ContainerFlex full row>
        <Sidebar setReload={handleReload} />
        <Content />
      </ContainerFlex>
      <Modal setReload={handleReload} />
    </>
  );
};

export default Ad;
