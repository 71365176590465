import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import useAuth from "../../../Hooks/useAuth";

import { Button, ButtonGroup } from "../../../Components/Button";
import { Text } from "../../../Components/Text";
import { Input } from "../../../Components/Form";
import { ContainerFlex } from "../../../Components/Layout";

const Upload = ({ uploadedFiles, setUploadedFiles, id }) => {
  const { token } = useAuth();

  const [selectedFile, setselectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    if (selectedFile) {
      onFileUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  // On file select (from the pop up)
  const onFileChange = (event) => {
    setLoading(true);
    setError({});
    // Update the state
    setselectedFile(event.target.files[0]);
  };

  const handleError = (error) => {
    if (error.data.errors) {
      let arr = [];
      for (let key in error.data.errors) {
        arr.push({
          key: key,
          message: error.data.errors[key][0],
        });
        // arr.push(error.response.data.errors[key]);
      }
      console.error(arr);
      setError(arr);
    } else {
      setError({});
    }
  };

  // On file upload (click the upload button)
  const onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedFile, selectedFile.name);

    if (id) {
      formData.append("support_message_id", id);
    }

    // Request made to the backend api
    // Send formData object
    axios
      .post("/api/upload/support", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.success) {
          setUploadedFiles([...uploadedFiles, res.data.file]);
        }
        setLoading(false);
        setselectedFile(null);
      })
      .catch((err) => {
        setLoading(false);
        handleError(err.response);
        setselectedFile(null);
      });
  };

  const handleDownload = (file) => {
    axios({
      url: `/api/download/support/${file}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteFile = (file) => {
    axios({
      url: `/api/file/${file}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        uploadedFiles.splice(uploadedFiles.indexOf(file), 1);
        setUploadedFiles([...uploadedFiles]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleLoadDocument = () => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      return (
        <ContainerFlex paddingTopBottom="2">
          <Input
            type="file"
            onChange={onFileChange}
            placeholder="Selecione um anexo"
          />
          <ContainerFlex paddingTopBottom="2" border>
            <Text tag="h6" padding="1">
              Aquivos Anexados
            </Text>

            {uploadedFiles.map((file, index) => (
              <ButtonGroup key={index}>
                <Button
                  paddingBottom="0"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    handleDownload(file.name);
                  }}
                >
                  <Text
                    tag="i"
                    className={`fas fa-file-${
                      file.name.includes("jpg")
                        ? "image"
                        : file.name.includes("jpeg")
                        ? "image"
                        : file.name.includes("bmp")
                        ? "image"
                        : file.name.includes("png")
                        ? "image"
                        : file.name.includes("doc")
                        ? "word"
                        : file.name.includes("docx")
                        ? "word"
                        : file.name.includes("csv")
                        ? "excel"
                        : file.name.includes("rtf")
                        ? "excel"
                        : file.name.includes("xlsx")
                        ? "excel"
                        : file.name.includes("xls")
                        ? "excel"
                        : file.name.includes("txt")
                        ? "alt"
                        : file.name.includes("pdf")
                        ? "pdf"
                        : file.name.includes("zip")
                        ? "archive"
                        : "file"
                    }`}
                    marginRight="2"
                  />
                  {file.name}
                </Button>
                <Button
                  className="btn btn-outline-secondary"
                  onClick={() => deleteFile(file.id)}
                >
                  <Text tag="i" className="fas fa-close" />
                </Button>
              </ButtonGroup>
            ))}
          </ContainerFlex>
        </ContainerFlex>
      );
    } else {
      return (
        <Input
          type="file"
          onChange={onFileChange}
          placeholder="Selecione um anexo"
        />
      );
    }
  };

  return (
    <ContainerFlex>
      {error && error.length > 0 && (
        <ContainerFlex col="12" marginBottom="3">
          <div className="alert alert-danger m-3">
            {error &&
              error.length > 0 &&
              error.map((item) => {
                return (
                  <Text tag="p" key={item.key}>
                    {item.message}
                  </Text>
                );
              })}
          </div>
        </ContainerFlex>
      )}
      {loading ? (
        <ContainerFlex full middle center column padding="2">
          <ReactLoading type={"bubbles"} color={"#1c208c"} height={"10"} />
          <Text>Salvando Anexos...</Text>
        </ContainerFlex>
      ) : (
        handleLoadDocument()
      )}
    </ContainerFlex>
  );
};

export default Upload;
