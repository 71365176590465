import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { useNavigate } from "react-router-dom";

const CategoryItem = ({ item }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/manual/${item.id}`);
  };

  return (
    <ContainerFlex
      type="button"
      className="card-item shadow-sm"
      marginBottom="3"
      padding="2"
    >
      <Row onClick={handleClick}>
        <Column col="12" between marginBottom="2">
          <ContainerFlex row middle>
            <Text
              tag="h5"
              padding="0"
              margin="0"
              marginTop="1"
              fontWeight="bolder"
            >
              {item.title}
            </Text>
          </ContainerFlex>
        </Column>
        <Column col="12">
          <Column col="12">
            <Text tag="small" className="text-muted" marginRight="2">
              Categoria:
            </Text>
            <Text tag="small" fontWeight="bolder">
              {item.category.name}
            </Text>
          </Column>
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default CategoryItem;
