import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import Select from "react-select";

import useAd from "../../../../Hooks/useAd";
import useCampaign from "../../../../Hooks/useCampaign";
import useSource from "../../../../Hooks/useSource";

import { FormLabel } from "../../../../Components/Form";
import { ButtonModalClose } from "../../../../Components/Button";
import { Column, ContainerFlex, Row } from "../../../../Components/Layout";
import { Modal, ModalBody, ModalFooter } from "../../../../Components/Modal";

const ModalPhone = ({ lead, inflow, open }) => {
  const { indexAd, listAd, resetAd } = useAd();
  const { indexSource, listSource, resetSource } = useSource();
  const { indexCampaign, listCampaign, resetCampaign } = useCampaign();

  const [source, setSource] = useState({});
  const [sourceList, setSourceList] = useState({});
  const [campaign, setCampaign] = useState({});
  const [ad, setAd] = useState({});

  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState("");

  useEffect(() => {
    if (open) {
      indexSource({ comp_id: lead.company.value });
      console.log("index source");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (inflow && lead && lead.id) {
      setPhone(lead.lead && lead.lead);

      setSource(inflow.source && inflow.source);
      setCampaign(inflow.campaign && inflow.campaign);
      setAd(inflow.ad && inflow.ad);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inflow, lead]);

  useEffect(() => {
    if (listSource && listSource.data) {
      let list = listSource.data.map((data) => {
        return {
          value: data.id,
          label: data.name + " - " + data.company.label,
        };
      });

      setSourceList(list);
    }
  }, [listSource]);

  useEffect(() => {
    if (sourceList && source !== "none") {
      indexCampaign({ source: source });
    }
    resetCampaign();
    resetAd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceList]);

  useEffect(() => {
    if (campaign && campaign !== "none") {
      indexAd({ campaign: campaign });
    }
    resetAd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const handleCheckPhone = (e) => {
    let value = e.replace(/[^\d]+/g, "");

    if (value < 10) {
      setValidPhone("Número inválido");
    } else {
      setValidPhone("");
    }
  };

  return (
    <Modal id="Modal_Phone_Patient" center fixed scroll size="sm">
      <ModalBody>
        <Row>
          <Column col="12" marginBottom="2">
            <FormLabel>Número *</FormLabel>
            <InputMask
              value={phone || ""}
              onBlur={(e) => handleCheckPhone(e.currentTarget.value)}
              className={`form-control bg-white ${validPhone && "is-invalid"}`}
              mask="(99) 99999-9999"
              maskPlaceholder={null}
              readOnly
            />
            {validPhone && <div className="invalid-feedback">{validPhone}</div>}
          </Column>

          <Column col="12" marginBottom="2">
            <FormLabel className="text-center">Origem</FormLabel>
            <Select
              defaultValue={source ? source.name : "-"}
              bgColor="white"
              menuPosition={"fixed"}
            />
          </Column>

          <Column col="12" marginBottom="2">
            <FormLabel className="text-center">Campanha</FormLabel>
            <Select
              defaultValue={campaign ? campaign.name : "-"}
              bgColor="white"
              menuPosition={"fixed"}
            />
          </Column>

          <Column col="12" marginBottom="2">
            <FormLabel className="text-center">Anúncio</FormLabel>
            <Select
              defaultValue={ad ? ad.name : "-"}
              readOnly
              menuPosition={"fixed"}
              bgColor="white"
            />
          </Column>
        </Row>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex right width>
          <ButtonModalClose bgColor="danger" txtColor="white">
            Fechar
          </ButtonModalClose>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default ModalPhone;
