import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const FormLabel = (props) => {
  let { tag: Tag, children, className, ...attributes } = props;

  return (
    <Tag {...attributes} className={cn('form-label', className)}>
      {children}
    </Tag>
  );
};

FormLabel.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
};

FormLabel.defaultProps = {
  tag: 'label',
  className: '',
};

export default FormLabel;
