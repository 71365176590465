import { ContainerFlex } from "../../Components/Layout";

import Content from "./Content";

const Lead = () => {
  return (
    <ContainerFlex full row>
      <Content />
    </ContainerFlex>
  );
};

export default Lead;
