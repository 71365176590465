import { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Lines = ({ data, dt_start, dt_end, accumulated, label }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      handleLabels(data);
    } else {
      setChartData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleLabels = (rs) => {
    let labels = [];
    let values = [];

    if (accumulated) {
      var one_day = 1000 * 60 * 60 * 24;
      let start = new Date(dt_start);
      let end = new Date(dt_end);
      let days = Math.round(end.getTime() - start.getTime()) / one_day;
      let total = 0;

      for (let i = 0; i <= days + 1; i++) {
        let label = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() + i
        );

        let value = 0;

        rs.forEach((item) => {
          if (item.date === format(label, "yyyy-MM-dd")) {
            value = item.value;
          }
        });
        total = total + value;
        labels.push(format(label, "dd"));
        values.push(total);
      }
    } else {
      rs.forEach((item) => {
        labels.push(format(item.label, "dd/MM/yyyy"));
        values.push(item.value);
      });
    }

    let chart = {
      labels: labels,
      datasets: [
        {
          label: label,
          data: values,
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(175,192,192,0.9)",
          borderColor: "rgba(175,192,192,1)",
          // borderCapStyle: "butt",
          // borderDash: [],
          // borderDashOffset: 0.0,
          // borderJoinStyle: "miter",
          // pointBorderColor: "rgba(75,192,192,1)",
          // pointBackgroundColor: "#fff",
          // pointBorderWidth: 1,
          // pointHoverRadius: 5,
          // pointHoverBackgroundColor: "rgba(75,192,192,1)",
          // pointHoverBorderColor: "rgba(220,220,220,1)",
          // pointHoverBorderWidth: 2,
          // pointRadius: 1,
          // pointHitRadius: 10,
        },
      ],
    };

    setChartData(chart);
  };

  //   const handleData = (listLeadsByDate) => {
  //     let data = {
  //       labels: listLeadsByDate.map((item) => item.label),
  //       datasets: [
  //         {
  //           label: "Contatos",
  //           data: listLeadsByDate.map((item) => item.value),
  //           backgroundColor: "rgba(255,99,132,0.2)",
  //           borderColor: "rgba(255,99,132,1)",
  //           borderWidth: 1,
  //           hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //           hoverBorderColor: "rgba(255,99,132,1)",
  //         },
  //       ],
  //     };

  //     setChartData(data);
  //   };
  // const options = {
  //   datalabels: {
  //     display: false,
  //   },
  // };

  return (
    <>
      {chartData && chartData.labels && chartData.labels.length > 0 && (
        <Line data={chartData} />
      )}
    </>
  );
};

export default Lines;
