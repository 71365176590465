import useSource from "../../../Hooks/useSource";

import { Column, ContainerFlex } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { ButtonModal } from "../../../Components/Button";

const Item = ({ item }) => {
  const { getSource } = useSource();

  let Active, Color;
  if (item.is_active) {
    Active = "Ativo";
    Color = "success";
  } else {
    Active = "Inativo";
    Color = "danger";
  }

  const handleEdit = () => {
    getSource(item.id);
  };

  return (
    <ContainerFlex
      bgColor="white"
      padding="3"
      className="row shadow-sm"
      marginBottom="3"
      rounded="pill"
    >
      <Column col="12" md="4">
        <Text>{item.name}</Text>
      </Column>
      <Column col="12" md="4">
        <Text>{item.company.label}</Text>
      </Column>
      <Column col="12" md="2" center>
        <Text
          bgColor={Color}
          padding="0"
          paddingLeftRight="3"
          txtColor="white"
          rounded="pill"
        >
          {Active}
        </Text>
      </Column>

      <Column col="12" md="2" right>
        <ButtonModal
          bgColor="info"
          txtColor="white"
          onClick={handleEdit}
          modal="Source_Modal"
          paddingTopBottom="0"
          rounded="pill"
          disabled
        >
          <Text tag="i" className="fa fa-pen" /> Editar
        </ButtonModal>
      </Column>
    </ContainerFlex>
  );
};

export default Item;
