import { useState } from "react";

import Filters from "./Filters";
import Table from "./Table";

import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
import { Button } from "../../../Components/Button";
import { Card, CardBody, CardHeader } from "../../../Components/Card";

const Content = () => {
  const [dataExport, setDataExport] = useState(false);

  const handleExport = () => {
    setDataExport(true);
  };

  return (
    <ContainerFlex full padding="3" overflow>
      <Filters />
      <Card marginTop="3" padding="2">
        <CardHeader between middle>
          <Text className="h5" margin="0">
            Listagem de Chamados
          </Text>
          <ContainerFlex row>
            <Button
              onClick={handleExport}
              modal="Campaign_Modal"
              id="btn-reload-campaign"
              className="btn btn-outline-secondary"
              size="sm"
              paddingTopBottom="0"
            >
              <Text tag="i" className="fa fa-file-excel" />
              <Text marginLeft="2">Exportar</Text>
            </Button>
          </ContainerFlex>
        </CardHeader>
        <CardBody>
          <Table exportExcel={dataExport} setExportExcel={setDataExport} />
        </CardBody>
      </Card>
    </ContainerFlex>
  );
};

export default Content;
