import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { formatPhone } from "../../Components/Form/masks";

const SearchBox = ({ searchList }) => {
  const navigate = useNavigate();

  const [listItens, setListItens] = useState([]);

  useEffect(() => {
    if (Array.isArray(searchList.data) && searchList.data.length > 0) {
      setListItens(searchList.data);
    } else {
      setListItens([]);
    }
  }, [searchList]);

  const handleSearchItemClick = (e) => {
    navigate(`/prospeccao/${e.id}`);
    // let menu = document.getElementById(`menu-${e.status.id}`);
    // let row = document.getElementById(`row-${e.id}`);

    // menu.click();

    // row.classList.add("active-row");
  };

  return (
    <ul className={`p-0 m-0 dropdown-menu`} aria-labelledby="SearchBoxProspect">
      {listItens.map((item, index) => (
        <li
          key={index}
          className="dropdown-item"
          onClick={() => handleSearchItemClick(item)}
        >
          <small className="text-muted me-2">Nome:</small>
          <small>{item.lead.patient.name}</small>
          <small className="p-0 mx-3 px-3 rounded-pill text-white bg-success">
            {item.status.name}
          </small>
          <small className="text-muted me-2">Contato:</small>
          <small>
            {item.lead.lead.lead && formatPhone(item.lead.lead.lead)}
          </small>
        </li>
      ))}
      <li>
        <hr className="dropdown-divider m-0" />
      </li>
      <li>
        <small className="m-2">
          Total de resultados da consulta: {listItens.length}
        </small>
      </li>
    </ul>
  );
};

export default SearchBox;
