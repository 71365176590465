import { VerticalTimelineElement } from "react-vertical-timeline-component";

import { formatDatetime } from "../../../Components/Form/masks";

import { ContainerFlex } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import HistoryProspect from "./HistoryProspect";
import HistoryInflow from "./HistoryInflow";
import HistoryAgenda from "./HistoryAgenda";

const HistoryItem = ({ history }) => {
  const handleType = () => {
    switch (history.type.id) {
      case 1:
        return <HistoryInflow history={history} />;
      case 2:
        return <HistoryProspect history={history} />;
      case 3:
        return <HistoryAgenda history={history} />;
      default:
        break;
    }
  };

  return (
    <VerticalTimelineElement
      dateClassName="opacity-100 w-100 p-0 border-0"
      className="vertical-timeline-element--work m-0 mb-3"
      contentStyle={{
        color: "#000",
        padding: "0",
        marginTop: "0",
        // border: 1,
      }}
      contentArrowStyle={{
        borderRight: `15px solid #dbdbdb`,
      }}
      iconStyle={{
        background: history.type.color,
        color: "#000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      icon={<Text tag="i" className={history.type.icon} />}
    >
      {/* {handleType()} */}
      <ContainerFlex>
        <ContainerFlex
          between
          padding="2"
          margin="0"
          style={{ background: history.type.color }}
        >
          <Text className="fw-bolder">{history.type.name}</Text>
          <Text>{formatDatetime(history.created_at)}</Text>
        </ContainerFlex>
        {handleType()}
      </ContainerFlex>
    </VerticalTimelineElement>
  );
};

export default HistoryItem;
