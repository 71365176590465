import { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import useCategory from "../../../Hooks/Support/useCategory";
import useStatus from "../../../Hooks/Support/useStatus";
import useTicket from "../../../Hooks/Support/useTicket";

import { Text } from "../../../Components/Text";
import { Button } from "../../../Components/Button";
import { Column, Row } from "../../../Components/Layout";
import { Card, CardBody } from "../../../Components/Card";

const Sidebar = () => {
  const animatedComponents = makeAnimated();
  const { listCategory } = useCategory();
  const { listStatus } = useStatus();
  const { indexTicket } = useTicket();

  const [categoryList, setCategoryList] = useState([
    { value: "all", label: "Todas" },
  ]);
  const [category, setCategory] = useState({ value: "all", label: "Todas" });

  const [statusList, setStatusList] = useState([
    { value: "all", label: "Todas" },
  ]);
  const [status, setStatus] = useState({ value: "all", label: "Todas" });

  useEffect(() => {
    indexTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let list = [];
    if (listCategory && listCategory.data && listCategory.data.length > 0) {
      list.push({
        value: "all",
        label: "Todas",
      });
      listCategory.data.forEach((item) => {
        list.push({
          value: item.id,
          label: item.name,
        });
      });
      setCategoryList(list);
    }
  }, [listCategory]);

  useEffect(() => {
    let list = [];

    if (listStatus && listStatus.data && listStatus.data.length > 0) {
      list.push({
        value: "all",
        label: "Todas",
      });
      listStatus.data.forEach((item) => {
        list.push({
          value: item.id,
          label: item.name,
        });
      });
      setStatusList(list);
    }
  }, [listStatus]);

  const handleCategory = (selected) => {
    setCategory(selected);
  };

  const handleStatus = (selected) => {
    setStatus(selected);
  };

  const handleFilter = () => {
    if (category !== "all" || status !== "all") {
      indexTicket({ category: category.value, status: status.value });
    } else {
      indexTicket();
    }
  };

  return (
    <Card width padding="2">
      <CardBody full>
        <Row height>
          <Column col="12" md="3">
            <Text tag="h6">Setor</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={{ value: "all", label: "Todas" }}
              value={category}
              options={categoryList}
              onChange={handleCategory}
              menuPosition={"fixed"}
            />
          </Column>
          <Column col="12" md="3">
            <Text tag="h6">Tipo</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue="all"
              value={status}
              options={statusList}
              menuPosition={"fixed"}
              onChange={handleStatus}
            />
          </Column>

          <Column col="12" md="2" top marginTop="3">
            <Button
              txtAlign="center"
              bgColor="info"
              width
              txtColor="white"
              marginTop="2"
              onClick={handleFilter}
              id="btn-filter-tickets"
            >
              <Text tag="i" className="fa fa-filter" marginRight="2" />
              <Text tag="small">Filtrar</Text>
            </Button>
          </Column>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Sidebar;
