import { ContainerFlex } from "../../Components/Layout";
import { Text } from "../../Components/Text";

const ItemAgenda = ({ event }) => {
  return (
    <ContainerFlex column>
      <Text txtColor="white" fontWeight="bolder">
        {event.patient.name.substring(0, 15)}
      </Text>
      <Text marginTop="2">{event.note.title}</Text>
    </ContainerFlex>
  );
};

export default ItemAgenda;
