import { useDispatch, useSelector } from "react-redux";

import useAuth from "../useAuth";
import api from "../../Data/api";

const useAgentTicket = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listAgentTicket = useSelector((state) => state.supportticket.index);

  function setIndex(index) {
    dispatch({
      type: "SUPPORT_TICKET_INDEX",
      payload: { index: index },
    });
  }

  function resetAgentTicket() {
    dispatch({
      type: "SUPPORT_TICKET_RESET",
    });
  }

  const indexAgentTicket = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/support/ticket?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.error(response.response);
        }
      })
      .catch((err) => {
        setIndex([]);
        console.error(err.response);
      });
  };

  const getAgentTicket = (id, success, error) => {
    api
      .get(`/support/ticket/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        } else {
          error(response.response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const postAgentTicket = (params, success, error) => {
    api
      .post("/support/ticket", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateAgentTicket = (id, params, success, error) => {
    api
      .update(`/support/ticket/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const closeAgentTicket = (id, success, error) => {
    api
      .update(`/support/ticket/close/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delAgentTicket = (id, success, error) => {
    api
      .delete(`/support/ticket/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    getAgentTicket,
    postAgentTicket,
    indexAgentTicket,
    updateAgentTicket,
    closeAgentTicket,
    delAgentTicket,
    listAgentTicket,
    resetAgentTicket,
    setIndex,
  };
};

export default useAgentTicket;
