import React from "react";
import PropTypes from "prop-types";

import { textProps, textClass } from "../utilities/text";
import { colorsProps, colorsClass } from "../utilities/colors";
import { bordersProps, bordersClass } from "../utilities/borders";

import cn from "classnames";

const ModalHeader = (props) => {
  let { tag: Tag, id, title, className, onClick, ...attributes } = props;

  let colors, text, borders;
  [text, attributes] = textClass(attributes);
  [colors, attributes] = colorsClass(attributes);
  [borders, attributes] = bordersClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn("modal-header", text, colors, borders, className)}
    >
      {title && <h5 className="modal-title">{title}</h5>}
      {id && (
        <button
          id={id}
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={onClick}
        ></button>
      )}
    </Tag>
  );
};

ModalHeader.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
  ...textProps(),
  ...colorsProps(),
  ...bordersProps(),
};

ModalHeader.defaultProps = {
  tag: "div",
  className: "",
};

export default ModalHeader;
