import React, { useEffect, useState } from "react";
import useAd from "../../../../Hooks/useAd";
import useCampaign from "../../../../Hooks/useCampaign";
import Uuid from "../../../../Components/utilities/Uuid";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../../Components/Modal";
import { ContainerFlex, Row, Column } from "../../../../Components/Layout";
import { Input, FormLabel } from "../../../../Components/Form";
import { Button, ButtonModalClose } from "../../../../Components/Button";
import { Switch } from "../../../../Components/Form";
import { Text } from "../../../../Components/Text";

const AdModal = () => {
  const { postAd, dataAd, updateAd } = useAd();
  const { genUUID } = Uuid();
  const { dataCampaign } = useCampaign();

  const [id, setId] = useState("");

  const [ad, setAd] = useState("");
  const [validAd, setValidAd] = useState("");

  const [tag, setTag] = useState("");
  const [validTag, setValidTag] = useState("");

  const [companyId, setCompanyId] = useState("");
  const [company, setCompany] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [campaign, setCampaign] = useState("");
  const [active, setActive] = useState(true);

  const [error, setError] = useState({});
  const handleError = (err) => {
    setError(err);
  };

  useEffect(() => {
    if (dataCampaign && dataCampaign.name) {
      setCompany(dataCampaign.company.label);
      setCampaign(dataCampaign.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCampaign]);

  const handleId = (e) => {
    setId(e.target.value);
  };

  const handleAd = (e) => {
    setAd(e.target.value);
    setValidAd("");
  };

  const handleTag = (e) => {
    var outString = e.target.value.replace(
      /[`~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/\s]/gi,
      ""
    );
    setTag(outString);
    setValidTag("");
  };

  const handleActive = () => {
    setActive(!active);
  };

  const handleSuccess = () => {
    document.getElementById("Ad_Modal_Campaign_Close").click();
    document
      .getElementById(
        `btn-reload-ads-${campaignId ? campaignId : dataCampaign.id}`
      )
      .click();

    handleDataDefault(null);
  };

  const handleValidade = () => {
    let err = true;
    if (ad.length <= 3) {
      setValidAd("O nome da campanha deve ser maior.");
      err = false;
    } else if (ad.length > 255) {
      setValidAd("O nome da campanha deve ser menor.");
      err = false;
    } else if (ad === "") {
      setValidAd("O nome da campanha não pode ser vazio.");
      err = false;
    }

    if (tag.length <= 3) {
      setValidTag("O nome da campanha deve ser maior.");
      err = false;
    } else if (tag.length > 255) {
      setValidAd("O nome da campanha deve ser menor.");
      err = false;
    } else if (tag === "") {
      setValidAd("O nome da campanha não pode ser vazio.");
      err = false;
    }

    return err;
  };

  const handleConfirm = () => {
    if (handleValidade()) {
      if (id === "") {
        let params = {
          name: ad,
          tag: tag,
          comp_id: dataCampaign.company.value,
          campaign_id: dataCampaign.id,
          is_active: active,
        };
        postAd(params, handleSuccess, handleError);
      } else {
        let params = {
          name: ad,
          tag: tag,
          comp_id: companyId,
          campaign_id: campaignId,
          is_active: active,
        };
        updateAd(id, params, handleSuccess, handleError);
      }
    }
  };

  const handleDataDefault = (Data) => {
    if (Data && Data.id) {
      setId(Data.id);
      setAd(Data.name);
      setTag(Data.tag);
      setCompanyId(Data.campaign.company.value);
      setCompany(Data.campaign.company.label);
      setCampaignId(Data.campaign.id);
      setCampaign(Data.campaign.name);
      setActive(Data.is_active);
    } else {
      setTimeout(() => {
        setId("");
        setAd("");
        setTag(genUUID(8));
        setActive(true);
        setValidAd("");
        setCompanyId("");
        setCompany("");
        setCampaignId("");
        setCampaign("");
      }, 1000);
    }
  };

  useEffect(() => {
    handleDataDefault(dataAd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAd]);

  return (
    <Modal id="Ad_Modal_Campaign" center fixed scroll>
      <ModalHeader
        title="Cadastro de Anúncio"
        id="Ad_Modal_Campaign_Close"
        onClick={handleDataDefault}
        border={false}
      />
      <ModalBody>
        <Row>
          {error && error.status && error.data.message && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                <Text tag="h6" fontWeight="bolder">
                  Erro: {error.status} - {error.data.message}
                </Text>
                <Text fontWeight="bolder"></Text>
                <p>{error.data.errors && JSON.stringify(error.data.errors)}</p>
              </div>
            </Column>
          )}
          <Input value={id} onChange={handleId} maxLength={60} type="hidden" />
          <Column col="12" md="8" marginBottom="3">
            <FormLabel>Nome do Anúncio</FormLabel>
            <Input
              value={ad}
              onChange={handleAd}
              maxLength={60}
              required
              className={validAd && "is-invalid"}
              onKeyDown={() => setValidAd("")}
            />
            {validAd && <div className="invalid-feedback">{validAd}</div>}
          </Column>
          <Column col="12" md="4" marginBottom="3">
            <FormLabel>Tag Identificação</FormLabel>
            <Input
              value={tag}
              onChange={handleTag}
              maxLength={60}
              required
              className={validTag && "is-invalid"}
              onKeyDown={() => setValidTag("")}
            />
            {validTag && <div className="invalid-feedback">{validTag}</div>}
          </Column>
          <Column col="12" md="6" marginBottom="3">
            <FormLabel>Campanha</FormLabel>
            <Input defaultValue={campaign} disabled />
          </Column>
          <Column col="12" md="6" marginBottom="3">
            <FormLabel>Unidade</FormLabel>
            <Input defaultValue={company} disabled />
          </Column>
          <Column col="12" md="5" marginBottom="3">
            <FormLabel>Status</FormLabel>
            <Switch
              id="is_active"
              label={active ? "Ativo" : "Inativo"}
              onClick={handleActive}
              checked={active}
            />
          </Column>
        </Row>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
          >
            Cancelar
          </ButtonModalClose>
          <Button bgColor="success" txtColor="white" onClick={handleConfirm}>
            Concluir
          </Button>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default AdModal;
