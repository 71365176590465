import React, { useState } from "react";

// import api from "../Data/osApi";

import "../Assets/scss/index.scss";
import "../Assets/scss/fontawesome.scss";

import { AppRoutes } from "../Routes";
import Login from "../Components/Login";
import Loading from "../Components/Loading";
import useAuth from "../Hooks/useAuth";

const App = () => {
  const { token, login } = useAuth();
  const [error, setError] = useState([]);

  const handleSubmit = (email, password) => {
    login(email, password, setError);
  };

  if (!token) {
    return <Login submit={handleSubmit} err={error} />;
  }
  return (
    <>
      <AppRoutes />
      <Loading />
    </>
  );
};

export default App;
