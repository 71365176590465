import { useEffect, useState } from "react";
import LineChart from "./LineChart";
import { Card, CardBody, CardHeader } from "../../../Components/Card";
import { Text } from "../../../Components/Text";
import useReport from "../../../Hooks/useReport";

const NewClients = ({ list }) => {
  const { reportdtstart, reportdtend } = useReport();

  const [listNewClientsByDate, setListNewClientsByDate] = useState([]);
  const [countNewClientsByDate, setCountNewClientsByDate] = useState(0);

  useEffect(() => {
    if (list && list.data && list.data.length > 0) {
      setListNewClientsByDate(list.data);
      let count = 0;
      list.data.forEach((item) => {
        count += Number(item.value);
      });
      setCountNewClientsByDate(count);
    } else {
      setListNewClientsByDate([]);
      setCountNewClientsByDate(0);
    }
  }, [list]);

  return (
    <Card>
      <CardHeader border={null} between middle>
        <Text fontWeight="bold" fontSize="5">
          Fechamentos
        </Text>
        <Text tag="h4" className="float-end m-0 p-0">
          {countNewClientsByDate ? countNewClientsByDate : 0}
        </Text>
      </CardHeader>
      <CardBody paddingTop="0">
        <LineChart
          data={listNewClientsByDate}
          dt_start={reportdtstart}
          dt_end={reportdtend}
          accumulated
          label="Orçamentos"
        />
      </CardBody>
    </Card>
  );
};

export default NewClients;
