import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "../App/Layout";
import Ads from "../App/Content/Ads";
import Agenda from "../App/Agenda";
import Campaign from "../App/Content/Campaign";
import Documents from "../App/Documents";
import Treinamentos from "../App/Treinamentos";
import Treinamento from "../App/Treinamentos/Content";
import DocumentContent from "../App/Documents/Content";
import DocumentsNew from "../App/Documents/New";
import DocumentsCategory from "../App/Documents/Category";
import DashboardProspects from "../App/Dashboard/Prospects";
import NewClient from "../App/NewClient";
import Source from "../App/Content/Source";
import ProspectList from "../App/Prospects";
import ProspectEdit from "../App/Prospects/ProspectEdit/index.js";
import Lead from "../App/Lead";
import Marketing from "../App/Marketing";
import MarketingManager from "../App/MarketingManager";
import MarketingManagerNew from "../App/MarketingManager/New";
import MarketingManagerEdit from "../App/MarketingManager/Edit";
import MarketingContent from "../App/Marketing/Content";
import NoMatch from "./NoMatch";
import Task from "../App/Task";
import Uuid from "../Components/utilities/UuidView";
import ReportProspects from "../App/Reports/Prospects";
import Support from "../App/Support";
import SupportCategory from "../App/Support/Category";
import SupportPriority from "../App/Support/Priority";
import SupportStatus from "../App/Support/Status";
import Ticket from "../App/Support/Ticket";
import NewTicket from "../App/Support/Ticket/New";
import Tickets from "../App/Support/Tickets";
import TicketAgent from "../App/Support/Ticket/indexAgent";
import SupportManager from "../App/Support/Manager";
import ReportSupport from "../App/Reports/Supports";
import Users from "../App/User";
import User from "../App/User/Edit";

const urlBase = "";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={"/"} element={<Layout />}>
          <Route index path={"agendas"} element={<Agenda />} />
          <Route path={"/contato"} element={<Lead />} />
          <Route path={"prospeccao"}>
            <Route exact path={":id"} element={<ProspectEdit />} />
            <Route index path={""} element={<ProspectList />} />
          </Route>
          <Route index path={"/conversao"} element={<NewClient />} />
          <Route path={"conteudo"}>
            <Route index path={"anuncio"} element={<Ads />} />
            <Route index path={"campanha"} element={<Campaign />} />
            <Route index path={"origem"} element={<Source />} />
          </Route>
          <Route exact path={"manuais"} element={<Documents />} />
          <Route exact path={"treinamentos"} element={<Treinamentos />} />
          <Route exact path={"treinamento/:name"} element={<Treinamento />} />
          <Route exact path={"manual/:id"} element={<DocumentContent />} />
          <Route index path={"marketing"} element={<Marketing />} />

          <Route path={"tools"}>
            <Route index path={"uuid"} element={<Uuid />} />
          </Route>
          <Route path={"relatorios"}>
            <Route index path={"prospects"} element={<ReportProspects />} />
            <Route index path={"chamados"} element={<ReportSupport />} />
          </Route>
          <Route exact path={"dashboard"} element={<DashboardProspects />}>
            <Route index path={"prospects"} element={<ReportProspects />} />
          </Route>
          <Route exact path={"marketing/:id"} element={<MarketingContent />} />
          <Route exact path={"suporte"} element={<Support />} />
          <Route path={"fale-conosco"}>
            <Route index path={""} element={<Tickets />} />
            <Route exact path={"ticket"} element={<NewTicket />} />
            <Route exact path={"ticket/:id"} element={<Ticket />} />
          </Route>
          <Route exact path={"gestao"}>
            <Route index path={"usuarios"} element={<Users />} />
            <Route index path={"usuarios/:id"} element={<User />} />
            <Route index path={"chamados"} element={<SupportManager />} />
            <Route index path={"chamados/:id"} element={<TicketAgent />} />
            <Route path={"documento"}>
              <Route
                index
                path={"categorias"}
                element={<DocumentsCategory />}
              />
              <Route index path={"novo"} element={<DocumentsNew />} />
            </Route>
            <Route path={"marketing"}>
              <Route exact path={""} element={<MarketingManager />} />
              <Route
                exact
                path={"novo/:date"}
                element={<MarketingManagerNew />}
              />
              <Route
                exact
                path={"editar/:id"}
                element={<MarketingManagerEdit />}
              />
            </Route>
            <Route path={"suporte"}>
              <Route index path={"categoria"} element={<SupportCategory />} />
              <Route index path={"prioridade"} element={<SupportPriority />} />
              <Route index path={"status"} element={<SupportStatus />} />
            </Route>
          </Route>
          <Route index path={"/tarefas"} element={<Task />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes, urlBase };
