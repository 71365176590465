import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useTask = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listTask = useSelector((state) => state.task.index);
  const dataTask = useSelector((state) => state.task.data);

  function setIndex(index) {
    dispatch({
      type: "CONTENT_TASK_INDEX",
      payload: { index: index },
    });
  }

  function setDataTask(data) {
    dispatch({
      type: "CONTENT_TASK_DATA",
      payload: { data: data },
    });
  }

  function resetTask() {
    dispatch({
      type: "CONTENT_TASK_RESET",
    });
  }

  const indexTask = (filters, success) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/task?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const getTask = (id) => {
    api
      .get(`/task/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataTask(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const postTask = (params, success, error) => {
    api
      .post("/task", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataTask(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateTask = (id, params, success, error) => {
    api
      .update(`/task/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delTask = (id, success, error) => {
    api
      .delete(`/task/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getTask,
    postTask,
    indexTask,
    updateTask,
    delTask,
    resetTask,
    listTask,
    dataTask,
    setIndex,
    setDataTask,
  };
};

export default useTask;
