import { useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Text } from "../../Components/Text";
import { Column, ContainerFlex, Row } from "../../Components/Layout";
import { CardBody, CardFooter } from "../../Components/Card";
import { Button } from "../../Components/Button";
import { Input } from "../../Components/Form";
import { formatDate, formatDatetime } from "../../Components/Form/masks";
import { Nav } from "../../Components/Nav";

const FormUser = ({ user, permissions, listCompany }) => {
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();

  const [statusActiveId, setStatusActiveId] = useState("menu-user-info");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState("");
  const [defaultCompany, setDefaultCompany] = useState({});
  const [companies, setCompanies] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [created_at, setCreated_at] = useState("");

  const [permissionsList, setPermissionsList] = useState([]);
  const [view, setView] = useState([]);
  const [edit, setEdit] = useState([]);
  const [manager, setManager] = useState([]);

  useEffect(() => {
    if (user && user.id) {
      setName(user.name);
      setEmail(user.email);
      setEmailVerified(user.email_verified_at);
      setDefaultCompany(user.default_company);
      let list = user.companies.map((company) => {
        return company.value;
      });
      setCompanies(list);
      setCompanies(user.companies);
      console.log(list);
      setCreated_at(user.created_at);
    }
  }, [user]);

  useEffect(() => {
    console.log(listCompany);
    if (listCompany && listCompany.length > 0) {
      setCompanyList(listCompany);
    }
  }, [listCompany]);

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      let list = [];

      permissions.forEach((permission) => {
        if (permission.main) {
          list.push({
            id: permission.id,
            name: permission.name,
          });
        }
      });

      setPermissionsList(list);
    }
  }, [permissions]);

  const handleView = (e) => {
    if (e.target.checked) {
      setView([...view, e.target.value]);
    } else {
      setView(view.filter((item) => item !== e.target.value));
    }
  };

  const handleEdit = (e) => {
    if (e.target.checked) {
      setEdit([...edit, e.target.value]);
      document.getElementById(`view_${e.target.value}`).checked = true;
    } else {
      setEdit(edit.filter((item) => item !== e.target.value));
    }
  };

  const handleManager = (e) => {
    if (e.target.checked) {
      setManager([...manager, e.target.value]);
      document.getElementById(`view_${e.target.value}`).checked = true;
      document.getElementById(`edit_${e.target.value}`).checked = true;
    } else {
      setManager(manager.filter((item) => item !== e.target.value));
    }
  };

  const handleSave = () => {
    console.log("handleSave");
  };

  return (
    <>
      <CardBody>
        <Nav
          className="nav nav-pills nav-justified"
          marginBottom="3"
          id="pills-tab"
          role="tablist"
        >
          <Button
            className={
              statusActiveId === "menu-user-info"
                ? "nav-link active active-tab rounded-top"
                : "nav-link border border-1 bg-white"
            }
            aria-current="page"
            id="menu-user-info"
            data-bs-toggle="tab"
            data-bs-target="#nav-user-info"
            onClick={() => setStatusActiveId("menu-user-info")}
          >
            Informações
          </Button>
          <Button
            // disabled={!permissionsList ? false : true}
            className={
              statusActiveId === `menu-user-permissions`
                ? "nav-link active active-tab rounded-top"
                : "nav-link border border-1 bg-white"
            }
            aria-current="page"
            id={`menu-user-permissions`}
            data-bs-toggle="tab"
            data-bs-target={`#nav-user-permissions`}
            onClick={() => setStatusActiveId(`menu-user-permissions`)}
          >
            Permissões
          </Button>
          <Button
            disabled={!permissionsList}
            className={
              statusActiveId === `menu-user-companies`
                ? "nav-link active active-tab rounded-top"
                : "nav-link border border-1 bg-white"
            }
            aria-current="page"
            id={`menu-user-companies`}
            data-bs-toggle="tab"
            data-bs-target={`#nav-user-companies`}
            onClick={() => setStatusActiveId(`menu-user-companies`)}
          >
            Unidades
          </Button>
        </Nav>
        <ContainerFlex className="tab-content" id="nav-tabContent" width>
          <ContainerFlex
            className={
              statusActiveId === "menu-user-info"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            role="tabpanel"
            aria-labelledby="nav-user-info"
            id="nav-user-info"
          >
            <Row>
              <Column col="12" lg="6" marginBottom="3">
                <Text>Nome:</Text>
                <Input value={name} onChange={setName} />
              </Column>
              <Column col="12" lg="3" marginBottom="3" column>
                <Text>Criado em:</Text>
                <Text bgColor="light" padding="2" rounded center>
                  {created_at ? formatDate(created_at) : ""}
                </Text>
              </Column>
              <Column col="12" lg="3" marginBottom="3" column>
                <Text>Verificado em:</Text>
                <Text
                  bgColor={`${emailVerified ? "success" : "danger"}`}
                  padding="2"
                  rounded
                  center
                  txtColor="white"
                >
                  {emailVerified ? formatDate(emailVerified) : "Não verificado"}
                </Text>
              </Column>
              <Column col="12" lg="6" marginBottom="3">
                <Text>E-mail</Text>
                <Input value={email} onChange={setEmail} />
              </Column>

              <Column col="12" lg="6" marginBottom="3">
                <Text>Unidade Principal</Text>
                <Select
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={defaultCompany}
                  options={companyList}
                  menuPosition={"fixed"}
                  onChange={setDefaultCompany}
                />
              </Column>
            </Row>
          </ContainerFlex>

          <ContainerFlex
            className={
              statusActiveId === "menu-user-permissions"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            role="tabpanel"
            aria-labelledby="nav-user-permissions"
            id="nav-user-permissions"
          >
            {permissionsList && (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Permissão</th>
                    <th scope="col" className="text-center">
                      Vizualizar
                    </th>
                    <th scope="col" className="text-center">
                      Editar
                    </th>
                    <th scope="col" className="text-center">
                      Subestabelecer
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {permissionsList.map((permission) => (
                    <tr key={permission.id}>
                      <td>{permission.name}</td>
                      <td className="text-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`view_${permission.id}`}
                          value={permission.id}
                          onChange={(e) => handleView(e)}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`edit_${permission.id}`}
                          value={permission.id}
                          onChange={(e) => handleEdit(e)}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`manager_${permission.id}`}
                          value={permission.id}
                          onChange={(e) => handleManager(e)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </ContainerFlex>
          <ContainerFlex
            className={
              statusActiveId === "menu-user-companies"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            role="tabpanel"
            aria-labelledby="nav-user-companies"
            id="nav-user-companies"
          >
            <Row between marginBottom="3">
              <Column col="6" className="text-center">
                Inativo
              </Column>
              <Column col="6" className="text-center">
                Ativo
              </Column>
            </Row>
            <DualListBox
              options={companyList}
              selected={companies}
              onChange={setCompanies}
            />
          </ContainerFlex>
        </ContainerFlex>
      </CardBody>
      <CardFooter bgColor="white">
        <ContainerFlex between paddingTop="2">
          <Button
            className="btn btn-danger "
            onClick={() => {
              navigate("/gestao/usuarios");
            }}
          >
            Voltar
          </Button>
          <Button
            className="btn btn-success"
            onClick={() => {
              handleSave();
            }}
          >
            Salvar
          </Button>
        </ContainerFlex>
      </CardFooter>
    </>
  );
};

export default FormUser;
