import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { parse, isValid } from "date-fns";
import { ptBR } from "date-fns/locale";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import NumberFormat from "react-number-format";

import useGender from "../../../Hooks/useGender";
import useCompany from "../../../Hooks/useCompany";
import usePatient from "../../../Hooks/usePatient";
import useProspect from "../../../Hooks/useProspect";
import useError from "../../../Hooks/useError";

import Numbers from "./Numbers";

import { Text } from "../../../Components/Text";
import { FormLabel, Input } from "../../../Components/Form";
import { formatDate } from "../../../Components/Form/masks";
import { Button, ButtonModal } from "../../../Components/Button";
import { validateCpf } from "../../../Components/Form/Validations";
import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Card } from "../../../Components/Card";
import Action from "../Action";

const Patient = ({ patient, lead, inflow, data }) => {
  const animatedComponents = makeAnimated();
  const { listGender } = useGender();
  const { listCompany } = useCompany();
  const { updatePatient } = usePatient();
  const { setAddError } = useError();
  const { indexProspectStatus, updateProspect } = useProspect();

  const [created_at, setCreated_at] = useState("");
  const [updated_at, setUpdated_at] = useState("");

  const [name, setName] = useState("");
  const [validName, setValidName] = useState("");

  const [cpf, setCpf] = useState("");
  const [validCpf, setValidCpf] = useState("");

  const [rg, setRg] = useState("");
  const [validRg, setValidRg] = useState("");

  const [gender, setGender] = useState({});
  const [genderList, setGenderList] = useState("");
  const [validGender, setValidGender] = useState("");

  const [birth_date, setbirth_date] = useState("");
  const [validbirth_date, setValidbirth_date] = useState("");

  const [company, setCompany] = useState({});
  const [companyList, setCompanyList] = useState("");
  const [validCompany, setValidCompany] = useState("");

  const [value, setValue] = useState("");
  const [validValue, setValidValue] = useState("");

  const [status, setStatus] = useState({});
  const [statusList, setStatusList] = useState([]);
  const [validStatus, setValidStatus] = useState("");

  const [showValue, setShowValue] = useState(false);

  useEffect(() => {
    indexProspectStatus({ all: 1 }, handleStatusList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (patient) {
      setName(patient.name && patient.name);
      setCpf(patient.cpf && patient.cpf);
      setRg(patient.rg && patient.rg);
      setGender(patient.gender && patient.gender);
      if (patient.birthday) {
        let bdate = patient.birthday.split("T");
        bdate = bdate[0].split("-").reverse().join("/");
        setbirth_date(bdate);
      }
      setCreated_at(patient.created_at && formatDate(patient.created_at));
      setUpdated_at(patient.updated_at && formatDate(patient.updated_at));
      setCompany(patient.company && patient.company);
    }
  }, [patient]);

  useEffect(() => {
    if (data) {
      setValue(data.value ? data.value.replace(".", ",") : 0);
      if (data.status && data.status.id === 7) setShowValue(true);
    }
  }, [data]);

  useEffect(() => {
    let list = [];
    if (listGender && listGender.length > 0) {
      list.push({
        value: "none",
        label: "Selecione",
      });
      listGender.forEach((item) => {
        list.push({
          value: item.id,
          label: item.name,
        });
      });
      setGenderList(list);
    }
  }, [listGender]);

  useEffect(() => {
    if (listCompany && listCompany.length > 0) {
      let list = [];
      list.push({
        value: "none",
        label: "Selecione",
      });
      listCompany.forEach((item) => {
        list.push(item);
      });
      setCompanyList(list);
    }
  }, [listCompany]);

  useEffect(() => {
    if (statusList && statusList.length > 0 && data && data.status) {
      setStatus({
        value: data.status.id,
        label: data.status.name,
      });
    }
  }, [statusList, data]);

  const handleName = (e) => {
    setName(e.currentTarget.value);
    setValidName("");
  };

  const handleCpf = (e) => {
    setCpf(e.currentTarget.value);
    setValidCpf("");
  };

  const handleCheckCpf = (cpf) => {
    if (validateCpf(cpf)) {
      setValidCpf("");
    } else {
      setValidCpf("CPF Inválido");
    }
  };

  const handleRg = (e) => {
    setRg(e.currentTarget.value);
    setValidRg("");
  };

  const handleGender = (selected) => {
    setGender(selected);
    setValidGender("");
  };

  const handlebirth_date = (e) => {
    setbirth_date(e.currentTarget.value);
    if (e.currentTarget.value.length === 10) {
      isValid(e.currentTarget.value);
      const parsedDate = parse(e.currentTarget.value, "P", new Date(), {
        locale: ptBR,
      });
      const isValidDate = isValid(parsedDate);
      if (isValidDate) {
        setValidbirth_date("");
      } else {
        setValidbirth_date("Data inválida");
      }
    } else if (
      e.currentTarget.value.length < 10 &&
      e.currentTarget.value.length > 0
    ) {
      setValidbirth_date("Data inválida");
    } else {
      setValidbirth_date("");
    }
  };

  const handleCompany = (selected) => {
    setCompany(selected);
    setValidCompany("");
  };

  const handleValue = (e) => {
    setValue(e.currentTarget.value);
    setValidValue("");
  };

  const handleStatus = (selected) => {
    setStatus(selected);
    setValidStatus("");

    console.log(selected);

    if (selected.value === 7) {
      setShowValue(true);
    } else {
      setShowValue(false);
    }
  };

  const handleStatusList = (result) => {
    let list = [];

    if (result && result.length > 0) {
      result.forEach((item) => {
        list.push({
          value: item.id,
          label: item.name,
        });
      });
      setStatusList(list);
    }
  };

  const handleValidate = () => {
    let valid = true;

    if (name.length === 0) {
      setValidName("O nome precisa ser maior.");
      valid = false;
      console.log("name");
    } else {
      setValidName("");
    }

    if (cpf && cpf.length > 0 && !validateCpf(cpf)) {
      setValidCpf("CPF Inválido");
      valid = false;
      console.log("cpf");
    } else {
      setValidCpf("");
    }

    if (rg && rg.length > 0 && rg.length < 5) {
      setValidRg("RG está pequeno demais.");
      valid = false;
      console.log("rg");
    } else {
      setValidRg("");
    }

    if (gender && gender.value === "none") {
      setValidGender("Selecione um sexo.");
      valid = false;
      console.log("gender");
    } else {
      setValidGender("");
    }

    let parsedDate = parse(birth_date, "P", new Date(), {
      locale: ptBR,
    });
    if (birth_date && !isValid(parsedDate)) {
      setValidbirth_date("Data inválida");
      valid = false;

      console.log("birth_date" + parsedDate);
    }

    if (company && company.value === "none") {
      setValidCompany("");
      valid = false;
      console.log("company");
    }

    return valid;
  };

  const handleSave = () => {
    console.log("save");
    if (handleValidate()) {
      let dt_patient = {
        name: name,
        cpf: cpf && cpf.replace(/\./g, "").replace("-", ""),
        rg: rg ? rg : null,
        birth_date: birth_date && birth_date.split("/").reverse().join("-"),
        gender_id: gender ? gender.value : null,
        company_id: company ? company.value : null,
      };

      let dt_prospect = {
        status_id: status.value,
        value: value
          ? value.replace("R$ ", "").replace(".", "").replace(",", ".")
          : 0,
        lead_id: data.lead.lead.id,
        user_id: data.user_id,
      };

      console.log(dt_patient);
      console.log(dt_prospect);

      updatePatient(patient.id, dt_patient, handleSuccess);
      updateProspect(data.id, dt_prospect);
    }
  };

  const handleActive = (result) => {
    console.log(result);
  };
  const handleSuccess = (result) => {
    console.log(result);
  };

  return (
    <ContainerFlex>
      <Row>
        <Column col="12" marginBottom="3">
          <Text className="h5">Dados do paciente</Text>
        </Column>
        <Column col="12" md="8" marginBottom="3">
          <FormLabel>Nome *</FormLabel>
          <Input
            value={name || ""}
            onChange={handleName}
            className={validName && "is-invalid"}
            maxLength="100"
          />
          {validName && <div className="invalid-feedback">{validName}</div>}
        </Column>
        <Column col="12" md="2" marginBottom="3" center column middle>
          <FormLabel>Cadastro</FormLabel>
          <Text tag="h5">
            <Text className="badge bg-light text-black">{created_at}</Text>
          </Text>
        </Column>

        <Column col="12" md="2" marginBottom="3" center column middle>
          <FormLabel>Atualização</FormLabel>
          <Text tag="h5">
            <Text className="badge bg-light text-black">{updated_at}</Text>
          </Text>
        </Column>

        <Column col="12" md="2" lg="3" xl="2" marginBottom="3">
          <FormLabel>Nascimento</FormLabel>
          <InputMask
            value={birth_date || ""}
            onChange={handlebirth_date}
            className={`form-control ${validbirth_date && "is-invalid"}`}
            mask="99/99/9999"
            maskPlaceholder={null}
          />
          {validbirth_date && (
            <div className="invalid-feedback">{validbirth_date}</div>
          )}
        </Column>
        <Column col="12" md="2" lg="3" xl="2" marginBottom="3">
          <FormLabel>CPF</FormLabel>
          <InputMask
            value={cpf || ""}
            onChange={handleCpf}
            onBlur={(e) => handleCheckCpf(e.currentTarget.value)}
            className={`form-control ${validCpf && "is-invalid"}`}
            mask="999.999.999-99"
            maskPlaceholder={null}
          />
          {validCpf && <div className="invalid-feedback">{validCpf}</div>}
        </Column>
        <Column col="12" md="2" lg="3" xl="2" marginBottom="3">
          <FormLabel>RG</FormLabel>
          <Input
            value={rg || ""}
            onChange={handleRg}
            className={validRg && "is-invalid"}
          />
          {validRg && <div className="invalid-feedback">{validRg}</div>}
        </Column>
        <Column col="12" md="2" xl="3" marginBottom="3">
          <FormLabel>Sexo</FormLabel>
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            defaultValue="none"
            value={gender}
            options={genderList}
            menuPosition={"fixed"}
            onChange={handleGender}
            placeholder="Selecione..."
          />
          {validGender && <div className="invalid-feedback">{validGender}</div>}
        </Column>
        <Column col="12" md="2" xl="3" marginBottom="3">
          <FormLabel>Unidade</FormLabel>
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            defaultValue="none"
            value={company}
            options={companyList}
            menuPosition={"fixed"}
            onChange={handleCompany}
            placeholder="Selecione..."
          />
          {validCompany && (
            <div className="invalid-feedback">{validCompany}</div>
          )}
        </Column>
      </Row>
      <hr />
      <Numbers lead={lead} inflow={inflow} />
      <hr />
      <Column col="12" marginBottom="3">
        <Text className="h5">Prospecção</Text>
      </Column>
      <Card border rounded padding="3" bgColor="light">
        <Row>
          <Column col="12" md="2" xl="3" marginBottom="3">
            <FormLabel>Situação</FormLabel>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue="none"
              value={status}
              options={statusList}
              menuPosition={"fixed"}
              onChange={handleStatus}
            />
            {validStatus && (
              <div className="invalid-feedback">{validStatus}</div>
            )}
          </Column>
          {showValue && (
            <Column col="12" lg="2" md="4" marginBottom="3">
              <FormLabel>Valor</FormLabel>
              <NumberFormat
                value={value}
                prefix="R$ "
                thousandsGroupStyle="thousand"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                className="form-control"
                inputMode="numeric"
                decimalScale={2}
                fixedDecimalScale={true}
                onChange={(e) => handleValue(e)}
              />
              {validValue && (
                <div className="invalid-feedback">{validValue}</div>
              )}
            </Column>
          )}
        </Row>
      </Card>
      <Row>
        <ContainerFlex middle right marginTop="3">
          <Button bgColor="danger" txtColor="white" marginRight="3">
            Cancelar
          </Button>
          <ButtonModal
            id="Prospect_Modal_Open_Action"
            bgColor="primary"
            txtColor="white"
            onClick={handleActive}
            modal="Prospect_Modal_Action"
            marginRight="3"
          >
            Agendamento
          </ButtonModal>
          <Button
            bgColor="success"
            txtColor="white"
            onClick={handleSave}
            marginRight="3"
          >
            Atualizar
          </Button>
        </ContainerFlex>
      </Row>

      <Action />
    </ContainerFlex>
  );
};

export default Patient;
