import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Patient from "./Patient";

import { ContainerFlex } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { Card, CardBody, CardHeader } from "../../../Components/Card";
import { Button, ButtonModal } from "../../../Components/Button";
import { Nav } from "../../../Components/Nav";
import NoteList from "./NoteList";
import Histories from "./Histories";
import Action from "../Action";

const Content = ({ data }) => {
  const navigate = useNavigate();

  const [tab, setTab] = useState("menu-patient");
  const [lead, setLead] = useState({});
  const [patient, setPatient] = useState({});
  const [prospect, setProspect] = useState({});
  const [inflow, setInflow] = useState({});

  useEffect(() => {
    if (data) {
      setLead(data.lead.lead);
      setProspect(data);
      setPatient(data.lead.patient);
      setInflow(data.lead.inflow);
    }
  }, [data]);

  return (
    <Card margin="3">
      <CardHeader border={false} bgColor="white">
        <ContainerFlex between middle paddingTop="2">
          <Text className="h5" margin="0">
            Cadastro de Paciente
          </Text>
          <Button
            className="btn btn-outline-secondary"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Text tag="i" className="fas fa-angle-double-left" />
            <Text marginLeft="2">Voltar</Text>
          </Button>
        </ContainerFlex>
      </CardHeader>
      <ContainerFlex>
        <Nav
          className="nav nav-pills nav-justified"
          id="pills-tab"
          role="tablist"
        >
          <Button
            className={
              tab === "menu-patient"
                ? "nav-link active active-tab rounded-top"
                : "nav-link border border-1 bg-white"
            }
            aria-current="page"
            id="menu-patient"
            data-bs-toggle="tab"
            data-bs-target="#nav-patient"
            onClick={() => {
              setTab("menu-patient");
            }}
          >
            Prospecção
          </Button>
          <Button
            className={
              tab === "menu-notes"
                ? "nav-link active active-tab rounded-top"
                : "nav-link border border-1 bg-white"
            }
            aria-current="page"
            id="menu-notes"
            data-bs-toggle="tab"
            data-bs-target="#nav-notes"
            onClick={() => {
              setTab("menu-notes");
            }}
          >
            Anotações
          </Button>
          <Button
            className={
              tab === "menu-inflow"
                ? "nav-link active active-tab rounded-top"
                : "nav-link border border-1 bg-white"
            }
            aria-current="page"
            id="menu-inflow"
            data-bs-toggle="tab"
            data-bs-target="#nav-inflow"
            onClick={() => {
              setTab("menu-inflow");
            }}
          >
            Histórico
          </Button>
        </Nav>
      </ContainerFlex>
      <ContainerFlex
        className="tab-content shadow-sm"
        id="nav-tabContent"
        width
      >
        <ContainerFlex
          className={
            tab === "menu-patient"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          role="tabpanel"
          aria-labelledby="nav-patient"
          id="nav-patient"
        >
          <Card bgColor="white" rounded="0">
            <CardBody>
              <Patient
                patient={patient}
                lead={lead}
                inflow={inflow}
                data={prospect}
              />
            </CardBody>
          </Card>
        </ContainerFlex>
        <ContainerFlex
          className={
            tab === "menu-notes" ? "tab-pane fade show active" : "tab-pane fade"
          }
          role="tabpanel"
          aria-labelledby="nav-notes"
          id="nav-notes"
        >
          <Card bgColor="white" rounded="0" border={false}>
            <NoteList history={data.history_id} />
          </Card>
        </ContainerFlex>
        <ContainerFlex
          className={
            tab === "menu-inflow"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          role="tabpanel"
          aria-labelledby="nav-inflow"
          id="nav-inflow"
        >
          <Card bgColor="white" rounded="0" border>
            <Histories history={data.history_id} />
          </Card>
        </ContainerFlex>
      </ContainerFlex>
    </Card>
  );
};

export default Content;
