import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";

import useUser from "../../Hooks/useUser";
import useCompany from "../../Hooks/useCompany";

import FormUser from "./FormUser";

import { Text } from "../../Components/Text";
import { ContainerFlex } from "../../Components/Layout";
import { Card, CardHeader } from "../../Components/Card";

const Users = () => {
  const { id } = useParams();

  const { getUser, managerPermissions } = useUser();
  const { listCompany } = useCompany();

  const [user, setUser] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getUser(id, handleSuccess, handleError);
      managerPermissions({}, handlePermissions, handleError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePermissions = (data) => {
    console.log(data);
    setPermissions(data);
  };

  const handleSuccess = (data) => {
    setUser(data);
    setLoading(false);
    return data;
  };

  const handleError = (error) => {
    console.log(error);
    setLoading(false);
  };

  const handleUser = () => {
    if (user && user.id) {
      // return users.map((data, index) => <Item key={index} item={data}></Item>);
      return (
        <FormUser
          user={user}
          permissions={permissions}
          listCompany={listCompany}
        />
      );
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum item foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <ContainerFlex full padding="3">
      <Card padding="3" className="shadow">
        <CardHeader>
          <Text className="h3 m-0 p-0" middle>
            Cadastro de Usuário
          </Text>
        </CardHeader>

        {loading ? (
          <ContainerFlex full middle center column>
            <ReactLoading type={"spin"} color={"#1c208c"} />
            <Text>Carregando...</Text>
          </ContainerFlex>
        ) : (
          handleUser()
        )}
      </Card>
    </ContainerFlex>
  );
};

export default Users;
