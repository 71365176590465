import { useDispatch, useSelector } from "react-redux";

const useError = () => {
  const dispatch = useDispatch();

  const errorList = useSelector((state) => state.error.index);

  const setReadError = (id) => {
    let filtered = errorList.filter((item) => item.id !== id);
    dispatch({ type: "ERROR_INDEX", payload: { index: filtered } });
  };

  const setAddError = (error) => {
    console.log(error);
    if (error && error.response) {
      let item = {
        id: Math.floor(Math.random() * 1000000),
        message: error.data.message ? error.data.message : "",

        code: error.status,
        title: error.data.error ? error.data.error : "",
      };

      dispatch({
        type: "ERROR_INDEX",
        payload: { index: [...errorList, item] },
      });
    }
  };

  return {
    setReadError,
    setAddError,
    errorList,
  };
};

export default useError;
