import { combineReducers } from "redux";

import Agenda from "./Agenda";
import App from "./App";
import Appointment from "./Appointment";
import AppointmentStatus from "./AppointmentStatus";
import Auth from "./Auth";
import Company from "./Company";
import ContentAd from "./Content/Ad";
import ContentCampaign from "./Content/Campaign";
import ContentSource from "./Content/Source";
import Error from "./Error";
import Gender from "./Gender";
import HistoryPoints from "./HistoryPoints";
import Lead from "./Lead";
import Marketing from "./Marketing";
import Menu from "./Menu";
import Modal from "./Modal";
import Note from "./Note";
import Notification from "./Notification";
import Patient from "./Patient";
import Prospect from "./Prospect";
import Report from "./Report";
import Documentation from "./Documentation/Document";
import DocumentationCategory from "./Documentation/Category";
import SupportCategory from "./Support/Category";
import SupportPriority from "./Support/Priority";
import SupportStatus from "./Support/Status";
import SupportTicket from "./Support/Ticket";
import Task from "./Task";

export default combineReducers({
  ad: ContentAd,
  agenda: Agenda,
  app: App,
  appointment: Appointment,
  appointmentstatus: AppointmentStatus,
  auth: Auth,
  campaign: ContentCampaign,
  company: Company,
  documentation: Documentation,
  documentationcategory: DocumentationCategory,
  error: Error,
  gender: Gender,
  historyPoints: HistoryPoints,
  lead: Lead,
  marketing: Marketing,
  menu: Menu,
  modal: Modal,
  note: Note,
  notification: Notification,
  patient: Patient,
  prospect: Prospect,
  supportcategory: SupportCategory,
  supportpriority: SupportPriority,
  supportstatus: SupportStatus,
  supportticket: SupportTicket,
  source: ContentSource,
  report: Report,
  task: Task,
});
