import { useEffect } from "react";
import { ContainerFlex } from "../../../Components/Layout";
import useCompany from "../../../Hooks/useCompany";
import useCategory from "../../../Hooks/Support/useCategory";
import useStatus from "../../../Hooks/Support/useStatus";
import usePriority from "../../../Hooks/Support/usePriority";

import Content from "./Content";

const ReportSupport = () => {
  // const { indexAgentTicket } = useAgentTicket();
  const { indexCompany } = useCompany();
  const { indexCategory } = useCategory();
  const { indexStatus } = useStatus();
  const { indexPriority } = usePriority();

  useEffect(() => {
    indexCompany();
    indexCategory();
    indexStatus();
    indexPriority();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerFlex full row>
      <Content />
    </ContainerFlex>
  );
};

export default ReportSupport;
