import React, { useEffect, useState } from "react";

import useStatus from "../../../../Hooks/Support/useStatus";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../../Components/Modal";
import { ContainerFlex, Row, Column } from "../../../../Components/Layout";
import { Input, FormLabel } from "../../../../Components/Form";
import { Button, ButtonModalClose } from "../../../../Components/Button";
import { Text } from "../../../../Components/Text";
import { Switch } from "../../../../Components/Form";

const StatusModal = ({ setReload }) => {
  const { dataStatus, postStatus, updateStatus } = useStatus();

  const [id, setId] = useState("");

  const [status, setStatus] = useState("");
  const [validStatus, setValidStatus] = useState("");

  const [active, setActive] = useState(true);

  const [error, setError] = useState({});
  const handleError = (err) => {
    setError(err);
  };

  const handleId = (e) => {
    setId(e.target.value);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    setValidStatus("");
  };

  const handleActive = () => {
    setActive(!active);
  };

  const handleSuccess = () => {
    document.getElementById("Modal_Status_Close").click();
    setReload();
    handleDataDefault(null);
  };

  const handleValidade = () => {
    let err = true;
    if (status.length <= 3 || status.length >= 100) {
      setValidStatus("O nome da prioridade é inválido.");
      err = false;
    } else {
      setValidStatus("");
    }

    return err;
  };

  const handleConfirm = () => {
    if (handleValidade()) {
      if (id === "") {
        let params = {
          name: status,
          is_open: active,
        };
        postStatus(params, handleSuccess, handleError);
      } else {
        let params = {
          name: status,
          is_open: active,
        };
        updateStatus(id, params, handleSuccess, handleError);
      }
    }
  };

  const handleDataDefault = (Data) => {
    if (Data && Data.id) {
      setId(Data.id);
      setStatus(Data.name);
      setActive(Data.is_open);
    } else {
      setTimeout(() => {
        setId("");
        setStatus("");
        setValidStatus("");
        setActive(true);
      }, 1000);
    }
  };

  useEffect(() => {
    handleDataDefault(dataStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStatus]);

  return (
    <Modal id="Support_Status_Modal" center fixed scroll>
      <ModalHeader
        title="Cadastro de Status"
        id="Modal_Status_Close"
        onClick={handleDataDefault}
        border={false}
      />
      <ModalBody>
        <Row>
          {error && error.status && error.data.message && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                <Text tag="h6" fontWeight="bolder">
                  Erro: {error.status} - {error.data.message}
                </Text>
                <Text fontWeight="bolder"></Text>
                <p>{error.data.errors && JSON.stringify(error.data.errors)}</p>
              </div>
            </Column>
          )}
          <Input value={id} onChange={handleId} maxLength={60} type="hidden" />
          <Column col="8" marginBottom="3">
            <FormLabel>Descrição</FormLabel>
            <Input
              value={status}
              onChange={handleStatus}
              maxLength={60}
              required
              className={validStatus && "is-invalid"}
              onKeyDown={() => setValidStatus("")}
            />
            {validStatus && (
              <div className="invalid-feedback">{validStatus}</div>
            )}
          </Column>
          <Column col="12" md="4" marginBottom="3">
            <FormLabel>Situação</FormLabel>
            <Switch
              id="is_active"
              label={active ? "Aberto" : "Fechado"}
              onClick={handleActive}
              checked={active}
            />
          </Column>
        </Row>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
          >
            Cancelar
          </ButtonModalClose>
          <Button bgColor="success" txtColor="white" onClick={handleConfirm}>
            Concluir
          </Button>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default StatusModal;
