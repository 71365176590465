import { useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import useReport from "../../../Hooks/useReport";
import useCompany from "../../../Hooks/useCompany";
import useCategory from "../../../Hooks/Support/useCategory";
import useStatus from "../../../Hooks/Support/useStatus";

import { Card, CardBody } from "../../../Components/Card";
import { Text } from "../../../Components/Text";
import { Column, Row } from "../../../Components/Layout";
import { Button } from "../../../Components/Button";

const Filters = () => {
  const animatedComponents = makeAnimated();

  const { listCompany } = useCompany();
  const { listStatus } = useStatus();
  const { listCategory } = useCategory();
  const { indexTickets } = useReport();

  const [companyList, setCompanyList] = useState([
    { value: "all", label: "Todas" },
  ]);
  const [company, setCompany] = useState({ value: "all", label: "Todas" });

  const [categoryList, setCategoryList] = useState([
    { value: "all", label: "Todas" },
  ]);
  const [category, setCategory] = useState({ value: "all", label: "Todas" });

  const [statusList, setStatusList] = useState([
    { value: "all", label: "Todas" },
  ]);
  const [status, setStatus] = useState({ value: "all", label: "Todas" });

  useEffect(() => {
    indexTickets({
      company: company.value,
      category: category.value,
      status: status.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listCompany && listCompany.length > 0) {
      setCompanyList(listCompany);
    }
  }, [listCompany]);

  useEffect(() => {
    let list = [];
    if (listCategory && listCategory.data.length > 0) {
      list.push({
        value: "all",
        label: "Todas",
      });
      listCategory.data.forEach((item) => {
        list.push({
          value: item.id,
          label: item.name,
        });
      });
      setCategoryList(list);
    }
  }, [listCategory]);

  useEffect(() => {
    let list = [];

    if (listStatus && listStatus.data.length > 0) {
      list.push({
        value: "all",
        label: "Todas",
      });
      listStatus.data.forEach((item) => {
        list.push({
          value: item.id,
          label: item.name,
        });
      });
      setStatusList(list);
    }
  }, [listStatus]);

  const handleCompany = (selected) => {
    setCompany(selected);
  };

  const handleCategory = (selected) => {
    setCategory(selected);
  };

  const handleStatus = (selected) => {
    setStatus(selected);
  };

  const handleFilter = () => {
    if (category !== "all" || status !== "all") {
      indexTickets({
        company: company.value,
        category: category.value,
        status: status.value,
      });
    } else {
      indexTickets();
    }
  };

  return (
    <Card width padding="2">
      <CardBody full>
        <Row height>
          <Column col="12" md="3">
            <Text tag="h6">Unidade</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={{ value: "all", label: "Todas" }}
              value={company}
              options={companyList}
              onChange={handleCompany}
              menuPosition={"fixed"}
            />
          </Column>
          <Column col="12" md="3">
            <Text tag="h6">Categoria</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={{ value: "all", label: "Todas" }}
              value={category}
              options={categoryList}
              onChange={handleCategory}
              menuPosition={"fixed"}
            />
          </Column>
          <Column col="12" md="3">
            <Text tag="h6">Situação</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue="all"
              value={status}
              options={statusList}
              menuPosition={"fixed"}
              onChange={handleStatus}
            />
          </Column>

          <Column col="12" md="2" top marginTop="3">
            <Button
              txtAlign="center"
              bgColor="info"
              width
              txtColor="white"
              marginTop="2"
              onClick={handleFilter}
              id="btn-filter-tickets"
            >
              <Text tag="i" className="fa fa-filter" marginRight="2" />
              <Text tag="small">Filtrar</Text>
            </Button>
          </Column>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Filters;
