import { useEffect, useState } from "react";
import useTask from "../../Hooks/useTask";

import { ContainerFlex } from "../../Components/Layout";
import { Text } from "../../Components/Text";
import { Card, CardBody, CardHeader } from "../../Components/Card";
import TaskItem from "./TaskItem";

const Content = ({ tasks }) => {
  const { indexTask } = useTask();

  const [pass, setpass] = useState([]);
  const [next, setnex] = useState([]);
  const [today, settoday] = useState([]);
  const [active, setactive] = useState("");

  const passCollapse = document.getElementById("pass-collapse");
  const nextCollapse = document.getElementById("next-collapse");
  const todayCollapse = document.getElementById("today-collapse");

  const handlePass = () => {
    setactive("pass");
    indexTask({ count: "pass" }, setpass);
    if (nextCollapse.classList.contains("show")) {
      nextCollapse.classList.remove("show");
    }
    if (todayCollapse.classList.contains("show")) {
      todayCollapse.classList.remove("show");
    }
  };

  const handleToday = () => {
    setactive("today");
    indexTask({ count: "today" }, settoday);
    if (nextCollapse.classList.contains("show")) {
      nextCollapse.classList.remove("show");
    }
    if (passCollapse.classList.contains("show")) {
      passCollapse.classList.remove("show");
    }
  };

  const handleNext = () => {
    setactive("next");
    indexTask({ count: "next" }, setnex);
    if (todayCollapse.classList.contains("show")) {
      todayCollapse.classList.remove("show");
    }
    if (passCollapse.classList.contains("show")) {
      passCollapse.classList.remove("show");
    }
  };

  useEffect(() => {
    switch (active) {
      case "pass":
        handlePass();
        break;
      case "next":
        handleNext();
        break;
      case "today":
        handleToday();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <ContainerFlex full column padding="5" overflow bgColor="white">
      {tasks && tasks.data.pass && (
        // {tasks && tasks.data.pass && tasks.data.pass.count > 0 && (
        <Card className="shadow" marginBottom="3" bgColor="danger">
          <CardHeader
            txtColor="white"
            data-bs-toggle="collapse"
            href="#pass-collapse"
            role="button"
            aria-expanded="false"
            aria-controls="pass-collapse"
            id="pass"
            onClick={handlePass}
          >
            <Text tag="h4" margin="0">
              Tarefas em atraso - {tasks.data.pass.count}
            </Text>
          </CardHeader>
          <CardBody
            className="collapse"
            id="pass-collapse"
            bgColor="white"
            paddingTopBottom="0"
          >
            {pass && pass.data && pass.data.length > 0 ? (
              pass.data.map((task, index) => (
                <TaskItem key={index} event={task} success={handlePass} />
              ))
            ) : (
              <ContainerFlex full padding="5" overflow bgColor="white">
                <Text>Nenhuma tarefa em atraso</Text>
              </ContainerFlex>
            )}
          </CardBody>
        </Card>
      )}
      {tasks && tasks.data.today && (
        // {tasks && tasks.data.today && tasks.data.today.count > 0 && (
        <Card className="shadow" marginBottom="3">
          <CardHeader
            data-bs-toggle="collapse"
            href="#today-collapse"
            role="button"
            aria-expanded="false"
            aria-controls="today-collapse"
            id="today"
            onClick={handleToday}
          >
            <Text tag="h4" margin="0">
              Tarefas Para Hoje - {tasks.data.today.count}
            </Text>
          </CardHeader>
          <CardBody className="collapse" id="today-collapse" bgColor="white">
            {today && today.data && today.data.length > 0 ? (
              today.data.map((task, index) => (
                <TaskItem key={index} event={task} success={handleToday} />
              ))
            ) : (
              <ContainerFlex full padding="5" overflow bgColor="white">
                <Text>Nenhuma tarefa para o dia de hoje.</Text>
              </ContainerFlex>
            )}
          </CardBody>
        </Card>
      )}
      {tasks && tasks.data.next && (
        // {tasks && tasks.data.next && tasks.data.next.count > 0 && (
        <Card
          className="shadow"
          marginBottom="3"
          bgColor="info"
          txtColor="white"
        >
          <CardHeader
            data-bs-toggle="collapse"
            href="#next-collapse"
            role="button"
            aria-expanded="false"
            aria-controls="next-collapse"
            id="next"
            onClick={handleNext}
          >
            <Text tag="h4" margin="0">
              Tarefas Futuras - {tasks.data.next.count}
            </Text>
          </CardHeader>
          <CardBody className="collapse" id="next-collapse" bgColor="white">
            {next && next.data && next.data.length > 0 ? (
              next.data.map((task, index) => (
                <TaskItem key={index} event={task} success={handleNext} />
              ))
            ) : (
              <ContainerFlex full padding="5" overflow bgColor="white">
                <Text>Nenhuma tarefa futura.</Text>
              </ContainerFlex>
            )}
          </CardBody>
        </Card>
      )}
    </ContainerFlex>
  );
};

export default Content;
