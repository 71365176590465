import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";

import useAuth from "../../../Hooks/useAuth";
import useDocumentation from "../../../Hooks/Documentation/useDocument";
import useWindowsSize from "../../../Hooks/useWindowsSize";

import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
import { Card, CardBody, CardHeader } from "../../../Components/Card";
import { Button } from "../../../Components/Button";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const DocumentContent = () => {
  let navigate = useNavigate();

  const { width } = useWindowsSize();

  const { id } = useParams();
  const { token } = useAuth();

  const { getDocumentation } = useDocumentation();
  const [loading, setLoading] = useState(true);
  const [documentation, setDocumentation] = useState({});
  const [files, setFiles] = useState([]);

  const [pdfFile, setPdfFile] = useState("");
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    setLoading(true);
    getDocumentation(id, handleSuccess, handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (files && files.length > 0) {
      files.forEach((file) => {
        if (file.name.includes("pdf")) {
          axios({
            url: `/api/download/document/${file.name}`,
            method: "GET",
            responseType: "blob", // important
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            setPdfFile(url);
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    if (documentation && documentation.files) {
      setFiles(documentation.files);
    } else {
      setFiles([]);
    }
  }, [documentation]);

  const handleSuccess = (data) => {
    setDocumentation(data);
    setLoading(false);
  };

  const handleError = (error) => {
    setLoading(false);
  };

  const handleDownload = (file) => {
    axios({
      url: `/api/download/document/${file}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleLoadDocument = () => {
    if (documentation && documentation.id) {
      return (
        <Card full padding="3" bgColor="white" rounded>
          <CardHeader between>
            <Text tag="h1">{documentation.title}</Text>
            <ContainerFlex>
              <Button
                size="sm"
                padding="0"
                paddingLeftRight="2"
                marginRight="2"
                className="btn btn-outline-secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Text tag="i" className="fas fa-angle-double-left" />
                <Text marginLeft="2">Voltar</Text>
              </Button>
            </ContainerFlex>
          </CardHeader>
          {files && files.length > 0 && (
            <ContainerFlex paddingTopBottom="2">
              <ContainerFlex paddingTopBottom="2" borderBottom>
                <Text tag="h6" padding="1">
                  Anexos:
                </Text>

                {files.map((file, index) => (
                  <Button
                    margin="1"
                    paddingBottom="0"
                    key={index}
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      handleDownload(file.name);
                    }}
                  >
                    <Text
                      tag="i"
                      className={`fas fa-file-${
                        file.name.includes("jpg")
                          ? "image"
                          : file.name.includes("jpeg")
                          ? "image"
                          : file.name.includes("bmp")
                          ? "image"
                          : file.name.includes("png")
                          ? "image"
                          : file.name.includes("doc")
                          ? "word"
                          : file.name.includes("docx")
                          ? "word"
                          : file.name.includes("csv")
                          ? "excel"
                          : file.name.includes("rtf")
                          ? "excel"
                          : file.name.includes("xlsx")
                          ? "excel"
                          : file.name.includes("xls")
                          ? "excel"
                          : file.name.includes("txt")
                          ? "alt"
                          : file.name.includes("pdf")
                          ? "pdf"
                          : file.name.includes("zip")
                          ? "archive"
                          : "file"
                      }`}
                      marginRight="2"
                    />
                    {file.name}
                  </Button>
                ))}
              </ContainerFlex>
            </ContainerFlex>
          )}
          <CardBody>
            {pdfFile ? (
              <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={width}
                  />
                ))}
              </Document>
            ) : (
              <ContainerFlex
                dangerouslySetInnerHTML={{
                  __html:
                    documentation.content && documentation.content[0]
                      ? documentation.content[0].content
                      : "",
                }}
              />
            )}
          </CardBody>
        </Card>
      );
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum conteúdo foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <ContainerFlex full padding="3">
      {loading ? (
        <ContainerFlex full middle center column padding="2">
          <ReactLoading type={"spin"} color={"#1c208c"} height={"20"} />
          <Text>Carregando...</Text>
        </ContainerFlex>
      ) : (
        handleLoadDocument()
      )}
      {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
    </ContainerFlex>
  );
};

export default DocumentContent;
