import { useEffect, useState } from "react";

import { ContainerFlex } from "../../Components/Layout";

import Content from "./Content";
import useAgenda from "../../Hooks/useAgenda";

const Agenda = () => {
  const { indexAgenda, listAgenda } = useAgenda();

  const [reload, setReload] = useState(true);

  const handleReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    indexAgenda();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      <ContainerFlex full row>
        {listAgenda && Array.isArray(listAgenda.data) && (
          <Content setReload={handleReload} agendas={listAgenda.data} />
        )}
      </ContainerFlex>
    </>
  );
};

export default Agenda;
