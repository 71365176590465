import React from 'react';
import PropTypes from 'prop-types';

import { sizingProps, sizingClass } from '../utilities/sizing';
import { spacingProps, spacingClass } from '../utilities/spacing';

import cn from 'classnames';

const Form = (props) => {
  let { tag: Tag, children, className, ...attributes } = props;
  
  let sizing, spacing;
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);

  return (
    <Tag 
      {...attributes} 
      className={cn(
        'form-area', 
        className,
        sizing,
        spacing,
      )}
    >
      {children}
    </Tag>
  );
};

Form.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  ...sizingProps(),
  ...spacingProps(),
};

Form.defaultProps = {
  tag: 'form',
  className: '',
};

export default Form;
