import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useAppointment = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listAppointment = useSelector((state) => state.appointment.index);
  const dataAppointment = useSelector((state) => state.appointment.data);

  function setIndex(index) {
    dispatch({
      type: "CONTENT_APPOINTMENT_INDEX",
      payload: { index: index },
    });
  }

  function setDataAppointment(data) {
    dispatch({
      type: "CONTENT_APPOINTMENT_DATA",
      payload: { data: data },
    });
  }

  function resetAppointment() {
    dispatch({
      type: "CONTENT_APPOINTMENT_RESET",
    });
  }

  const indexAppointment = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/agenda/appointment?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const getAppointment = (id) => {
    api
      .get(`/agenda/appointment/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataAppointment(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const postAppointment = (params, success, error) => {
    api
      .post("/agenda/appointment", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataAppointment(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateAppointment = (id, params, success, error) => {
    api
      .update(`/agenda/appointment/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delAppointment = (id, success, error) => {
    api
      .delete(`/agenda/appointment/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getAppointment,
    postAppointment,
    indexAppointment,
    updateAppointment,
    delAppointment,
    resetAppointment,
    listAppointment,
    dataAppointment,
    setDataAppointment,
  };
};

export default useAppointment;
