import React from 'react';
import PropTypes from 'prop-types';

import { sizingProps, sizingClass } from '../utilities/sizing';

import cn from 'classnames';

const ButtonGroup = (props) => {
  let {
    tag: Tag,
    className,
    children,
    type,
    size,
    vertical,
    ...attributes
  } = props;

  let sizing;
  [sizing, attributes] = sizingClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn(
        !vertical ? `btn-${type}` : `btn-${type}-vertical`,
        size && `btn-group-${size}`,
        sizing,
        className
      )}
    >
      {children}
    </Tag>
  );
};

ButtonGroup.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(['group', 'toolbar']),
  size: PropTypes.oneOf(['sm', 'lg']),
  vertical: PropTypes.bool,
  ...sizingProps(),
};

ButtonGroup.defaultProps = {
  tag: 'div',
  className: '',
  type: 'group',
};

export default ButtonGroup;
