import { useEffect, useState } from "react";

import { ContainerFlex } from "../../Components/Layout";

import Content from "./Content";
import Filters from "./Filters";

const Lead = () => {
  const [reload, setReload] = useState(true);
  const [prospectList, setProspectList] = useState(true);

  const handleReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <ContainerFlex full padding="3">
      <Filters setProspectList={setProspectList} />
      <Content setReload={handleReload} prospectList={prospectList} />
    </ContainerFlex>
  );
};

export default Lead;
