import { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import MenuFather from "./MenuFather";
import MenuCollapse from "./MenuCollapse";
import { Accordion } from "../../Components/Accordion";

const MenuList = () => {
  const { token, me, refresh } = useAuth();

  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    if (token) {
      refresh(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (me && me.data && me.data.menus && me.data.menus.length > 0) {
      let menu = [];

      me.data.menus.forEach((element) => {
        if (element.main) {
          let m = {
            ...element,
            children: me.data.menus.filter((item) => item.owner === element.id),
          };
          menu.push(m);
        }
      });
      setMenuList(menu);
    }
  }, [me]);

  return (
    <Accordion id="sidemenu" flush>
      {menuList.length > 0 &&
        menuList.map((data, index) =>
          data.children.length > 0 ? (
            <MenuCollapse key={index} item={data} />
          ) : (
            <MenuFather key={index} item={data} />
          )
        )}
      <MenuFather
        item={{
          id: 13,
          name: "Fale Conosco",
          url: "/fale-conosco",
          main: 1,
          owner: 0,
          order: 9,
          icon: "fas fa-life-ring",
          class: 9,
          is_active: true,
        }}
      />
      {/* </ul> */}
    </Accordion>
  );
};

export default MenuList;
