import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import { Button } from "../../../Components/Button";
import { ContainerFlex } from "../../../Components/Layout";
import {
  Table,
  TableBody,
  TableHead,
  TableHeadTh,
  TableTd,
  TableTr,
} from "../../../Components/Table";
import { Text } from "../../../Components/Text";

const SupportManagerTable = ({ listTickets }) => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (listTickets) {
      setData(listTickets);
    }
  }, [listTickets]);

  const handleEdit = (data) => {
    navigate(`/gestao/chamados/${data.currentTarget.id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Assunto",
        accessor: "title",
        Cell: ({ cell: { value } }) => {
          return <ContainerFlex middle>{value}</ContainerFlex>;
        },
      },
      {
        Header: "Unidade",
        accessor: "company_name",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              {value}
            </ContainerFlex>
          );
        },
      },
      {
        Header: "Setor",
        accessor: "category_name",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              {value}
            </ContainerFlex>
          );
        },
      },
      {
        Header: "Situação",
        accessor: "status_name",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              {value}
            </ContainerFlex>
          );
        },
      },
      {
        Header: "Prioridade",
        accessor: "priority_name",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              {value}
            </ContainerFlex>
          );
        },
      },
      {
        Header: "Usuário",
        accessor: "user_name",
        Cell: ({ cell: { value } }) => {
          return (
            <ContainerFlex center middle>
              {value}
            </ContainerFlex>
          );
        },
      },
      {
        id: "Opções",
        Header: "Opções",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="d-flex justify-content-evenly">
              <Button
                bgColor="success"
                id={value}
                onClick={handleEdit}
                size="sm"
              >
                <Text tag="i" className="fa fa-pen" txtColor="white" />
              </Button>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Table hover border {...getTableProps()} style={{ fontSize: "14px" }}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableTr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeadTh {...column.getHeaderProps()} txtAlign="center">
                {column.render("Header")}
              </TableHeadTh>
            ))}
          </TableTr>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableTr
              {...row.getRowProps()}
              id={`row-${row.original.id}`}
              style={{
                fontWeight: row.original.vizualized_agent ? "400" : "bolder",
              }}
            >
              {row.cells.map((cell) => {
                return (
                  <TableTd {...cell.getCellProps()} middle>
                    {cell.render("Cell")}
                  </TableTd>
                );
              })}
            </TableTr>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SupportManagerTable;
