import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useSource = () => {
  const { token } = useAuth();
  const dispatch = useDispatch();

  const listSource = useSelector((state) => state.source.index);
  const selectedSources = useSelector((state) => state.source.selected);
  const dataSource = useSelector((state) => state.source.data);

  function setIndex(index) {
    dispatch({
      type: "CONTENT_SOURCE_INDEX",
      payload: { index: index },
    });
  }

  function setSelectedSources(data) {
    dispatch({
      type: "CONTENT_SOURCE_SELECTED",
      payload: { selected: data },
    });
  }

  function setData(data) {
    dispatch({
      type: "CONTENT_SOURCE_DATA",
      payload: { data: data },
    });
  }

  function resetSource(data) {
    dispatch({
      type: "CONTENT_SOURCE_RESET",
    });
  }

  const indexSource = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }

    api
      .get(`/content/source?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const getSource = (id) => {
    api
      .get(`/content/source/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setData(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const postSource = (params, success, error) => {
    api
      .post("/content/source", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setData(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateSource = (id, params, success, error) => {
    api
      .update(`/content/source/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delSource = (id, success, error) => {
    api
      .delete(`/content/source/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    getSource,
    postSource,
    indexSource,
    updateSource,
    delSource,
    resetSource,
    listSource,
    dataSource,
    selectedSources,
    setSelectedSources,
  };
};

export default useSource;
