import { useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import DatePicker from "react-datepicker";
import isValid from "date-fns/isValid";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";

import useCompany from "../../Hooks/useCompany";
import useProspect from "../../Hooks/useProspect";

import { Card, CardBody } from "../../Components/Card";
import { Text } from "../../Components/Text";
import { Column, Row } from "../../Components/Layout";
import { Button } from "../../Components/Button";

const Filters = ({ setProspectList }) => {
  const animatedComponents = makeAnimated();

  const { listCompany } = useCompany();
  const { indexConversions } = useProspect();

  const today = new Date();

  const [startDate, setStartDate] = useState(today.setDate(1));
  const [validStartDate, setValidStartDate] = useState("");

  const [endDate, setEndDate] = useState(
    new Date(today.getFullYear(), today.getMonth() + 1, 0)
  );
  const [validEndDate, setValidEndDate] = useState("");

  const [unit, setUnit] = useState([
    { value: "all", label: "Todas as unidades" },
  ]);
  const [listUnits, setListUnits] = useState([]);
  const [validUnits, setValidUnits] = useState("");

  useEffect(() => {
    if (listCompany) {
      let list = [{ value: "all", label: "Todas as unidades" }];
      listCompany.forEach((data) => {
        list.push(data);
      });
      setListUnits(list);
    }
  }, [listCompany]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setValidStartDate(isValid(date) ? "" : "Data inválida");
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setValidEndDate(isValid(date) ? "" : "Data inválida");
  };

  const handleCompany = (e) => {
    setUnit(e);
    setValidUnits(e ? "" : "Selecione uma empresa");
  };

  const handleValidate = () => {
    let valid = true;

    if (!isValid(startDate)) {
      setValidStartDate("Data inicial inválida");
      valid = false;
    } else {
      setValidStartDate("");
    }

    if (!isValid(endDate)) {
      setValidEndDate("Data final inválida");
      valid = false;
    } else {
      setValidEndDate("");
    }

    if (unit[0].value !== "all") {
      if (!unit || unit.value === undefined) {
        console.log(unit[0].value);
        setValidUnits("Selecione uma empresa");
        valid = false;
      } else {
        setValidUnits("");
      }
    }

    return valid;
  };

  const handleSuccess = (data) => {
    if (data && data.data) {
      setProspectList(data.data);
    }
  };

  const handleFilter = () => {
    console.log(unit);
    if (handleValidate()) {
      let params = {
        dt_start: format(startDate, "yyyy-MM-dd"),
        dt_end: format(endDate, "yyyy-MM-dd"),
        company_id: unit.value,
      };

      indexConversions(params, handleSuccess);
    }
  };

  return (
    <Card bgColor="white" marginBottom="3" padding="2">
      <CardBody>
        <Row>
          <Column col="6" md="2">
            <Text tag="h6">Data Início</Text>
            <DatePicker
              locale="ptBR"
              selected={startDate}
              onChange={(date) => handleStartDateChange(date)}
              onBlur={() => handleStartDateChange(startDate)}
              dateFormat="dd/MM/yyyy"
              portalId="root"
              className={
                validStartDate
                  ? "form-control is-invalid bg-white"
                  : "form-control bg-white"
              }
              maxDate={endDate}
              minDate={subDays(endDate, 60)}
            />
            {validStartDate && (
              <div className="invalid-feedback">{validStartDate}</div>
            )}
          </Column>
          <Column col="6" md="2">
            <Text tag="h6">Data Final</Text>
            <DatePicker
              locale="ptBR"
              selected={endDate}
              onChange={(date) => handleEndDateChange(date)}
              onBlur={() => handleEndDateChange(endDate)}
              dateFormat="dd/MM/yyyy"
              portalId="root"
              className={
                validEndDate
                  ? "form-control is-invalid bg-white"
                  : "form-control bg-white"
              }
              minDate={startDate}
              maxDate={addDays(startDate, 60)}
            />
            {validEndDate && (
              <div className="invalid-feedback">{validEndDate}</div>
            )}
          </Column>
          <Column col="12" md="4">
            <Text tag="h6">Unidades</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={[{ value: "all", label: "Todas as Unidades" }]}
              value={unit}
              options={listUnits}
              onChange={handleCompany}
              menuPosition={"fixed"}
              className={validUnits && "form-control is-invalid bg-white"}
            />
            {validUnits && <div className="invalid-feedback">{validUnits}</div>}
          </Column>

          <Column col="12" md="2" top marginTop="3">
            <Button
              txtAlign="center"
              bgColor="info"
              width
              txtColor="white"
              marginTop="2"
              onClick={handleFilter}
              id="btn-filter-campaign"
            >
              <Text tag="i" className="fa fa-file-download" marginRight="2" />
              <Text tag="small">Filtrar</Text>
            </Button>
          </Column>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Filters;
