import { useEffect } from "react";
import Filters from "./Filters";
import List from "./List";

import { ContainerFlex } from "../../../Components/Layout";

import useCategory from "../../../Hooks/Support/useCategory";
import useStatus from "../../../Hooks/Support/useStatus";

const Ticket = () => {
  const { indexCategory } = useCategory();
  const { indexStatus } = useStatus();

  useEffect(() => {
    indexStatus();
    indexCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerFlex full padding="3">
      <Filters />
      <List />
    </ContainerFlex>
  );
};

export default Ticket;
