import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import useAd from "../../../Hooks/useAd";
import useCampaign from "../../../Hooks/useCampaign";

import AdsTable from "./AdsTable";
import ModalAd from "./ModalAd";

import { Text } from "../../../Components/Text";
import { Button, ButtonModal } from "../../../Components/Button";
import { Column, ContainerFlex, Row } from "../../../Components/Layout";

const AdsList = ({ campaign, open }) => {
  const { getCampaign } = useCampaign();
  const { indexAdCampaign } = useAd();

  const [loading, setLoading] = useState(true);
  const [listAds, setListAds] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (campaign && open) {
      setLoading(true);
      indexAdCampaign({ campaign: campaign, status: "all" }, handleListAds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign, open]);

  useEffect(() => {
    if (reload) {
      setLoading(true);
      indexAdCampaign({ campaign: campaign, status: "all" }, handleListAds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const handleCampign = () => {
    getCampaign(campaign);
  };

  const handleListAds = (data) => {
    setLoading(false);
    setReload(false);
    setListAds(data);
  };

  const handleLoadAds = () => {
    if (listAds && listAds.length > 0) {
      return <AdsTable listAds={listAds} />;
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum anúncio foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <Row>
      <Column col="12" between middle bgColor="secondary" padding="2">
        <Text margin="0" txtColor="white">
          Listagem de Anúncios
        </Text>
        <ContainerFlex>
          <Button
            id={`btn-reload-ads-${campaign}`}
            onClick={() => {
              setReload(true);
            }}
            txtColor="white"
            paddingTopBottom="1"
          >
            <i className="fa fa-redo-alt"></i>
          </Button>
          <ButtonModal
            modal="Ad_Modal_Campaign"
            onClick={handleCampign}
            txtColor="white"
            paddingTopBottom="1"
          >
            <i className="fa fa-plus"></i>
          </ButtonModal>
        </ContainerFlex>
      </Column>
      {/* <Column col="12">
        <hr className="p-0 m-0" />
      </Column> */}
      <Column col="12" padding="0" border>
        {loading ? (
          <ContainerFlex full middle center column padding="2">
            <ReactLoading type={"spin"} color={"#1c208c"} height={"20"} />
            <Text>Carregando...</Text>
          </ContainerFlex>
        ) : (
          handleLoadAds()
        )}
        {open && <ModalAd reload={setReload} />}
      </Column>
    </Row>
  );
};

export default AdsList;
