import useAuth from "../useAuth";
import api from "../../Data/api";

const useMessage = () => {
  const { token } = useAuth();

  const indexMessage = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/support/message?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data.data);
        } else {
          error(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const getMessage = (id, success, error) => {
    api
      .get(`/support/message/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        } else {
          error(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const postMessage = (params, success, error) => {
    api
      .post("/support/message", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateMessage = (id, params, success, error) => {
    api
      .update(`/support/message/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delMessage = (id, success, error) => {
    api
      .delete(`/support/message/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    getMessage,
    postMessage,
    indexMessage,
    updateMessage,
    delMessage,
  };
};

export default useMessage;
