import { useNavigate } from "react-router-dom";

import { Button } from "../../Components/Button";
import { Card, CardBody, CardHeader } from "../../Components/Card";
import { ContainerFlex } from "../../Components/Layout";
import { Text } from "../../Components/Text";

const Support = () => {
  let navigate = useNavigate();

  const handleDocuments = (e) => {
    e.preventDefault();
    navigate("/manuais");
  };

  const handleTicket = (e) => {
    e.preventDefault();
    navigate("/fale-conosco");
  };

  return (
    <ContainerFlex middle column padding="3">
      <Card full>
        <CardHeader border={false} center className="p-4">
          <Text tag="h1">Suporte</Text>
        </CardHeader>
        <CardBody>
          <Text tag="h5" center>
            Retire suas dúvidas através de nossa base de documentos e arquivos.
          </Text>
          <Text tag="h5" center>
            Aqui você encontra a maioria das soluções para te ajudar.
          </Text>
          <ContainerFlex middle column marginTop="5">
            <Button
              size="lg"
              column
              marginBottom="5"
              className="border shadow "
              padding="5"
              onClick={handleDocuments}
              middle
            >
              <Text tag="h1" className="fa fa-archive" />
              <Text tag="h5">Documentos</Text>
            </Button>
          </ContainerFlex>
          <hr />
        </CardBody>
        <ContainerFlex middle column marginTop="5">
          <Text tag="h5">
            Caso não encontre a solução que procura, você pode enviar um
            chamado.
          </Text>
          <Text tag="h5">
            A equipe de suporte irá responder o mais rápido possível.
          </Text>
          <Button
            size="lg"
            column
            bgColor="info"
            txtColor="white"
            marginTop="3"
            marginBottom="5"
            padding="4"
            className="rounded border shadow "
            onClick={handleTicket}
            middle
          >
            <Text tag="h1" className="fa fa-clipboard-list" />
            <Text tag="h5">Meus Chamados</Text>
          </Button>
        </ContainerFlex>
      </Card>
    </ContainerFlex>
  );
};

export default Support;
