import React from "react";

import MenuList from "./MenuList";

// import { NavBar } from "../../Components/Nav";
import Image from "../../Components/Image";

import Logo from "../../Assets/images/logo192.png";
import { ContainerFlex } from "../../Components/Layout";

const Sidebar = () => {
  return (
    <ContainerFlex
      height="vh-100"
      // txtColor="white"
      // paddingLeftRight="2"
      className="d-flex flex-shrink-0 text-white bg-dark sidebar"
      top
      column
      style={{ overflow: "auto" }}
    >
      <ContainerFlex padding="3">
        <Image src={Logo} width />
      </ContainerFlex>
      <hr />
      <ContainerFlex overflow width>
        <MenuList />
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default Sidebar;
