import useAuth from "./useAuth";
import api from "../Data/api";

const useUser = () => {
  const { token } = useAuth();

  const indexUser = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`user?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data.data);
        } else {
          error(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const managerPermissions = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`user/permissions/manager?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data.data);
        } else {
          error(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const getUser = (id, success, error) => {
    api
      .get(`user/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const postUser = (params, success, error) => {
    api
      .post("/user", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateUser = (id, params, success, error) => {
    api
      .update(`user/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          // setData User(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delUser = (id, success, error) => {
    api
      .delete(`user/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getUser,
    postUser,
    indexUser,
    managerPermissions,
    updateUser,
    delUser,
  };
};

export default useUser;
