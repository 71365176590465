import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";

import {
  Table,
  TableBody,
  TableHead,
  TableHeadTh,
  TableTd,
  TableTr,
} from "../../Components/Table";

import { formatPhone, formatDate } from "../../Components/Form/masks";

import useLead from "../../Hooks/useLead";
import { Button } from "../../Components/Button";
import { Text } from "../../Components/Text";
import { ContainerFlex } from "../../Components/Layout";

const LeadTable = () => {
  const { listLead } = useLead();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (listLead && listLead.data) {
      setData(listLead.data);
    }
  }, [listLead]);

  const columns = useMemo(
    () => [
      {
        Header: "Dados do Contato",
        columns: [
          {
            Header: "Contato",
            accessor: "lead",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value && formatPhone(value)}
                </ContainerFlex>
              );
            },
          },
          {
            Header: "Nome",
            accessor: "patients[0].name",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
          {
            Header: "Unidade",
            accessor: "company.name",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
        ],
      },
      {
        Header: "Campanha",
        columns: [
          {
            Header: "Data",
            accessor: "updated_at",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value && formatDate(value)}
                </ContainerFlex>
              );
            },
          },
          {
            Header: "Fluxo",
            accessor: "ads[0].campaign.source.name",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
          {
            Header: "Anuncio",
            accessor: "ads[0].name",
            Cell: ({ cell: { value } }) => {
              return (
                <ContainerFlex center middle>
                  {value}
                </ContainerFlex>
              );
            },
          },
          // {
          //   Header: "Conversão",
          //   accessor: "status",
          // },
          {
            Header: "Opções",
            accessor: "id",
            Cell: ({ cell: { value } }) => {
              return (
                <div className="d-flex justify-content-evenly">
                  <Button bgColor="info">
                    <Text tag="i" className="fa fa-pen" txtColor="white" />
                  </Button>
                  {/* <Button bgColor="danger">
                    <Text
                      tag="i"
                      className="fa fa-trash-alt"
                      txtColor="white"
                    />
                  </Button> */}
                </div>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Table hover border {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableTr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeadTh {...column.getHeaderProps()} txtAlign="center">
                {column.render("Header")}
              </TableHeadTh>
            ))}
          </TableTr>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableTr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableTd {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableTd>
                );
              })}
            </TableTr>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default LeadTable;
