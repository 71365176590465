import { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Text } from "../../../Components/Text";
import { Button } from "../../../Components/Button";
import { Column, Row } from "../../../Components/Layout";
import { Card, CardBody } from "../../../Components/Card";

import useCompany from "../../../Hooks/useCompany";
import useSource from "../../../Hooks/useSource";
import useCampaign from "../../../Hooks/useCampaign";

const Sidebar = () => {
  const animatedComponents = makeAnimated();

  const { listCompany } = useCompany();
  const { indexSource, listSource } = useSource();
  const { indexCampaign } = useCampaign();

  const [status, setStatus] = useState({ value: 1, label: "Ativo" });
  const [listStatus] = useState([
    { value: 1, label: "Ativo" },
    { value: 0, label: "Inativo" },
  ]);

  const [units, setUnits] = useState();
  const [listUnits, setListUnits] = useState([]);

  const [sources, setSources] = useState();
  const [listSources, setListSources] = useState([]);

  useEffect(() => {
    if (listCompany) {
      setListUnits(listCompany);
    }
  }, [listCompany]);

  useEffect(() => {
    setListSources([]);
    setSources([]);
    if (units && units.length > 0) {
      let comps = units.map((data) => {
        return data.value;
      });
      indexSource({ comp_id: comps });
    } else {
      indexSource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units]);

  useEffect(() => {
    if (listSource) {
      let list = listSource.data.map((data) => {
        return {
          value: data.id,
          label: data.name + " - " + data.company.label,
        };
      });
      setListSources(list);
    }
  }, [listSource]);

  const handleCompany = (e) => {
    setUnits(e);
  };

  const handleSources = (e) => {
    setSources(e);
  };

  const handleFilter = () => {
    if (sources.length > 0) {
      let list = sources.map((data) => {
        return data.value;
      });
      indexCampaign({ source: list, status: status.value });
    } else if (units && units.length > 0) {
      let comps = units.map((data) => {
        return data.value;
      });
      indexCampaign({ comp_id: comps.join(","), status: status.value });
    } else {
      indexCampaign({ status: status.value });
    }
  };

  return (
    <Card width padding="2">
      <CardBody full>
        <Row height>
          <Column col="12" md="4">
            <Text tag="h6">Unidades</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              // defaultValue={selectedCompanies}
              value={units}
              isMulti
              options={listUnits}
              onChange={handleCompany}
              // menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </Column>
          <Column col="12" md="4">
            <Text tag="h6">Origens</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              // defaultValue={selectedSources}
              value={sources}
              isMulti
              options={listSources}
              // menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={handleSources}
            />
          </Column>
          <Column col="12" md="2">
            <Text tag="h6">Status</Text>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={[{ value: 1, label: "Ativo" }]}
              value={status}
              options={listStatus}
              // menuPortalTarget={document.body}
              menuPosition={"fixed"}
              onChange={(e) => setStatus(e)}
            />
          </Column>
          <Column col="12" md="2" top marginTop="3">
            <Button
              txtAlign="center"
              bgColor="info"
              width
              txtColor="white"
              marginTop="2"
              onClick={handleFilter}
              id="btn-filter-campaign"
            >
              <Text tag="i" className="fa fa-file-download" marginRight="2" />
              <Text tag="small">Filtrar</Text>
            </Button>
          </Column>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Sidebar;
