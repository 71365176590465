const initialState = {
  index: [],
  selected: [],
  data: {},
};

const SupportPriority = (state = initialState, action) => {
  switch (action.type) {
    case "SUPPORT_PRIORITY_INDEX":
      return { ...state, index: action.payload.index };
    case "SUPPORT_PRIORITY_SELECTED":
      return { ...state, selected: action.payload.selected };
    case "SUPPORT_PRIORITY_DATA":
      return { ...state, data: action.payload.data };
    case "SUPPORT_PRIORITY_RESET":
      return initialState;
    default:
      return state;
  }
};

export default SupportPriority;
