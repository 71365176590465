import React from 'react';
import PropTypes from 'prop-types';

import { colorsProps, colorsClass } from '../utilities/colors';
import { spacingProps, spacingClass } from '../utilities/spacing';

import cn from 'classnames';

const Radio = (props) => {
  let { 
    className, 
    label,
    name, 
    id,
    setValue,
    ...attributes
  } = props;

  let colors, spacing;
  [colors, attributes] = colorsClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  
  return (
    <div className={cn("form-check",spacing,colors,className)}>
      <input className="form-check-input" type="radio" name={name} id={id} {...attributes} />
      <label className="form-check-label" htmlFor={id}>{label}</label>
    </div>
  )
};

Radio.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired, 
  id: PropTypes.string,
  ...colorsProps(),
  ...spacingProps(),
};

Radio.defaultProps = {
  className: '',
  placeholder: '',
};

export default Radio;


