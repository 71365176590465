import React, { useEffect, useState } from "react";

import Logo from "../../Assets/images/logo192.png";

import Image from "../Image";
import { Column, ContainerFlex, Row } from "../Layout";
import { Form, Input, InputGroup, Span } from "../Form";
import { Button } from "../Button";
import { Text } from "../Text";

const Login = ({ submit, err }) => {
  const [view, setView] = useState(false);
  const [username, setUsername] = useState("");
  const [validUsername, setValidUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState("");

  const [error, setError] = useState({});

  const handleUsername = (e) => {
    setUsername(e.target.value);
    setValidUsername("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setValidPassword("");
  };

  const handleValidation = () => {
    let valid = true;
    if (username === "") {
      setValidUsername("Insira um email válido.");
      valid = false;
    }
    if (password === "") {
      setValidPassword("A senha não pode ser vazia.");
      valid = false;
    }
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      submit(username, password);
    }
  };

  useEffect(() => {
    if (err && err.data) {
      handleError(err);
    }
  }, [err]);

  const handleError = (error) => {
    if (error.data && error.data.errors) {
      if (error.data.errors) {
        let arr = [];
        for (let key in error.data.errors) {
          arr.push({
            key: key,
            message: error.data.errors[key][0],
          });
        }
        setError(arr);
      }
    } else {
      setError({});
    }
  };

  return (
    <ContainerFlex full middle className="bg-ydental-gradient" padding="4">
      <Row width middle center>
        <Column col="12" lg="4" md="5" sm="7" />
        <Column col="10" lg="4" md="5" sm="7">
          <Form onSubmit={handleSubmit}>
            <Image src={Logo} marginBottom="5" />
            <InputGroup marginBottom="3">
              <Span tag="span" paddingTopBottom="2">
                <Text tag="i" className="fa fa-envelope" />
              </Span>
              <Input
                type="text"
                placeholder="Email de acesso"
                value={username}
                onChange={handleUsername}
                className={validUsername && "is-invalid"}
              />
              {validUsername && (
                <div className="invalid-feedback">{validUsername}</div>
              )}
            </InputGroup>

            <InputGroup marginBottom="3">
              <Span tag="span">
                <Text tag="i" className="fa fa-lock" />
              </Span>
              <Input
                type={view ? "text" : "password"}
                placeholder="Senha"
                value={password}
                onChange={handlePassword}
                className={validPassword && "is-invalid"}
              />
              <Text
                tag="i"
                padding="2"
                className="fas fa-eye border"
                onClick={() => setView(!view)}
              />
              {validPassword && (
                <div className="invalid-feedback">{validPassword}</div>
              )}
            </InputGroup>

            <Button
              type="submit"
              bgColor="primary"
              txtColor="white"
              fontWeight="bolder"
              width="w-100"
            >
              Entrar
            </Button>
          </Form>
          {error && error.length > 0 && (
            <Column col="12">
              <div className="alert alert-danger mt-3">
                {error &&
                  error.length > 0 &&
                  error.map((item) => {
                    return (
                      <Text tag="p" txtAlign="center" key={item.key}>
                        {item.message}
                      </Text>
                    );
                  })}
              </div>
            </Column>
          )}
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default Login;
