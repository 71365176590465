import { Column, ContainerFlex } from "../../Components/Layout";
import { FormLabel, Select } from "../../Components/Form";
import { useEffect, useState } from "react";
import MyCalendar from "./Appointments";

import useAppointment from "../../Hooks/useAppointment";
import useAgenda from "../../Hooks/useAgenda";

const Content = ({ agendas }) => {
  const { indexAppointment, listAppointment } = useAppointment();
  const { activeAgenda, setActiveAgenda } = useAgenda();

  const [agenda, setAgenda] = useState("none");
  const [date, setDate] = useState("");
  const [listAgenda, setListAgenda] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [validAgenda, setValidagenda] = useState("");

  const handleAgenda = (e) => {
    setAgenda(e.currentTarget.value);
    setValidagenda("");
  };

  const handleList = () => {
    const list = listAppointment.data.map((data) => {
      let start, end;
      start = new Date(data.start);
      end = new Date(data.end);
      return { ...data, start, end };
    });
    setAppointments(list);
  };

  useEffect(() => {
    setAgenda(activeAgenda);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agenda && agenda !== "none") {
      setActiveAgenda(agenda);

      indexAppointment({ agenda_id: agenda, date: date });
    } else {
      setAppointments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenda, date]);

  useEffect(() => {
    if (agendas && agendas.length > 0) {
      setListAgenda(agendas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendas]);

  useEffect(() => {
    if (listAppointment && listAppointment.data) {
      handleList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAppointment]);

  return (
    <ContainerFlex column full>
      <ContainerFlex
        paddingLeftRight="4"
        paddingTop="4"
        overflow
        bgColor="white"
        height="h-50"
      >
        <Column col="12" md="4">
          <FormLabel>Agenda</FormLabel>
          <Select
            onChange={(e) => handleAgenda(e)}
            value={agenda}
            className={validAgenda && "is-invalid"}
          >
            <option value="none" defaultValue>
              Selecione uma agenda
            </option>
            {listAgenda &&
              listAgenda.length > 0 &&
              listAgenda.map((data, index) => (
                <option value={data.id} key={index}>
                  {data.name}
                </option>
              ))}
          </Select>
        </Column>
      </ContainerFlex>
      <ContainerFlex
        paddingLeftRight="4"
        paddingBottom="4"
        paddingTop="2"
        overflow
        bgColor="white"
      >
        <MyCalendar agendas={appointments} setDate={setDate} />
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default Content;
