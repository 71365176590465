import { format, isValid } from "date-fns";
import "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function App({ data, dt_start, dt_end, accumulated, label }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      handleLabels(data);
    } else {
      setChartData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleLabels = (rs) => {
    let labels = [];
    let values = [];

    if (accumulated) {
      var one_day = 1000 * 60 * 60 * 24;
      let start = new Date(dt_start);
      let end = new Date(dt_end);
      let days = Math.round(end.getTime() - start.getTime()) / one_day;
      let total = 0;

      for (let i = 0; i <= days + 1; i++) {
        let label = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() + i
        );

        let value = 0;

        rs.forEach((item) => {
          if (item.date === format(label, "yyyy-MM-dd")) {
            value = item.value;
          }
        });

        total = total + value;
        labels.push(format(label, "dd"));
        values.push(total);
      }
    } else {
      rs.forEach((item) => {
        if (isValid(item.date)) {
          labels.push(format(item.label, "dd/MM/yyyy"));
        } else {
          labels.push(item.label);
        }

        values.push(item.value);
      });
    }

    let chart = {
      labels: labels,
      datasets: [
        {
          display: false,
          label: label,
          data: values,

          fill: "start",
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
        },
      ],
    };
    setChartData(chart);
  };

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        formatter: (value) => {
          return Math.round(value);
        },
        color: "black",
        font: {
          size: 26,
          weight: "regular",
        },
      },
    },
  };

  return (
    <>
      {chartData && chartData.labels && chartData.labels.length > 0 && (
        <Bar data={chartData} plugins={[ChartDataLabels]} options={options} />
      )}
    </>
  );
}

export default App;
