import React from 'react';
import PropTypes from 'prop-types';

import { colorsProps, colorsClass } from '../utilities/colors';
import { sizingProps, sizingClass } from '../utilities/sizing';
import { spacingProps, spacingClass } from '../utilities/spacing';
import { bordersProps, bordersClass } from '../utilities/borders';

import cn from 'classnames';

const Span = (props) => {
  let { 
    tag: Tag, 
    children, 
    className, 
    ...attributes
  } = props;

  let colors, sizing, spacing, borders;
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);
  
  return (
      <Tag 
        {...attributes} 
        className={cn(
          "input-group-text",
          sizing,
          spacing,
          borders,
          colors,
          className
        )}
      >
      {children}
      </Tag>
  )
};

Span.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  ...sizingProps(),
  ...spacingProps(),
  ...colorsProps(),
  ...bordersProps(),
};

Span.defaultProps = {
  tag: 'span',
  className: '',
};

export default Span;