import React from "react";
import PropTypes from "prop-types";

import { spacingProps, spacingClass } from "../utilities/spacing";

import cn from "classnames";

const Select = (props) => {
  let { className, size, children, ...attributes } = props;

  let spacing;
  [spacing, attributes] = spacingClass(attributes);

  size = size ? `form-select-${size}` : "";

  return (
    <select
      className={cn("form-select", size, spacing, className)}
      {...attributes}
    >
      {children}
    </select>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  ...spacingProps(),
};

Select.defaultProps = {
  className: "",
  placeholder: "",
};

export default Select;
