import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useAd = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listAd = useSelector((state) => state.ad.index);
  const dataAd = useSelector((state) => state.ad.data);

  function setIndex(index) {
    dispatch({
      type: "CONTENT_AD_INDEX",
      payload: { index: index },
    });
  }

  function setDataAd(data) {
    dispatch({
      type: "CONTENT_AD_DATA",
      payload: { data: data },
    });
  }

  function resetAd(data) {
    dispatch({
      type: "CONTENT_AD_RESET",
    });
  }

  const indexAd = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/content/ad?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const indexAdCampaign = (filters, success) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/content/ad?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAd = (id) => {
    api
      .get(`/content/ad/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataAd(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const postAd = (params, success, error) => {
    api
      .post("/content/ad", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateAd = (id, params, success, error) => {
    api
      .update(`/content/ad/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delAd = (id, success, error) => {
    api
      .delete(`/content/ad/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    getAd,
    postAd,
    indexAd,
    updateAd,
    delAd,
    resetAd,
    listAd,
    dataAd,
    setDataAd,
    indexAdCampaign,
  };
};

export default useAd;
