import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactSwitch from "react-switch";

import { colorsProps, colorsClass } from "../utilities/colors";
import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";
import { bordersProps, bordersClass } from "../utilities/borders";

import cn from "classnames";

const Switch = (props) => {
  let { className, label, type, name, id, check, onClick, ...attributes } =
    props;

  let colors, sizing, spacing, borders;
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);

  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    onClick();
    setChecked(nextChecked);
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <ReactSwitch
        onChange={handleChange}
        checked={checked}
        offColor="#ef476f"
        onColor="#06d6a0"
        className={cn(
          "react-switch",
          sizing,
          spacing,
          borders,
          colors,
          className
        )}
        {...attributes}
      />
      <label className="form-check-label ms-2" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  check: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ...sizingProps(),
  ...colorsProps(),
  ...spacingProps(),
  ...bordersProps(),
};

Switch.defaultProps = {
  type: "text",
  className: "",
  check: true,
};

export default Switch;
