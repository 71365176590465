import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import useMarketing from "../../Hooks/useMarketing";

const Calendar = ({ setoutsidelist, setDayClick, setError }) => {
  const navigate = useNavigate();
  const { indexMarketing } = useMarketing();
  const [agenda, setAgenda] = useState({});

  const handleNew = (start) => {
    if (start && start.indexOf("T") > -1) {
      setDayClick(start.split("T")[0]);
    } else {
      setDayClick(start);
    }
    document.getElementById("Open_Marketing_Modal").click();
  };

  const handleOpen = (id) => {
    navigate("/marketing/" + id);
  };

  const handleSuccess = (listAppointment) => {
    const list = listAppointment.data.map((data) => {
      let start, end;
      if (data.is_allday) {
        start = data.start.replace(" 00:00:00", "");
        end = data.end.replace(" 00:00:00", "");
      } else {
        start = data.start;
        end = data.end;
      }
      return { ...data, start, end };
    });
    setAgenda(list);
    setoutsidelist(list);
  };

  const handleError = (error) => {
    if (error.data.errors) {
      let arr = [];
      for (let key in error.data.errors) {
        arr.push({
          key: key,
          message: error.data.errors[key][0],
        });
      }
      console.error(arr);
      setError(arr);
    } else {
      setError({});
    }
  };

  const handleIndex = (info) => {
    indexMarketing(
      {
        start: format(info.start, "yyyy-MM-dd HH:mm:00"),
        end: format(info.end, "yyyy-MM-dd HH:mm:00"),
      },
      handleSuccess,
      handleError
    );
  };

  return (
    <FullCalendar
      datesSet={(info) => {
        handleIndex(info);
      }}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        center: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      buttonText={{
        today: "Hoje",
        dayGridMonth: "Mês",
        timeGridWeek: "Semana",
        timeGridDay: "Dia",
      }}
      allDayText="Dia Inteiro"
      events={agenda}
      eventColor="#ddd"
      eventTextColor="#000"
      nowIndicator
      dateClick={(e) => handleNew(e.dateStr)}
      eventClick={(e) => handleOpen(e.event.id)}
      locale="pt-br"
    />
  );
};

export default Calendar;
