import { Text } from "../../Components/Text";
import { useNavigate } from "react-router-dom";
import useMenu from "../../Hooks/useMenu";

import { NavLink } from "../../Components/Nav";

const MenuFather = ({ item }) => {
  const { activeMenu, setActiveMenu } = useMenu();

  let navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    setActiveMenu(item.id);
    navigate(item.url);
  };

  return (
    <li
      className={
        activeMenu === item.id ? "menu-submenu selected" : "menu-submenu"
      }
      onClick={(e) => handleClick(e)}
      data-bs-parent="#accordion-sidemenu"
    >
      <NavLink className="nav-link">
        <Text tag="i" className={`submenu-icon bi ${item.icon} me-2`} />
        <Text>{item.name}</Text>
      </NavLink>
    </li>
  );
};

export default MenuFather;
