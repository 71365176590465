const initialState = {
  index: [],
};

const Gender = (state = initialState, action) => {
  switch (action.type) {
    case "GENDER_INDEX":
      return { ...state, index: action.payload.index };
    case "GENDER_RESET":
      return initialState;
    default:
      return state;
  }
};

export default Gender;
