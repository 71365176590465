import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import Item from "../Item";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../Components/Modal";
import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
import { ButtonModalClose } from "../../../Components/Button";
import { formatDate } from "../../../Components/Form/masks";
import useMarketing from "../../../Hooks/useMarketing";
import { useNavigate } from "react-router-dom";

registerLocale("ptBR", ptBR);

const MarketingModal = ({ dayClick, setDayClick }) => {
  const navigate = useNavigate();

  const { indexMarketing } = useMarketing();
  const [loading, setLoading] = useState(true);

  const [list, setList] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    if (dayClick) {
      indexMarketing(
        {
          start: dayClick + " 00:00:00",
          end: dayClick + " 23:59:59",
        },
        handleSuccess,
        handleError
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayClick]);

  const handleNew = () => {
    navigate("/gestao/marketing/novo/" + dayClick);
  };

  const handleClose = () => {
    setDayClick("");
    setList({});
    setError({});
  };

  const handleSuccess = (listAppointment) => {
    setError({});

    const list = listAppointment.data.map((data) => {
      let start, end;
      if (data.is_allday) {
        start = data.start.replace(" 00:00:00", "");
        end = data.end.replace(" 00:00:00", "");
      } else {
        start = data.start;
        end = data.end;
      }
      return { ...data, start, end };
    });
    setList(list);
    setLoading(false);
  };

  const handleError = (error) => {
    setLoading(false);
    if (error.data.errors) {
      let arr = [];
      for (let key in error.data.errors) {
        arr.push({
          key: key,
          message: error.data.errors[key][0],
        });
      }
      console.error(arr);
      setError(arr);
    } else {
      setError({});
    }
  };

  const handleList = () => {
    if (list && list.length > 0) {
      return list.map((data, index) => <Item item={data} key={index} />);
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum item foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <Modal id="Marketing_Modal" center fixed scroll size="md">
      <ModalHeader
        id="Marketing_Modal_Close"
        border={false}
        title={`Lista de Eventos - ${formatDate(dayClick + " 00:00:00")}`}
        // onClick={handleDataDefault}
      />
      <ModalBody paddingTopBottom="0">
        {error && error.length > 0 && (
          <ContainerFlex col="12" marginBottom="3">
            <div className="alert alert-danger m-3">
              {error &&
                error.length > 0 &&
                error.map((item) => {
                  return (
                    <Text tag="p" key={item.key}>
                      {item.message}
                    </Text>
                  );
                })}
            </div>
          </ContainerFlex>
        )}
        {loading ? (
          <ContainerFlex full middle center column>
            <ReactLoading type={"spin"} color={"#1c208c"} />
            <Text>Carregando...</Text>
          </ContainerFlex>
        ) : (
          handleList()
        )}
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="success"
            txtColor="white"
            onClick={handleNew}
          >
            Novo Evento
          </ButtonModalClose>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleClose}
          >
            Fechar Janela
          </ButtonModalClose>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default MarketingModal;
