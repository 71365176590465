import React from "react";
import ReactLoading from "react-loading";
import styled from "styled-components";
import { useSelector } from "react-redux";

export const LoadingOverlay = styled.div`
  display: ${(props) => (props.status ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  position: absolute;
  /* background-image: linear-gradient(
    210deg,
    rgba(8, 0, 94, 0.9),
    rgba(8, 0, 94, 0.8),
    rgba(8, 0, 94, 0.7),
    rgba(8, 0, 94, 0.8),
    rgba(8, 0, 94, 0.9)
  ); */
  background: rgba(8, 0, 94, 0.9);
  color: #fff;
  height: 100vh;
  width: 100vw;
  transition: display 1s linear;
`;

const Loading = () => {
  const statusLoading = useSelector((state) => state.app.statusLoading);
  return (
    <LoadingOverlay status={statusLoading}>
      <ReactLoading type={"spin"} color={"white"} />
      <h4>CARREGANDO</h4>
    </LoadingOverlay>
  );
};

export default Loading;
