import React from "react";
import PropTypes from "prop-types";

import { colorsDef } from "../utilities/colors";
import { textProps, textClass } from "../utilities/text";
import { colorsProps, colorsClass } from "../utilities/colors";
import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";
import { flexProps, flexClass } from "../utilities/flex";

import cn from "classnames";

const AccordionHeader = (props) => {
  let { tag: Tag, id, className, children, onClick, ...attributes } = props;

  let colors, sizing, spacing, text, flex;
  [text, attributes] = textClass(attributes);
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [flex, attributes] = flexClass(attributes);

  return (
    <div
      {...attributes}
      className="accordion-header"
      onClick={onClick}
      id={`header-${id}`}
    >
      <Tag
        className={cn(
          "accordion-button collapsed",
          flex,
          text,
          sizing,
          spacing,
          colors,
          className
        )}
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#accordion-body-${id}`}
        aria-expanded="false"
        aria-controls={`accordion-body-${id}`}
      >
        {children}
      </Tag>
    </div>
  );
};

AccordionHeader.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOf([...colorsDef]),
  id: PropTypes.string.isRequired,
  ...textProps(),
  ...sizingProps(),
  ...colorsProps(),
  ...spacingProps(),
  ...flexProps(),
};

AccordionHeader.defaultProps = {
  tag: "button",
  className: "",
};

export default AccordionHeader;
