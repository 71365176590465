import { useNavigate } from "react-router-dom";

import { Text } from "../../Components/Text";
import { Button } from "../../Components/Button";
import { formatDate, formatDatetime } from "../../Components/Form/masks";
import { Column, ContainerFlex, Row } from "../../Components/Layout";

const Item = ({ item }) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/gestao/marketing/editar/${item.id}`);
  };

  const handleOpen = () => {
    navigate(`/marketing/${item.id}`);
  };

  return (
    <ContainerFlex
      className="card-item accordion-item shadow-sm"
      padding="2"
      marginTopBottom="2"
      rounded
    >
      <Row>
        <Column col="12" between>
          <ContainerFlex row middle>
            <Text
              tag="small"
              padding="0"
              margin="0"
              marginTop="1"
              fontWeight="bolder"
            >
              {item.title}
            </Text>
          </ContainerFlex>
        </Column>

        {!item.is_allday ? (
          <Column col="12" between>
            <ContainerFlex column>
              <Text style={{ fontSize: "10px" }} marginRight="2">
                Início
              </Text>
              <Text
                style={{ fontSize: "10px", fontWeight: "bold" }}
                marginRight="2"
              >
                {formatDatetime(item.start)}
              </Text>
            </ContainerFlex>
            <ContainerFlex column>
              <Text style={{ fontSize: "10px" }} marginRight="2">
                Termino
              </Text>
              <Text
                style={{ fontSize: "10px", fontWeight: "bold" }}
                marginRight="2"
              >
                {formatDatetime(item.end)}
              </Text>
            </ContainerFlex>
          </Column>
        ) : (
          <Column col="12" left>
            <ContainerFlex column>
              <Text style={{ fontSize: "10px" }} marginRight="2">
                Dia Inteiro
              </Text>
              <Text
                style={{ fontSize: "10px", fontWeight: "bold" }}
                marginRight="2"
              >
                {formatDate(item.start)}
              </Text>
            </ContainerFlex>
          </Column>
        )}
        <Column col="12" between>
          <Button
            txtColor="ydental-gray"
            onClick={handleEdit}
            className="btn btn-outline-dark"
            style={{ fontSize: "12px" }}
            paddingTopBottom="0"
            paddingLeftRight="1"
          >
            <Text tag="i" className="fa fa-edit" />
            <Text tag="small" marginLeft="2">
              Editar
            </Text>
          </Button>
          <Button
            txtColor="ydental-gray"
            onClick={handleOpen}
            className="btn btn-outline-dark"
            style={{ fontSize: "12px" }}
            paddingTopBottom="0"
            paddingLeftRight="1"
          >
            <Text tag="i" className="fa fa-envelope-open" />
            <Text tag="small" marginLeft="2">
              Abrir
            </Text>
          </Button>
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default Item;
