import { Text } from "../../../Components/Text";
import { useNavigate } from "react-router-dom";
import { ContainerFlex } from "../../../Components/Layout";
import useNotification from "../../../Hooks/useNotification";

const NotificationItem = ({ item }) => {
  const navigate = useNavigate();
  const { indexNotification, readNotification } = useNotification();

  const handleClick = (item) => {
    if (item.link) {
      navigate(item.link);
    }
    if (item.read === false) {
      readNotification(
        item.id,
        {},
        () => {
          indexNotification();
        },
        (err) => {}
      );
    }
  };

  return (
    <>
      <li>
        <hr className="dropdown-divider" />
      </li>
      <li key={item.id} onClick={() => handleClick(item)} role="button">
        <ContainerFlex className="dropdown-item" row>
          <ContainerFlex paddingRight="3" center middle>
            {item.icon ? (
              <Text
                className={item.icon}
                style={{ color: item.color ? item.color : "black" }}
              />
            ) : (
              <Text
                className="fa fa-circle-dot"
                style={{ color: item.color ? item.color : "blue" }}
              />
            )}
          </ContainerFlex>
          <ContainerFlex column>
            <Text tag={item.read ? "span" : "strong"} className="text-dark">
              {item.message}
            </Text>
            <Text tag="small" className="text-muted">
              {item.type}
            </Text>
          </ContainerFlex>
        </ContainerFlex>
      </li>
    </>
  );
};

export default NotificationItem;
