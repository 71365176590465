const initialState = {
  active: "none",
  index: [],
  data: {},
};

const Agenda = (state = initialState, action) => {
  switch (action.type) {
    case "CONTENT_AGENDA_ACTIVE":
      return { ...state, active: action.payload.active };
    case "CONTENT_AGENDA_INDEX":
      return { ...state, index: action.payload.index };
    case "CONTENT_AGENDA_DATA":
      return { ...state, data: action.payload.data };
    case "CONTENT_AGENDA_RESET":
      return initialState;
    default:
      return state;
  }
};

export default Agenda;
