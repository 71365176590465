import React from "react";
import PropTypes from "prop-types";

import { bordersProps, bordersClass } from "../utilities/borders";
import { colorsDef } from "../utilities/colors";
import { textProps, textClass } from "../utilities/text";
import { colorsProps, colorsClass } from "../utilities/colors";
import { sizingProps, sizingClass } from "../utilities/sizing";
import { spacingProps, spacingClass } from "../utilities/spacing";
import { flexProps, flexClass } from "../utilities/flex";

import cn from "classnames";

const ButtonModal = (props) => {
  let {
    tag: Tag,
    className,
    children,
    onClick,
    size,
    block,
    outline,
    disabled,
    modal,
    ...attributes
  } = props;

  let colors, sizing, spacing, text, borders, flex;
  [text, attributes] = textClass(attributes);
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [flex, attributes] = flexClass(attributes);
  [borders, attributes] = bordersClass(attributes);

  size = size ? `btn-${size}` : "";
  block = block ? `btn-${block}` : "";

  // modalClose = modalClose ? `data-bs-dismiss="modal"` : '';
  // modalToggle = modal ? `data-bs-target="${modalToggle}" data-bs-toggle="modal" data-bs-dismiss="modal"` : '';

  return (
    <Tag
      {...attributes}
      className={cn(
        "btn",
        flex,
        text,
        size,
        block,
        sizing,
        borders,
        spacing,
        disabled && "disabled",
        colors,
        outline && `btn-outline-${outline}`,
        className
      )}
      data-bs-toggle="modal"
      data-bs-target={`#${modal}`}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};

ButtonModal.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  modal: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["sm", "lg"]),
  color: PropTypes.oneOf([...colorsDef]),
  block: PropTypes.bool,
  outline: PropTypes.oneOf([...colorsDef]),
  disabled: PropTypes.bool,
  ...textProps(),
  ...sizingProps(),
  ...colorsProps(),
  ...spacingProps(),
  ...flexProps(),
  ...bordersProps(),
};
ButtonModal.defaultProps = {
  tag: "button",
  className: "",
};

export default ButtonModal;
