import { useEffect, useState } from "react";
import Alert from "../../Components/Alerts";

import { ContainerFlex } from "../../Components/Layout";
import useError from "../../Hooks/useError";

const ErrorList = () => {
  const { errorList, setReadError } = useError();

  const [list, setList] = useState([]);

  useEffect(() => {
    if (errorList && errorList.length > 0) {
      setList(errorList);
    }
  }, [errorList]);

  const handleReadError = (id) => {
    setReadError(id);
  };

  return (
    <ContainerFlex className="notification-error p-2">
      {list.map((item) => (
        <Alert
          key={item.id}
          color={`${item.color ? item.color : "danger"}`}
          className="mb-2"
          close={() => handleReadError(item.id)}
        >
          <ContainerFlex className="justify-content-between">
            {item.title && <span className="fw-bolder">{item.title}</span>}
            {item.message && <p>{item.message}</p>}
          </ContainerFlex>
        </Alert>
      ))}
    </ContainerFlex>
  );
};

export default ErrorList;
