import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import useTicket from "../../../Hooks/Support/useTicket";
import useMessage from "../../../Hooks/Support/useMessage";
import useStatus from "../../../Hooks/Support/useStatus";
import usePriority from "../../../Hooks/Support/usePriority";
import useCategory from "../../../Hooks/Support/useCategory";
import useAgentTicket from "../../../Hooks/Support/useAgentTicket";

import Editor from "./Editor";
import Upload from "./Upload";

import { Text } from "../../../Components/Text";
import { Card, CardBody, CardHeader } from "../../../Components/Card";
import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { formatDatetime } from "../../../Components/Form/masks";
import { Button } from "../../../Components/Button";
import Collapse from "../../../Components/Collapse";
import Message from "./Message";

const TicketAgent = () => {
  let navigate = useNavigate();
  const animatedComponents = makeAnimated();

  const MySwal = withReactContent(Swal);

  const { id } = useParams();

  const { getTicket, closeTicket, updateTicket, setVizualized } = useTicket();
  const { indexMessage } = useMessage();
  const { listStatus } = useStatus();
  const { listCategory } = useCategory();
  const { listPriority } = usePriority();
  const { postAgentTicket } = useAgentTicket();

  const [ticket, setTicket] = useState({});
  const [messageList, setMessageList] = useState([]);

  const [category, setCategory] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [validCategory, setValidCategory] = useState([]);

  const [priority, setPriority] = useState({});
  const [priorityList, setPriorityList] = useState([]);
  const [validPriority, setValidPriority] = useState([]);

  const [status, setStatus] = useState({});
  const [statusList, setStatusList] = useState([]);
  const [validStatus, setValidStatus] = useState([]);

  const [title, setTitle] = useState("");

  const [text, setText] = useState("");
  const [validText, setValidText] = useState("");

  const [files, setFiles] = useState([]);

  const [errorTitle, setErrorTitle] = useState("");
  const [error, setError] = useState({});

  const handleGetTicketError = (error) => {
    setError(error);
    MySwal.fire({
      title: "Erro ao carregar o ticket.",
      text: error.message,
      icon: "error",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    getTicket(id, setTicket, handleGetTicketError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(listStatus.data) && listStatus.data.length > 0) {
      let list = listStatus.data.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setStatusList(list);
    }
  }, [listStatus]);

  useEffect(() => {
    if (Array.isArray(listCategory.data) && listCategory.data.length > 0) {
      let list = listCategory.data.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setCategoryList(list);
    }
  }, [listCategory]);

  useEffect(() => {
    if (Array.isArray(listPriority.data) && listPriority.data.length > 0) {
      let list = listPriority.data.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setPriorityList(list);
    }
  }, [listPriority]);

  useEffect(() => {
    if (ticket) {
      setTitle(ticket.title);
      setMessageList(ticket.messages);

      statusList.forEach((element) => {
        if (element.value === ticket.status.id) {
          setStatus(element);
        }
      });

      categoryList.forEach((element) => {
        if (element.value === ticket.category.id) {
          setCategory(element);
        }
      });

      if (ticket.priority) {
        priorityList.forEach((element) => {
          if (element.value === ticket.priority.id) {
            setPriority(element);
          }
        });
      }

      if (ticket.vizualized_agent === false) {
        setVizualized(ticket.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  const handleListMessage = () => {
    indexMessage({ support_id: ticket.id }, setMessageList, handleError);
  };

  const handleText = (e) => {
    setText(e);
    setValidText("");
  };

  const handleStatus = (e) => {
    setStatus(e);
    setValidStatus("");
  };

  const handleCategory = (e) => {
    setCategory(e);
    setValidCategory("");
  };

  const handlePriority = (e) => {
    setPriority(e);
    setValidPriority("");
  };

  const handleValidadeMessage = () => {
    let err = true;

    if (!text) {
      setValidText("A nota não pode ser vazia.");
      err = false;
    }

    return err;
  };

  const handleValidade = () => {
    let err = true;

    if (!status) {
      setValidStatus("Selecione uma situação válida.");
      err = false;
    }

    if (!category) {
      setValidCategory("Selecione um setor válido.");
      err = false;
    }

    return err;
  };

  const handleCloseTicket = () => {
    MySwal.fire({
      title: "Tem certeza que deseja fechar este ticket?",
      text: "Ao fechar o ticket, ele não poderá ser reaberto.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, fechar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        closeTicket(ticket.id, setTicket, setError);
        navigate(-1);
      }
    });
  };

  const handleUpdateTicket = (response) => {
    MySwal.fire({
      title: "Chamado atualizado com sucesso!",
      text: "Deseja permanecer retornar ao menu anterior ou permanecer nesta página?",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#198754",
      confirmButtonText: "Retornar ao menu",
      cancelButtonText: "Permanecer",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  };

  const handleSuccess = (rs) => {
    if (rs && rs.data) {
      setError({});
      setText("");
      setFiles([]);
      document.getElementById("new_message_button").click();
      handleListMessage();
    } else {
      setError(rs.data);
    }
  };

  const handleError = (error) => {
    console.error(error);

    if (error.data) {
      if (error.data.errors) {
        let arr = [];
        for (let key in error.data.errors) {
          arr.push({
            key: key,
            message: error.data.errors[key][0],
          });
        }
        setError(arr);
        setErrorTitle(`Erro: ${error.status} - ${error.data.message}`);
      }
      if (error.data.message) {
        setErrorTitle(`Erro: ${error.status} - ${error.data.message}`);
      }
    } else {
      setError({});
      setErrorTitle("");
    }
  };

  const handleSubmit = () => {
    if (handleValidadeMessage()) {
      setValidText("");

      let params = {
        support_id: ticket.id,
        message: text,
        files,
      };

      postAgentTicket(params, handleSuccess, handleError);
    } else {
      setValidText("Preencha o campo de texto.");
    }
  };

  const handleUpdate = () => {
    if (handleValidade()) {
      setValidText("");

      let params = {
        support_category_id: category.value,
        support_priority_id: priority.value,
        support_status_id: status.value,
      };

      updateTicket(ticket.id, params, handleUpdateTicket, handleError);
    }
  };

  return (
    <ContainerFlex full padding="3" stretch>
      <Card padding="2" full>
        {(error && error.length > 0) ||
          (errorTitle && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                {errorTitle && (
                  <Text tag="h6" fontWeight="bolder">
                    {errorTitle}
                  </Text>
                )}
                {error &&
                  error.length > 0 &&
                  error.map((item) => {
                    return (
                      <Text tag="p" key={item.key}>
                        {item.message}
                      </Text>
                    );
                  })}
              </div>
            </Column>
          ))}
        {ticket && (
          <>
            <CardHeader>
              <Row marginTop="3">
                <Column col="12" between middle>
                  <Text className="h5" margin="0">
                    {title && title}
                  </Text>
                  <Button
                    size="sm"
                    padding="0"
                    paddingLeftRight="2"
                    marginRight="2"
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <Text tag="i" className="fas fa-angle-double-left" />
                    <Text marginLeft="2">Voltar</Text>
                  </Button>
                </Column>
                <Column col="12" md="3">
                  <Text>Situação</Text>
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={status}
                    options={statusList}
                    menuPosition={"fixed"}
                    onChange={handleStatus}
                    className={validStatus && "is-invalid"}
                    placeholder="Selecione uma situação"
                    isDisabled={true}
                  />
                  {validStatus && (
                    <div className="invalid-feedback">{validStatus}</div>
                  )}
                </Column>
                <Column col="12" md="3">
                  <Text>Setor</Text>
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={category}
                    options={categoryList}
                    menuPosition={"fixed"}
                    onChange={handleCategory}
                    className={validCategory && "is-invalid"}
                    placeholder="Selecione uma situação"
                  />
                  {validCategory && (
                    <div className="invalid-feedback">{validCategory}</div>
                  )}
                </Column>
                <Column col="12" md="3">
                  <Text>Prioridade</Text>
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={priority}
                    options={priorityList}
                    menuPosition={"fixed"}
                    onChange={handlePriority}
                    className={validPriority && "is-invalid"}
                    placeholder="Selecione o nível de prioridade"
                  />
                  {validPriority && (
                    <div className="invalid-feedback">{validPriority}</div>
                  )}
                </Column>
              </Row>
              <Row marginTop="3">
                <Column col="12" md="3">
                  <Text tag="p" className="text-muted" margin="0">
                    Data de Abertura
                  </Text>
                  <Text fontWeight="bolder">
                    {formatDatetime(ticket && ticket.created_at)}
                  </Text>
                </Column>
                <Column col="12" md="3">
                  <Text tag="p" className="text-muted" margin="0">
                    Data de Atualização
                  </Text>
                  <Text fontWeight="bolder">
                    {formatDatetime(ticket && ticket.updated_at)}
                  </Text>
                </Column>
                {ticket && ticket.status && ticket.status.is_open && (
                  <Column col="12">
                    <ContainerFlex between marginTop="3">
                      <ContainerFlex>
                        <Button
                          size="sm"
                          padding="0"
                          paddingLeftRight="2"
                          marginRight="2"
                          className="btn btn-outline-primary"
                          data-bs-toggle="collapse"
                          href="#new_message"
                          role="button"
                          aria-expanded="false"
                          aria-controls="new_message"
                          id="new_message_button"
                        >
                          <Text tag="i" className="fas fa-plus" />
                          <Text marginLeft="2">Nova Mensagem</Text>
                        </Button>

                        <Button
                          size="sm"
                          padding="0"
                          paddingLeftRight="2"
                          marginRight="2"
                          className="btn btn-outline-success"
                          onClick={() => {
                            handleUpdate();
                          }}
                        >
                          <Text tag="i" className="fas fa-check" />
                          <Text marginLeft="2">Salvar Alterações</Text>
                        </Button>
                      </ContainerFlex>
                      <Button
                        size="sm"
                        padding="0"
                        paddingLeftRight="2"
                        marginRight="2"
                        className="btn btn-outline-danger"
                        onClick={handleCloseTicket}
                      >
                        <Text tag="i" className="fas fa-ban" />
                        <Text marginLeft="2">Encerrar Chamado</Text>
                      </Button>
                    </ContainerFlex>
                  </Column>
                )}
              </Row>
            </CardHeader>
            <CardBody padding="3">
              <Row>
                <Column col="12">
                  {messageList &&
                    messageList.length > 0 &&
                    messageList.map((message, index) => (
                      <Message key={index} message={message} />
                    ))}
                </Column>
              </Row>
            </CardBody>
            <Collapse marginBottom="3" id="new_message">
              <Upload uploadedFiles={files} setUploadedFiles={setFiles} />
              <Editor content={text} setContent={handleText} />
              <Column col="12" marginTop="3"></Column>
              {validText && (
                <div className="invalid-feedback d-block">{validText}</div>
              )}
              <Column col="12" marginTop="3">
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  bgColor="success"
                  txtColor="white"
                  full
                >
                  <Text marginRight="1">Enviar</Text>
                  <Text tag="i" className="fas fa-paper-plane" />
                </Button>
              </Column>
            </Collapse>
          </>
        )}
      </Card>
    </ContainerFlex>
  );
};

export default TicketAgent;
