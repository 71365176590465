import React from "react";
import PropTypes from "prop-types";

import { textProps, textClass } from "../utilities/text";
import { colorsProps, colorsClass } from "../utilities/colors";
import { spacingProps, spacingClass } from "../utilities/spacing";

import cn from "classnames";

const Modal = (props) => {
  let {
    tag: Tag,
    id,
    size,
    center,
    scroll,
    fixed,
    keyboard,
    children,
    className,
    fullscreen,
    ...attributes
  } = props;

  let colors, spacing, text;
  [text, attributes] = textClass(attributes);
  [colors, attributes] = colorsClass(attributes);
  [spacing, attributes] = spacingClass(attributes);

  size = size ? `modal-${size}` : "";
  center = center ? `modal-dialog-centered` : "";
  scroll = scroll ? `modal-dialog-scrollable` : "";
  fixed = fixed ? "static" : "";

  if (fullscreen && fullscreen.lenght > 0) {
    fullscreen = `modal-fullscreen-${fullscreen}-down`;
  } else if (fullscreen === true) {
    fullscreen = `modal-fullscreen`;
  } else {
    fullscreen = "";
  }

  return (
    <Tag
      id={id}
      tabIndex="-1"
      className="modal fade"
      data-bs-backdrop={fixed}
      data-bs-keyboard={keyboard}
    >
      <div className={cn("modal-dialog", size, center, scroll, fullscreen)}>
        <div
          {...attributes}
          className={cn("modal-content", text, colors, spacing, className)}
        >
          {children}
        </div>
      </div>
    </Tag>
  );
};

Modal.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  fixed: PropTypes.bool,
  center: PropTypes.bool,
  scroll: PropTypes.bool,
  keyboard: PropTypes.bool,
  id: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["xl", "sm", "md", "lg", "xxl"]),
  fullscreen: PropTypes.oneOf(["xl", "sm", "md", "lg", "xxl", true]),
  ...textProps(),
  ...colorsProps(),
  ...spacingProps(),
};
Modal.defaultProps = {
  tag: "div",
  className: "",
  keyboard: false,
};

export default Modal;
