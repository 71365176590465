import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/Company";

const useCompany = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listCompany = useSelector((state) => state.company.index);
  const dataCompany = useSelector((state) => state.company.data);
  const selectedCompanies = useSelector((state) => state.company.selected);

  function setIndex(index) {
    dispatch({
      type: "COMPANY_INDEX",
      payload: { index: index },
    });
  }

  function setDataCompany(data) {
    dispatch({
      type: "COMPANY_DATA",
      payload: { data: data },
    });
  }

  function setSelectedCompanies(data) {
    dispatch({
      type: "COMPANY_SELECTED",
      payload: { selected: data },
    });
  }

  function resetCompany(data) {
    dispatch({
      type: "COMPANY_RESET",
    });
  }

  const indexCompany = () => {
    api
      .index(token, setIndex)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCompany = (id) => {
    api
      .get(token, id)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataCompany(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const postCompany = (params, success, error) => {
    api
      .post(token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err);
        error();
      });
  };

  const updateCompany = (id, params, success, error) => {
    api
      .update(token, id, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err);
      });
  };

  const delCompany = (id, success, error) => {
    api
      .delete(token, id)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err);
        error();
      });
  };

  return {
    getCompany,
    postCompany,
    indexCompany,
    updateCompany,
    delCompany,
    resetCompany,
    listCompany,
    dataCompany,
    setDataCompany,
    selectedCompanies,
    setSelectedCompanies,
  };
};

export default useCompany;
