const initialState = {
  index: [],
  selected: [],
  data: {},
};

const SupportTicket = (state = initialState, action) => {
  switch (action.type) {
    case "SUPPORT_TICKET_INDEX":
      return { ...state, index: action.payload.index };
    case "SUPPORT_TICKET_SELECTED":
      return { ...state, selected: action.payload.selected };
    case "SUPPORT_TICKET_DATA":
      return { ...state, data: action.payload.data };
    case "SUPPORT_TICKET_RESET":
      return initialState;
    default:
      return state;
  }
};

export default SupportTicket;
