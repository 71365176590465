import PropTypes from "prop-types";

export function flexProps() {
  return {
    breakpoint: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
    left: PropTypes.bool,
    right: PropTypes.bool,
    center: PropTypes.bool,
    between: PropTypes.bool,
    around: PropTypes.bool,
    top: PropTypes.bool,
    middle: PropTypes.bool,
    bottom: PropTypes.bool,
    row: PropTypes.bool,
    column: PropTypes.bool,
    stretch: PropTypes.bool,
  };
}

export function flexClass(obj) {
  let className = "";

  let {
    flex,
    row,
    column,
    stretch,
    breakpoint,
    left,
    right,
    center,
    between,
    around,
    top,
    middle,
    bottom,
    ...attributes
  } = obj;

  breakpoint = breakpoint ? `${breakpoint}-` : "";

  if (flex) {
    className += `d-${breakpoint}flex`;
  }

  // Se não for setado d-flex, mas passar justify ou align, seta automaticamente d-flex.
  if (
    (row ||
      column ||
      top ||
      middle ||
      bottom ||
      left ||
      right ||
      center ||
      between ||
      stretch ||
      around) &&
    !flex
  ) {
    className += `d-${breakpoint}flex `;
  }

  if (row) className += `flex-${breakpoint}row `;
  if (column) className += `flex-${breakpoint}column `;
  if (top) className += `align-items-${breakpoint}start `;
  if (middle) className += `align-items-${breakpoint}center `;
  if (bottom) className += `align-items-${breakpoint}end `;
  if (stretch) className += `align-items-stretch `;
  if (left) className += `justify-content-${breakpoint}start `;
  if (right) className += `justify-content-${breakpoint}end `;
  if (center) className += `justify-content-${breakpoint}center `;
  if (between) className += `justify-content-${breakpoint}between `;
  if (around) className += `justify-content-${breakpoint}around `;

  return [className, attributes];
}
