import React from 'react';
import PropTypes from 'prop-types';

import { bordersProps, bordersClass } from '../utilities/borders';
import { colorsProps, colorsClass } from '../utilities/colors';
import { flexProps, flexClass } from '../utilities/flex';
import { overflowProps, overflowClass } from '../utilities/overflow';
import { sizingProps, sizingClass } from '../utilities/sizing';
import { spacingProps, spacingClass } from '../utilities/spacing';
import { textProps, textClass } from '../utilities/text';

import cn from 'classnames';

const Text = (props) => {
  let { 
    tag: Tag, 
    children, 
    className, 
    ...attributes
  } = props;

  let colors, sizing, spacing, borders, flex, overflow, text;
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);
  [overflow, attributes] = overflowClass(attributes);
  [flex, attributes] = flexClass(attributes);
  [text, attributes] = textClass(attributes);
  
  return (
      <Tag 
        {...attributes} 
        className={cn(
          flex,
          sizing,
          spacing,
          borders,
          colors,
          overflow,
          text,
          className
        )}
      >
      {children}
      </Tag>
  )
};

Text.propTypes = {
  tag: PropTypes.oneOf([
    'p',
    'label',
    'span',
    'i',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'pre',
    'pre',
    'del',
    's',
    'ins',
    'u',
    'small',
    'strong',
    'em',
  ]).isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  ...bordersProps(),
  ...colorsProps(),
  ...flexProps(),
  ...overflowProps(),
  ...sizingProps(),
  ...spacingProps(),
  ...textProps(),
};

Text.defaultProps = {
  tag: 'span',
  className: '',
};

export default Text;