const initialState = {
  index: [],
  selected: [],
  data: {},
};

const SupportCategory = (state = initialState, action) => {
  switch (action.type) {
    case "SUPPORT_CATEGORY_INDEX":
      return { ...state, index: action.payload.index };
    case "SUPPORT_CATEGORY_SELECTED":
      return { ...state, selected: action.payload.selected };
    case "SUPPORT_CATEGORY_DATA":
      return { ...state, data: action.payload.data };
    case "SUPPORT_CATEGORY_RESET":
      return initialState;
    default:
      return state;
  }
};

export default SupportCategory;
