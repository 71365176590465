import React from 'react';
import PropTypes from 'prop-types';

import { colorsProps, colorsClass } from '../utilities/colors';
import { sizingProps, sizingClass } from '../utilities/sizing';
import { spacingProps, spacingClass } from '../utilities/spacing';
import { bordersProps, bordersClass } from '../utilities/borders';
import { overflowProps, overflowClass } from '../utilities/overflow';
import { flexProps, flexClass } from '../utilities/flex';

import cn from 'classnames';

const ContainerFlex = (props) => {

  let {
    tag: Tag,
    children,
    className,
    
    ...attributes
  } = props;

  let colors, sizing, spacing, borders, overflow, flex;
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);
  [overflow, attributes] = overflowClass(attributes);
  [flex, attributes] = flexClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn(
        flex,
        colors,
        sizing,
        spacing,
        borders,
        overflow,
        className
      )}
    >
      {children}
    </Tag>
  );
};

ContainerFlex.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  
  ...sizingProps(),
  ...colorsProps(),
  ...spacingProps(),
  ...bordersProps(),
  ...overflowProps(),
  ...flexProps(),
};
ContainerFlex.defaultProps = {
  tag: 'div',
  className: '',
};

export default ContainerFlex;
