import { useEffect, useState } from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import ReactLoading from "react-loading";

import useHistoryPoinst from "../../../Hooks/useHistoryPoinst";

import HistoryItem from "./HistoryItem";

import { ContainerFlex } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";

const Histories = ({ history }) => {
  const { indexHistoryPoints, listHistoryPoints } = useHistoryPoinst();

  const [loading, setLoading] = useState(true);
  const [listHistories, setListHistories] = useState([]);

  useEffect(() => {
    indexHistoryPoints({ history: history }, handleSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (listHistoryPoints && listHistoryPoints.data) {
      setListHistories(listHistoryPoints.data);
    }
  }, [listHistoryPoints]);

  const handleSuccess = (data) => {
    setLoading(false);
    setListHistories(data);
  };

  const handleHistoryList = () => {
    if (listHistories && listHistories.length > 0) {
      return (
        <VerticalTimeline layout={"1-column-left"} lineColor={"black"}>
          {listHistories.map((data, index) => (
            <HistoryItem key={index} history={data} />
          ))}
        </VerticalTimeline>
      );
    } else {
      return (
        <ContainerFlex full middle center column>
          <Text>Nenhum item foi localizado.</Text>
        </ContainerFlex>
      );
    }
  };

  return (
    <ContainerFlex overflow="scroll">
      {loading ? (
        <ContainerFlex full middle center column>
          <ReactLoading type={"spin"} color={"#1c208c"} />
          <Text>Carregando...</Text>
        </ContainerFlex>
      ) : (
        handleHistoryList()
      )}
    </ContainerFlex>
  );
};

export default Histories;
