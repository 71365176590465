import React from 'react';
import PropTypes from 'prop-types';

import { sizingProps, sizingClass } from '../utilities/sizing';
import { spacingProps, spacingClass } from '../utilities/spacing';
import { overflowProps, overflowClass } from '../utilities/overflow';
import { flexProps, flexClass } from '../utilities/flex';
import { gutterProps, gutterClass } from './Gutter';

import cn from 'classnames';

const Row = (props) => {

  let {
    tag: Tag,
    children,
    className,
    ...attributes
  } = props;

  let sizing, spacing, overflow, flex, gutter;
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [overflow, attributes] = overflowClass(attributes);
  [gutter, attributes] = gutterClass(attributes);
  [flex, attributes] = flexClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn(
        'row',
        flex,
        gutter,
        sizing,
        spacing,
        overflow,
        className
      )}
    >
      {children}
    </Tag>
  );
};

Row.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string, 
  ...sizingProps(),
  ...spacingProps(),
  ...overflowProps(),
  ...gutterProps(),
  ...flexProps(),
};

Row.defaultProps = {
  tag: 'div',
  className: '',
};

export default Row;