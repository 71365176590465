import { useEffect, useState } from "react";
import { BarsHorizontal } from "../../../Components/Charts";
import { Card, CardBody, CardHeader } from "../../../Components/Card";
import { Text } from "../../../Components/Text";
import useReport from "../../../Hooks/useReport";

const Sources = ({ list }) => {
  const { reportdtstart, reportdtend } = useReport();

  const [listSourcesByDate, setListSourcesByDate] = useState([]);

  useEffect(() => {
    if (list && list.data && list.data.length > 0) {
      setListSourcesByDate(list.data);
    } else {
      setListSourcesByDate([]);
    }
  }, [list]);

  return (
    <Card height>
      <CardHeader border={null} between middle>
        <Text fontWeight="bold" fontSize="5">
          Origens
        </Text>
      </CardHeader>
      <CardBody paddingTop="0">
        <BarsHorizontal
          data={listSourcesByDate}
          dt_start={reportdtstart}
          dt_end={reportdtend}
          label="Origem"
        />
      </CardBody>
    </Card>
  );
};

export default Sources;
