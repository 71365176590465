import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { ButtonModal } from "../../../Components/Button";
import useCategory from "../../../Hooks/Support/useCategory";

const Item = ({ item }) => {
  const { getCategory } = useCategory();

  let Active, Color;
  if (!item.detete_at) {
    Active = "Ativo";
    Color = "success";
  } else {
    Active = "Inativo";
    Color = "danger";
  }

  const handleEdit = () => {
    getCategory(item.id);
  };

  return (
    <ContainerFlex
      className="card-item accordion-item shadow-sm"
      marginBottom="3"
      padding="2"
    >
      <Row>
        <Column col="12" between>
          <ContainerFlex row middle>
            <Text
              bgColor={Color}
              padding="0"
              paddingLeftRight="2"
              margin="0"
              marginRight="2"
              txtColor="white"
              rounded
              tag="small"
            >
              {Active}
            </Text>
            <Text
              tag="h5"
              padding="0"
              margin="0"
              marginTop="1"
              fontWeight="bolder"
            >
              {item.name}
            </Text>
          </ContainerFlex>
          <ContainerFlex middle>
            <ButtonModal
              txtColor="ydental-gray"
              onClick={handleEdit}
              modal="Support_Category_Modal"
              marginRight="2"
              middle
            >
              <Text tag="i" className="fa fa-pen" />
            </ButtonModal>
          </ContainerFlex>
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default Item;
