import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useCampaign = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const activeCampaign = useSelector((state) => state.campaign.active);
  const listCampaign = useSelector((state) => state.campaign.index);
  const dataCampaign = useSelector((state) => state.campaign.data);

  function setIndex(index) {
    dispatch({
      type: "CONTENT_CAMPAIGN_INDEX",
      payload: { index: index },
    });
  }

  function setDataCampaign(data) {
    dispatch({
      type: "CONTENT_CAMPAIGN_DATA",
      payload: { data: data },
    });
  }

  function setActiveCampaign(data) {
    dispatch({
      type: "CONTENT_CAMPAIGN_ACTIVE",
      payload: { active: data },
    });
  }

  function resetCampaign(data) {
    dispatch({
      type: "CONTENT_CAMPAIGN_RESET",
    });
  }

  const indexCampaign = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/content/campaign?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCampaign = (id) => {
    api
      .get(`/content/campaign/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataCampaign(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const postCampaign = (params, success, error) => {
    api
      .post("/content/campaign", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataCampaign(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateCampaign = (id, params, success, error) => {
    api
      .update(`/content/campaign/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delCampaign = (id, success, error) => {
    api
      .delete(`/content/campaign/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err);
        error();
      });
  };

  return {
    getCampaign,
    postCampaign,
    indexCampaign,
    updateCampaign,
    delCampaign,
    resetCampaign,
    listCampaign,
    dataCampaign,
    setDataCampaign,
    setActiveCampaign,
    activeCampaign,
  };
};

export default useCampaign;
