import useAuth from "../useAuth";
import api from "../../Data/api";

const useDocumentation = () => {
  const { token } = useAuth();

  const indexDocumentation = (filters, success, error) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/documentation?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          success(response.data);
        } else {
          error(response.response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const getDocumentation = (id, success, error) => {
    api
      .get(`/documentation/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        } else {
          error(response.response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const postDocumentation = (params, success, error) => {
    api
      .post("/documentation", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateDocumentation = (id, params, success, error) => {
    api
      .update(`/documentation/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const closeDocumentation = (id, success, error) => {
    api
      .update(`/documentation/close/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delDocumentation = (id, success, error) => {
    api
      .delete(`/documentation/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    postDocumentation,
    getDocumentation,
    indexDocumentation,
    updateDocumentation,
    closeDocumentation,
    delDocumentation,
  };
};

export default useDocumentation;
