import React, { useEffect, useState } from "react";
import useAd from "../../../../Hooks/useAd";
import useCompany from "../../../../Hooks/useCompany";
import useCampaign from "../../../../Hooks/useCampaign";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../../Components/Modal";
import { ContainerFlex, Row, Column } from "../../../../Components/Layout";
import { Input, FormLabel, Select } from "../../../../Components/Form";
import { Button, ButtonModalClose } from "../../../../Components/Button";
import { Switch } from "../../../../Components/Form";
import { Text } from "../../../../Components/Text";

const AdModal = ({ setReload }) => {
  const { postAd, updateAd, dataAd } = useAd();
  const { listCampaign } = useCampaign();
  const { indexCompany, listCompany } = useCompany();

  const [validAd, setValidAd] = useState("");
  const [validTag, setValidTag] = useState("");
  const [validCampaign, setValidCampaign] = useState("");
  const [validUnit, setValidUnit] = useState("");

  const [error, setError] = useState({});
  const handleError = (err) => {
    setError(err);
  };

  const [unit, setUnit] = useState("");
  const handleUnit = (e) => {
    setUnit(e.currentTarget.value);
    setValidUnit("");
  };

  useEffect(() => {
    indexCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [id, setId] = useState("");
  const handleId = (e) => {
    setId(e.target.value);
  };

  const [ad, setAd] = useState("");
  const handleAd = (e) => {
    setAd(e.target.value);
    setValidAd("");
  };

  const [tag, setTag] = useState("");
  const handleTag = (e) => {
    var outString = e.target.value.replace(
      /[`~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/\s]/gi,
      ""
    );
    setTag(outString);
    setValidTag("");
  };

  const [campaign, setCampaign] = useState("");
  const handleCampaign = (e) => {
    setCampaign(e.target.value);
    setValidCampaign("");
  };

  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive(!active);
  };

  const handleSuccess = () => {
    setReload();

    let thisModal = document.getElementById("Ad_Modal_Close");
    thisModal.click();

    handleDataDefault(null);
  };

  const handleValidade = () => {
    let err = true;
    if (ad.length <= 3) {
      setValidAd("O nome da campanha deve ser maior.");
      err = false;
    } else if (ad.length > 255) {
      setValidAd("O nome da campanha deve ser menor.");
      err = false;
    } else if (ad === "") {
      setValidAd("O nome da campanha não pode ser vazio.");
      err = false;
    }

    if (tag.length <= 3) {
      setValidTag("O nome da campanha deve ser maior.");
      err = false;
    } else if (tag.length > 255) {
      setValidAd("O nome da campanha deve ser menor.");
      err = false;
    } else if (tag === "") {
      setValidAd("O nome da campanha não pode ser vazio.");
      err = false;
    }

    if (campaign === "") {
      setValidCampaign("A campaign não pode ser vazia.");
      err = false;
    } else if (campaign === "none") {
      setValidCampaign("Selecione uma campaign.");
      err = false;
    }

    if (unit === "" || unit === "none") {
      setValidUnit("Selecione uma campaign.");
      err = false;
    }
    return err;
  };

  const handleConfirm = () => {
    let params = {
      name: ad,
      tag: tag,
      comp_id: unit,
      campaign_id: campaign,
      is_active: active,
    };

    if (handleValidade()) {
      if (id === "") {
        postAd(params, handleSuccess, handleError);
      } else if (id !== "" && campaign) {
        updateAd(id, params, handleSuccess, handleError);
      }
    }
  };

  const handleDataDefault = (Data) => {
    if (Data && Data.id) {
      setId(Data.id);
      setAd(Data.name);
      setTag(Data.tag);
      setUnit(Data.comp_id);
      setCampaign(Data.campaign_id);
      setActive(Data.is_active);
    } else {
      setId("");
      setAd("");
      setTag("");
      setCampaign("none");
      setUnit("none");
      setActive(true);
      setValidAd("");
      setValidCampaign("");
    }
  };

  useEffect(() => {
    handleDataDefault(dataAd);
  }, [dataAd]);

  return (
    <Modal id="Ad_Modal" center fixed scroll>
      <ModalHeader
        title="Cadastro de Anúncio"
        id="Ad_Modal_Close"
        onClick={handleDataDefault}
        border={false}
      />
      <ModalBody>
        <Row>
          {error && error.status && error.data.message && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                <Text tag="h6" fontWeight="bolder">
                  Erro: {error.status} - {error.data.message}
                </Text>
                <Text fontWeight="bolder"></Text>
                <p>{error.data.errors && JSON.stringify(error.data.errors)}</p>
              </div>
            </Column>
          )}
          <Input value={id} onChange={handleId} maxLength={60} type="hidden" />
          <Column col="12" marginBottom="3">
            <FormLabel>Nome do Anúncio</FormLabel>
            <Input
              value={ad}
              onChange={handleAd}
              maxLength={60}
              required
              className={validAd && "is-invalid"}
              onKeyDown={() => setValidAd("")}
            />
            {validAd && <div className="invalid-feedback">{validAd}</div>}
          </Column>
          <Column col="12" marginBottom="3">
            <FormLabel>Tag Identificação</FormLabel>
            <Input
              value={tag}
              onChange={handleTag}
              maxLength={60}
              required
              className={validTag && "is-invalid"}
              onKeyDown={() => setValidTag("")}
            />
            {validTag && <div className="invalid-feedback">{validTag}</div>}
          </Column>
          <Column col="12" marginBottom="3">
            <FormLabel>Campaign</FormLabel>
            <Select
              onChange={(e) => handleCampaign(e)}
              value={campaign}
              className={validCampaign && "is-invalid"}
              required
            >
              <option value="none">Selecione</option>
              {listCampaign.data &&
                listCampaign.data.length > 0 &&
                listCampaign.data.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.name}
                  </option>
                ))}
            </Select>
            {validCampaign && (
              <div className="invalid-feedback">{validCampaign}</div>
            )}
          </Column>
          <Column col="12" md="7" marginBottom="3">
            <FormLabel>Unidade</FormLabel>
            <Select
              onChange={(e) => handleUnit(e)}
              value={unit}
              className={validUnit && "is-invalid"}
            >
              <option value="none" defaultValue>
                Selecione
              </option>
              {/* {listCompany &&
                listCompany.length > 0 &&
                listCompany.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.name}
                  </option>
                ))} */}
            </Select>
            {validUnit && <div className="invalid-feedback">{validUnit}</div>}
          </Column>
          <Column col="12" md="5" marginBottom="3">
            <FormLabel>Status</FormLabel>
            <Switch
              id="is_active"
              label={active ? "Ativo" : "Inativo"}
              onClick={handleActive}
              checked={active}
            />
          </Column>
        </Row>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
          >
            Cancelar
          </ButtonModalClose>
          <Button bgColor="success" txtColor="white" onClick={handleConfirm}>
            Concluir
          </Button>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default AdModal;
