import useAuth from "../../../Hooks/useAuth";

import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
import { formatDatetime } from "../../../Components/Form/masks";
import { Button } from "../../../Components/Button";
import axios from "axios";

const Message = ({ message }) => {
  const { token } = useAuth();

  const handleDownload = (file) => {
    axios({
      url: `/api/download/support/${file}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <ContainerFlex
      border
      borderColor="gray"
      rounded
      padding="2"
      marginBottom="3"
      width="w-75"
      key={message.id}
      style={{
        backgroundColor: message.client_side === true ? "#fff" : "#7dccff36",
        float: message.client_side === true ? "left" : "right",
      }}
    >
      {message.files && message.files.length > 0 && (
        <ContainerFlex paddingTopBottom="2">
          <ContainerFlex paddingTopBottom="2" border>
            <Text tag="i" padding="1">
              Anexos:
            </Text>

            {message.files.map((file, index) => (
              <Button
                margin="1"
                paddingBottom="0"
                key={index}
                className="btn btn-outline-secondary"
                onClick={() => {
                  handleDownload(file.name);
                }}
              >
                <Text
                  tag="i"
                  className={`fas fa-file-${
                    file.name.includes("jpg")
                      ? "image"
                      : file.name.includes("jpeg")
                      ? "image"
                      : file.name.includes("bmp")
                      ? "image"
                      : file.name.includes("png")
                      ? "image"
                      : file.name.includes("doc")
                      ? "word"
                      : file.name.includes("docx")
                      ? "word"
                      : file.name.includes("csv")
                      ? "excel"
                      : file.name.includes("rtf")
                      ? "excel"
                      : file.name.includes("xlsx")
                      ? "excel"
                      : file.name.includes("xls")
                      ? "excel"
                      : file.name.includes("txt")
                      ? "alt"
                      : file.name.includes("pdf")
                      ? "pdf"
                      : file.name.includes("zip")
                      ? "archive"
                      : "file"
                  }`}
                  marginRight="2"
                />
                {file.name}
              </Button>
            ))}
          </ContainerFlex>
        </ContainerFlex>
      )}
      <ContainerFlex
        dangerouslySetInnerHTML={{ __html: message.message }}
      ></ContainerFlex>
      <hr className="m-1" />
      <ContainerFlex paddingLeft="1">
        <Text tag="small" className="text-muted">
          {message.user.name} -{" "}
        </Text>
        <Text tag="small" className="text-muted">
          {formatDatetime(message.created_at)}
        </Text>
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default Message;
