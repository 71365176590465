import { ContainerFlex } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { Button } from "../../../Components/Button";

import Item from "./ContentItem";

import useSource from "../../../Hooks/useSource";

const Content = () => {
  const { listSource } = useSource();

  return (
    <ContainerFlex full padding="3">
      <ContainerFlex between middle>
        <Text tag="h5" margin="0">
          Listagem de Origem das Campanhas
        </Text>
        <ContainerFlex row>
          <Button>
            <i className="fa fa-bars"></i>
          </Button>
          <Button>
            <i className="fa fa-th"></i>
          </Button>
        </ContainerFlex>
      </ContainerFlex>
      <hr />
      <ContainerFlex padding="2">
        {listSource.data && listSource.data.length > 0 ? (
          listSource.data.map((data, index) => (
            <Item key={index} item={data}></Item>
          ))
        ) : (
          <ContainerFlex full center middle column>
            <Text tag="i" className="fa fa-frown h1" />
            <Text tag="h5">Nenhum item foi encontrado</Text>
          </ContainerFlex>
        )}
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default Content;
