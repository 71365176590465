const initialState = {
  id: "",
  index: [],
  type: [],
  data: {},
  indexHistoryType: [],
};

const Lead = (state = initialState, action) => {
  switch (action.type) {
    case "CONTENT_LEAD_ID":
      return { ...state, id: action.payload.id };
    case "CONTENT_LEAD_INDEX":
      return { ...state, index: action.payload.index };
    case "CONTENT_LEAD_TYPE":
      return { ...state, type: action.payload.type };
    case "CONTENT_LEAD_HISTOTY_TYPE_INDEX":
      return { ...state, indexHistoryType: action.payload.indexHistoryType };
    case "CONTENT_LEAD_DATA":
      return { ...state, data: action.payload.data };
    case "CONTENT_LEAD_RESET":
      return initialState;
    default:
      return state;
  }
};

export default Lead;
