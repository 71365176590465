import React, { useEffect, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import "moment/locale/pt-br";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventModal from "./Modal";

import ItemEvent from "./ItemEvent";
import { ButtonModal } from "../../Components/Button";

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const MyCalendar = ({ agendas, setDate }) => {
  const today = new Date();
  const [event, setEvent] = useState({});
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    Array.isArray(agendas) && setEventList(agendas);
  }, [agendas]);

  useEffect(() => {
    if (event && event.id) {
      handleOpenModal();
    }
  }, [event]);

  function Event({ event }) {
    return <ItemEvent event={event} />;
  }

  const handleOpenModal = () => {
    let thisModal = document.getElementById("Event_Modal_Open");
    thisModal.click();
  };

  const handleSelectEvent = (event) => {
    setEvent(event);
  };

  const messages = {
    allDay: "Dia Inteiro",
    previous: "<",
    next: ">",
    today: "Hoje",
    month: "Mês",
    week: "Semana",
    day: "Dia",
    agenda: "Agenda",
    date: "Data",
    time: "Horario",
    event: "Evento",
    noEventsInRange: "Nenhum evento encontrado neste período.",
    work_week: "Semana de Trabalho",
    yesterday: "Ontem",
    tomorrow: "Amanhã",
    showMore: function (e) {
      return "+" + e + " Mais";
    },
  };

  const eventStyleGetter = (event) => {
    var style = {
      backgroundColor: event.status.color,
      borderRadius: "5px",
      opacity: 1,
      color: "white",
      borderStyle: "groove",
      display: "block",
    };
    return {
      style: style,
    };
  };

  return (
    <>
      <DragAndDropCalendar
        culture="pt"
        selectable
        step={10}
        timeslots={1}
        localizer={localizer}
        events={eventList}
        resizable
        showMultiDayTimes={true}
        defaultView={Views.WEEK}
        views={["week", "day"]}
        eventPropGetter={eventStyleGetter}
        defaultDate={new Date()}
        popup={true}
        // handleDragStart={handleDragStart}
        messages={messages}
        components={{ event: Event }}
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8)
        }
        max={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 20)
        }
        onSelectEvent={(event) => handleSelectEvent(event)}
        // onDoubleClickEvent={handleSelectEvent}
        // onDragStart={console.log}
        // onNavigate={handleNavigate}
        onNavigate={(date, view) => {
          setDate(moment(date).format("YYYY-MM-DD HH:mm:ss"));
          // console.log("#### onNavigate");
          // console.log("#### date=", date.get);
          // console.log("#### view=", view);
          // console.log(moment(date).format("YYYY-MM-DD HH:mm:ss"));
          // console.log(moment(date).endOf("month").endOf("week"));
        }}
        // onDropFromOutside={onDropFromOutside}
        // onRangeChange={handleRange}
        // onEventDrop={moveEvent}
        // onEventResize={resizeEvent}
        // onSelectSlot={handleSelect}
        // dragFromOutsideItem={displayDragItemInCell ? dragFromOutsideItem : null}
      />
      <EventModal
        event={event}
        setEvent={setEvent}
        // open={openModal}
        // setOpen={setOpenModal}
      />
      <ButtonModal
        modal="Prospect_Modal"
        id="Event_Modal_Open"
        className="d-none"
      />
    </>
  );
};

export default MyCalendar;
