import PropTypes from "prop-types";

import { colorsDef } from "./colors";

export function bordersProps() {
  return {
    border: PropTypes.bool,
    borderTop: PropTypes.bool,
    borderLeft: PropTypes.bool,
    borderRight: PropTypes.bool,
    borderBottom: PropTypes.bool,

    borderColor: PropTypes.oneOf([...colorsDef]),
    rounded: PropTypes.oneOf([
      "top",
      "right",
      "bottom",
      "left",
      "circle",
      "pill",
      "0",
      "sm",
      "lg",
      true,
      false,
    ]),
  };
}

export function bordersClass(obj) {
  let className = "";
  let {
    border,
    borderTop,
    borderLeft,
    borderRight,
    borderBottom,
    borderColor,
    rounded,
    ...attributes
  } = obj;

  if (typeof border !== "undefined") {
    if (border) {
      className += "border ";
    } else {
      className += `border-0 `;
    }
  }

  if (typeof borderTop !== "undefined") {
    if (borderTop) {
      className += "border-top ";
    } else {
      className += `border-top-0 `;
    }
  }

  if (typeof borderLeft !== "undefined") {
    if (borderLeft) {
      className += "border-start ";
    } else {
      className += `border-start-0 `;
    }
  }

  if (typeof borderRight !== "undefined") {
    if (borderRight) {
      className += "border-end ";
    } else {
      className += `border-end-0 `;
    }
  }

  if (typeof borderBottom !== "undefined") {
    if (borderBottom) {
      className += "border-bottom ";
    } else {
      className += `border-bottom-0 `;
    }
  }

  if (borderColor) {
    className += `border-${borderColor} `;
  }

  if (typeof rounded === "boolean") {
    if (rounded) className += "rounded ";
  } else {
    if (rounded) className += `rounded-${rounded} `;
  }

  return [className.trim(), attributes];
}
