import { useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";

import SearchBox from "./SearchBox";

import useCompany from "../../Hooks/useCompany";
import useProspect from "../../Hooks/useProspect";

import { Column, ContainerFlex, Row } from "../../Components/Layout";
import { FormLabel, Input, Select } from "../../Components/Form";

const FilterOptions = ({setCompany}) => {
  const { listCompany } = useCompany();
  const { indexProspectFilter, setIndexProspect, dataProspectStatus } =
    useProspect();

  const [search, setsearch] = useState([]);
  
  const [company, setcompany] = useState("");
  const [companyList, setcompanyList] = useState("");

  const handleSearch = (e) => {
    if (e.target.value.length > 2) {
      indexProspectFilter({ name: e.target.value }, setsearch);
    } else {
      setsearch([]);
    }
  };

  const handleCompany = (e) => {
    setcompany(e.currentTarget.value);
    setCompany(e.currentTarget.value);
  };

  useEffect(() => {
    if (listCompany && listCompany.length > 0) {
      setcompanyList(listCompany);
      setcompany(listCompany[0].value)
      setCompany(listCompany[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCompany]);

  const debouncedChangeHandler = useMemo(
    () => debounce(handleSearch, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ContainerFlex
      bgColor="white"
      padding="3"
      marginBottom="3"
      className="shadow"
      rounded
    >
      <Row>
        <Column col="12" md="3">
          <FormLabel>Unidade</FormLabel>
          <Select value={company} onChange={handleCompany}>
            {companyList &&
              companyList.map((company, index) => (
                <option key={index} value={company.value} >
                  {company.label}
                </option>
              ))}
          </Select>
        </Column>
        <Column col="12" md="5" className="btn-group" column>
          <FormLabel>Pesquisar por nome:</FormLabel>
          <Input
            onChange={debouncedChangeHandler}
            id="SearchBoxProspect"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <SearchBox searchList={search} />
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default FilterOptions;
