import React, { useRef } from "react";
import JoditEditor from "jodit-react";

const Editor = ({ content, setContent }) => {
  const editor = useRef(null);

  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: false,
    placeholder: "",
    useSearch: false,
    toolbar: false,
  };

  return (
    <div className="mt-3">
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onBlur={(newContent) => setContent(newContent)}
        // onChange={(newContent) => {}}
        placeholder="Informe o assunto resumido sobre o chamado."
      />
      {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
    </div>
  );
};

export default Editor;
