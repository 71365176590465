import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useNote = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const activeNote = useSelector((state) => state.note.active);
  const listNote = useSelector((state) => state.note.index);
  const dataNote = useSelector((state) => state.note.data);

  function setActiveNote(active) {
    dispatch({
      type: "CONTENT_NOTE_ACTIVE",
      payload: { active: active },
    });
  }

  function setIndexNote(index) {
    dispatch({
      type: "CONTENT_NOTE_INDEX",
      payload: { index: index },
    });
  }

  function resetNote() {
    dispatch({
      type: "CONTENT_NOTE_RESET",
    });
  }

  const indexNote = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/note?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndexNote(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const getNote = (id, success) => {
    api
      .get(`/note/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.warn(err.response);
      });
  };

  const postNote = (params, success, error) => {
    api
      .post("/note", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateNote = (id, params, success, error) => {
    api
      .update(`/note/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delNote = (id, success, error) => {
    api
      .delete(`/note/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getNote,
    postNote,
    indexNote,
    updateNote,
    delNote,
    resetNote,
    activeNote,
    dataNote,
    listNote,
    setActiveNote,
    setIndexNote,
  };
};

export default useNote;
