import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import useCategory from "../../../Hooks/Support/useCategory";

import { Button } from "../../../Components/Button";
import { Card, CardBody } from "../../../Components/Card";
import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import useAgentTicket from "../../../Hooks/Support/useAgentTicket";

const Filter = () => {
  const animatedComponents = makeAnimated();

  const { listCategory } = useCategory();
  const { indexAgentTicket } = useAgentTicket();

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({ value: "all", label: "Todos" });
  const [validCategory, setValidCategory] = useState("");

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(listCategory.data) && listCategory.data.length > 0) {
      let list = [];

      list.push({
        value: "all",
        label: "Todos",
      });
      listCategory.data.forEach((data) => {
        list.push({
          value: data.id,
          label: data.name,
        });
      });
      setCategories(list);
    }
  }, [listCategory]);

  const handleCategory = (value) => {
    setCategory(value);
    setValidCategory("");
  };

  const validation = () => {
    let valid = true;

    if (!category) {
      setValidCategory("Campo obrigatório");
      valid = false;
    }

    return valid;
  };

  const handleSubmit = () => {
    if (validation()) {
      let params = {
        category_id: category.value,
      };

      indexAgentTicket(params);
    }
  };

  return (
    <ContainerFlex column>
      <Card>
        <CardBody>
          <Row>
            <Column col="12" md="4">
              <Text>Setor</Text>
              <Select
                closeMenuOnSelect={true}
                components={animatedComponents}
                value={category}
                options={categories}
                menuPosition={"fixed"}
                onChange={handleCategory}
                className={validCategory && "is-invalid"}
                placeholder="Selecione um setor"
              />
              {validCategory && (
                <div className="invalid-feedback">{validCategory}</div>
              )}
            </Column>
            <Column col="12" md="4" bottom>
              <Button bgColor="info" onClick={handleSubmit}>
                <Text tag="i" className="fas fa-filter" marginRight="2"></Text>
                <Text>Filtrar</Text>
              </Button>
            </Column>
          </Row>
        </CardBody>
      </Card>
    </ContainerFlex>
  );
};

export default Filter;
