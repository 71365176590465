import React from 'react';
import PropTypes from 'prop-types';

import { colorsProps, colorsClass } from '../utilities/colors';
import { sizingProps, sizingClass } from '../utilities/sizing';
import { spacingProps, spacingClass } from '../utilities/spacing';
import { bordersProps, bordersClass } from '../utilities/borders';

import cn from 'classnames';

const Card = (props) => {
  let { tag: Tag, children, className, ...attributes } = props;

  let colors, sizing, spacing, borders;
  [colors, attributes] = colorsClass(attributes);
  [sizing, attributes] = sizingClass(attributes);
  [spacing, attributes] = spacingClass(attributes);
  [borders, attributes] = bordersClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn('card', colors, sizing, borders, spacing, className)}
    >
      {children}
    </Tag>
  );
};

Card.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  ...sizingProps(),
  ...spacingProps(),
  ...colorsProps(),
  ...bordersProps(),
};

Card.defaultProps = {
  tag: 'div',
  className: '',
};

export default Card;
