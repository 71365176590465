import { useEffect, useState } from "react";

import ReactInputMask from "react-input-mask";

import ModalPhone from "./ModalPhone";

import { Text } from "../../../Components/Text";
import { Card } from "../../../Components/Card";
import { FormLabel } from "../../../Components/Form";
import { Column, ContainerFlex, Row } from "../../../Components/Layout";

const Numbers = ({ lead, inflow }) => {
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState("");

  const [source, setSource] = useState({});
  const [campaign, setCampaign] = useState({});
  const [ad, setAd] = useState({});

  const [open, setOpen] = useState(false);

  const handlePhone = (e) => {
    setPhone(e.currentTarget.value);
    setValidPhone("");
  };

  const handleCheckPhone = (e) => {
    let value = e.replace(/[^\d]+/g, "");

    if (value < 10) {
      setValidPhone("Número inválido");
    } else {
      setValidPhone("");
    }
  };

  useEffect(() => {
    if (lead) {
      setPhone(lead.lead && lead.lead);
    }
  }, [lead]);

  useEffect(() => {
    if (inflow) {
      setSource(inflow.source && inflow.source);
      setCampaign(inflow.campaign && inflow.campaign);
      setAd(inflow.ad && inflow.ad);
    }
  }, [inflow]);

  return (
    <ContainerFlex>
      <ContainerFlex>
        <Text className="h5">Informações de contato</Text>
      </ContainerFlex>
      <ContainerFlex>
        <Card padding="3" bgColor="light" marginTopBottom="2">
          <Row>
            {/* <Column col="12" right>
              <ContainerFlex bottom>
                <ButtonModal
                  size="sm"
                  outline="primary"
                  modal="Modal_Phone_Patient"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Editar
                  <Text tag="i" className="fas fa-pen" marginLeft="2" />
                </ButtonModal>
                
              </ContainerFlex>
            </Column> */}
            <Column col="12" lg="2" md="3">
              <FormLabel>Número:</FormLabel>
              <ReactInputMask
                value={phone || ""}
                onBlur={(e) => handleCheckPhone(e.currentTarget.value)}
                className={`form-control bg-white ${
                  validPhone && "is-invalid"
                }`}
                mask={`${
                  phone && phone.length == 10
                    ? "(99) 9999-9999"
                    : "(99) 99999-9999"
                }`}
                maskPlaceholder={null}
                readOnly
              />

              {validPhone && (
                <div className="invalid-feedback">{validPhone}</div>
              )}
            </Column>

            <Column col="12" md="3">
              <ContainerFlex column>
                <FormLabel className="pe-2">Origem:</FormLabel>
                <ContainerFlex row middle>
                  <Text center bgColor="white" border padding="2" rounded width>
                    {source ? source.name : "-"}
                  </Text>
                  <Text
                    tag="i"
                    className="fas fa-arrow-circle-right"
                    marginLeft="4"
                  />
                </ContainerFlex>
              </ContainerFlex>
            </Column>
            <Column col="12" md="3">
              <ContainerFlex column>
                <FormLabel>Campanha:</FormLabel>
                <ContainerFlex row middle>
                  <Text center bgColor="white" border padding="2" rounded width>
                    {campaign ? campaign.name : "-"}
                  </Text>
                  <Text
                    tag="i"
                    className="fas fa-arrow-circle-right"
                    marginLeft="4"
                  />
                </ContainerFlex>
              </ContainerFlex>
            </Column>

            <Column col="12" md="4">
              <ContainerFlex column>
                <FormLabel>Anúncio:</FormLabel>
                <Text center bgColor="white" border padding="2" rounded>
                  {ad ? ad.name : "-"}
                </Text>
              </ContainerFlex>
            </Column>
          </Row>
        </Card>
      </ContainerFlex>
      <ModalPhone lead={lead} inflow={inflow} open={open} setOpen={setOpen} />
    </ContainerFlex>
  );
};

export default Numbers;
