import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";

const HistoryAgenda = ({ history }) => {
  return (
    <ContainerFlex border padding="2">
      <Row>
        <Column col="12" md="6">
          <Text tag="small" className="text-muted" marginRight="2">
            Situação do Agendamento:
          </Text>
          <Text fontSize="6" padding="0" margin="0">
            {history.agenda_status_id.name}
          </Text>
        </Column>
        {history.note && (
          <Column col="12">
            <small>{history.note.title}</small>
          </Column>
        )}
      </Row>
    </ContainerFlex>
  );
};

export default HistoryAgenda;
