import { useNavigate } from "react-router-dom";

import { Text } from "../../Components/Text";
import { ButtonModalClose } from "../../Components/Button";
import { formatDatetime } from "../../Components/Form/masks";
import { Column, ContainerFlex, Row } from "../../Components/Layout";

const Item = ({ item }) => {
  const navigate = useNavigate();

  const handleOpen = () => {
    navigate(`/marketing/${item.id}`);
  };

  return (
    <ButtonModalClose
      className="card-item accordion-item shadow-sm"
      padding="2"
      marginTopBottom="2"
      onClick={handleOpen}
      role="button"
      width
    >
      <Row>
        <Column col="12" between marginBottom="2">
          <ContainerFlex row middle>
            <Text
              tag="h5"
              padding="0"
              margin="0"
              marginTop="1"
              fontWeight="bolder"
            >
              {item.title}
            </Text>
          </ContainerFlex>
        </Column>

        {!item.is_allday ? (
          <Column col="12" between>
            <Text tag="small" marginRight="2">
              Início: {formatDatetime(item.start)}
            </Text>
            <Text tag="small" marginRight="2">
              Fim: {formatDatetime(item.end)}
            </Text>
          </Column>
        ) : (
          <Column col="12" left>
            <Text tag="small" marginRight="2">
              Dia inteiro
            </Text>
          </Column>
        )}
      </Row>
    </ButtonModalClose>
  );
};

export default Item;
