import { useEffect } from "react";

import Filter from "./Filter";

import useCategory from "../../../Hooks/Support/useCategory";
import useStatus from "../../../Hooks/Support/useStatus";
import useAgentTicket from "../../../Hooks/Support/useAgentTicket";
import usePriority from "../../../Hooks/Support/usePriority";

import { ContainerFlex } from "../../../Components/Layout";
import Content from "./Content";

const SupportManager = () => {
  const { indexCategory } = useCategory();
  const { indexStatus } = useStatus();
  const { indexPriority } = usePriority();
  const { setIndex } = useAgentTicket();

  useEffect(() => {
    indexStatus();
    indexCategory();
    indexPriority();
    setIndex([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerFlex full padding="3">
      <Filter />
      <Content />
    </ContainerFlex>
  );
};

export default SupportManager;
