import { useEffect, useState } from "react";

import useReport from "../../../Hooks/useReport";

import LineChart from "./LineChart";
import { Text } from "../../../Components/Text";
import { Card, CardBody, CardHeader } from "../../../Components/Card";

const Leads = ({ list, old }) => {
  const { reportdtstart, reportdtend } = useReport();

  const [listLeadsByDate, setListLeadsByDate] = useState([]);
  const [countLeadsByDate, setCountLeadsByDate] = useState(0);
  const [listLeadsOldByDate, setListLeadsOldByDate] = useState([]);

  useEffect(() => {
    if (list && list.data && list.data.length > 0) {
      setListLeadsByDate(list.data);
      let count = 0;
      list.data.forEach((item) => {
        count += Number(item.value);
      });
      setCountLeadsByDate(count);
    } else {
      setListLeadsByDate([]);
      setCountLeadsByDate(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (old && old.data && old.data.length > 0) {
      setListLeadsOldByDate(old.data);
    } else {
      setListLeadsOldByDate([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <Card>
      <CardHeader border={null} between middle>
        <Text fontWeight="bold" fontSize="5">
          Oportunidades
        </Text>
        <Text tag="h4" className="float-end m-0 p-0">
          {countLeadsByDate ? countLeadsByDate : 0}
        </Text>
      </CardHeader>
      <CardBody paddingTop="0">
        <LineChart
          data={listLeadsByDate}
          old={listLeadsOldByDate}
          dt_start={reportdtstart}
          dt_end={reportdtend}
          accumulated
          label="Contatos"
        />
      </CardBody>
    </Card>
  );
};

export default Leads;
