import { useEffect } from "react";
import { ContainerFlex } from "../../../Components/Layout";
import useProspect from "../../../Hooks/useProspect";

import Content from "./Content";

const ReportProspect = () => {
  const { indexProspectStatus } = useProspect();

  useEffect(() => {
    indexProspectStatus({ all: 0 }, () => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerFlex full row>
      <Content />
    </ContainerFlex>
  );
};

export default ReportProspect;
