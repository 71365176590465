import { Card, CardBody, CardHeader } from "../../Components/Card";
import { ContainerFlex } from "../../Components/Layout";
import { Text } from "../../Components/Text";
import ContentList from "./ContentList/index";

const Documents = () => {
  return (
    <ContainerFlex middle column padding="3">
      <Card full>
        <CardHeader>
          <Text tag="h1" margin="2" center>
            Manuais
          </Text>
        </CardHeader>
        <CardBody>
          <ContentList />
        </CardBody>
      </Card>
    </ContainerFlex>
  );
};

export default Documents;
