import React from 'react';
import PropTypes from 'prop-types';

import { textProps, textClass } from '../utilities/text';
import { colorsProps, colorsClass } from '../utilities/colors';
import { bordersProps, bordersClass } from '../utilities/borders';
import { flexProps, flexClass } from '../utilities/flex';

import cn from 'classnames';

const CardHeader = (props) => {
  let { 
    tag: Tag,
    className,
    children,
    ...attributes 
  } = props;

  let text, colors, borders,flex;
  [text, attributes] = textClass(attributes);
  [colors, attributes] = colorsClass(attributes);
  [borders, attributes] = bordersClass(attributes);
  [flex, attributes] = flexClass(attributes);

  return (
    <Tag
      {...attributes}
      className={cn(
        'card-header', 
        text, 
        colors, 
        flex,
        borders, 
        className
      )}
    >
      {children}
    </Tag>
  );
};

CardHeader.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  ...textProps(),
  ...colorsProps(),
  ...bordersProps(),
  ...flexProps(),

};

CardHeader.defaultProps = {
  tag: 'header',
  className: '',
};

export default CardHeader;
