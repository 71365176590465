import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import useAuth from "../../Hooks/useAuth";

import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import ErrorList from "./ErrorList";
import LeadModal from "../Lead/Modal";

import { ContainerFlex } from "../../Components/Layout";

const Layout = () => {
  const { me } = useAuth();
  const [prospect, setProspect] = useState(false);

  useEffect(() => {
    if (me && me.data && me.data.menus) {
      handleProspect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const handleProspect = () => {
    me.data.menus.forEach((element) => {
      if (element.name === "Prospecção") {
        setProspect(true);
      }
    });
  };

  return (
    <>
      <ContainerFlex className="base-container">
        <Sidebar />
        <ContainerFlex className="container-right">
          <Topbar prospect />
          <ContainerFlex className="container-dynamic">
            <ErrorList />
            <Outlet />
          </ContainerFlex>
        </ContainerFlex>
      </ContainerFlex>
      {prospect && <LeadModal />}
    </>
  );
};

export default Layout;
