import { useDispatch, useSelector } from "react-redux";

import useAuth from "./useAuth";
import api from "../Data/api";

const useGender = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listGender = useSelector((state) => state.gender.index);

  function setIndexGender(index) {
    dispatch({
      type: "GENDER_INDEX",
      payload: { index: index },
    });
  }

  function resetGender() {
    dispatch({
      type: "GENDER_RESET",
    });
  }

  const indexGender = () => {
    api
      .get(`gender`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndexGender(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  };

  const getGender = (id, success, error) => {
    api
      .get(`gender/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const postGender = (params, success, error) => {
    api
      .post("/gender", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success(response.data.data);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateGender = (id, params, success, error) => {
    api
      .update(`gender/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          // setData Gender(response.data.data);
          success(response);
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delGender = (id, success, error) => {
    api
      .delete(`gender/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        console.warn(err.response);
        error(err.response);
      });
  };

  return {
    getGender,
    postGender,
    indexGender,
    updateGender,
    delGender,
    resetGender,
    setIndexGender,
    listGender,
  };
};

export default useGender;
