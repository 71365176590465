import { useEffect, useState } from "react";
import { Bars } from "../../../Components/Charts";
import { Card, CardBody, CardHeader } from "../../../Components/Card";
import { Text } from "../../../Components/Text";
import useReport from "../../../Hooks/useReport";

const Ads = ({ list }) => {
  const { reportdtstart, reportdtend } = useReport();

  const [listAdsByDate, setListAdsByDate] = useState([]);

  useEffect(() => {
    if (list && list.data && list.data.length > 0) {
      setListAdsByDate(list.data);
    } else {
      setListAdsByDate([]);
    }
  }, [list]);

  return (
    <Card height>
      <CardHeader border={null} between middle>
        <Text fontWeight="bold" fontSize="5">
          Anúncios
        </Text>
      </CardHeader>
      <CardBody paddingTop="0">
        <Bars
          data={listAdsByDate}
          dt_start={reportdtstart}
          dt_end={reportdtend}
          label="Anúncios"
        />
      </CardBody>
    </Card>
  );
};

export default Ads;
