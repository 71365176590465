import { useDispatch, useSelector } from "react-redux";

import useAuth from "../useAuth";
import api from "../../Data/api";

const useCategory = () => {
  const { token } = useAuth();

  const dispatch = useDispatch();

  const listCategory = useSelector(
    (state) => state.documentationcategory.index
  );
  const dataCategory = useSelector((state) => state.documentationcategory.data);

  function setIndex(index) {
    dispatch({
      type: "DOCUMENTATION_CATEGORY_INDEX",
      payload: { index: index },
    });
  }

  function setDataCategory(data) {
    dispatch({
      type: "DOCUMENTATION_CATEGORY_DATA",
      payload: { data: data },
    });
  }

  function resetCategory() {
    dispatch({
      type: "DOCUMENTATION_CATEGORY_RESET",
    });
  }

  const indexCategory = (filters) => {
    let qs = "";
    if (filters) {
      qs = Object.keys(filters)
        .map((key) => `${key}=${filters[key]}`)
        .join("&");
    }
    api
      .get(`/documentation/category?${qs}`, token)
      .then((response) => {
        if (response.status === 200) {
          setIndex(response.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCategory = (id) => {
    api
      .get(`/documentation/category/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setDataCategory(response.data.data);
        } else {
          console.warn(response);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const postCategory = (params, success, error) => {
    api
      .post("/documentation/category", token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const updateCategory = (id, params, success, error) => {
    api
      .update(`/documentation/category/${id}`, token, params)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  const delCategory = (id, success, error) => {
    api
      .delete(`/documentation/category/${id}`, token)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          success();
        }
      })
      .catch((err) => {
        error(err.response);
      });
  };

  return {
    getCategory,
    postCategory,
    indexCategory,
    updateCategory,
    delCategory,
    resetCategory,
    listCategory,
    dataCategory,
    setDataCategory,
  };
};

export default useCategory;
