import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import subDays from "date-fns/subDays";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import format from "date-fns/format";
import NumberFormat from "react-number-format";

// import addMinutes from "date-fns/addMinutes";

import useAppointment from "../../../Hooks/useAppointment";
import useAppointmentStatus from "../../../Hooks/useAppointmentStatus";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../Components/Modal";
import { Text } from "../../../Components/Text";
import { Row, Column } from "../../../Components/Layout";
import { Input, FormLabel, Select } from "../../../Components/Form";
import { ContainerFlex } from "../../../Components/Layout";
import { Button, ButtonModalClose } from "../../../Components/Button";
import { formatPhone } from "../../../Components/Form/masks";

registerLocale("ptBR", ptBR);

const ProspectModal = ({ event, setEvent }) => {
  const { updateAppointment, indexAppointment } = useAppointment();

  const { indexAppointmentStatus, listAppointmentStatus } =
    useAppointmentStatus();

  const [patientName, setPatientName] = useState("");

  const [desc, setDesc] = useState("");

  const [status, setStatus] = useState({});
  const [validStatus, setValidStatus] = useState({});

  const [start, setStart] = useState("");
  const [validStart, setValidStart] = useState("");

  const [end, setEnd] = useState("");
  const [validEnd, setValidEnd] = useState("");

  const [lead, setLead] = useState("");

  const [value, setValue] = useState("");
  const [showValue, setShowValue] = useState(false);
  const [validValue, setValidValue] = useState("");

  const [error, setError] = useState({});

  useEffect(() => {
    handleDataDefault();
    indexAppointmentStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (event && event.id) {
      handleDataDefault(event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  useEffect(() => {
    if (status === "3" || status === "6") {
      setShowValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handlePhone = (number) => {
    let lead_phone = formatPhone(number);
    setLead(lead_phone);
  };

  const handleStatus = (e) => {
    setStatus(e.currentTarget.value);
    setValidStatus("");
  };

  const handleValue = (e) => {
    setValue(e.currentTarget.value);
    setValidValue("");
  };

  const handleDesc = (e) => {
    setDesc(e.currentTarget.value);
  };

  const handleDateStart = (date) => {
    setStart(date);
    setValidStart("");
  };

  const handleDateEnd = (date) => {
    setEnd(date);
    setValidEnd("");
  };

  const handleValidade = () => {
    let err = true;

    return err;
  };

  const handleSuccess = (result) => {
    indexAppointment();
    let thisModal = document.getElementById("Prospect_Modal_Close");
    thisModal.click();
    handleDataDefault();
    // setOpen(false);
  };

  const handleError = (err) => {
    setError(err);
  };

  const handleConfirm = () => {
    let money = value.replace("R$", "");
    money = money.replace(/\./g, "");
    money = money.replace(",", ".");

    let params = {
      desc: desc,
      agenda_id: event.agenda.id,
      patient_id: event.patient.id,
      status_id: status,
      start: start && format(start, "yyyy-MM-dd HH:mm:00"),
      end: end && format(end, "yyyy-MM-dd HH:mm:00"),
      is_active: event.is_active,
      prospect_id: event.prospect.id,
      history_id: event.prospect.history_id,
      value: money,
    };

    if (handleValidade()) {
      updateAppointment(event.id, params, handleSuccess, handleError);
    }
  };

  const handleDataDefault = (event) => {
    if (event && event.agenda) {
      handlePhone(event.prospect.lead.lead.lead);
      setPatientName(event.patient.name);
      // setDesc(event.desc ? event.desc : "");
      setStatus(event.status.id);
      setValidStatus("");
      setStart(new Date(event.start));
      setValidStart("");
      setEnd(new Date(event.end));
      setValidEnd("");
      if (event.prospect.value && event.prospect.value > 0) {
        setValue(event.prospect.value.replace(".", ","));
        setShowValue(true);
      } else {
        setShowValue(false);
      }
      setValidValue("");
      setError({});
    } else {
      setPatientName("");
      setDesc("");
      setStatus("");
      setValidStatus("");
      setStart("");
      setValidStart("");
      setEnd("");
      setValidEnd("");
      setLead("");
      setValue("");
      setShowValue(false);
      setValidValue("");
      setError({});
      setEvent({});
    }
  };

  return (
    <Modal id="Prospect_Modal" center fixed scroll size="lg">
      <ModalHeader
        id="Prospect_Modal_Close"
        border={false}
        title="Agendamento"
        onClick={handleDataDefault}
      />
      <ModalBody>
        <Row>
          {error.status && error.data.message && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                <Text tag="h6" fontWeight="bolder">
                  Erro: {error.status} - {error.data.message}
                </Text>
                <Text fontWeight="bolder"></Text>
                <p>{error.data.errors && JSON.stringify(error.data.errors)}</p>
              </div>
            </Column>
          )}
          <Column col="12" md="8" marginBottom="3">
            <FormLabel>Nome</FormLabel>
            <Input defaultValue={patientName} />
          </Column>
          <Column col="12" md="4" marginBottom="3">
            <FormLabel>Contato</FormLabel>
            <Input className="text-center" defaultValue={lead} />
          </Column>
          <Column col="12" marginBottom="3">
            <FormLabel>Descrição</FormLabel>
            <Input
              onChange={(e) => handleDesc(e)}
              tag="textarea"
              value={desc}
            />
          </Column>
          <Column col="12" md="3" marginBottom="3">
            <FormLabel>Data Início</FormLabel>
            <DatePicker
              locale="ptBR"
              selected={start}
              onChange={(date) => handleDateStart(date)}
              selectsStart
              showTimeSelect
              minTime={setHours(setMinutes(new Date(), 0), 8)}
              maxTime={setHours(setMinutes(new Date(end), 0), 18)}
              minDate={subDays(new Date(), 0)}
              maxDate={new Date(end)}
              timeCaption="Hora"
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd/MM/yyyy HH:mm"
              withPortal
              portalId="root-start"
              className={validStart && "form-control is-invalid"}
            />
          </Column>
          <Column col="12" md="3" marginBottom="3">
            <FormLabel>Data Final</FormLabel>
            <DatePicker
              locale="ptBR"
              selected={end}
              onChange={(date) => handleDateEnd(date)}
              selectsEnd
              showTimeSelect
              minTime={setHours(setMinutes(new Date(), 0), start)}
              maxTime={setHours(setMinutes(new Date(), 0), 18)}
              minDate={new Date(start)}
              timeCaption="Hora"
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd/MM/yyyy HH:mm"
              withPortal
              portalId="root-end"
              className={validEnd && "form-control is-invalid"}
            />
          </Column>
          <Column col="4" md="3" marginBottom="3">
            <FormLabel>Status</FormLabel>
            <Select
              onChange={(e) => handleStatus(e)}
              value={status}
              className={validStatus && "is-invalid"}
            >
              <option value="none" defaultValue>
                Selecione
              </option>
              {listAppointmentStatus.data &&
                listAppointmentStatus.data.length > 0 &&
                listAppointmentStatus.data.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.name}
                  </option>
                ))}
            </Select>
            {/* {validUnit && <div className="invalid-feedback">{validUnit}</div>} */}
          </Column>
          {showValue && (
            <Column col="12" md="3" marginBottom="3">
              <FormLabel>Valor</FormLabel>
              <NumberFormat
                value={value}
                prefix="R$ "
                thousandsGroupStyle="thousand"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                className="form-control"
                inputmode="numeric"
                decimalScale={2}
                fixedDecimalScale={true}
                onChange={(e) => handleValue(e)}
              />
              {validValue && (
                <div className="invalid-feedback">{validValue}</div>
              )}
            </Column>
          )}
        </Row>
      </ModalBody>
      <ModalFooter border={false}>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
          >
            Cancelar
          </ButtonModalClose>
          <Button bgColor="success" txtColor="white" onClick={handleConfirm}>
            Salvar
          </Button>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default ProspectModal;
