import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import useSource from "../../../../Hooks/useSource";
import useCompany from "../../../../Hooks/useCompany";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../../Components/Modal";
import { ContainerFlex, Row, Column } from "../../../../Components/Layout";
import { Input, FormLabel } from "../../../../Components/Form";
import { Button, ButtonModalClose } from "../../../../Components/Button";
import { Switch } from "../../../../Components/Form";
import { Text } from "../../../../Components/Text";

const SourceModal = ({ setReload }) => {
  const { indexCompany, listCompany, selectedCompanies, setSelectedCompanies } =
    useCompany();
  const animatedComponents = makeAnimated();
  const { dataSource, postSource, updateSource } = useSource();

  const [validUnit, setValidUnit] = useState("");
  const [validOrigem, setValidOrigem] = useState("");
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);

  const [error, setError] = useState({});
  const handleError = (err) => {
    setError(err);
  };

  const handleUnit = (e) => {
    // setUnit(e.currentTarget.value);
    setSelectedCompanies(e);
    setValidUnit("");
  };

  useEffect(() => {
    indexCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listCompany) {
      setList(listCompany);
    }
  }, [listCompany]);

  useEffect(() => {
    if (selectedCompanies) {
      setSelected(selectedCompanies);
    }
  }, [selectedCompanies]);

  const [id, setId] = useState("");
  const handleId = (e) => {
    setId(e.target.value);
  };

  const [origem, setOrigem] = useState("");
  const handleOrigem = (e) => {
    setOrigem(e.target.value);
  };

  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive(!active);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleConfirm();
    }
  };

  const handleSuccess = () => {
    setReload();

    let thisModal = document.getElementById("Source_Modal_Close");
    thisModal.click();

    handleDataDefault(null);
  };

  const handleValidate = () => {
    let err = true;
    if (origem.length <= 3) {
      setValidOrigem("Tamanho da origem é muito pequeno.");
      err = false;
    }

    if (!Array.isArray(selected) || selected.length < 1) {
      setValidUnit("Selecione uma unidade.");
      err = false;
    }
    return err;
  };

  const handleConfirm = () => {
    if (handleValidate()) {
      let comps = selected.map((data) => {
        return { comp_id: data.value, comp_name: data.label };
      });
      let params = { name: origem, comp_id: comps, is_active: active };

      if (id === "") {
        postSource(params, handleSuccess, handleError);
      } else if (id && origem) {
        updateSource(id, params, handleSuccess, handleError);
      }
    }
  };

  const handleDataDefault = (Data) => {
    if (Data.id) {
      setId(Data.id);
      setOrigem(Data.name);
      setSelected(Data.comp_id);
      setActive(Data.is_active);
    } else {
      setId("");
      setOrigem("");
      setActive(true);
    }
  };

  useEffect(() => {
    handleDataDefault(dataSource);
  }, [dataSource]);

  return (
    <Modal id="Source_Modal" center fixed scroll>
      <ModalHeader
        title="Cadastro de Origem"
        id="Source_Modal_Close"
        onClick={handleDataDefault}
      />
      <ModalBody>
        <Row>
          {error && error.status && error.data.message && (
            <Column col="12" marginBottom="3">
              <div className="alert alert-danger m-3">
                <Text tag="h6" fontWeight="bolder">
                  Erro: {error.status} - {error.data.message}
                </Text>
                <Text fontWeight="bolder"></Text>
                <p>{error.data.errors && JSON.stringify(error.data.errors)}</p>
              </div>
            </Column>
          )}
          <Input value={id} onChange={handleId} maxLength={60} type="hidden" />

          <Column col="12" md="7" marginBottom="3">
            <FormLabel>Unidade</FormLabel>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              defaultValue={selected}
              value={selected}
              isMulti
              options={list}
              onChange={handleUnit}
            />
            {validUnit && <div className="text-danger p-1">{validUnit}</div>}
          </Column>

          <Column col="12" md="5" marginBottom="3">
            <FormLabel>Status</FormLabel>
            <Switch
              id="is_active"
              label={active ? "Ativo" : "Inativo"}
              onClick={handleActive}
              checked={active}
            />
          </Column>

          <Column col="12" marginBottom="3">
            <FormLabel>Nome da Origem</FormLabel>
            <Input
              value={origem}
              onChange={handleOrigem}
              maxLength={60}
              onKeyDown={handleKeyDown}
              className={validOrigem && "is-invalid"}
              onClick={() => setValidOrigem("")}
            />
            {validOrigem && (
              <div className="invalid-feedback">{validOrigem}</div>
            )}
          </Column>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ContainerFlex between width>
          <ButtonModalClose
            bgColor="danger"
            txtColor="white"
            onClick={handleDataDefault}
          >
            Cancelar
          </ButtonModalClose>
          <Button bgColor="success" txtColor="white" onClick={handleConfirm}>
            Concluir
          </Button>
        </ContainerFlex>
      </ModalFooter>
    </Modal>
  );
};

export default SourceModal;
