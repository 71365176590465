import { Column, ContainerFlex, Row } from "../../../Components/Layout";
import { Text } from "../../../Components/Text";
import { ButtonModal } from "../../../Components/Button";
import usePriority from "../../../Hooks/Support/usePriority";

const Item = ({ item }) => {
  const { getPriority } = usePriority();

  const handleEdit = () => {
    getPriority(item.id);
  };

  return (
    <ContainerFlex
      className="card-item accordion-item shadow-sm"
      marginBottom="3"
      padding="2"
    >
      <Row>
        <Column col="12" between>
          <ContainerFlex row middle>
            <ContainerFlex
              style={{ background: item.color, color: item.color }}
              padding="0"
              paddingLeftRight="2"
              margin="0"
              marginRight="2"
              rounded
              tag="small"
            >
              ...
            </ContainerFlex>
            <Text
              tag="h5"
              padding="0"
              margin="0"
              marginTop="1"
              fontWeight="bolder"
            >
              {item.name}
            </Text>
          </ContainerFlex>
          <ContainerFlex middle>
            <ButtonModal
              txtColor="ydental-gray"
              onClick={handleEdit}
              modal="Support_Priority_Modal"
              marginRight="2"
              middle
            >
              <Text tag="i" className="fa fa-pen" />
            </ButtonModal>
          </ContainerFlex>
        </Column>
        <Column col="12">
          <Column col="12">
            <Text tag="small" className="text-muted" marginRight="2">
              Nível de prioridade:
            </Text>
            <Text tag="small" fontWeight="bolder">
              {item.order}
            </Text>
          </Column>
        </Column>
      </Row>
    </ContainerFlex>
  );
};

export default Item;
