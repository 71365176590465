import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import useAuth from "../Hooks/useAuth";

const instance = axios.create({
  baseURL: "/api",
  headers: {
    "content-type": "application/json",
  },
});

// const handleStatus = (response) => {
//   // Respostas de informação (100-199),
//   // Respostas de sucesso (200-299),
//   // Redirecionamentos (300-399),
//   // Erros do cliente (400-499),
//   // Erros do servidor (500-599).

//   if (response >= 100 && response <= 199) {
//   }
//   if (response >= 200 && response <= 299) {
//   }
//   if (response >= 300 && response <= 399) {
//   }
//   if (response >= 400 && response <= 499) {
//     if (response.message) {
//     }
//   }
//   if (response >= 500 && response <= 599) {
//   }
// };

// instance.interceptors.request.use(async (config) => {
//   const { token } = useAuth();

//   if (token) {
//     instance.headers.authorization = `Bearer ${token}`;
//   }

//   return config;
// });

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      localStorage.removeItem("persist:root");
      window.location = "/";

      // handle error: inform user, go to login, etc
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
